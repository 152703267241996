import { HookProfileSide } from "../../../responses/hookSource";
import { EnumPropertyOption, EnumPropertySelector } from "./enumPropertySelector";
import "./inputs.css";

type Props = {
    value: HookProfileSide;
    onChange: (newValue: HookProfileSide) => void;
};

const options: EnumPropertyOption[] = [
    [1, "Inner"],
    [2, "Outer"],
];

export const HookProfileSideSelector = ({ value, onChange }: Props) => {
    return <EnumPropertySelector
        items={options}
        value={value}
        title="Profile side:"
        onChange={newValue => onChange(newValue)} />;
};