import { isRemoveKeyPressed } from "../utils/keyboardEventUtils";
import { ToolInterface } from "./toolInterface";
import eventBus from "../eventBus/eventDispatcher";

export class ViewerKeyboardEventsTransmitterTool extends ToolInterface {
    constructor() {
        super();

        this.names = ["dextall-studio-keyboard-events-transmitter"];
    }

    getPriority(): number {
        return -1;
    }

    handleKeyDown(event: KeyboardEvent): boolean {
        if (isRemoveKeyPressed(event)) {
            setTimeout(() =>
                eventBus.dispatchEvent({ type: "Dextall.Common.RemoveSelectedEntityRequested", payload: null }),
            );
        } else if (
            (event.code === "KeyU" || event.code === "KeyY" || (event.code === "KeyZ" && event.shiftKey)) &&
            event.ctrlKey
        ) {
            eventBus.dispatchEvent({ type: "Dextall.UndoRedo.Redo", payload: null });

            return true;
        } else if (event.code === "KeyZ" && event.ctrlKey) {
            eventBus.dispatchEvent({ type: "Dextall.UndoRedo.Undo", payload: null });

            return true;
        }

        return false;
    }
}
