import { useEffect, useState } from "react";
import { CornerHookType, HookProfileSide, HookType } from "../../../../responses/hookSource";
import { HookProfileSideSelector } from "../../editor-inputs/hookProfileSideSelector";
import { HookTypeSelector } from "../../editor-inputs/hookTypeSelector";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";
import { CornerHook } from "../../panels/cornerHook";
import { Hook } from "../../panels/hook";
import { IModelHook } from "../../panels/modelHook";
import { HookSelectionChangedEventPayload } from "../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type SelectedHookType = "panel-hook" | "corner-hook";

type HookFields = IModelHook & { type: SelectedHookType };

type PanelFields = {
    elementName: string;
    typeName: string;
};

export const HookEditorDockingPanelContent = () => {
    const [hook, setHook] = useState<HookFields>({
        type: "panel-hook",
        cornerHookType: CornerHookType.None,
        hookType: HookType.NotAdjustable,
        dbId: 0,
        panelId: "",
        profileSide: HookProfileSide.Inner,
        x: 0,
        y: 0,
    });

    const [panel, setPanel] = useState<PanelFields>({ elementName: "", typeName: "" });

    useEffect(() => {
        const onHookUpdated = (hookInstance: Hook | CornerHook | undefined) => {
            if (!hookInstance) {
                return;
            }

            const { cornerHookType, hookType, dbId, panelId, profileSide, x, y } = hookInstance;

            setHook({
                type: hookInstance instanceof Hook ? "panel-hook" : "corner-hook",
                cornerHookType,
                hookType,
                dbId,
                panelId,
                profileSide,
                x,
                y,
            });
        };

        const onSelectionChanged = (event: IApplicationEvent<HookSelectionChangedEventPayload | null>) => {
            onHookUpdated(event.payload?.hook);

            if (!event.payload) {
                return;
            }

            const { panel, panelType } = event.payload;

            setPanel({ elementName: panel.elementName, typeName: panelType.name });
        };

        const onHookGeometryUpdated = (event: IApplicationEvent<Hook | CornerHook | undefined>) => onHookUpdated(event.payload);

        eventBus.addEventListener("Dextall.Hooks.SelectionChanged", onSelectionChanged);
        eventBus.addEventListener("Dextall.Hooks.UpdateHookGeometry", onHookGeometryUpdated);

        return () => {
            eventBus.removeEventListener("Dextall.Hooks.SelectionChanged", onSelectionChanged);
            eventBus.removeEventListener("Dextall.Hooks.UpdateHookGeometry", onHookGeometryUpdated);
        };
    }, []);

    const onChange = (updatedState: HookFields) => {
        setHook(updatedState);
        if (updatedState.type === "panel-hook") {
            eventBus.dispatchEvent({
                type: "Dextall.Hooks.UI.PanelHook.ParameterChanged",
                payload: updatedState,
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.Hooks.UI.CornerHook.ParameterChanged",
                payload: updatedState,
            });
        }
    };

    return <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
        <div className="parameters-pane fullheight">
            <HookProfileSideSelector value={hook.profileSide} onChange={value => onChange({ ...hook, profileSide: value })} />
            <HookTypeSelector value={hook.hookType} onChange={value => onChange({ ...hook, hookType: value })} />
            <ImperialAndMetricDimensionEditor value={hook.x} onChange={value => onChange({ ...hook, x: value })} title="Offset X:" />
            <ImperialAndMetricDimensionEditor value={hook.y} onChange={value => onChange({ ...hook, y: value })} title="Offset Y:" />
            <TextPropertyEditor title="Panel type:" value={panel.typeName} onChange={() => { }} disabled={true} />
            <TextPropertyEditor title="Panel name:" value={panel.elementName} onChange={() => { }} disabled={true} />
        </div>
    </div>;
};