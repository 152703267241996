import { CornerHookType } from "../../../responses/hookSource";
import { Corner2DEditorDockingPanel } from "../docking-panels/corner2DEditorDockingPanel";
import { CustomCorner2DEditorDockingPanel } from "../docking-panels/customCorner2DEditorDockingPanel";
import { CustomPanel2DEditorDockingPanel } from "../docking-panels/customPanel2DEditorDockingPanel";
import { CustomZShapedPanel2DEditorDockingPanel } from "../docking-panels/customZShapedPanel2DEditorDockingPanel";
import { Panel2DEditorDockingPanel } from "../docking-panels/panel2DEditorDockingPanel";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { CustomCornersModelEditor } from "../editors/customCornersModelEditor";
import { CustomPanelsModelEditor } from "../editors/customPanelsModelEditor";
import { CustomZShapedPanelsModelEditor } from "../editors/customZShapedPanelsModelEditor";
import { HooksModelEditor } from "../editors/hooksModelEditor";
import { PanelsModelEditor } from "../editors/panelsModelEditor";
import { Corner } from "../panels/corner";
import { CornerHook } from "../panels/cornerHook";
import { CustomCorner } from "../panels/customCorner";
import { CustomPanel } from "../panels/customPanel";
import { CustomZShapedPanel } from "../panels/customZShapedPanel";
import { Hook } from "../panels/hook";
import { Panel } from "../panels/panel";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import { ViewerModelUtils } from "../viewer-utils/viewerModelUtils";
import { RefreshEventPayload } from "../eventBus/hooksDesignerEventPayloads";
import { PanelTypeSwitchedEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgePanel2DEditorExtensionLoadOptions = {
    panelsEditor: PanelsModelEditor;
    cornersEditor: CornersModelEditor;
    hooksEditor: HooksModelEditor;
    customPanelsEditor: CustomPanelsModelEditor;
    customCornersEditor: CustomCornersModelEditor;
    customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
};

export class ForgePanel2DEditorExtension extends Autodesk.Viewing.Extension {
    private readonly panelsEditor: PanelsModelEditor;
    private readonly cornersEditor: CornersModelEditor;
    private readonly hooksEditor: HooksModelEditor;
    private readonly customPanelsEditor: CustomPanelsModelEditor;
    private readonly customCornersEditor: CustomCornersModelEditor;
    private readonly customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
    private panel2DEditor: Panel2DEditorDockingPanel | null = null;
    private corner2DEditor: Corner2DEditorDockingPanel | null = null
    private customPanel2DEditor: CustomPanel2DEditorDockingPanel | null = null;
    private customCorner2DEditor: CustomCorner2DEditorDockingPanel | null = null;
    private customZShapePanel2DEditor: CustomZShapedPanel2DEditorDockingPanel | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgePanel2DEditorExtensionLoadOptions) {
        super(viewer, options);

        this.panelsEditor = options.panelsEditor;
        this.cornersEditor = options.cornersEditor;
        this.hooksEditor = options.hooksEditor;
        this.customPanelsEditor = options.customPanelsEditor;
        this.customCornersEditor = options.customCornersEditor;
        this.customZShapedPanelsModelEditor = options.customZShapedPanelsModelEditor;

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onHookUpdated = this.onHookUpdated.bind(this);
        this.onPanelTypeSwitched = this.onPanelTypeSwitched.bind(this);
        this.onCornerPanelTypeSwitched = this.onCornerPanelTypeSwitched.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    load() {
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.addEventListener("Dextall.Hooks.Designer.ModelHookUpdated", this.onHookUpdated);
        eventBus.addEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.addEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onCornerPanelTypeSwitched);
        eventBus.addEventListener("Dextall.Hooks.Designer.Refresh", this.onRefresh);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.removeEventListener("Dextall.Hooks.Designer.ModelHookUpdated", this.onHookUpdated);
        eventBus.removeEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onCornerPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.Hooks.Designer.Refresh", this.onRefresh);

        this.panel2DEditor?.shutdown();
        this.corner2DEditor?.shutdown();
        this.customPanel2DEditor?.shutdown();
        this.customCorner2DEditor?.shutdown();
        this.customZShapePanel2DEditor?.shutdown();

        this.panel2DEditor = null;
        this.corner2DEditor = null;
        this.customPanel2DEditor = null;
        this.customCorner2DEditor = null;
        this.customZShapePanel2DEditor = null;

        return true;
    }

    onToolbarCreated() {
        const isMetric = ViewerModelUtils.isMetric(this.viewer.model);

        this.panel2DEditor = new Panel2DEditorDockingPanel(this.viewer.container, isMetric);
        this.corner2DEditor = new Corner2DEditorDockingPanel(this.viewer.container, isMetric);
        this.customPanel2DEditor = new CustomPanel2DEditorDockingPanel(this.viewer.container);
        this.customCorner2DEditor = new CustomCorner2DEditorDockingPanel(this.viewer.container);
        this.customZShapePanel2DEditor = new CustomZShapedPanel2DEditorDockingPanel(this.viewer.container);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selectedDbId = event.selections
            .filter(x => x.model.id === this.panelsEditor.modelId)
            .flatMap(x => x.dbIdArray)
            .find(x => x);

        if (selectedDbId === undefined) {
            this.hideEditorPanels();

            return;
        }

        const selectedPanel = this.panelsEditor.findPanel(selectedDbId);

        if (selectedPanel) {
            this.activatePanel2DEditor(selectedPanel, null);

            return;
        }

        const selectedCornerPanel = this.cornersEditor.findCorner(selectedDbId);

        if (selectedCornerPanel) {
            this.activateCorner2DEditor(selectedCornerPanel, null);

            return;
        }

        const selectedCustomPanel = this.customPanelsEditor.findPanel(selectedDbId);

        if (selectedCustomPanel) {
            this.activateCustomPanel2DEditor(selectedCustomPanel);

            return;
        }

        const selectedCustomCorner = this.customCornersEditor.findCustomCorner(selectedDbId);

        if (selectedCustomCorner) {
            this.activateCustomCorner2DEditor(selectedCustomCorner);

            return;
        }

        const selectedCustomZShapedPanel = this.customZShapedPanelsModelEditor.findCustomPanel(selectedDbId);

        if (selectedCustomZShapedPanel) {
            this.activateCustomZShapedPanel2DEditor(selectedCustomZShapedPanel);

            return;
        }

        const selectedHook = this.hooksEditor.findHook(selectedDbId);

        if (!selectedHook) {
            this.hideEditorPanels();

            return;
        }

        this.selectHook(selectedHook);
    }

    private onHookUpdated(event: IApplicationEvent<Hook | CornerHook>) {
        if (!(this.panel2DEditor?.visible || this.corner2DEditor?.visible))
            return;

        this.selectHook(event.payload);
    }

    private onPanelTypeSwitched(event: IApplicationEvent<PanelTypeSwitchedEventPayload>) {
        const panel = this.panelsEditor.findPanelById(event.payload.panelId)!;

        this.activatePanel2DEditor(panel, null);
    }

    private onCornerPanelTypeSwitched(event: IApplicationEvent<PanelTypeSwitchedEventPayload>) {
        const panel = this.cornersEditor.findCornerById(event.payload.panelId)!;

        this.activateCorner2DEditor(panel, null);
    }

    private onRefresh(event: IApplicationEvent<RefreshEventPayload>) {
        const { panelId, type } = event.payload;

        if (type === "panel" && this.panel2DEditor?.visible) {
            const panel = this.panelsEditor.findPanelById(panelId)!;

            this.activatePanel2DEditor(panel, null);
        }

        if (type === "corner" && this.corner2DEditor?.visible) {
            const panel = this.cornersEditor.findCornerById(panelId)!;

            this.activateCorner2DEditor(panel, null, true);
        }
    }

    private selectHook(selectedHook: Hook | CornerHook) {
        if (selectedHook instanceof Hook) {
            const panel = this.panelsEditor.findPanelById(selectedHook.panelId)!;

            this.activatePanel2DEditor(panel, selectedHook);
        } else {
            const corner = this.cornersEditor.findCornerById(selectedHook.panelId)!;

            this.activateCorner2DEditor(corner, selectedHook);
        }
    }

    private hideEditorPanels() {
        this.panel2DEditor?.setVisible(false);
        this.corner2DEditor?.setVisible(false);
        this.customPanel2DEditor?.setVisible(false);
        this.customCorner2DEditor?.setVisible(false);
        this.customZShapePanel2DEditor?.setVisible(false);
    }

    private activatePanel2DEditor(panel: Panel, selectedHook: Hook | null) {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.Panel.SelectionChanged",
            payload: {
                panel,
                hooks: this.hooksEditor.findPanelHooks(panel),
                selectedHook,
                customPanelType: this.panelsEditor.findPanelCustomPanelType(panel.id)
            }
        });

        this.showEditorPanel("panel");
    }

    private activateCorner2DEditor(panel: Corner, selectedHook: CornerHook | null, preserveSide = false) {
        const side = preserveSide
            ? CornerHookType.None
            : selectedHook?.cornerHookType || CornerHookType.CornerLeftWing;

        const leftWingHooks = this.hooksEditor.findCornerPanelHooks(panel, CornerHookType.CornerLeftWing);
        const rightWingHooks = this.hooksEditor.findCornerPanelHooks(panel, CornerHookType.CornerRightWing);

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.Corner.SelectionChanged",
            payload: {
                panel,
                leftWingHooks,
                rightWingHooks,
                side,
                selectedHook,
                customPanelType: this.cornersEditor.findCornerCustomPanelType(panel.id)
            }
        });

        this.showEditorPanel("corner");
    }

    private activateCustomPanel2DEditor(panel: CustomPanel) {
        const customPanelType = this.customPanelsEditor.findCustomPanelType(panel.customPanelTypeId)!;

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.CustomPanel.SelectionChanged",
            payload: {
                id: panel.id,
                customTypeId: panel.customPanelTypeId,
                modelBubbleUrl: customPanelType.bubbleUrl!
            }
        });

        this.showEditorPanel("custom-panel");
    }

    private activateCustomCorner2DEditor(panel: CustomCorner) {
        const customCornerType = this.customCornersEditor.findCustomCornerType(panel.customPanelTypeId)!;

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.CustomCorner.SelectionChanged",
            payload: {
                id: panel.id,
                customTypeId: panel.customPanelTypeId,
                modelBubbleUrl: customCornerType.bubbleUrl!
            }
        });

        this.showEditorPanel("custom-corner");
    }

    private activateCustomZShapedPanel2DEditor(panel: CustomZShapedPanel) {
        const customPanelType = this.customZShapedPanelsModelEditor.findCustomPanelType(panel.customPanelTypeId)!;

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.CustomZShapedPanel.SelectionChanged",
            payload: {
                id: panel.id,
                customTypeId: panel.customPanelTypeId,
                modelBubbleUrl: customPanelType.bubbleUrl!
            }
        });

        this.showEditorPanel("custom-z-panel");
    }

    private showEditorPanel(type: "panel" | "corner" | "custom-panel" | "custom-corner" | "custom-z-panel") {
        this.panel2DEditor?.setVisible(type === "panel");

        this.corner2DEditor?.setVisible(type === "corner");

        this.customPanel2DEditor?.setVisible(type === "custom-panel");

        this.customCorner2DEditor?.setVisible(type === "custom-corner");

        this.customZShapePanel2DEditor?.setVisible(type === "custom-z-panel");
    }
}

export const forgePanel2DEditorExtensionName = "Dextall.ForgePanel2DEditorExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgePanel2DEditorExtensionName, ForgePanel2DEditorExtension);
