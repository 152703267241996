import { useEffect, useRef, useState } from "react";
import { ICustomPanelType } from "../../../../responses/customPanelTypes";
import { IPanelGeneratedDrawingDto } from "../../../../responses/panelGeneratedDrawingDto";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../../responses/panelGeneratedModelDto";
import { Panel } from "../../panels/panel";
import { CladdingCell, PanelEditor2D, getCladdingCellMaterial } from "./2d/panelEditor2D";
import { Editor2DMenu, Tab, TabsVisibility } from "./editor2DMenu";
import { GeneratedPanelDrawings } from "./generatedPanelDrawings";
import { GeneratedPanelModel } from "./generatedPanelModel";
import { GeneratedPanelModelLoadedPayload } from "../../eventBus/generatedModelsEventPayloads";
import { ActivatePanelHooksDesignerEventPayload } from "../../eventBus/hooksDesignerEventPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";
import "./editor2DContent.css";

type Props = {
    isMetric: boolean;
};

export const Panel2DEditorDockingPanelContent = ({ isMetric }: Props) => {
    const [panel, setPanel] = useState<ActivatePanelHooksDesignerEventPayload>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [model, setModel] = useState<Omit<IPanelGeneratedModelDto, "typeId">>();
    const [customModelBubbleUrl, setCustomModelBubbleUrl] = useState<string | null>();
    const [is2DHookPlacementActive, setIs2DHookPlacementActive] = useState(false);
    const [selectedTab, setSelectedTab] = useState<Tab>("editor");
    const [drawings, setDrawings] = useState<IPanelGeneratedDrawingDto[]>([]);
    const [selectedDrawingIndex, setSelectedDrawingIndex] = useState(0);
    const panelId = useRef<string>();
    const panelTypeId = useRef<string>();
    const customPanelType = useRef<ICustomPanelType>();

    useEffect(() => {
        const onPanelSelected = (event: IApplicationEvent<ActivatePanelHooksDesignerEventPayload>) => {
            setPanel(event.payload);

            const {
                id: selectedPanelId,
                panelTypeId: selectedPanelTypeId,
                customPanelTypeId: selectedCustomPanelTypeId } = event.payload.panel;

            const selectedCustomPanelType = event.payload.customPanelType;

            if (panelId.current !== selectedPanelId
                || panelTypeId.current !== selectedPanelTypeId
                || customPanelType.current?.id !== selectedCustomPanelTypeId) {
                setIs2DHookPlacementActive(false);
                setIsLoading(true);
                setSelectedDrawingIndex(0);
            }

            if (selectedCustomPanelType) {
                setSelectedTab("model");
            } else if (panelTypeId.current !== selectedPanelTypeId) {
                setSelectedTab("editor");
            }

            panelId.current = selectedPanelId;
            panelTypeId.current = selectedPanelTypeId;
            customPanelType.current = selectedCustomPanelType;

            setCustomModelBubbleUrl(selectedCustomPanelType?.bubbleUrl);
        };

        const onLoaded = (event: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => {
            if (panelTypeId.current !== event.payload.panelTypeId) {
                return;
            }

            setIsLoading(false);
            setModel(event.payload.model);
            setDrawings(event.payload.drawings);
        };

        eventBus.addEventListener("Dextall.Hooks.Designer.Panel.SelectionChanged", onPanelSelected);
        eventBus.addEventListener("Dextall.Panels.GeneratedModel.Loaded", onLoaded);
        eventBus.addEventListener("Dextall.Panels.GeneratedModel.Generated", onLoaded);

        return () => {
            eventBus.removeEventListener("Dextall.Hooks.Designer.Panel.SelectionChanged", onPanelSelected);
            eventBus.removeEventListener("Dextall.Panels.GeneratedModel.Loaded", onLoaded);
            eventBus.removeEventListener("Dextall.Panels.GeneratedModel.Generated", onLoaded);
        };
    }, []);

    if (!panel) {
        return (null);
    }

    const onHookPlacementRequested = (position: THREE.Vector2) => {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.CreatePanelHookRequested",
            payload: { panelId: panel.panel.id, position },
        });
    };

    const tabsVisibility: TabsVisibility = {
        model: true,
        editor: !customModelBubbleUrl,
        drawings: !customModelBubbleUrl,
    };

    return <div className="editor-2d-container">
        <Editor2DMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabVisibility={tabsVisibility} />
        <div className="editor-2d-content">
            {selectedTab === "editor" && <PanelEditor2D
                length={panel.panel.length}
                height={panel.panel.height}
                cells={getCladdingCells(panel.panel)}
                hooks={panel.hooks}
                selectedHook={panel.selectedHook}
                isMetric={isMetric}
                is2DHookPlacementActive={is2DHookPlacementActive}
                onHookPlacementRequested={onHookPlacementRequested}
                setIs2DHookPlacementActive={setIs2DHookPlacementActive} />}
            {selectedTab === "model" && !customModelBubbleUrl &&
                <GeneratedPanelModel
                    model={model}
                    isLoading={isLoading}
                    contentType="panel"
                    panelTypeId={panel.panel.panelTypeId} />}
            {selectedTab === "model" && customModelBubbleUrl &&
                <GeneratedPanelModel
                    model={{
                        bubble: customModelBubbleUrl,
                        id: panel.panel.customPanelTypeId!,
                        status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully,
                    }}
                    contentType="custom-panel"
                    isLoading={false}
                    panelTypeId={panel.panel.customPanelTypeId!} />}
            {selectedTab === "drawing" && <GeneratedPanelDrawings
                model={model}
                drawings={drawings}
                isLoading={isLoading}
                selectedDrawingIndex={selectedDrawingIndex}
                setSelectedDrawingIndex={setSelectedDrawingIndex}
                panelTypeId={panel.panel.panelTypeId}
                isPanelModel={true} />}
        </div>
    </div>;
};

const getCladdingCells = (panel: Panel): CladdingCell[] => {
    return panel.claddingCells.map(x => {
        return {
            box: new THREE.Box3(
                new THREE.Vector3().subVectors(x.box.min, panel.box.min),
                new THREE.Vector3().subVectors(x.box.max, panel.box.min),
            ),
            color: x.color,
            material: getCladdingCellMaterial(x.style, x.sheetMetalThickness, x.depth),
            titleColor: x.titleColor,
        };
    });
};