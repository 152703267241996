import { UpdateCustomPanelOffsetCommand } from "../../../commands/updateCustomPanelOffsetCommand";
import { BasicItemResponse } from "../../../responses/basicResponses";
import { CustomPanelType, CustomPanelTypeShape } from "../../../responses/customPanelTypes";
import { CustomPanelDockingPanel } from "../docking-panels/customPanelDockingPanel";
import { CustomPanelsModelEditor } from "../editors/customPanelsModelEditor";
import { SnapGrid } from "../geometry/snapGrid";
import { CustomPanel } from "../panels/customPanel";
import { WallFacesCollection } from "../panels/wallFacesCollection";
import { customComponentsToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { CustomPanelPlacementTool } from "../viewer-tools/customPanelPlacementTool";
import { CustomWallPanelOffsetTool } from "../viewer-tools/customWallPanelOffsetTool";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import {
    ChangeCustomPanelOffsetEventPayload,
    CustomPanelGizmoDraggingCompletedEventPayload,
    CustomPanelGizmoDraggingPayload,
    CustomPanelPlacementRequestEventPayload,
} from "../eventBus/customPanelsEventPayloads";
import { ElementParameterChangedEventPayload } from "../eventBus/elementParameterChangedEventPayload";
import { SwitchPanelTypeEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgeCustomPanelsEditorExtensionLoadOptions = {
    customPanelsEditor: CustomPanelsModelEditor;
    wallFacesCollection: WallFacesCollection;
};

export class ForgeCustomPanelsEditorExtension extends Autodesk.Viewing.Extension {
    private readonly customPanelsEditor: CustomPanelsModelEditor;
    private readonly customPanelOffsetGizmo = new CustomWallPanelOffsetTool();
    private readonly customPanelPlacementTool: CustomPanelPlacementTool;
    private editorPanel: CustomPanelDockingPanel | null = null;
    private toggleCustomPanelsPlacementButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeCustomPanelsEditorExtensionLoadOptions) {
        super(viewer, options);

        this.customPanelsEditor = options.customPanelsEditor;

        this.customPanelPlacementTool = new CustomPanelPlacementTool(options.wallFacesCollection, new SnapGrid(viewer.model));

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSwitchPanelType = this.onSwitchPanelType.bind(this);
        this.onChangeElementName = this.onChangeElementName.bind(this);
        this.onChangeOffset = this.onChangeOffset.bind(this);
        this.onOffsetChangingByGizmo = this.onOffsetChangingByGizmo.bind(this);
        this.onOffsetChangingByGizmoCompleted = this.onOffsetChangingByGizmoCompleted.bind(this);
        this.onToggleCustomPanelPlacement = this.onToggleCustomPanelPlacement.bind(this);
        this.onSelectCustomPanelToPlace = this.onSelectCustomPanelToPlace.bind(this);
        this.onCustomPanelCreateRequest = this.onCustomPanelCreateRequest.bind(this);
        this.onEntityRemoveRequested = this.onEntityRemoveRequested.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.customPanelsEditor.createPanels();

        this.viewer.toolController.registerTool(this.customPanelOffsetGizmo);
        this.viewer.toolController.activateTool(this.customPanelOffsetGizmo.getName());

        this.viewer.toolController.registerTool(this.customPanelPlacementTool);

        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.addEventListener("Dextall.CustomPanels.UI.SwitchType", this.onSwitchPanelType);
        eventBus.addEventListener("Dextall.CustomPanels.UI.SetElementName", this.onChangeElementName);
        eventBus.addEventListener("Dextall.CustomPanels.UI.SetOffset", this.onChangeOffset);
        eventBus.addEventListener("Dextall.CustomPanels.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.addEventListener("Dextall.CustomPanels.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.addEventListener("Dextall.CustomPanels.PromptPanelPlacement", this.onSelectCustomPanelToPlace);
        eventBus.addEventListener("Dextall.CustomPanels.CreateNew", this.onCustomPanelCreateRequest);
        eventBus.addEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        this.viewer.toolController.deactivateTool(this.customPanelOffsetGizmo.getName());
        this.viewer.toolController.deregisterTool(this.customPanelOffsetGizmo.getName());

        this.viewer.toolController.deregisterTool(this.customPanelPlacementTool);

        eventBus.removeEventListener("Dextall.CustomPanels.UI.SwitchType", this.onSwitchPanelType);
        eventBus.removeEventListener("Dextall.CustomPanels.UI.SetElementName", this.onChangeElementName);
        eventBus.removeEventListener("Dextall.CustomPanels.UI.SetOffset", this.onChangeOffset);
        eventBus.removeEventListener("Dextall.CustomPanels.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.removeEventListener("Dextall.CustomPanels.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.removeEventListener("Dextall.CustomPanels.PromptPanelPlacement", this.onSelectCustomPanelToPlace);
        eventBus.removeEventListener("Dextall.CustomPanels.CreateNew", this.onCustomPanelCreateRequest);
        eventBus.removeEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        this.editorPanel?.shutdown();

        this.toggleCustomPanelsPlacementButton?.removeEventListener("click", this.onToggleCustomPanelPlacement);
        this.toggleCustomPanelsPlacementButton = null;

        return true;
    }

    onToolbarCreated() {
        this.editorPanel = new CustomPanelDockingPanel(this.viewer.container);

        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(customComponentsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(customComponentsToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleCustomPanelsPlacementButton = new Autodesk.Viewing.UI.Button("dextall-place-custom-panel-button");
        this.toggleCustomPanelsPlacementButton.setToolTip("Add custom panel");
        this.toggleCustomPanelsPlacementButton.setIcon("viewer-wall-editor-button");
        this.toggleCustomPanelsPlacementButton.addEventListener("click", this.onToggleCustomPanelPlacement);

        group.addControl(this.toggleCustomPanelsPlacementButton);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selection = event.selections.find((x: { model: { id: number; }; }) => x.model.id === this.customPanelsEditor.model.id);

        const elementDbId = selection?.dbIdArray[0];

        this.raiseSelectionChanged(elementDbId);
    }

    private async switchPanelType(panelId: string, targetTypeId: string, panelDbId?: number) {
        const result = await this.customPanelsEditor.switchPanelType(panelId, targetTypeId);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        this.raiseSelectionChanged(panelDbId);

        return result;
    }

    private async onSwitchPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;
        const panel = this.customPanelsEditor.findPanelById(panelId);
        let savedPanelType = panel?.customPanelTypeId;
        const savedInternalId = panel?.internalId;

        const undoRedo = async () => {
            if (savedPanelType && savedInternalId) {
                const panel = this.customPanelsEditor.findPanelByInternalId(savedInternalId);
                const temp = panel?.customPanelTypeId;

                await this.switchPanelType(panel?.id || panelId, savedPanelType, panel?.panelDbId);

                savedPanelType = temp;
            }
        };

        const result = await this.switchPanelType(panelId, targetTypeId, panel?.panelDbId);

        if (result.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Switch panel type", undo: undoRedo, redo: undoRedo },
                },
            });
        }
    }

    private async renameElement(panelId: string, elementName: string) {
        const result = await this.customPanelsEditor.setElementName(panelId, elementName);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        const panel = this.customPanelsEditor.findPanelById(panelId);

        this.raiseSelectionChanged(panel?.panelDbId);
    }

    private async onChangeElementName(event: IApplicationEvent<ElementParameterChangedEventPayload>) {
        const { id, elementName } = event.payload;
        const panel = this.customPanelsEditor.findPanelById(id);
        let savedElementName = panel?.elementName ?? "";
        const savedInternalId = panel?.internalId;

        const undoRedo = async () => {
            const panel = this.customPanelsEditor.findPanelByInternalId(savedInternalId || id);
            const temp = panel?.elementName ?? "";

            await this.renameElement(panel?.id || id, savedElementName);

            savedElementName = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Rename element", undo: undoRedo, redo: undoRedo },
            },
        });

        await this.renameElement(id, elementName);
    }

    private onOffsetChangingByGizmo(event: IApplicationEvent<CustomPanelGizmoDraggingPayload>) {
        const { id, offset } = event.payload;

        const result = this.customPanelsEditor.setOffsetLocal(id, offset);

        if (!result.isSuccess)
            this.notifyError(result.message);

        const panel = this.customPanelsEditor.findPanelById(id);

        this.raiseSelectionChanged(panel?.panelDbId, true);
    }

    private async moveCustomPanel(
        panelId: string,
        targetOffset: UpdateCustomPanelOffsetCommand,
        initialOffset?: UpdateCustomPanelOffsetCommand,
    ) {
        const panel = this.customPanelsEditor.findPanelById(panelId);
        const savedInternalId = panel?.internalId;
        let savedOffset: UpdateCustomPanelOffsetCommand = initialOffset
            ? structuredClone(initialOffset)
            : {
                offsetX: panel?.offsetX ?? targetOffset.offsetX,
                offsetY: panel?.offsetY ?? targetOffset.offsetY,
            };

        const undoRedo = async () => {
            const panel = this.customPanelsEditor.findPanelByInternalId(savedInternalId || panelId);
            const temp: UpdateCustomPanelOffsetCommand = {
                offsetX: panel?.offsetX ?? targetOffset.offsetX,
                offsetY: panel?.offsetY ?? targetOffset.offsetY,
            };

            await this.customPanelsEditor.setOffset(savedInternalId || panelId, savedOffset);

            savedOffset = temp;
            this.raiseSelectionChanged(panel?.panelDbId);
        };

        const result = await this.customPanelsEditor.setOffset(panelId, targetOffset);

        if (!result.isSuccess) {
            this.notifyError(result.message);

            this.customPanelsEditor.setOffsetLocal(panelId, savedOffset);
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Move custom panel", undo: undoRedo, redo: undoRedo },
                },
            });
        }

        this.raiseSelectionChanged(panel?.panelDbId);
    }

    private async onOffsetChangingByGizmoCompleted(
        event: IApplicationEvent<CustomPanelGizmoDraggingCompletedEventPayload>,
    ) {
        await this.moveCustomPanel(event.payload.id, event.payload.targetOffset, event.payload.initialOffset);
    }

    private async onChangeOffset(event: IApplicationEvent<ChangeCustomPanelOffsetEventPayload>) {
        await this.moveCustomPanel(event.payload.id, event.payload);
    }

    private async createCustomPanel(
        panelData: CustomPanelPlacementRequestEventPayload,
    ): Promise<BasicItemResponse<CustomPanel>> {
        const result = await this.customPanelsEditor.createNewCustomPanel(panelData);

        eventBus.dispatchEvent({ type: "Dextall.CustomPanels.Created", payload: null });

        if (!result.isSuccess) {
            this.notifyError(result.message);

            return result;
        }

        if (panelData.single) {
            this.viewer.toolController.deactivateTool(this.customPanelPlacementTool.getName());

            this.viewer.select(result.item.panelDbId, this.customPanelsEditor.model);
        }

        return result;
    }

    private async onCustomPanelCreateRequest(event: IApplicationEvent<CustomPanelPlacementRequestEventPayload>) {
        const undo = () => {
            if (savedInternalId) {
                const panel = this.customPanelsEditor.findPanelByInternalId(savedInternalId);
                if (panel) {
                    this.removeCustomPanel(panel);
                }
            }
        };

        const redo = async () => {
            await this.createCustomPanel({ ...event.payload, internalId: savedInternalId });
        };


        const result = await this.createCustomPanel(event.payload);
        const savedInternalId = result.item?.internalId;

        if (result.item) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Add custom panel", undo, redo },
                },
            });
        }
    }

    private async removeCustomPanel(panel: CustomPanel) {
        this.viewer.select([]);

        const result = await this.customPanelsEditor.removePanel(panel);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }
    }

    private async removeCustomPanelWithUndo(panel: CustomPanel) {
        const savedInternalId = panel.internalId;
        const panelData: CustomPanelPlacementRequestEventPayload = {
            wallFaceId: panel.wallFaceId,
            customPanelTypeId: panel.customPanelTypeId,
            offsetX: panel.offsetX,
            offsetY: panel.offsetY,
            single: true,
        };

        const undo = async () => {
            await this.createCustomPanel({ ...panelData, internalId: savedInternalId });
        };

        const redo = () => {
            if (savedInternalId) {
                const panel = this.customPanelsEditor.findPanelByInternalId(savedInternalId);
                if (panel) {
                    this.removeCustomPanel(panel);
                }
            }
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Remove custom panel", undo, redo },
            },
        });
        await this.removeCustomPanel(panel);
    }

    private async onEntityRemoveRequested() {
        const selectedDbId = this.viewer
            .getAggregateSelection()
            .find(x => x.model.id === this.customPanelsEditor.model.id)?.selection[0];

        const panel = selectedDbId !== undefined ? this.customPanelsEditor.findPanel(selectedDbId) : undefined;

        if (!panel) {
            return;
        }

        await this.removeCustomPanelWithUndo(panel);
    }

    private onToggleCustomPanelPlacement() {
        if (!this.isCustomPanelPlacementActive())
            eventBus.dispatchEvent({ type: "Dextall.CustomPanels.OpenLibrarySelector", payload: null });
        else
            this.viewer.toolController.deactivateTool(this.customPanelPlacementTool.getName());
    }

    private onSelectCustomPanelToPlace(event: IApplicationEvent<CustomPanelType>) {
        const panelType = event.payload;

        if (panelType.shapeType !== CustomPanelTypeShape.Panel)
            return;

        this.customPanelPlacementTool.setCustomPanelType(panelType);

        this.viewer.toolController.activateTool(this.customPanelPlacementTool.getName());
    }

    private onViewToolChanged(event: { toolName: string; active: boolean }) {
        if (event.toolName !== this.customPanelPlacementTool.getName())
            return;

        const newState = event.active
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleCustomPanelsPlacementButton?.setState(newState);
    }

    private onEscape() {
        const toolName = this.customPanelPlacementTool.getName();

        const toolController = this.viewer.toolController;

        if (toolController.isToolActivated(toolName))
            toolController.deactivateTool(toolName);
    }

    private isCustomPanelPlacementActive() {
        return this.viewer.toolController.isToolActivated(this.customPanelPlacementTool.getName());
    }

    private raiseSelectionChanged(elementDbId: number | undefined, skipGizmoUpdate = false) {
        const panel = elementDbId !== undefined ? this.customPanelsEditor.findPanel(elementDbId) : undefined;

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.SelectionChanged",
            payload: panel ? {
                panel,
                customPanelTypes: this.customPanelsEditor.findValidCustomPanelTypesForCustomPanel(panel.id)
            } : null
        });

        this.editorPanel?.setVisible(panel !== undefined);

        if (skipGizmoUpdate)
            return;

        if (panel)
            this.customPanelOffsetGizmo.setPanel(panel);
        else
            this.customPanelOffsetGizmo.disposeGizmo();
    }

    private notifyError(message: string) {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message
        })
    }
}

export const forgeCustomPanelsEditorExtensionName = "Dextall.ForgeCustomPanelsEditorExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeCustomPanelsEditorExtensionName,
    ForgeCustomPanelsEditorExtension,
);
