import { forgeCornersEditorName, ForgeCornersEditorLoadOptions } from "./forge/extensions/forgeCornersEditor";
import {
    forgeCustomCornersEditorExtensionName,
    ForgeCustomCornersEditorExtensionLoadOptions,
} from "./forge/extensions/forgeCustomCornersEditorExtension";
import {
    forgeCustomPanelsEditorExtensionName,
    ForgeCustomPanelsEditorExtensionLoadOptions,
} from "./forge/extensions/forgeCustomPanelsEditorExtension";
import {
    forgeCustomZShapedPanelsExtensionName,
    ForgeCustomZShapedPanelsEditorExtensionLoadOptions,
} from "./forge/extensions/forgeCustomZShapedPanelsEditorExtension";
import { forgeHooksAlignmentName, ForgeHooksAlignmentLoadOptions } from "./forge/extensions/forgeHooksAlignment";
import {
    forgeHooksDefaultOffsetName,
    ForgeHooksDefaultOffsetLoadOptions,
} from "./forge/extensions/forgeHooksDefaultOffset";
import { forgeHooksEditorName, ForgeHooksEditorLoadOptions } from "./forge/extensions/forgeHooksEditor";
import {
    forgeModelsGenerationListName,
    ForgeModelsGenerationListLoadOptions,
} from "./forge/extensions/forgeModelsGenerationList";
import {
    forgePanel2DEditorExtensionName,
    ForgePanel2DEditorExtensionLoadOptions,
} from "./forge/extensions/forgePanel2DEditorExtension";
import { forgePanelsEditorName, ForgePanelsEditorLoadOptions } from "./forge/extensions/forgePanelsEditor";
import {
    forgeRevitModelsGeneratorName,
    ForgeHooksModelGeneratorLoadOptions,
} from "./forge/extensions/forgeRevitModeslGeneratorExtension";
import {
    forgeSearchElementsByIdExtensionName,
    ForgeSearchElementsByIdExtensionLoadOptions,
} from "./forge/extensions/forgeSearchElementsByIdExtension";
import {
    forgeStickyNotesQRCodesExtensionName,
    ForgeStickyNotesQRCodesExtensionLoadOptions,
} from "./forge/extensions/forgeStickyNotesQRCodesExtension";
import {
    forgeUndoRedoExtensionName,
    ForgeUndoRedoExtensionLoadOptions,
} from "./forge/extensions/forgeUndoRedoExtension";
import { forgeWallsExtensionName, ForgeWallsExtensionLoadOptions } from "./forge/extensions/forgeWallsExtension";

type ViewerExtension<TName extends string, TLoadOptions extends object> = { id: TName; loadOptions: TLoadOptions };

export type LoadingExtension =
    | ViewerExtension<typeof forgeWallsExtensionName, ForgeWallsExtensionLoadOptions>
    | ViewerExtension<typeof forgePanelsEditorName, ForgePanelsEditorLoadOptions>
    | ViewerExtension<typeof forgeCustomPanelsEditorExtensionName, ForgeCustomPanelsEditorExtensionLoadOptions>
    | ViewerExtension<typeof forgeCornersEditorName, ForgeCornersEditorLoadOptions>
    | ViewerExtension<typeof forgeCustomCornersEditorExtensionName, ForgeCustomCornersEditorExtensionLoadOptions>
    | ViewerExtension<typeof forgeCustomZShapedPanelsExtensionName, ForgeCustomZShapedPanelsEditorExtensionLoadOptions>
    | ViewerExtension<typeof forgeHooksEditorName, ForgeHooksEditorLoadOptions>
    | ViewerExtension<typeof forgeHooksDefaultOffsetName, ForgeHooksDefaultOffsetLoadOptions>
    | ViewerExtension<typeof forgeHooksAlignmentName, ForgeHooksAlignmentLoadOptions>
    | ViewerExtension<typeof forgeSearchElementsByIdExtensionName, ForgeSearchElementsByIdExtensionLoadOptions>
    | ViewerExtension<typeof forgePanel2DEditorExtensionName, ForgePanel2DEditorExtensionLoadOptions>
    | ViewerExtension<typeof forgeRevitModelsGeneratorName, ForgeHooksModelGeneratorLoadOptions>
    | ViewerExtension<typeof forgeStickyNotesQRCodesExtensionName, ForgeStickyNotesQRCodesExtensionLoadOptions>
    | ViewerExtension<typeof forgeModelsGenerationListName, ForgeModelsGenerationListLoadOptions>
    | ViewerExtension<typeof forgeUndoRedoExtensionName, ForgeUndoRedoExtensionLoadOptions>;

export const loadViewerExtensions = async (viewer: Autodesk.Viewing.GuiViewer3D, extensions: LoadingExtension[]) => {
    for (const extension of extensions) {
        await viewer.loadExtension(extension.id, extension.loadOptions);
    }
};
