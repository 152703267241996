import Button from "@weave-design/button";
import { Download24 } from "@weave-design/icons";
import { ApplicationType } from "../../../../PanelModelApp";
import { GenerateNewPanelContent } from "./generateNewPanelContent";
import eventBus from "../../eventBus/eventDispatcher";

type Props = {
    contentType: ApplicationType;
    id: string;
    modelId: string;
    panelTypeId: string;
};

export const GeneratedModelFrame = ({ id, contentType, panelTypeId, modelId }: Props) => {
    const url = `/panel-model/${id}?type=${contentType}&r=${Date.now().toString()}${window.location.hash}`;

    return <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", marginTop: 16 }}>
        <iframe src={url} style={{ width: "100%", height: "calc(100% - 30px)", border: 0 }} />
        <div style={{ marginLeft: "auto", marginRight: 20, marginTop: 15, display: "flex", flexDirection: "row" }}>
            <div style={{ marginTop: 17, marginBottom: 17 }}>
                <Button title="Download" icon={<Download24 />} onClick={() => eventBus.dispatchEvent({ type: "Dextall.GeneratedModel.Download", payload: modelId })} />
            </div>
            {contentType !== "custom-panel" && contentType !== "custom-corner" && contentType !== "custom-z-panel" &&
                <GenerateNewPanelContent panelTypeId={panelTypeId} isPanelModel={contentType === "panel" || contentType === "panel-drawing"} isRegenerate={true} />}
        </div>
    </div>;
};