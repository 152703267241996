import { ApplicationType } from "../../../../PanelModelApp";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../../responses/panelGeneratedModelDto";
import { ProcessingIndicator } from "../../../processing-pane/processingIndicator";
import { GeneratedModelFrame } from "./generatedModelFrame";
import { GeneratedPanelFailed } from "./generatedPanelFailed";
import { GenerateNewPanelContent } from "./generateNewPanelContent";
import "./editor2DContent.css";

type Props = {
    isLoading: boolean;
    model?: Omit<IPanelGeneratedModelDto, "typeId">;
    contentType: Exclude<ApplicationType, "panel-drawing" | "corner-drawing">;
    panelTypeId: string;
};

export const GeneratedPanelModel = ({ isLoading, model, contentType, panelTypeId }: Props) => {

    if (isLoading) {
        return <div className="editor-processing-indicator-container"><ProcessingIndicator /></div>;
    }

    switch (model?.status) {
        case PanelTypeGenerationStatus.None:
            return <GenerateNewPanelContent isPanelModel={contentType === "panel"} panelTypeId={panelTypeId} />;

        case PanelTypeGenerationStatus.ModelGenerationInProgress:
            return <div className="editor-processing-indicator-container">
                <ProcessingIndicator message="Generating panel model..." />
            </div>;

        case PanelTypeGenerationStatus.ModelGeneratedSuccessfully:
            return <GeneratedModelFrame id={model.id} contentType={contentType} modelId={model.id} panelTypeId={panelTypeId} />;

        default:
            return <GeneratedPanelFailed isPanelModel={contentType === "panel"} panelTypeId={panelTypeId} />;
    }
};