import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Button from "@weave-design/button";
import Input from "@weave-design/input";
import Label from "@weave-design/label";
import { ProcessingFailure } from "../../../processing-failure/processingFailure";
import { rootElement } from "../../../shared";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

export const FindObjectByIdPanelContent = () => {
    const [idToSearch, _setIdToSearch] = useState("");
    const [isProcessingFailureDialogVisible, setIsProcessingFailureDialogVisible] = useState<boolean>(false);
    const idInputRef = useRef<HTMLInputElement>();
    const idToSearchRef = useRef("");

    const setIdToSearch = (newValue: string) => {
        _setIdToSearch(newValue);
        idToSearchRef.current = newValue;
    };

    useEffect(() => {
        const onElementSearchFailed = () => {
            setIsProcessingFailureDialogVisible(true);

            idInputRef.current?.blur();
        };

        const onVisibilityChanged = (eventData: IApplicationEvent<boolean>) => {
            setIsProcessingFailureDialogVisible(false);

            if (eventData.payload) {
                idInputRef.current?.focus();
            }
        };

        const onSearchBoxKeypress = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                eventBus.dispatchEvent({
                    type: "Dextall.SearchByIdTool.VisibilityChanged",
                    payload: false,
                });
            }

            if (ev.key !== "Enter" || idToSearchRef.current.trim() === "") {
                return;
            }

            eventBus.dispatchEvent({ type: "Dextall.SearchByIdTool.SearchRequested", payload: idToSearchRef.current });
        };

        eventBus.addEventListener("Dextall.SearchByIdTool.SearchFailed", onElementSearchFailed);
        eventBus.addEventListener("Dextall.SearchByIdTool.VisibilityChanged", onVisibilityChanged);

        idInputRef.current!.addEventListener("keydown", onSearchBoxKeypress);

        return () => {
            eventBus.removeEventListener("Dextall.SearchByIdTool.SearchFailed", onElementSearchFailed);
            eventBus.removeEventListener("Dextall.SearchByIdTool.VisibilityChanged", onVisibilityChanged);
            idInputRef.current?.removeEventListener("keypress", onSearchBoxKeypress);
        };
    }, []);

    const onCloseProcessingFailure = () => {
        setIsProcessingFailureDialogVisible(false);
        idInputRef.current?.focus();
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight" style={{ overflowY: "scroll" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "auto", marginBottom: "auto", width: "25%" }}>
                        <Label>Object Id:</Label>
                    </div>
                    <div style={{ display: "flex", width: "75%" }}>
                        <Input
                            inputRef={(x: any) => (idInputRef.current = x)}
                            value={idToSearch}
                            onChange={(data: any) => setIdToSearch(data.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "18px",
                    flexFlow: "column",
                }}
            >
                <div style={{ alignSelf: "flex-end", flexFlow: "row" }}>
                    <Button
                        style={{ width: "100px" }}
                        type="primary"
                        size="standard"
                        title="Select"
                        width="grow"
                        disabled={idToSearch.trim() === ""}
                        onClick={() =>
                            eventBus.dispatchEvent({
                                type: "Dextall.SearchByIdTool.SearchRequested",
                                payload: idToSearch,
                            })
                        }
                    />
                </div>
            </div>
            {ReactDOM.createPortal(
                <ProcessingFailure
                    isVisible={isProcessingFailureDialogVisible}
                    closeProcessingErrorDialog={() => onCloseProcessingFailure()}
                    title="Failed to find element by id"
                    message={`The following element ID is invalid: ${idToSearch}`}
                />,
                rootElement,
            )}
        </div>
    );
};
