import { Error16 } from "@weave-design/icons";
import { GenerateNewRevitHooksModelControls } from "./generateNewRevitHooksModelControls";
import "../../../../../processing-failure/modalFail.css";

export const GeneratedHooksModelFailed = () => {
    return <>
        <div className="generated-revit-model-content-button-container">
            <Error16 className="errorIcon" />
        </div>

        <div className="generated-revit-model-content-button-container">
            <span style={{ color: "red" }}>Generation failed</span>
        </div>

        <GenerateNewRevitHooksModelControls isRegenerate={true} />
    </>;
};