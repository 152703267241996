import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CustomPanelType, CustomPanelTypeShape, ICustomPanelType } from "../../../../responses/customPanelTypes";
import { ComponentsLibraryDialog } from "../../../custom-components-library/componentsLibraryDialog";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { rootElement, noop } from "../../../shared";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import { ObjectUserUniqueId } from "../../editor-inputs/objectUserUniqueId";
import { PanelTypeSelector } from "../../editor-inputs/panelTypeSelector";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";
import { getCustomPanelUserUniqueIdObjectPrefix } from "../../ids/userObjectIds";
import { PanelStickyNote } from "./panelStickyNote";
import { ChangeCustomPanelOffsetEventPayload } from "../../eventBus/customPanelsEventPayloads";
import { CustomPanelSelectionChangedEventPayload } from "../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type CustomPanelFields = {
    id: string;
    userUniqueId: number;
    customPanelTypeId: string;
    customPanelTypeName: string;
    elementName: string;
    length: number;
    height: number;
    offsetX: number;
    offsetY: number;
    customPanelTypes: ICustomPanelType[];
};

type AsyncOperation = {
    title: string;
    message: string;
};

export const CustomPanelEditorDockingPanelContent = () => {
    const [panel, setPanel] = useState<CustomPanelFields>({
        id: "",
        userUniqueId: 0,
        customPanelTypeId: "",
        customPanelTypeName: "",
        elementName: "",
        length: 0,
        height: 0,
        offsetX: 0,
        offsetY: 0,
        customPanelTypes: [],
    });
    const [customComponentLibraryOpened, setCustomComponentLibraryOpened] = useState(false);
    const [asyncOperation, setAsyncOperation] = useState<AsyncOperation>();

    useEffect(() => {
        const onSelectionChanged = (event: IApplicationEvent<CustomPanelSelectionChangedEventPayload | null>) => {
            setCustomComponentLibraryOpened(false);
            setAsyncOperation(undefined);

            if (!event.payload) {
                return;
            }

            const { panel, customPanelTypes } = event.payload;
            const { id, userUniqueId, customPanelTypeId, elementName, length, height, offsetX, offsetY } = panel;
            const customPanelTypeName = customPanelTypes.find(x => x.id === customPanelTypeId)!.name;

            setPanel({
                id,
                userUniqueId,
                customPanelTypeId,
                customPanelTypeName,
                elementName,
                customPanelTypes,
                length,
                height,
                offsetX,
                offsetY,
            });
        };

        eventBus.addEventListener("Dextall.CustomPanels.SelectionChanged", onSelectionChanged);

        return () => {
            eventBus.removeEventListener("Dextall.CustomPanels.SelectionChanged", onSelectionChanged);
        };
    }, []);

    const onSelectCustomPanelType = (customPanelType: CustomPanelType) => {
        if (customPanelType.shapeType !== CustomPanelTypeShape.Panel) {
            throw new Error("Invalid state!");
        }

        setAsyncOperation({
            title: "Custom panel type",
            message: "Setting custom panel type...",
        });

        setCustomComponentLibraryOpened(false);

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.UI.SwitchType",
            payload: {
                panelId: panel.id,
                targetTypeId: customPanelType.id,
            },
        });
    };

    const onElementNameChanged = (newName: string) => {
        setAsyncOperation({
            title: "Element name",
            message: "Setting element name...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.UI.SetElementName",
            payload: {
                id: panel.id,
                elementName: newName,
            },
        });
    };

    const onOffsetChanged = (offset: Omit<ChangeCustomPanelOffsetEventPayload, "id">) => {
        setPanel({ ...panel, ...offset });

        setAsyncOperation({
            title: "Element offset",
            message: "Setting element offset...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.UI.SetOffset",
            payload: { ...offset, id: panel.id },
        });
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight">
                <ObjectUserUniqueId prefix={getCustomPanelUserUniqueIdObjectPrefix()} idValue={panel.userUniqueId} />
                <PanelTypeSelector
                    panelTypes={[]}
                    selectedPanelType={{ name: "fake", withoutCladdings: false }}
                    title="Type:"
                    selectCustomPanelTypeAvailable={true}
                    onChange={noop}
                    onEditTypeRequested={noop}
                    selectedCustomPanelTypeName={panel.customPanelTypeName}
                    onCustomPanelTypeRequested={() => setCustomComponentLibraryOpened(true)}
                />
                <TextPropertyEditor title="Elem name:" value={panel.elementName} onChange={onElementNameChanged} />
                <ImperialAndMetricDimensionEditor
                    title="Length:"
                    value={panel.length}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={panel.height}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="X offset:"
                    value={panel.offsetX}
                    onChange={offsetX => onOffsetChanged({ offsetX, offsetY: panel.offsetY })}
                />
                <ImperialAndMetricDimensionEditor
                    title="Y offset:"
                    value={panel.offsetY}
                    onChange={offsetY => onOffsetChanged({ offsetX: panel.offsetX, offsetY })}
                />
                <PanelStickyNote id={panel.id} type="custom-panels" />
                {customComponentLibraryOpened &&
                    ReactDOM.createPortal(
                        <ComponentsLibraryDialog
                            closeDialog={() => setCustomComponentLibraryOpened(false)}
                            customPanelTypes={panel.customPanelTypes}
                            selectLibraryComponent={onSelectCustomPanelType}
                            currentCustomPanelTypeId={panel.customPanelTypeId}
                        />,
                        rootElement,
                    )}
                {asyncOperation &&
                    ReactDOM.createPortal(
                        <ProcessingPane
                            isVisible={true}
                            message={asyncOperation.message}
                            title={asyncOperation.title}
                        />,
                        rootElement,
                    )}
            </div>
        </div>
    );
};
