import { zFightingFixDistance } from "@dextall/panels-defaults";
import { claddingCellGap, editorCladdingGeometryThickness } from "../defaults";
import { ICustomCornerSource } from "../../../responses/customCornerSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { CornerCladdingCellGeometry } from "../geometry/cornerCladdingCellGeometry";
import { HooksCoordinateSystems } from "./hooksCoordinateSystems";
import customPanelsColorFactory from "../colors/customComponentColorsFactory";

export class CustomCorner {
    private readonly material = customPanelsColorFactory.getDefaultCornerMaterial();

    constructor(
        private readonly cornerSource: ICustomCornerSource,
        private readonly modelCorner: IModelCorner,
        private readonly hooksCoordinateSystem: HooksCoordinateSystems,
        private readonly cornerCladdingCellGeometry: CornerCladdingCellGeometry,
        private readonly modelOffset: THREE.Vector3,
        public readonly panelDbId: number,
        public readonly internalId?: string,
    ) {
        // this id is used to keep original corner id after undo or redo actions with corner
        this.internalId = internalId || cornerSource.id;
    }

    get id(): string {
        return this.cornerSource.id;
    }

    get customPanelTypeId(): string {
        return this.cornerSource.customCornerTypeId;
    }

    get wallCornerId(): string {
        return this.cornerSource.wallCornerId;
    }

    get userUniqueId(): number {
        return this.cornerSource.userUniqueId;
    }

    get heightIndex(): number {
        return this.cornerSource.heightIndex;
    }

    get offset(): number {
        return this.cornerSource.offset;
    }

    get leftWing(): number {
        return this.cornerSource.leftWing;
    }

    get rightWing(): number {
        return this.cornerSource.rightWing;
    }

    get height(): number {
        return this.cornerSource.height;
    }

    get angle(): number {
        return this.cornerSource.angle;
    }

    get elementName(): string {
        return this.cornerSource.elementName;
    }

    createGeometry(): ExtrusionGeometry[] {
        const origin = this.computeGeometryOrigin(tempVector);

        return this.cornerCladdingCellGeometry.createGeometry(origin, this.material, this.material);
    }

    getCornerCoordinateSystem(): THREE.Matrix4 {
        const origin = this.computeGeometryOrigin(tempVector);

        return this.cornerCladdingCellGeometry.getCornerCoordinateSystem(origin);
    }

    computeTitleMatrix(titleSize: THREE.Vector3, forLeftWing: boolean): THREE.Matrix4 {
        const wingMatrix = this.hooksCoordinateSystem.getCornerWingTransform(this, forLeftWing)!;

        const wingLength = forLeftWing ? this.leftWing : this.rightWing;

        const position = new THREE.Vector3(
            0.5 * (wingLength - titleSize.x),
            0.5 * (this.height - titleSize.y),
            editorCladdingGeometryThickness + zFightingFixDistance
        ).applyMatrix4(wingMatrix);

        return wingMatrix.setPosition(position);
    }

    setPanelType(targetPanelTypeId: string) {
        this.cornerSource.customCornerTypeId = targetPanelTypeId;
    }

    setElementName(elementName: string) {
        this.cornerSource.elementName = elementName;
    }

    setOffset(offset: number) {
        this.cornerSource.offset = offset;
    }

    getGeometryCenter(targetVector?: THREE.Vector3): THREE.Vector3 {
        const origin = this.computeGeometryOrigin(targetVector, false);

        const centerOffset = new THREE.Vector3()
            .addVectors(this.modelCorner.left.wallOrientation, this.modelCorner.right.wallOrientation)
            .normalize()
            .multiplyScalar(0.5 * editorCladdingGeometryThickness);

        return origin.add(centerOffset);
    }

    getTargetOffsetFor(worldPoint: THREE.Vector3): number {
        const cornerOrigin = this.computeWallCornerOrigin(tempVector);

        return worldPoint.z - cornerOrigin.z;
    }

    private computeGeometryOrigin(targetVector?: THREE.Vector3, addCladdingCellGap = true): THREE.Vector3 {
        targetVector = targetVector || new THREE.Vector3();

        this.computeWallCornerOrigin(targetVector);

        targetVector.z += this.offset + (addCladdingCellGap ? claddingCellGap : 0);

        return targetVector;
    }

    private computeWallCornerOrigin(targetVector?: THREE.Vector3): THREE.Vector3 {
        targetVector = targetVector || new THREE.Vector3();

        targetVector
            .copy(this.modelOffset)
            .add(this.modelCorner.origin);

        targetVector.z += this.modelCorner.heights[this.heightIndex];

        return targetVector;
    }
}

const tempVector = new THREE.Vector3();