import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from '@weave-design/button';
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../../../responses/panelGeneratedModelDto";
import { styles } from "../../../../../assets/generationsListStyles"
import { StatusesUpdatedEventPayload } from "../../../eventBus/modelsGenerationsListEventPayloads";
import { Category, PanelGroup } from "../../../../../responses/modelsGenerationsListGroups";
import { Corner } from "../../../panels/corner";
import { createComparer } from "@dextall/shared";
import { Panel } from "../../../panels/panel";
import CategorySection from "./categorySection";
import GroupSection from "./groupSection";
import PanelSection from "./panelSection";
import { CreateNewPanelTypeEventPayload, SwitchPanelTypeEventPayload, UpdatePanelTypeEventPayload } from "../../../eventBus/typesEditionEventsPayload";
import { CornerPanelSelectionChangedEventPayload, PanelSelectionChangedEventPayload } from "../../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../../eventBus/eventDispatcher";

const ModelsGenerationListContent: React.FC = () => {
    const [categories, setCategories] = useState<Record<string, Category>>({});
    const [generatedModels, setGeneratedModels] = useState<IPanelGeneratedModelDto[]>([]);
    const [selectedPanelTypeId, setSelectedPanelTypeId] = useState<string | null>(null);
    const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);

    useEffect(() => {
        eventBus.addEventListener("Dextall.GeneratedModel.StatusesUpdated", onStatusesUpdated);
        eventBus.addEventListener("Dextall.PanelTypes.Updated", onRenamePanelType);
        eventBus.addEventListener("Dextall.PanelTypes.NewPanelType", onCreatePanelType);
        eventBus.addEventListener("Dextall.PanelTypes.SwitchPanelTypeRequested", onSwitchPanelType);
        eventBus.addEventListener("Dextall.Panels.SelectionChanged", onPanelSelectionChanged);
        eventBus.addEventListener("Dextall.Corners.SelectionChanged", onCornerSelectionChanged);

        return () => {
            eventBus.removeEventListener("Dextall.GeneratedModel.StatusesUpdated", onStatusesUpdated);
            eventBus.removeEventListener("Dextall.PanelTypes.Updated", onRenamePanelType);
            eventBus.removeEventListener("Dextall.PanelTypes.NewPanelType", onCreatePanelType);
            eventBus.removeEventListener("Dextall.PanelTypes.SwitchPanelTypeRequested", onSwitchPanelType);
            eventBus.removeEventListener("Dextall.Panels.SelectionChanged", onPanelSelectionChanged);
            eventBus.removeEventListener("Dextall.Corners.SelectionChanged", onCornerSelectionChanged);
        }
    }, []);

    const sortedCategories = useMemo(() => {
        const categoryOrder = ["opaque", "window", "corner"];
        return Object.entries(categories).sort(([, catA], [, catB]) => {
            return categoryOrder.indexOf(catA.name.toLowerCase()) - categoryOrder.indexOf(catB.name.toLowerCase());
        });
    }, [categories]);    

    const onStatusesUpdated = (eventData: IApplicationEvent<StatusesUpdatedEventPayload>) => {
        const { categories, models } = eventData.payload;

        const updatedCategories = { ...categories };
        Object.values(updatedCategories).forEach(category => {
            category.groups.forEach(group => {
                if(group.isSelected)
                    setSelectedPanelTypeId(null);
            });
        });

        setCategories(categories);
        setGeneratedModels(models);
    };

    const onRenamePanelType = (eventData: IApplicationEvent<UpdatePanelTypeEventPayload>) => {
        const { id: panelTypeId, name: newName } = eventData.payload;

        setCategories(prevCategories => {
            const updatedCategories = { ...prevCategories };

            Object.values(updatedCategories).forEach(category => {
                category.groups.forEach(group => {
                    if (group.panelTypeId === panelTypeId) {
                        group.name = newName;
                    }
                });
            });

            return updatedCategories;
        });
    };

    const onCreatePanelType = (eventData: IApplicationEvent<CreateNewPanelTypeEventPayload>) => {
        const { panelId, name: typeName } = eventData.payload;

        setCategories(prevCategories => {
            const updatedCategories = { ...prevCategories };

            Object.values(updatedCategories).forEach(category => {
                category.groups.forEach(group => {
                    if (group.panels.some(panel => panel.id === panelId)) {
                        group.name = typeName;
                        group.status = PanelTypeGenerationStatus.None;
                    }
                });
            });

            return updatedCategories;
        });
    }

    const onSwitchPanelType = (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => 
        setSelectedPanelTypeId(eventData.payload.targetTypeId);

    const onPanelSelectionChanged = (event: IApplicationEvent<PanelSelectionChangedEventPayload | null>) => {
        if (!event.payload) {
            setSelectedPanelId(null);
            return;
        }

        const panel = event.payload.panel;
        setSelectedPanelId(panel.id);
    };

    const onCornerSelectionChanged = (event: IApplicationEvent<CornerPanelSelectionChangedEventPayload | null>) => {
        if (!event.payload)
            return;

        const panel = event.payload.panel;
        setSelectedPanelId(panel.id);
    }   

    const generateSinglePanel = (panelTypeId: string, isPanelModel: boolean) => {
        setCategories(prevCategories => {
            const updatedCategories = { ...prevCategories };

            Object.values(updatedCategories).forEach(category => {
                category.groups.forEach(group => {
                    if (group.panelTypeId === panelTypeId) {
                        group.status = PanelTypeGenerationStatus.ModelGenerationInProgress;
                    }
                });
            });

            return updatedCategories;
        });

        eventBus.dispatchEvent({
            type: "Dextall.GeneratedModel.GenerationRequested",
            payload: { panelTypeId, isPanelModel },
        });
    };

    const generateAll = () => eventBus.dispatchEvent({ type: "Dextall.GeneratedModel.RequestAllGenerations", payload: null });

    const downloadModel = (typeId: string) => {
        const modelId = generatedModels.find(model => model.typeId === typeId)?.id;

        eventBus.dispatchEvent({ type: "Dextall.GeneratedModel.Download", payload: modelId ? modelId : "" });
    };

    const handlePanelIdClick = (panel: Panel | Corner, group: any) => {
        setSelectedPanelId(panel.id);
        
        setSelectedPanelTypeId(group.panelTypeId);

        const topRightCladdingCell = group.isPanelModel ? (panel as Panel).findRightTopMostCladdingCell() : undefined;
        const topCornerCell = !group.isPanelModel ? (panel as Corner).findTopmostCornerCladding() : undefined;

        const dbId = group.isPanelModel ? topRightCladdingCell?.dbId : topCornerCell?.dbId;
    
        eventBus.dispatchEvent({
            type: "Dextall.GeneratedModel.PanelId.Click",
            payload: {
                panel,
                dbId,
                isPanelModel: group.isPanelModel,
            },
        });
    };      

    const handlePanelTypeClick = (group: PanelGroup) => {
        eventBus.dispatchEvent({
            type: "Dextall.PanelTypes.UI.PanelTypeSelected",
            payload: { group }
        });

        setSelectedPanelTypeId(group.panelTypeId);
    };

    const compareGroups = useCallback((x: PanelGroup, y: PanelGroup) => x.name.localeCompare(y.name, "en", { numeric: true, sensitivity: "base" }), []);

    const comparePanels = useCallback(createComparer<Panel | Corner>((x: Panel | Corner) => x.userUniqueId), []);

    return (
        <div style={styles.container}>
            <div style={styles.scrollableContent}>
                {sortedCategories.map(([categoryId, category]) => (
                    <CategorySection
                        key={categoryId}
                        category={category}
                    >
                        {category.groups.sort(compareGroups).map((group, index) => (
                            <GroupSection
                                key={index}
                                group={group}
                                isSelected={group.panelTypeId === selectedPanelTypeId}
                                onPanelTypeClick={() => handlePanelTypeClick(group)}
                                onGenerateSinglePanel={() => generateSinglePanel(group.panelTypeId, group.isPanelModel)}
                                onDownloadPanelModel={() => downloadModel(group.panelTypeId)}
                            >
                                <PanelSection
                                    key={`${categoryId}-${index}`}
                                    panels={group.panels.sort(comparePanels)}
                                    isPanelModel={group.isPanelModel}
                                    selectedPanelId={selectedPanelId}
                                    onPanelClick={(panel) => handlePanelIdClick(panel, group)}
                                />
                            </GroupSection>
                        ))}
                    </CategorySection>
                ))}
            </div>
            <div style={styles.generateButtonContainer}>
                <Button
                    style={{ width: "100px" }}
                    type="primary"
                    size="standard"
                    title="Generate All"
                    width="grow"
                    onClick={generateAll}
                />
            </div>
        </div>
    );
};

export default ModelsGenerationListContent;