import { HookType } from "../../../responses/hookSource";
import { EnumPropertyOption, EnumPropertySelector } from "./enumPropertySelector";

type Props = {
    value: HookType;
    onChange: (newValue: HookType) => void;
};

const options: EnumPropertyOption[] = [
    [1, "Adjustable LEFT"],
    [2, "Adjustable RIGHT"],
    [3, "Not adjustable"],
];

export const HookTypeSelector = ({ value, onChange }: Props) => {
    return <EnumPropertySelector
        items={options}
        value={value}
        title="Hook type:"
        onChange={newValue => onChange(newValue)} />;
};