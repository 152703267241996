import { claddingCellGap } from "../../../defaults";
import { CornerHook } from "../../../panels/cornerHook";
import { Hook } from "../../../panels/hook";
import eventBus from "../../../eventBus/eventDispatcher";

type Props = {
    hook: Hook | CornerHook;
    isSelected: boolean;
};

const radius = 0.15;
const selectionRadius = 0.2;
const lineLength = 0.5;

export const Hook2D = ({ hook, isSelected }: Props) => {
    const onSelectHook = () => {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.SelectHook",
            payload: hook,
        });
    };

    return <g transform={`translate(${hook.x} ${hook.y})`} style={{ cursor: "pointer" }} onClick={() => onSelectHook()}>
        {isSelected && <circle x="0" y="0" r={selectionRadius} fill="#0d6efd" />}
        <circle x="0" y="0" r={radius} />
        <line x1={-0.5 * lineLength} y1="0" x2={0.5 * lineLength} y2="0" strokeWidth={claddingCellGap} stroke="black" />
        <line x1="0" y1={-0.5 * lineLength} x2="0" y2={0.5 * lineLength} strokeWidth={claddingCellGap} stroke="black" />
    </g>;
};