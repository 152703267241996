import { useEffect, useState } from "react";
import { PanelTypeGenerationStatus } from "../../../../../responses/panelGeneratedModelDto";
import { RevitGeneratedModel } from "../../../../../responses/revitGeneratedModel";
import { ProcessingProgressBar } from "../../../../processing-pane/processingProgressBar";
import { progressBarStyle } from "./constants";
import { GeneratedHooksModel } from "./generated-hooks-revit-model/generatedHooksModel";
import { GeneratedHooksModelFailed } from "./generated-hooks-revit-model/generatedHooksModelFailed";
import { GenerateNewRevitHooksModelControls } from "./generated-hooks-revit-model/generateNewRevitHooksModelControls";
import { HooksGeneratedModelLoadedPayload } from "../../../eventBus/hooksGeneratedModelsEventPayloads";
import eventBus, { IApplicationEvent } from "../../../eventBus/eventDispatcher";
import "../editor2DContent.css";
import "../revitModelContent.css";


export const GeneratedHooksRevitModel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [model, setModel] = useState<RevitGeneratedModel>();

    useEffect(() => {
        const onLoaded = (event: IApplicationEvent<HooksGeneratedModelLoadedPayload>) => {
            setIsLoading(false);
            setModel(event.payload.model);
        };

        eventBus.addEventListener("Dextall.HooksRevitModel.Loaded", onLoaded);
        eventBus.addEventListener("Dextall.HooksRevitModel.Generated", onLoaded);

        return () => {
            eventBus.removeEventListener("Dextall.HooksRevitModel.Loaded", onLoaded);
            eventBus.removeEventListener("Dextall.HooksRevitModel.Generated", onLoaded);
        };
    }, []);

    return <div className="generated-revit-model-container">
        <h4 className="generated-revit-model-title-container">Hooks model</h4>
        {isLoading && <ProcessingProgressBar style={progressBarStyle} />}
        {!isLoading &&
            <div style={{ display: "flex" }}>
                {model?.status === PanelTypeGenerationStatus.ModelGenerationInProgress &&
                    <ProcessingProgressBar
                        style={progressBarStyle}
                        tooltip={{
                            message: "generating hooks model",
                            style: { top: 10 },
                        }} />}
                {model?.status === PanelTypeGenerationStatus.None &&
                    <GenerateNewRevitHooksModelControls />}

                {model?.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully &&
                    <GeneratedHooksModel id={model.id} />}

                {model?.status === PanelTypeGenerationStatus.ModelGenerationFailed &&
                    <GeneratedHooksModelFailed />}
            </div>}
    </div>;
};