import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { CustomPanelType, CustomPanelTypeShape, ICustomCornerType } from "../../../../responses/customPanelTypes";
import { CladdingCellStyle } from "../../../../responses/panelSource";
import { ComponentsLibraryDialog } from "../../../custom-components-library/componentsLibraryDialog";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { noop, rootElement } from "../../../shared";
import { BooleanPropertyEditor } from "../../editor-inputs/booleanPropertyEditor";
import { EditorPanelType, newPanelTypeDropdownItem } from "../../editor-inputs/editorPanelType";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import { ObjectUserUniqueId } from "../../editor-inputs/objectUserUniqueId";
import { PanelTypeSelector } from "../../editor-inputs/panelTypeSelector";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";
import { getCornerUserUniqueIdObjectPrefix } from "../../ids/userObjectIds";
import { CladdingsMaterialTextField } from "./claddingsMaterialTextField";
import { EditedType, PanelTypeEditor } from "./panel-type-editor/panelTypeEditor";
import { PanelStickyNote } from "./panelStickyNote";
import { CornerPanelSelectionChangedEventPayload } from "../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type PanelFields = {
    id: string;
    userUniqueId: number;
    elementName: string;
    leftWing: number;
    rightWing: number;
    height: number;
    panelTypeId: string;
    isReversed: boolean;
    thicknessLabel: string;
    isCustomPanel: boolean;
    style: CladdingCellStyle;
    isParapetPanel: boolean;
    customPanelTypes: ICustomCornerType[];
    customPanelTypeName: string | undefined;
};

type AsyncOperation = {
    title: string;
    message: string;
};

export const CornerEditorDockingPanelContent = () => {
    const [panel, setPanel] = useState<PanelFields>({
        id: "",
        userUniqueId: 0,
        elementName: "",
        leftWing: 0,
        rightWing: 0,
        height: 0,
        panelTypeId: "",
        isReversed: false,
        thicknessLabel: "",
        style: CladdingCellStyle.Exposed,
        isParapetPanel: false,
        isCustomPanel: false,
        customPanelTypes: [],
        customPanelTypeName: undefined,
    });
    const [panelTypes, setPanelTypes] = useState<EditorPanelType[]>([]);
    const [selectedPanelType, setSelectedPanelType] = useState<EditorPanelType>({
        name: "",
        withoutCladdings: false,
    });
    const [editingType, setEditingType] = useState<EditorPanelType>();
    const [asyncOperation, setAsyncOperation] = useState<AsyncOperation>();
    const [customComponentLibraryOpened, setCustomComponentLibraryOpened] = useState(false);

    useEffect(() => {
        const onSelectionChanged = (event: IApplicationEvent<CornerPanelSelectionChangedEventPayload | null>) => {
            setAsyncOperation(undefined);

            if (!event.payload) {
                return;
            }

            const {
                id,
                userUniqueId,
                elementName,
                leftWing,
                rightWing,
                height,
                panelTypeId,
                isReversed,
                thicknessLabel,
                style,
                customPanelTypeId,
                isParapetPanel,
            } = event.payload.panel;

            const customPanelTypes = event.payload.customPanelTypes;

            const customPanelTypeName = customPanelTypeId
                ? customPanelTypes.find(x => x.id === customPanelTypeId)?.name
                : undefined;

            setPanel({
                id,
                userUniqueId,
                elementName,
                leftWing,
                rightWing,
                height,
                panelTypeId,
                isReversed,
                thicknessLabel,
                style,
                isParapetPanel,
                isCustomPanel: !!customPanelTypeId,
                customPanelTypes,
                customPanelTypeName,
            });

            const family = event.payload.family;

            const familyPanelTypes: EditorPanelType[] = family.panelTypes.map(x => {
                return { name: x.name, id: x.id, withoutCladdings: x.withoutCladdings };
            });

            familyPanelTypes.push(newPanelTypeDropdownItem);

            setPanelTypes(familyPanelTypes);

            const targetPanelType = familyPanelTypes.find(x => x.id === panelTypeId);

            if (!targetPanelType) {
                throw new Error("Invalid state!");
            }

            setSelectedPanelType(targetPanelType);
        };

        eventBus.addEventListener("Dextall.Corners.SelectionChanged", onSelectionChanged);

        return () => {
            eventBus.removeEventListener("Dextall.Corners.SelectionChanged", onSelectionChanged);
        };
    }, []);

    const updatePanel = (elementName: string) => {
        setAsyncOperation({
            title: "Element name",
            message: "Setting element name...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.Corners.UI.ParametersChanged",
            payload: {
                id: panel.id,
                elementName,
            },
        });
    };

    const onPanelTypeEdited = (updatedType: EditedType) => {
        if (!editingType) {
            throw new Error("Invalid state!");
        }

        if (editingType.id) {
            eventBus.dispatchEvent({
                type: "Dextall.CornerTypes.Updated",
                payload: {
                    ...updatedType,
                    id: editingType.id,
                },
            });

            setAsyncOperation({
                title: "Updating corner type...",
                message: "Updating corner type...",
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.CornerTypes.NewPanelType",
                payload: {
                    ...updatedType,
                    panelId: panel.id,
                    id: panel.panelTypeId,
                },
            });

            setAsyncOperation({
                title: "Creating a new corner type...",
                message: "Creating a new corner type...",
            });
        }

        setEditingType(undefined);
    };

    const onSelectPanelType = (selectedType: EditorPanelType) => {
        if (selectedType.id) {
            eventBus.dispatchEvent({
                type: "Dextall.CornerTypes.SwitchPanelTypeRequested",
                payload: {
                    panelId: panel.id,
                    targetTypeId: selectedType.id,
                },
            });

            setAsyncOperation({
                title: "Switching panel type...",
                message: "Switching panel type...",
            });
        } else {
            setEditingType({ name: `${selectedPanelType.name}_new`, withoutCladdings: false });
        }
    };

    const onSelectCustomPanelType = (customPanelType: CustomPanelType) => {
        if (customPanelType.shapeType !== CustomPanelTypeShape.Corner) {
            throw new Error("Invalid state");
        }

        setCustomComponentLibraryOpened(false);

        setAsyncOperation({
            title: "Custom component...",
            message: "Replacing with custom component...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.Corners.UI.SwitchToCustomPanelType",
            payload: {
                panelId: panel.id,
                targetTypeId: customPanelType.id,
            },
        });
    };

    const onResetCustomPanelType = () => {
        setAsyncOperation({
            title: "Custom component...",
            message: "Setting regular panel type...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.Corners.UI.RestoreRegularPanelType",
            payload: panel.id,
        });
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight">
                <ObjectUserUniqueId prefix={getCornerUserUniqueIdObjectPrefix()} idValue={panel.userUniqueId} />
                <PanelTypeSelector
                    title="Type:"
                    panelTypes={panelTypes}
                    selectedPanelType={selectedPanelType}
                    onChange={onSelectPanelType}
                    onEditTypeRequested={() => setEditingType({ ...selectedPanelType })}
                    onCustomPanelTypeRequested={() => setCustomComponentLibraryOpened(true)}
                    onCustomPanelTypeReset={onResetCustomPanelType}
                    selectedCustomPanelTypeName={panel.customPanelTypeName}
                />
                <TextPropertyEditor
                    title="Elem name:"
                    value={panel.elementName}
                    onChange={newValue => updatePanel(newValue)}
                />
                <TextPropertyEditor
                    title="Thickness:"
                    value={panel.thicknessLabel}
                    onChange={noop}
                    disabled={true}
                />
                <CladdingsMaterialTextField claddingCellStyle={panel.style} />
                <BooleanPropertyEditor
                    title="Parapet:"
                    value={panel.isParapetPanel}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={panel.height}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Left:"
                    value={panel.isReversed ? panel.rightWing : panel.leftWing}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Right:"
                    value={panel.isReversed ? panel.leftWing : panel.rightWing}
                    onChange={noop}
                    disabled={true}
                />
                <PanelStickyNote id={panel.id} type="corners" />
                {editingType &&
                    ReactDOM.createPortal(
                        <PanelTypeEditor
                            title="Edit corner panel type"
                            panelType={editingType}
                            onConfirm={onPanelTypeEdited}
                            onCancel={() => setEditingType(undefined)} />,
                        rootElement,
                    )}
                {asyncOperation &&
                    ReactDOM.createPortal(
                        <ProcessingPane
                            isVisible={true}
                            message={asyncOperation.message}
                            title={asyncOperation.title}
                        />,
                        rootElement,
                    )}
                {customComponentLibraryOpened &&
                    ReactDOM.createPortal(
                        <ComponentsLibraryDialog
                            closeDialog={() => setCustomComponentLibraryOpened(false)}
                            customPanelTypes={panel.customPanelTypes}
                            selectLibraryComponent={onSelectCustomPanelType}
                        />,
                        rootElement,
                    )}
            </div>
        </div>
    );
};
