import { ICustomZShapedPanelSource } from "../../../responses/customZShapedPanelSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { claddingCellGap, editorCladdingGeometryThickness } from "../defaults";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { ZShapeCladdingCellGeometry } from "../geometry/zShapeCladdingCellGeometry";
import { ZShapeGeometryType } from "@dextall/corner-geometry";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import customPanelsColorFactory from "../colors/customComponentColorsFactory";

export class CustomZShapedPanel {
    private readonly material = customPanelsColorFactory.getDefaultCornerMaterial();

    constructor(
        private readonly panelSource: ICustomZShapedPanelSource,
        private readonly geometry: ZShapeCladdingCellGeometry,
        private readonly modelCorner: IModelCorner,
        private readonly modelOffset: THREE.Vector3,
        public readonly panelDbId: number,
        public readonly internalId?: string,
    ) {
        // this id is used to keep original corner id after undo or redo actions with corner
        this.internalId = internalId || panelSource.id;
    }

    get id(): string {
        return this.panelSource.id;
    }

    get customPanelTypeId(): string {
        return this.panelSource.customZShapedPanelTypeId;
    }

    get wallCornerId(): string {
        return this.panelSource.wallCornerId;
    }

    get shelfEndWallCornerId(): string {
        return this.panelSource.shelfEndWallCornerId;
    }

    get userUniqueId(): number {
        return this.panelSource.userUniqueId;
    }

    get heightIndex(): number {
        return this.panelSource.heightIndex;
    }

    get offset(): number {
        return this.panelSource.offset;
    }

    get angle1(): number {
        return this.panelSource.angle1;
    }

    get angle2(): number {
        return this.panelSource.angle2;
    }

    get leftWing(): number {
        return this.panelSource.leftWingLength;
    }

    get shelf(): number {
        return this.panelSource.shelfLength;
    }

    get rightWing(): number {
        return this.panelSource.rightWingLength;
    }

    get height(): number {
        return this.panelSource.height;
    }

    get elementName(): string {
        return this.panelSource.elementName;
    }

    createGeometry(): ExtrusionGeometry[] {
        const origin = this.computeGeometryOrigin(tempVector);

        return this.geometry.createGeometry(origin, this.material, this.material, this.material);
    }

    getCoordinateSystem(): THREE.Matrix4 {
        const origin = this.computeGeometryOrigin(tempVector);

        return this.geometry.getCornerCoordinateSystem(origin);
    }

    computeTitleMatrix(titleSize: THREE.Vector3, geometryType: ZShapeGeometryType): THREE.Matrix4 {
        const origin = this.computeGeometryOrigin(tempVector);

        const wingMatrix = this.geometry.getWingMatrix(origin, geometryType);

        const wingLength = this.getWingLength(geometryType);

        const position = new THREE.Vector3(
            0.5 * (wingLength - titleSize.x),
            0.5 * (this.height - titleSize.y),
            zFightingFixDistance
        ).applyMatrix4(wingMatrix);

        return wingMatrix.setPosition(position);
    }

    getGizmoPosition(targetVector?: THREE.Vector3): THREE.Vector3 {
        targetVector = targetVector || new THREE.Vector3();

        const origin = this.computeGeometryOrigin(tempVector);

        const shelfMatrix = this.geometry.getWingMatrix(origin, "shelf");

        const shelfLength = this.getWingLength("shelf");

        targetVector.set(0.5 * shelfLength, -0.5 * editorCladdingGeometryThickness, 0);

        return targetVector.applyMatrix4(shelfMatrix);
    }

    getTargetOffsetFor(worldPoint: THREE.Vector3): number {
        const cornerOrigin = this.computeWallCornerOrigin(tempVector);

        return worldPoint.z - cornerOrigin.z;
    }

    setPanelType(targetPanelTypeId: string) {
        this.panelSource.customZShapedPanelTypeId = targetPanelTypeId;
    }

    setElementName(elementName: string) {
        this.panelSource.elementName = elementName;
    }

    setOffset(offset: number) {
        this.panelSource.offset = offset;
    }

    private getWingLength(geometryType: ZShapeGeometryType) {
        switch (geometryType) {
            case "left-wing":
                return this.leftWing;

            case "shelf":
                return this.shelf;

            case "right-wing":
                return this.rightWing;

            default:
                throw new Error("Invalid geometry type");
        }
    }

    private computeGeometryOrigin(targetVector?: THREE.Vector3, addCladdingCellGap = true): THREE.Vector3 {
        targetVector = targetVector || new THREE.Vector3();

        this.computeWallCornerOrigin(targetVector);

        targetVector.z += this.offset + (addCladdingCellGap ? claddingCellGap : 0);

        return targetVector;
    }

    private computeWallCornerOrigin(targetVector?: THREE.Vector3): THREE.Vector3 {
        targetVector = targetVector || new THREE.Vector3();

        targetVector
            .copy(this.modelOffset)
            .add(this.modelCorner.origin);

        targetVector.z += this.modelCorner.heights[this.heightIndex];

        return targetVector;
    }
}

const tempVector = new THREE.Vector3();