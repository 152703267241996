import CheckBox from "@weave-design/checkbox";
import Label from "@weave-design/label";
import "./inputs.css";

type Props = {
    value: boolean;
    title: string;
    disabled?: boolean;
    onChange: (value: boolean) => void;
};

export const BooleanPropertyEditor = ({ title, value, disabled, onChange }: Props) => {
    return <div className="property-input-container">
        <div className="property-input-title-container">
            <Label>{title}</Label>
        </div>
        <div className="property-input-value-conainer" style={{ height: 44 }}>
            <CheckBox checked={value} onChange={onChange} disabled={!!disabled} stylesheet={checkBoxStyles} />
        </div>
    </div>;
};

const checkBoxStyles = (styles: any) => {
    return {
        ...styles,
        checkboxWrapper: {
            ...styles.checkboxWrapper,
            marginTop: "auto",
            marginBottom: "auto",
        },
    };
};