import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { RevitFacadeGeneratedModel } from "../../../responses/revitGeneratedModel";
import { WorkerAction, WorkerResultMessage } from "./revitFacadeModelWorker";
import eventBus from "../eventBus/eventDispatcher";
import repo from "../../../Repository";

export class RevitFacadeModel {
    private readonly worker: Worker;

    constructor(modelId: string) {
        this.worker = new Worker(new URL("./revitFacadeModelWorker.ts", import.meta.url), { type: "module" });

        this.onWorkerMessage = this.onWorkerMessage.bind(this);

        this.worker.onmessage = this.onWorkerMessage;

        this.postMessage({
            action: "initialize",
            modelId,
            token: repo.getAccessToken(),
        });
    }

    load() {
        this.postMessage({ action: "load" });
    }

    generateRevitFacadeModel() {
        // during first 2 stages we don't have a real facade model,
        // but we need to show progress status
        eventBus.dispatchEvent({
            type: "Dextall.RevitFacadeModel.Loaded",
            payload: fakeFacadeModel,
        });

        this.postMessage({ action: "generate-model" });
    }

    private postMessage(action: WorkerAction) {
        this.worker.postMessage(action);
    }

    private onWorkerMessage(event: MessageEvent<WorkerResultMessage>) {
        const data = event.data;

        switch (data.type) {
            case "loaded": {
                eventBus.dispatchEvent({
                    type: "Dextall.RevitFacadeModel.Loaded",
                    payload: data.model,
                });
                break;
            }
            case "failed": {
                eventBus.dispatchEvent({ type: "Dextall.RevitFacadeModel.Loaded", payload: null });
                eventBus.dispatchEvent({ type: "Dextall.RevitFacadeModel.Failed", payload: data.message });
                break;
            }
            case "stage-changed": {
                eventBus.dispatchEvent({ type: "Dextall.RevitFacadeModel.GenerationStage", payload: data.stage });
                break;
            }
        }
    }
}

const fakeFacadeModel: RevitFacadeGeneratedModel = {
    id: "fake",
    isOutdated: false,
    status: PanelTypeGenerationStatus.ModelGenerationInProgress,
};