import Dropdown from "@weave-design/dropdown";
import { IPanelGeneratedDrawingDto } from "../../../../responses/panelGeneratedDrawingDto";

type Props = {
    drawings: IPanelGeneratedDrawingDto[];
    selectedDrawingIndex: number;
    setSelectedDrawingIndex: (index: number) => void;
};

export const GeneratedDrawingsSelector = ({ drawings, selectedDrawingIndex, setSelectedDrawingIndex }: Props) => {
    return <div style={{ position: "absolute", right: 0, top: -10, width: 300 }}>
        <Dropdown
            options={drawings.map((x, i) => toDropdownOption(x, i))}
            value={toDropdownOption(drawings[selectedDrawingIndex], selectedDrawingIndex)}
            onChange={(value: string) => setSelectedDrawingIndex(fromDropdownOption(value))}
            formatOption={(item: string) => drawings[fromDropdownOption(item)].name} />
    </div>;
};

const splitter = String.fromCharCode(7);

const toDropdownOption = (item: IPanelGeneratedDrawingDto, index: number) => `${index}${splitter}${item.name}`;

const fromDropdownOption = (dropDownValue: string): number => {
    const [index, _] = dropDownValue.split(splitter);

    return parseInt(index);
};