import colorsFactory from "../../../colors/ralColorsFactory";
import { claddingCellGap } from "../../../defaults";
import { CladdingCell } from "./panelEditor2D";

type Props = {
    cell: CladdingCell;
};

export const CladdingCellBox2D = ({ cell }: Props) => {
    const { box, color } = cell;

    return <g transform={`translate (${box.min.x}, ${box.min.y})`}>
        <rect
            width={box.max.x - box.min.x}
            height={box.max.y - box.min.y} />

        <rect
            x={0.5 * claddingCellGap}
            y={0.5 * claddingCellGap}
            width={box.max.x - box.min.x - claddingCellGap}
            height={box.max.y - box.min.y - claddingCellGap}
            fill={colorsFactory.getColor(color)}></rect>

        <text
            transform="scale(1 -1)"
            y={-claddingCellGap}
            x={box.max.x - box.min.x}
            fontFamily="Arial"
            fontSize={fontSize}
            dominantBaseline="auto"
            textAnchor="end"
            textRendering="auto"
            fill={`#${cell.titleColor}`}>{cell.material}</text>
    </g>;
};

const fontSize = 0.3;