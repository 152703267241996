import { useEffect, useState } from "react";
import Checkbox from "@weave-design/checkbox";
import Label from "@weave-design/label";
import { ModelInstanceTreeNode } from "../forge/extensions/forgeCustomComponentNavigationExtension";
import eventBus, { IApplicationEvent } from "../forge/eventBus/eventDispatcher";
import "./customComponentVisibilityEditor.css";

type Props = {
    componentId: string;
    disabled: boolean;
};

type CheckableInstanceTreeNode = ModelInstanceTreeNode & { isVisible: boolean };

export const CustomComponentVisibilityEditor = ({ componentId }: Props) => {
    const [instanceTreeNodes, setInstanceTreeNodes] = useState<CheckableInstanceTreeNode[]>([]);

    useEffect(() => {
        setInstanceTreeNodes([]);

        const onInstanceTreeNodesReady = (event: IApplicationEvent<ModelInstanceTreeNode[]>) =>
            setInstanceTreeNodes(event.payload.map(x => {
                return { ...x, isVisible: true }; 
            }));

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.InstancesReady", onInstanceTreeNodesReady);

        return () => {
            eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.InstancesReady", onInstanceTreeNodesReady);
        };
    }, [componentId]);

    const onNodeVisibilityChanged = (node: CheckableInstanceTreeNode, visibility: boolean) => {
        const nodes = instanceTreeNodes
            .map(x => {
                if (x.id === node.id) {
                    return { ...x, isVisible: visibility };
                }

                return x;
            });

        setInstanceTreeNodes(nodes);

        if (visibility) {
            eventBus.dispatchEvent({ type: "Dextall.CustomComponentLibrary.Model.UI.ShowInstanceTreeNode", payload: node });
        } else {
            eventBus.dispatchEvent({ type: "Dextall.CustomComponentLibrary.Model.UI.HideInstanceTreeNode", payload: node });
        }
    };

    const nodes = instanceTreeNodes
        .map(x => <div key={`instance-tree-node-visibility-${x.id}`}><Checkbox
            checked={x.isVisible}
            onChange={(newVisibility: boolean) => onNodeVisibilityChanged(x, newVisibility)} />&nbsp;<Label>{x.name}</Label></div>);

    return <div className="custom-component-instance-tree-visibility-container">
        <Label>Elements visibility:</Label>
        {nodes}
    </div>;
};