import { HooksDefaultOffsetDockingPanel } from "../docking-panels/hooksDefaultOffsetDockingPanel";
import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";

export type ForgeHooksDefaultOffsetLoadOptions = Record<string, never>;

export class ForgeHooksDefaultOffset extends Autodesk.Viewing.Extension {
    private toggleHooksDefaultPositionButton: Autodesk.Viewing.UI.Button | null = null;
    private dockingPanel: HooksDefaultOffsetDockingPanel | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeHooksDefaultOffsetLoadOptions) {
        super(viewer, options);

        this.onToggleHooksDefaultOffset = this.onToggleHooksDefaultOffset.bind(this);
        this.onDockingPanelVisibilityChanged = this.onDockingPanelVisibilityChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);
        this.toggleHooksDefaultPositionButton?.removeEventListener("click", this.onToggleHooksDefaultOffset);
        this.dockingPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as Autodesk.Viewing.UI.ControlGroup | undefined;

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as
                | Autodesk.Viewing.UI.ControlGroup
                | undefined;

            if (!measureTools) {
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });
            }

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleHooksDefaultPositionButton = new Autodesk.Viewing.UI.Button("dextall-hooks-default-offset");
        this.toggleHooksDefaultPositionButton.setToolTip("Hooks default offset");
        this.toggleHooksDefaultPositionButton.setIcon("viewer-hooks-default-offset");
        this.toggleHooksDefaultPositionButton.addEventListener("click", this.onToggleHooksDefaultOffset);

        this.dockingPanel = new HooksDefaultOffsetDockingPanel(
            this.viewer.container,
            this.onDockingPanelVisibilityChanged,
        );

        group.addControl(this.toggleHooksDefaultPositionButton);
    }

    private onToggleHooksDefaultOffset() {
        if (!this.dockingPanel) {
            return;
        }

        const newState = !this.dockingPanel.visible;

        this.dockingPanel.setVisible(newState);
    }

    private onDockingPanelVisibilityChanged(visibility: boolean) {
        const newState = visibility
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleHooksDefaultPositionButton?.setState(newState);
    }

    private onEscape() {
        this.dockingPanel?.setVisible(false);
    }
}

export const forgeHooksDefaultOffsetName = "Dextall.ForgeHooksOffsetPosition" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeHooksDefaultOffsetName, ForgeHooksDefaultOffset);
