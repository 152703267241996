import { OperationCompletedResults } from "./generatedPanelsContentWorker";
import repo from "../../../Repository";
import eventBus from "../eventBus/eventDispatcher";

export class GeneratedPanelsContent {
    private readonly worker: Worker;

    constructor() {
        this.worker = new Worker(new URL("./generatedPanelsContentWorker.ts", import.meta.url), { type: "module" });

        this.workerJobDone = this.workerJobDone.bind(this);
        this.worker.onmessage = this.workerJobDone;

        this.worker.postMessage({ type: "initialize", accessToken: repo.getAccessToken() });
    }

    loadAllModels(modelId: string) {
        this.worker.postMessage({ type: "load-all-models", modelId });
    }

    abortCheck() {
        this.worker.postMessage({ type: "abort-panel-generation-check" });
    }

    private async workerJobDone(e: MessageEvent<OperationCompletedResults>) {
        eventBus.dispatchEvent({ type: "Dextall.GeneratedModel.PanelModelsLoaded", payload: e.data });
    }
}
