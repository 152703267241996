import { HooksDefaultOffsetDockingPanelContent } from "./content/hooksDefaultOffsetDockingPanelContent";
import { DockingPanel, IDockingPanelOptions } from "./dockingPanel";

export class HooksDefaultOffsetDockingPanel {
    private readonly panel: DockingPanel;
    private _visible: boolean;

    constructor(container: HTMLElement, onVisibilityChanged: (visibility: boolean) => void) {
        const component = <HooksDefaultOffsetDockingPanelContent />;
        const containerRect = container.getBoundingClientRect();
        const panelWidth = 500;
        const options: IDockingPanelOptions = {
            style: {},
            dimensionsControl: {
                preventOverlapsWithViewerToolbar: true,
                widthDimensionControl: { value: panelWidth, minValue: panelWidth },
                heightDimensionControl: { value: 150, minValue: 150 },
                leftOffset: 0.5 * (containerRect.width - panelWidth),
                bottomOffset: 2,
            },
        };

        this._visible = false;
        this.panel = new DockingPanel(
            container,
            "dextall-hooks-default-offset-docking-panel",
            "Hooks default offset",
            component,
            options,
        );
        this.panel.addVisibilityListener((x: boolean) => {
            this._visible = x;
            onVisibilityChanged(x);
        });
    }

    get visible() {
        return this._visible;
    }

    setVisible(visibility: boolean) {
        if (this._visible === visibility) {
            return;
        }

        this._visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}
