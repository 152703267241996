import { useEffect, useRef } from "react";
import Input from "@weave-design/input";
import Label from "@weave-design/label";
import { UserUniqueIdObjectPrefix } from "../ids/userObjectIds";
import "./inputs.css";

type Props = {
    prefix: UserUniqueIdObjectPrefix;
    idValue: number;
};

export const ObjectUserUniqueId = ({ prefix, idValue }: Props) => {
    const parentDivRef = useRef<HTMLDivElement>(null);
    const idInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        const onKeyPressed = (event: KeyboardEvent) => {
            if (event.key !== "Enter") {
                return;
            }

            const inputElements = (parentDivRef.current!.parentElement?.querySelectorAll("input:not([disabled])") || []) as HTMLInputElement[];

            const currentInputIndex = Array.from(inputElements).indexOf(event.target as HTMLInputElement);

            const nextIndex = currentInputIndex < inputElements.length - 1 ? currentInputIndex + 1 : 0;

            inputElements[nextIndex].focus();
        };

        parentDivRef.current?.addEventListener("keypress", onKeyPressed);

        return () => parentDivRef.current?.removeEventListener("keypress", onKeyPressed);
    }, []);

    return <div className="property-input-container" ref={parentDivRef}>
        <div className="property-input-title-container">
            <Label>Id:</Label>
        </div>
        <div className="property-input-value-conainer">
            <Input
                inputRef={(x: any) => idInputRef.current = x}
                onFocus={() => idInputRef.current?.select()}
                value={prefix + idValue}
                onChange={() => { }} />
        </div>
    </div>;
};