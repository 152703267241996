import { isAlmostEqual } from "@dextall/shared";
import { UpdateCustomCornerOffsetCommand } from "../../../commands/updateCustomCornerOffsetCommand";
import { BasicItemResponse } from "../../../responses/basicResponses";
import { CustomPanelType, CustomPanelTypeShape } from "../../../responses/customPanelTypes";
import { IModelCorner } from "../../../responses/modelCorner";
import { CustomCornerDockingPanel } from "../docking-panels/customCornerDockingPanel";
import { CustomCornersModelEditor } from "../editors/customCornersModelEditor";
import { CustomCorner } from "../panels/customCorner";
import { customComponentsToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { CustomCornerPlacementTool } from "../viewer-tools/customCornerPlacementTool";
import { CustomWallCornerPanelOffsetTool } from "../viewer-tools/customWallCornerPanelOffsetTool";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import {
    ChangeCustomCornerOffsetEventPayload,
    CustomCornerGizmoDraggingCompletedEventPayload,
    CustomCornerGizmoDraggingPayload,
    CustomCornerPlacementRequestEventPayload,
} from "../eventBus/customPanelsEventPayloads";
import { ElementParameterChangedEventPayload } from "../eventBus/elementParameterChangedEventPayload";
import { SwitchPanelTypeEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgeCustomCornersEditorExtensionLoadOptions = {
    customCornersEditor: CustomCornersModelEditor;
    modelCorners: IModelCorner[];
};

export class ForgeCustomCornersEditorExtension extends Autodesk.Viewing.Extension {
    private readonly customCornersEditor: CustomCornersModelEditor;
    private readonly modelCorners: IModelCorner[];
    private readonly customCornerOffsetGizmo = new CustomWallCornerPanelOffsetTool();
    private readonly customCornerPlacementTool = new CustomCornerPlacementTool();
    private editorPanel: CustomCornerDockingPanel | null = null;
    private toggleCustomCornersPlacementButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeCustomCornersEditorExtensionLoadOptions) {
        super(viewer, options);

        this.customCornersEditor = options.customCornersEditor;
        this.modelCorners = options.modelCorners;

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSwitchPanelType = this.onSwitchPanelType.bind(this);
        this.onChangeElementName = this.onChangeElementName.bind(this);
        this.onChangeOffset = this.onChangeOffset.bind(this);
        this.onOffsetChangingByGizmo = this.onOffsetChangingByGizmo.bind(this);
        this.onOffsetChangingByGizmoCompleted = this.onOffsetChangingByGizmoCompleted.bind(this);
        this.onToggleCustomCornerPlacement = this.onToggleCustomCornerPlacement.bind(this);
        this.onSelectCustomPanelToPlace = this.onSelectCustomPanelToPlace.bind(this);
        this.onCustomCornerCreateRequest = this.onCustomCornerCreateRequest.bind(this);
        this.onEntityRemoveRequested = this.onEntityRemoveRequested.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.customCornersEditor.createCustomCorners();

        this.viewer.toolController.registerTool(this.customCornerOffsetGizmo);
        this.viewer.toolController.activateTool(this.customCornerOffsetGizmo.getName());

        this.viewer.toolController.registerTool(this.customCornerPlacementTool);

        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.addEventListener("Dextall.CustomCorners.UI.SwitchType", this.onSwitchPanelType);
        eventBus.addEventListener("Dextall.CustomCorners.UI.SetElementName", this.onChangeElementName);
        eventBus.addEventListener("Dextall.CustomCorners.UI.SetOffset", this.onChangeOffset);
        eventBus.addEventListener("Dextall.CustomCorners.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.addEventListener("Dextall.CustomCorners.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.addEventListener("Dextall.CustomCorners.PromptCornerPlacement", this.onSelectCustomPanelToPlace);
        eventBus.addEventListener("Dextall.CustomCorners.CreateNew", this.onCustomCornerCreateRequest);
        eventBus.addEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        this.viewer.toolController.deactivateTool(this.customCornerOffsetGizmo.getName());
        this.viewer.toolController.deregisterTool(this.customCornerOffsetGizmo);

        this.viewer.toolController.deregisterTool(this.customCornerPlacementTool);

        eventBus.removeEventListener("Dextall.CustomCorners.UI.SwitchType", this.onSwitchPanelType);
        eventBus.removeEventListener("Dextall.CustomCorners.UI.SetElementName", this.onChangeElementName);
        eventBus.removeEventListener("Dextall.CustomCorners.UI.SetOffset", this.onChangeOffset);
        eventBus.removeEventListener("Dextall.CustomCorners.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.removeEventListener("Dextall.CustomCorners.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.removeEventListener("Dextall.CustomCorners.PromptCornerPlacement", this.onSelectCustomPanelToPlace);
        eventBus.removeEventListener("Dextall.CustomCorners.CreateNew", this.onCustomCornerCreateRequest);
        eventBus.removeEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        this.editorPanel?.shutdown();

        this.toggleCustomCornersPlacementButton?.removeEventListener("click", this.onToggleCustomCornerPlacement);
        this.toggleCustomCornersPlacementButton = null;

        return true;
    }

    onToolbarCreated() {
        this.editorPanel = new CustomCornerDockingPanel(this.viewer.container);

        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(customComponentsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(customComponentsToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleCustomCornersPlacementButton = new Autodesk.Viewing.UI.Button("dextall-place-custom-corner-button");
        this.toggleCustomCornersPlacementButton.setToolTip("Add custom corner");
        this.toggleCustomCornersPlacementButton.setIcon("viewer-corner-editor-button");
        this.toggleCustomCornersPlacementButton.addEventListener("click", this.onToggleCustomCornerPlacement);

        group.addControl(this.toggleCustomCornersPlacementButton);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selection = event.selections.find((x: { model: { id: number; }; }) => x.model.id === this.customCornersEditor.model.id);

        const elementDbId = selection?.dbIdArray[0];

        this.raiseSelectionChanged(elementDbId);
    }

    private async switchPanelType(panelId: string, targetTypeId: string, panelDbId?: number) {
        const result = await this.customCornersEditor.switchPanelType(panelId, targetTypeId);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        this.raiseSelectionChanged(panelDbId);

        return result;
    }

    private async onSwitchPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;
        const corner = this.customCornersEditor.findCustomCornerById(panelId);
        let savedPanelType = corner?.customPanelTypeId;
        const savedInternalId = corner?.internalId;

        const undoRedo = async () => {
            if (savedPanelType && savedInternalId) {
                const corner = this.customCornersEditor.findCustomCornerByInternalId(savedInternalId);
                const temp = corner?.customPanelTypeId;

                await this.switchPanelType(corner?.id || panelId, savedPanelType, corner?.panelDbId);

                savedPanelType = temp;
            }
        };

        const result = await this.switchPanelType(panelId, targetTypeId, corner?.panelDbId);

        if (result.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Switch corner type", undo: undoRedo, redo: undoRedo },
                },
            });
        }
    }

    private async renameElement(cornerId: string, elementName: string) {
        const result = await this.customCornersEditor.setElementName(cornerId, elementName);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        const panel = this.customCornersEditor.findCustomCornerById(cornerId);

        this.raiseSelectionChanged(panel?.panelDbId);
    }

    private async onChangeElementName(event: IApplicationEvent<ElementParameterChangedEventPayload>) {
        const { id, elementName } = event.payload;
        const corner = this.customCornersEditor.findCustomCornerById(id);
        let savedElementName = corner?.elementName ?? "";
        const savedInternalId = corner?.internalId;

        const undoRedo = async () => {
            const corner = this.customCornersEditor.findCustomCornerByInternalId(savedInternalId || id);
            const temp = corner?.elementName ?? "";

            await this.renameElement(corner?.id || id, savedElementName);

            savedElementName = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Rename element", undo: undoRedo, redo: undoRedo },
            },
        });

        await this.renameElement(id, elementName);
    }

    private onOffsetChangingByGizmo(event: IApplicationEvent<CustomCornerGizmoDraggingPayload>) {
        const { id, offset } = event.payload;

        this.customCornersEditor.setCornerOffsetLocal(id, offset.offset);

        const panel = this.customCornersEditor.findCustomCornerById(id);

        this.raiseSelectionChanged(panel?.panelDbId, true);
    }

    private async moveCustomCorner(
        panelId: string,
        targetOffset: UpdateCustomCornerOffsetCommand,
        initialOffset?: UpdateCustomCornerOffsetCommand,
    ) {
        const corner = this.customCornersEditor.findCustomCornerById(panelId);
        const savedInternalId = corner?.internalId;
        let savedOffset: UpdateCustomCornerOffsetCommand = initialOffset
            ? structuredClone(initialOffset)
            : {
                offset: corner?.offset ?? targetOffset.offset,
            };

        const undoRedo = async () => {
            const corner = this.customCornersEditor.findCustomCornerByInternalId(savedInternalId || panelId);
            const temp: UpdateCustomCornerOffsetCommand = {
                offset: corner?.offset ?? targetOffset.offset,
            };

            await this.customCornersEditor.setOffset(panelId, savedOffset);

            savedOffset = temp;
            this.raiseSelectionChanged(corner?.panelDbId);
        };

        const result = await this.customCornersEditor.setOffset(panelId, targetOffset);

        if (!result.isSuccess) {
            this.notifyError(result.message);

            this.customCornersEditor.setCornerOffsetLocal(panelId, savedOffset.offset);
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Move custom corner", undo: undoRedo, redo: undoRedo },
                },
            });
        }

        this.raiseSelectionChanged(corner?.panelDbId);
    }

    private async onOffsetChangingByGizmoCompleted(
        event: IApplicationEvent<CustomCornerGizmoDraggingCompletedEventPayload>,
    ) {
        await this.moveCustomCorner(event.payload.id, event.payload.targetOffset, event.payload.initialOffset);
    }

    private async onChangeOffset(event: IApplicationEvent<ChangeCustomCornerOffsetEventPayload>) {
        await this.moveCustomCorner(event.payload.id, event.payload);
    }

    private async createCustomCorner(
        cornerData: CustomCornerPlacementRequestEventPayload,
    ): Promise<BasicItemResponse<CustomCorner>> {
        const result = await this.customCornersEditor.createNewCustomCorner(cornerData);

        eventBus.dispatchEvent({ type: "Dextall.CustomCorners.Created", payload: null });

        if (!result.isSuccess) {
            this.notifyError(result.message);

            return result;
        }

        if (cornerData.single) {
            this.viewer.toolController.deactivateTool(this.customCornerPlacementTool.getName());

            this.viewer.select(result.item.panelDbId, this.customCornersEditor.model);
        }

        return result;
    }

    private async onCustomCornerCreateRequest(event: IApplicationEvent<CustomCornerPlacementRequestEventPayload>) {
        const undo = () => {
            if (savedInternalId) {
                const corner = this.customCornersEditor.findCustomCornerByInternalId(savedInternalId);
                if (corner) {
                    this.removeCustomCorner(corner);
                }
            }
        };

        const redo = async () => {
            await this.createCustomCorner({ ...event.payload, internalId: savedInternalId });
        };

        const result = await this.createCustomCorner(event.payload);
        const savedInternalId = result.item?.internalId;

        if (result.item) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Add custom corner", undo, redo },
                },
            });
        }

    }

    private async removeCustomCorner(corner: CustomCorner) {
        this.viewer.select([]);

        const result = await this.customCornersEditor.removeCustomCorner(corner);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }
    }

    private async removeCustomCornerWithUndo(corner: CustomCorner) {
        const savedInternalId = corner.internalId;
        const panelData: CustomCornerPlacementRequestEventPayload = {
            wallCornerId: corner.wallCornerId,
            customCornerTypeId: corner.customPanelTypeId,
            heightIndex: corner.heightIndex,
            offset: corner.offset,
            single: true,
        };

        const undo = async () => {
            await this.createCustomCorner({ ...panelData, internalId: savedInternalId });
        };

        const redo = () => {
            if (savedInternalId) {
                const corner = this.customCornersEditor.findCustomCornerByInternalId(savedInternalId);
                if (corner) {
                    this.removeCustomCorner(corner);
                }
            }
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Remove custom corner", undo, redo },
            },
        });
        await this.removeCustomCorner(corner);
    }

    private async onEntityRemoveRequested() {
        const selectedDbId = this.viewer
            .getAggregateSelection()
            .find(x => x.model.id === this.customCornersEditor.model.id)?.selection[0];

        const corner = selectedDbId !== undefined
            ? this.customCornersEditor.findCustomCorner(selectedDbId)
            : undefined;

        if (!corner) {
            return;
        }

        await this.removeCustomCornerWithUndo(corner);
    }

    private onToggleCustomCornerPlacement() {
        if (!this.isCustomPanelPlacementActive())
            eventBus.dispatchEvent({ type: "Dextall.CustomCorners.OpenLibrarySelector", payload: null });
        else
            this.viewer.toolController.deactivateTool(this.customCornerPlacementTool.getName());
    }

    private onSelectCustomPanelToPlace(event: IApplicationEvent<CustomPanelType>) {
        const cornerType = event.payload;

        if (cornerType.shapeType !== CustomPanelTypeShape.Corner)
            return;

        const availableModelCorners = this.modelCorners.filter(x => isAlmostEqual(x.angle, cornerType.angle));

        if (availableModelCorners.length === 0) {
            this.notifyError(`Can't place a custom corner. There are no corners with angle=${cornerType.angle}\u00B0 in the model`);

            return;
        }

        this.viewer.toolController.activateTool(this.customCornerPlacementTool.getName());

        this.customCornerPlacementTool.setCorners(availableModelCorners, cornerType);
    }

    private onViewToolChanged(event: { toolName: string; active: boolean }) {
        if (event.toolName !== this.customCornerPlacementTool.getName())
            return;

        const newState = event.active
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleCustomCornersPlacementButton?.setState(newState);
    }

    private onEscape() {
        const toolName = this.customCornerPlacementTool.getName();

        const toolController = this.viewer.toolController;

        if (toolController.isToolActivated(toolName))
            toolController.deactivateTool(toolName);
    }

    private isCustomPanelPlacementActive() {
        return this.viewer.toolController.isToolActivated(this.customCornerPlacementTool.getName());
    }

    private raiseSelectionChanged(elementDbId: number | undefined, skipGizmoUpdate = false) {
        const corner = elementDbId !== undefined ? this.customCornersEditor.findCustomCorner(elementDbId) : undefined;

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.SelectionChanged",
            payload: corner ? {
                panel: corner,
                customPanelTypes: this.customCornersEditor.findValidCustomCornerTypesForPanel(corner.id)
            } : null
        });

        this.editorPanel?.setVisible(corner !== undefined);

        if (skipGizmoUpdate)
            return;

        if (corner)
            this.customCornerOffsetGizmo.setPanel(corner);
        else
            this.customCornerOffsetGizmo.disposeGizmo();
    }

    private notifyError(message: string) {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message
        })
    }
}

export const forgeCustomCornersEditorExtensionName = "Dextall.ForgeCustomCornersEditorExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeCustomCornersEditorExtensionName,
    ForgeCustomCornersEditorExtension,
);
