import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { ICustomCornerSource } from "../../../responses/customCornerSource";
import { ICustomZShapedPanelSource } from "../../../responses/customZShapedPanelSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { claddingCellGap, editorCladdingGeometryThickness } from "../defaults";
import { CornerCladdingCellGeometry } from "../geometry/cornerCladdingCellGeometry";
import { ZShapeCladdingCellGeometry } from "../geometry/zShapeCladdingCellGeometry";
import { ModelBuilderObjectIds } from "../ids/modelBuilderObjectIds";
import { Corner } from "./corner";
import { CornerCladdingsFactory } from "./cornerCladdingsFactory";
import { CustomCorner } from "./customCorner";
import { CustomZShapedPanel } from "./customZShapedPanel";
import { HooksCoordinateSystems } from "./hooksCoordinateSystems";
import { WallFacesCollection } from "./wallFacesCollection";

export class CornersFactory {
    private readonly hooksCoordinateSystem: HooksCoordinateSystems;
    private readonly wallCorners = new Map<string, IModelCorner>();
    private readonly cornerCladdingsFactory = new CornerCladdingsFactory();
    private readonly wingBoundaryPlaneMaterial = new THREE.MeshBasicMaterial({ color: "#ffffff", opacity: 0, transparent: true });
    private readonly wallBoundaryLoops: Map<string, BoundaryLoop> = new Map();
    private readonly boundaryLoopsFactory: BoundaryLoopsFactory = new BoundaryLoopsFactory();
    private readonly wallFacesCollection: WallFacesCollection;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, wallCorners: IModelCorner[],
        wallFacesCollection: WallFacesCollection,
        private readonly objectIds: ModelBuilderObjectIds) {
        offset.copy(viewer.model.getGlobalOffset());
        negatedOffset.copy(offset).negate();

        this.wallFacesCollection = wallFacesCollection;

        this.hooksCoordinateSystem = new HooksCoordinateSystems(wallFacesCollection, wallCorners, viewer);

        for (const wallCorner of wallCorners)
            this.wallCorners.set(wallCorner.id, wallCorner);
    }

    create(panelSource: ICornerPanelSource): Corner {
        const wallCorner = this.wallCorners.get(panelSource.wallCornerId);

        if (!wallCorner)
            throw new Error("Can't get wall corner for the corner panel");

        const panelDbId = this.objectIds.getNewId();

        const leftWingHooksCoordinateSystem = this.hooksCoordinateSystem.getCornerWingTransform(panelSource, true);
        const rightWingHooksCoordinateSystem = this.hooksCoordinateSystem.getCornerWingTransform(panelSource, false);

        if (!(leftWingHooksCoordinateSystem && rightWingHooksCoordinateSystem))
            throw new Error("Can't get hooks coordinate systems");

        const leftWallBoundaryLoop = this.getWallBoundaryLoop(wallCorner.left.wallFaceId);
        const rightWallBoundaryLoop = this.getWallBoundaryLoop(wallCorner.right.wallFaceId);

        return new Corner(panelSource, wallCorner, panelDbId, leftWingHooksCoordinateSystem, rightWingHooksCoordinateSystem, offset, this.cornerCladdingsFactory,
            this.wingBoundaryPlaneMaterial, leftWallBoundaryLoop, rightWallBoundaryLoop);
    }

    getWallBoundaryLoop(wallFaceId: string): BoundaryLoop {
        const createdBoundaryLoop = this.wallBoundaryLoops.get(wallFaceId);

        if (createdBoundaryLoop)
            return createdBoundaryLoop;

        const wallFace = this.wallFacesCollection.findById(wallFaceId)!;

        const boundaryLoop = this.boundaryLoopsFactory.createLoop(wallFace, negatedOffset);

        this.wallBoundaryLoops.set(wallFaceId, boundaryLoop);

        return boundaryLoop;
    }

    createCustomCorner(panelSource: ICustomCornerSource, internalId?: string): CustomCorner {
        const wallCorner = this.wallCorners.get(panelSource.wallCornerId);

        if (!wallCorner) {
            throw new Error("Can't get wall corner for the corner panel");
        }

        const panelDbId = this.objectIds.getNewId();

        const leftWing = panelSource.angle > 180 ? wallCorner.right : wallCorner.left;

        const leftWingLength = panelSource.leftWing - claddingCellGap;
        const rightWingLength = panelSource.rightWing - claddingCellGap;
        const height = panelSource.height - 2 * claddingCellGap;

        const cornerCladdingCellGeometry = new CornerCladdingCellGeometry(THREE.Math.degToRad(panelSource.angle),
            leftWingLength, rightWingLength, height, editorCladdingGeometryThickness, leftWing.direction);

        return new CustomCorner(
            panelSource,
            wallCorner,
            this.hooksCoordinateSystem,
            cornerCladdingCellGeometry,
            negatedOffset,
            panelDbId,
            internalId,
        );
    }

    createCustomZShapedPanel(panelSource: ICustomZShapedPanelSource, internalId?: string) {
        const wallCorner = this.wallCorners.get(panelSource.wallCornerId);

        if (!wallCorner)
            throw new Error("Can't get wall corner for the custom z-shaped panel");

        const shelfEndCorner = this.wallCorners.get(panelSource.shelfEndWallCornerId);

        if (!shelfEndCorner)
            throw new Error("Can't get shelf end wall corner for the custom z-shaped panel");

        const panelDbId = this.objectIds.getNewId();

        const leftWing = wallCorner.left.wallFaceId === shelfEndCorner.left.wallFaceId || wallCorner.left.wallFaceId === shelfEndCorner.right.wallFaceId
            ? wallCorner.right
            : wallCorner.left;

        const leftWingLength = panelSource.leftWingLength - claddingCellGap;
        const shelfLength = panelSource.shelfLength;
        const rightWingLength = panelSource.rightWingLength - claddingCellGap;
        const height = panelSource.height - 2 * claddingCellGap;

        const geometry = new ZShapeCladdingCellGeometry(THREE.Math.degToRad(panelSource.angle1), THREE.Math.degToRad(panelSource.angle2),
            leftWingLength, shelfLength, rightWingLength, height, editorCladdingGeometryThickness, leftWing.direction);

        return new CustomZShapedPanel(panelSource, geometry, wallCorner, negatedOffset, panelDbId, internalId);
    }
}

const offset = new THREE.Vector3();
const negatedOffset = new THREE.Vector3();