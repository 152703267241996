import React, { useEffect, useRef, useState } from "react"
import { claddingCellGap } from "../../../defaults";
import { CornerHook } from "../../../panels/cornerHook";
import { Hook } from "../../../panels/hook";
import { CladdingCellBox2D } from "./claddingCellBox2D";
import { DimensionType } from "./dimension2D";
import { Hook2D } from "./hook2D";
import { HookDimension2DEditor } from "./hookDimension2DEditor";
import { Panel2DHooksDimensions } from "./panel2DHooksDimensions";
import { newHookCursor } from "../../../viewer-tools/toolCursors";
import { CladdingCellStyle } from "../../../../../responses/panelSource";
import { findPredefinedCladdingDepthIndex, findPredefinedSheetMetallThicknessIndex, predefinedCladdingDepths } from "@dextall/panels-defaults";
import "./panelEditor2D.css"

export type CladdingCell = {
    box: THREE.Box3;
    color: string;
    material: string;
    titleColor: string;
}

type Props = {
    length: number;
    height: number;
    cells: CladdingCell[];
    hooks: (Hook | CornerHook)[];
    selectedHook: Hook | CornerHook | null;
    isMetric: boolean;
    is2DHookPlacementActive: boolean,
    setIs2DHookPlacementActive: (isActive: boolean) => void;
    onHookPlacementRequested: (position: THREE.Vector2) => void;
}

type SelectedDimension = {
    type: DimensionType;
    position: { x: number, y: number }
}

export const PanelEditor2D = ({ length, height, cells, hooks, selectedHook, isMetric,
    is2DHookPlacementActive, onHookPlacementRequested, setIs2DHookPlacementActive }: Props) => {
    const [selectedDimension, setSelectedDimension] = useState<SelectedDimension>();
    const panelRect = useRef<SVGRectElement>(null);

    useEffect(() => {
        setSelectedDimension(undefined);
    }, [length, height, cells, hooks, selectedHook, isMetric]);

    const toggle2DHookPlacement = () => {
        setIs2DHookPlacementActive(!is2DHookPlacementActive);
    }

    const onPanelRectangleClick = (event: React.MouseEvent) => {
        if (!is2DHookPlacementActive)
            return;

        const panelsRectBound = panelRect.current!.getBoundingClientRect();

        const scale = length / panelsRectBound.width;

        const position = new THREE.Vector2(
            (event.clientX - panelsRectBound.x) * scale,
            height - (event.clientY - panelsRectBound.y) * scale
        );

        onHookPlacementRequested(position);
    }

    return <div style={{ width: "100%", height: "100%", position: "relative", marginTop: 16 }}>
        <svg viewBox={`${-3 * claddingCellGap} ${-3 * claddingCellGap} ${length + 6 * claddingCellGap} ${height + 6 * claddingCellGap}`}
            style={{ width: "100%", height: "100%" }}
            transform={`scale(1 -1) translate(0 ${height})`}
            onClick={() => setSelectedDimension(undefined)}>
            {cells.map((x, i) => <CladdingCellBox2D cell={x} key={`cladding-cell-2d-box-${i + 1}`} />)}
            <rect x="0" y="0" width={length} height={height}
                fill="white"
                stroke="none"
                className="panel-rect"
                style={{ cursor: is2DHookPlacementActive ? newHookCursor : "default" }}
                onClick={onPanelRectangleClick}
                ref={panelRect} />
            {hooks.map((x, i) => <Hook2D hook={x} isSelected={x.dbId === selectedHook?.dbId} key={`hook-2d-${i + 1}`} />)}
            {selectedHook && <Panel2DHooksDimensions
                hook={selectedHook}
                panelLength={length}
                panelHeight={height}
                isMetric={isMetric}
                selectedDimension={selectedDimension?.type}
                selectDimension={(dimension, position) => setSelectedDimension({ type: dimension, position })} />}
        </svg>
        {selectedHook && selectedDimension && <HookDimension2DEditor
            hook={selectedHook}
            dimensionType={selectedDimension.type}
            position={selectedDimension.position}
            panelLength={length}
            panelHeight={height}
            panelRect={panelRect.current!} />}

        <div className={`adsk-control adsk-button dextall-2d-new-hook new-hook-button ${is2DHookPlacementActive ? "active" : "inactive"}`} onClick={toggle2DHookPlacement}>
            <div className="adsk-button-icon viewer-tools-new-hook"> </div>
            <div className="adsk-control-tooltip">New hook</div>
        </div>
    </div>
}

export const getCladdingCellMaterial = (style: CladdingCellStyle, sheetMetalThickness: number, depth: number): string => {
    if (style === CladdingCellStyle.Acm)
        return "ACM";

    const defaultSheetMetalThicknessIndex = findPredefinedSheetMetallThicknessIndex(sheetMetalThickness);

    const defaultSheetMetalDepthIndex = findPredefinedCladdingDepthIndex(depth);

    const claddingDepth = predefinedCladdingDepths[defaultSheetMetalDepthIndex].name;

    switch (defaultSheetMetalThicknessIndex) {
        case 0:
            return `AL2 - ${claddingDepth}`;

        case 1:
            return `AL3 - ${claddingDepth}`;

        default:
            throw new Error("Invalid state");
    }
}