import { SnapGrid } from "../geometry/snapGrid";
import { CustomWallPanelOffsetGizmo } from "../gizmos/customWallPanelOffsetGizmo";
import { CustomPanel } from "../panels/customPanel";
import { ToolInterface } from "./toolInterface";

const overlayName = "dextall-custom-panel-offset-tool";

export class CustomWallPanelOffsetTool extends ToolInterface {
    private viewer: Autodesk.Viewing.Viewer3D | null = null;
    private gizmo: CustomWallPanelOffsetGizmo | null = null;

    constructor() {
        super();
        this.updateGizmo = this.updateGizmo.bind(this);

        this.names = ["dextall-editor-custom-wall-panels-offset-tool"]
    }

    setPanel(panel: CustomPanel) {
        if (!this.viewer)
            throw new Error("The tool must be activated before setting the custom panel!");

        this.disposeGizmo();

        const snapGrid = new SnapGrid(this.viewer.model);

        this.gizmo = new CustomWallPanelOffsetGizmo(this.viewer, panel, snapGrid);

        this.viewer.impl.addOverlay(overlayName, this.gizmo.transformControl);
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        this.viewer = viewer;
        this.viewer.impl.createOverlayScene(overlayName);
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
    }

    deactivate(): void {
        this.disposeGizmo();
        if (this.viewer) {
            this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
            this.viewer.impl.removeOverlayScene(overlayName);
        }

        this.viewer = null;
    }

    getPriority() {
        return 42; // Use any number higher than 0 (the priority of all default tools)
    }

    handleButtonDown(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.gizmo && button === 0))
            return false;

        const result = this.gizmo.startDraggingAt(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        return result;
    }

    handleButtonUp(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.gizmo && button === 0))
            return false;

        const result = this.gizmo.endDraggingAt(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        return result;
    }

    handleMouseMove(event: MouseEvent): boolean {
        if (!(this.viewer && this.gizmo))
            return false;

        const result = this.gizmo.handleMouseMove(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        return result;
    }

    disposeGizmo() {
        if (this.viewer && this.gizmo)
            this.viewer.impl.removeOverlay(overlayName, this.gizmo.transformControl);

        this.gizmo?.dispose();
        this.gizmo = null;
    }

    private updateGizmo() {
        this.gizmo?.update();
    }
}