import Dropdown from "@weave-design/dropdown";
import IconButton from "@weave-design/icon-button";
import { Edit16, DocumentManagement16, Unlink16 } from "@weave-design/icons";
import Input from "@weave-design/input";
import Label from "@weave-design/label";
import { noop } from "../../shared";
import { EditorPanelType } from "./editorPanelType";
import "./inputs.css";

type Props = {
    title: string;
    panelTypes: EditorPanelType[];
    selectedPanelType: EditorPanelType | undefined;
    selectedCustomPanelTypeName?: string | undefined;
    selectCustomPanelTypeAvailable?: boolean;
    onChange: (value: EditorPanelType) => void;
    onEditTypeRequested: () => void;
    onCustomPanelTypeRequested?: () => void | undefined;
    onCustomPanelTypeReset?: () => void | undefined;
};

export const PanelTypeSelector = ({
    title,
    panelTypes,
    selectedPanelType,
    selectedCustomPanelTypeName,
    onChange,
    selectCustomPanelTypeAvailable,
    onEditTypeRequested,
    onCustomPanelTypeRequested,
    onCustomPanelTypeReset,
}: Props) => {
    const inputValueContainerWidth = `calc(75% - ${onCustomPanelTypeRequested !== undefined ? "52" : "26"}px)`;

    return (
        <div className="property-input-container">
            <div className="property-input-title-container">
                <Label>{title}</Label>
            </div>
            <div className="property-input-value-conainer" style={{ width: inputValueContainerWidth }}>
                {selectedCustomPanelTypeName === undefined && (
                    <Dropdown
                        options={panelTypes.map(toDropdownOption)}
                        value={toDropdownOption(selectedPanelType)}
                        onChange={(value: string) => onChange(fromDropDownOption(value))}
                        formatOption={(item: string) => fromDropDownOption(item).name}
                    />
                )}
                {selectedCustomPanelTypeName !== undefined && (
                    <Input value={selectedCustomPanelTypeName} disabled={true} onChange={noop} />
                )}
            </div>
            <div style={{ width: 26, marginTop: "auto" }}>
                <IconButton
                    icon={<Edit16 />}
                    title="Edit type"
                    disabled={selectedCustomPanelTypeName !== undefined}
                    onClick={onEditTypeRequested}
                />
            </div>
            {onCustomPanelTypeRequested &&
                (selectedCustomPanelTypeName === undefined || selectCustomPanelTypeAvailable) && (
                <div style={{ width: 26, marginTop: "auto" }}>
                    <IconButton
                        icon={<DocumentManagement16 />}
                        title="Select custom library component"
                        onClick={onCustomPanelTypeRequested}
                    />
                </div>
            )}
            {onCustomPanelTypeReset && selectedCustomPanelTypeName !== undefined && (
                <div style={{ width: 26, marginTop: "auto" }}>
                    <IconButton icon={<Unlink16 />} title="Reset to standard type" onClick={onCustomPanelTypeReset} />
                </div>
            )}
        </div>
    );
};

const splitter = String.fromCharCode(7);

const toDropdownOption = (item: EditorPanelType | undefined): string => {
    if (item === undefined) {
        return "";
    }

    return `${item.id || ""}${splitter}${item.name}${splitter}${item.withoutCladdings ? "true" : ""}`;
};

const fromDropDownOption = (dropDownValue: string): EditorPanelType => {
    const [id, name, withoutCladdings] = dropDownValue.split(splitter);

    return { id: id !== "" ? id : undefined, name, withoutCladdings: !!withoutCladdings };
};
