import { Download } from "react-feather";
import { GenerateNewRevitHooksModelControls } from "./generateNewRevitHooksModelControls";
import eventBus from "../../../../eventBus/eventDispatcher";
import "../../../../../processing-failure/modalFail.css";

type Props = {
    id: string;
};

export const GeneratedHooksModel = ({ id }: Props) => {
    return <>
        <div className="generated-revit-model-content-button-container" title="Download hooks model">
            <Download
                size={16}
                color="#0796D8"
                onClick={() => eventBus.dispatchEvent({ type: "Dextall.HooksRevitModel.Download", payload: id })} />
        </div>

        <GenerateNewRevitHooksModelControls isRegenerate={true} />
    </>;
};