import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IHookSource } from "../../../responses/hookSource";
import { IPanelSource } from "../../../responses/panelSource";
import { IPanelType } from "../../../responses/panelType";
import { Corner } from "./corner";
import { Panel } from "./panel";

type PanelsType<T> =
    T extends IPanelSource ? Panel :
        T extends ICornerPanelSource ? Corner :
            never;

export class PanelType<T extends IPanelSource | ICornerPanelSource> {
    private readonly _panels = new Map<string, PanelsType<T>>();
    private _withoutCladdings: boolean;

    constructor(panelTypeSource: IPanelType) {
        this.id = panelTypeSource.id;
        this.hooks = panelTypeSource.hooks;
        this.name = panelTypeSource.name;
        this._withoutCladdings = panelTypeSource.withoutCladdings;
    }

    readonly id: string;

    readonly hooks: IHookSource[];

    name: string;

    get panels(): PanelsType<T>[] {
        return Array.from(this._panels.values());
    }

    get withoutCladdings(): boolean {
        return this._withoutCladdings;
    }

    set withoutCladdings(value: boolean) {
        if (this._withoutCladdings === value) {
            return;
        }

        this._withoutCladdings = value;

        for (const panel of this.panels) {
            panel.setPanelType(this);
        }
    }

    addPanel(panel: PanelsType<T>) {
        panel.setPanelType(this);

        this._panels.set(panel.id, panel);
    }

    addHook(hook: IHookSource, index: number) {
        this.hooks.splice(index, 0, hook);
    }

    updateHook(index: number, hook: IHookSource) {
        const panelHook = this.hooks[index];

        panelHook.x = hook.x;
        panelHook.y = hook.y;
        panelHook.hookType = hook.hookType;
        panelHook.profileSide = hook.profileSide;
    }

    removePanel(panel: PanelsType<T>) {
        this._panels.delete(panel.id);
    }

    removeHook(index: number) {
        this.hooks.splice(index, 1);
    }
}