import React from "react"
import "./editor2DContent.css"

export type Tab = "editor" | "model" | "drawing";

export type TabsVisibility = {
    editor: boolean;
    model: boolean;
    drawings: boolean;
}

type Props = {
    selectedTab: Tab;
    tabVisibility?: TabsVisibility;
    setSelectedTab: (tab: Tab) => void;
}

export const Editor2DMenu = ({ selectedTab, setSelectedTab, tabVisibility: tabVisibilityPreference }: Props) => {
    const tabVisibility: TabsVisibility = tabVisibilityPreference || { editor: true, model: true, drawings: true };

    return <div className="editor-2d-menu-row">
        {tabVisibility.editor && <div className="editor-2d-menu-item" style={selectedTab === "editor" ? selectedTabStyle : tabStyle} onClick={() => setSelectedTab("editor")}>Editor</div>}
        {tabVisibility.model && <div className="editor-2d-menu-item" style={selectedTab === "model" ? selectedTabStyle : tabStyle} onClick={() => setSelectedTab("model")}>Model</div>}
        {tabVisibility.drawings && <div className="editor-2d-menu-item" style={selectedTab === "drawing" ? selectedTabStyle : tabStyle} onClick={() => setSelectedTab("drawing")}>Drawings</div>}
    </div>
}

const selectedTabStyle: React.CSSProperties = { color: "#333333" };
const tabStyle: React.CSSProperties = { cursor: "pointer" };