import ModelsGenerationListContent from "./content/modelsGenerationsList/modelsGenerationListContent";
import { DockingPanel } from "./dockingPanel";

export class ModelsGenerationListDockingPanel {
    private readonly panel: DockingPanel;
    private _visible: boolean;

    constructor(container: HTMLElement, onVisibilityChanged: (visibility: boolean) => void) {
        this._visible = false;

        const component = <ModelsGenerationListContent />

        const panelWidth = 350;
        const panelHeight = 505;

        this.panel = new DockingPanel(container, "dextall-models-generation-list-docking-panel", "Models Generation List", component, {
            style: {
                resize: "none",
            },
            dimensionsControl: {
                preventOverlapsWithViewerToolbar: true,
                widthDimensionControl: { value: panelWidth, minValue: panelWidth },
                heightDimensionControl: { value: panelHeight, minValue: panelHeight },
                rightOffset: 4,
                topOffset: 4,
            },
        });

        this.panel.addVisibilityListener((x: boolean) => {
            this._visible = x;
            onVisibilityChanged(x);
        });
    }

    get visible() {
        return this._visible;
    }

    setVisible(visibility: boolean) {
        if (this._visible === visibility) {
            return;
        }

        this._visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}