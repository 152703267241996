import IconButton from "@weave-design/icon-button";
import { List24, DocumentManagement24 } from "@weave-design/icons";
import "./toolbar.css";

type Props = {
    libraryOpened: boolean;
    setLibraryOpened: (opened: boolean) => void;
    setNewCustomPanelDialogOpened: (opened: boolean) => void;
};

export const Toolbar = ({ libraryOpened, setLibraryOpened, setNewCustomPanelDialogOpened }: Props) => {
    return <div className="library-components-toolbar">
        <div className="library-components-toolbar-buttons-container">
            <IconButton icon={<List24 />} title="Library" onClick={() => setLibraryOpened(!libraryOpened)} />

            <IconButton icon={<DocumentManagement24 />} title="New component" onClick={setNewCustomPanelDialogOpened} />
        </div>
    </div>;
};