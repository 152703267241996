import Typography from "@weave-design/typography";
import { CustomPanelType, CustomPanelTypeStatus, ICustomPanelType } from "../../responses/customPanelTypes";
import { CustomComponentCard } from "./customComponentCard";
import { formatLength } from "./customComponentCommon";
import "./customPanelComponentCard.css";

type Props = {
    panel: ICustomPanelType;
    selectLibraryComponent: (component: CustomPanelType) => void;
    currentCustomPanelTypeId?: string;
};

export const CustomPanelComponentCard = ({ panel, selectLibraryComponent, currentCustomPanelTypeId }: Props) => {
    const width = panel.status === CustomPanelTypeStatus.Published
        ? formatLength(panel.length)
        : "???";
    const height = panel.status === CustomPanelTypeStatus.Published
        ? formatLength(panel.height)
        : "???";

    return (
        <CustomComponentCard
            panel={panel}
            selectLibraryComponent={selectLibraryComponent}
            currentCustomPanelTypeId={currentCustomPanelTypeId}
            typeIcon="custom-panel-component-card-stats-custom-panel-type"
            panelProperties={
                <>
                    <div>
                        <Typography>Width: {width}</Typography>
                    </div>
                    <div>
                        <Typography>Height: {height}</Typography>
                    </div>
                </>
            }
        />
    );
};
