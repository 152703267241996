import { PanelEditorDockingPanelContent } from "./content/panelEditorDockingPanelContent";
import { DockingPanel } from "./dockingPanel";

export class PanelDockingPanel {
    private readonly panel: DockingPanel;
    private visible: boolean;

    constructor(container: HTMLElement) {
        this.visible = false;

        const component = (<PanelEditorDockingPanelContent />);

        this.panel = new DockingPanel(container, "dextall-studio-wall-panel-editor", "Panel properties", component, {
            style: {
                width: "360px",
                height: "550px",
                minWidth: "360px",
                minHeight: "550px",
                resize: "both",
                left: "43px",
                top: "2px",
            },
        });
        this.panel.addVisibilityListener((x: boolean) => this.visible = x);
    }

    setVisible(visibility: boolean) {
        if (this.visible === visibility) {            
            return;
        }

        this.visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}