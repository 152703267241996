import { CSSProperties } from "react";
import Modal from "@weave-design/modal";
import { ProcessingIndicator } from "./processingIndicator";

type Props = {
    isVisible: boolean;
    title: string;
    message: string | null;
    onClose?: () => void;
};

type ModalStyles = {
    modal: {
        window: CSSProperties;
        bodyContent: CSSProperties;
    };
};

export const ProcessingPane = ({ isVisible, title, message, onClose }: Props) => {
    return (
        <div className="dialog">
            <Modal open={isVisible} title={title} stylesheet={modalStyles} onCloseClick={onClose}>
                <ProcessingIndicator message={message || ""} />
            </Modal>
        </div>
    );
};

const modalStyles = (styles: ModalStyles) : ModalStyles => {
    return {
        ...styles,
        modal: {
            ...styles.modal,
            window: {
                ...styles.modal.window,
                width: "400px",
                height: "200px",
                top: "calc(50% - 100px)",
            },
            bodyContent: {
                ...styles.modal.bodyContent,
                overflow: "hidden",
            },
        },
    };
};
