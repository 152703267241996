import { ITreeItem, ITreeItemBox, RTree } from "@dextall/rtree";
import { isAlmostEqual } from "@dextall/shared";
import { ICustomZShapeType } from "../../../../responses/customPanelTypes";
import { IModelCorner } from "../../../../responses/modelCorner";
import { IWallFace } from "../../../../responses/wallFace";
import { WallFacesCollection } from "../wallFacesCollection";
import { ZShapeCorner } from "./zShapeCorner";

export class ZShapeCornersCollection {
    private readonly trees = new Map<string, RTree<ZShapeCorner>>;

    private constructor(corners: ZShapeCorner[]) {
        for (const corner of corners) {
            const key = getCornerKey(corner);

            const tree = this.trees.get(key) || new RTree<ZShapeCorner>();

            tree.insert(mapToTreeItem(corner));

            this.trees.set(key, tree);
        }
    }

    findModelCornersForCustomZShapedPanelType(customPanelType: ICustomZShapeType): ZShapeCorner[] {
        const key = getCustomPanelTypeKey(customPanelType);

        const tree = this.trees.get(key);

        if (!tree)
            return [];

        const searchBox = mapToSearchBox(customPanelType);

        return tree
            .search(searchBox)
            .map(x => x.payload)
            .filter((x): x is ZShapeCorner => x !== undefined
                && isAlmostEqual(x.shelf, customPanelType.shelfLength)
                && isAlmostEqual(x.wallCorner.angle, customPanelType.angle1));
    }

    static create(wallFacesCollection: WallFacesCollection, corners: IModelCorner[]): ZShapeCornersCollection {
        const leftCornersByShelfWallFace = new Map<string, IModelCorner[]>();
        const rightCornersByShelfWallFace = new Map<string, IModelCorner[]>();

        for (const corner of corners) {
            const leftFaceId = corner.angle <= 180 ? corner.left.wallFaceId : corner.right.wallFaceId;
            const rightFaceId = corner.angle <= 180 ? corner.right.wallFaceId : corner.left.wallFaceId;

            addToDictionary(leftCornersByShelfWallFace, rightFaceId, corner);
            addToDictionary(rightCornersByShelfWallFace, leftFaceId, corner);
        }

        const zCorners: ZShapeCorner[] = [];

        for (const wallFace of wallFacesCollection.asArray()) {
            const leftCorners = leftCornersByShelfWallFace.get(wallFace.id) || [];
            const rightCorners = rightCornersByShelfWallFace.get(wallFace.id) || [];

            const wallFaceCorners = createCorners(wallFace, leftCorners, rightCorners);

            zCorners.splice(zCorners.length, 0, ...wallFaceCorners);
        }

        return new ZShapeCornersCollection(zCorners);
    }
}

const createCorners = (wallFace: IWallFace,
    leftCorners: IModelCorner[],
    rightCorners: IModelCorner[]): ZShapeCorner[] => {
    const shelf = wallFace.box.max.x - wallFace.box.min.x;
    const shelfWallFaceId = wallFace.id;

    const corners: ZShapeCorner[] = [];

    for (const leftCorner of leftCorners) {
        for (const rightCorner of rightCorners)
            corners.push({ wallCorner: leftCorner, shelfEndCorner: rightCorner, shelf, shelfWallFaceId });
    }

    return corners;
};

const addToDictionary = (dictionary: Map<string, IModelCorner[]>,
    id: string, corner: IModelCorner) => {
    const corners = dictionary.get(id) || [];

    corners.push(corner);

    dictionary.set(id, corners);
};

const getCornerKey = (corner: ZShapeCorner): string => corner.shelfEndCorner.angle.toFixed(2);

const getCustomPanelTypeKey = (panelType: ICustomZShapeType): string => panelType.angle2.toFixed(2);

const itemSize = 1e-3;

const mapToTreeItem = (corner: ZShapeCorner): ITreeItem<ZShapeCorner> => {
    return {
        minX: corner.wallCorner.angle - 0.5 * itemSize,
        minY: corner.shelf - 0.5 * itemSize,
        maxX: corner.wallCorner.angle + 0.5 * itemSize,
        maxY: corner.shelf + 0.5 * itemSize,
        payload: corner,
    };
};

const mapToSearchBox = (panelType: ICustomZShapeType): ITreeItemBox => {
    return {
        minX: panelType.angle1 - 0.5 * itemSize,
        minY: panelType.shelfLength - 0.5 * itemSize,
        maxX: panelType.angle1 + 0.5 * itemSize,
        maxY: panelType.shelfLength + 0.5 * itemSize,
    };
};