import { useEffect, useState } from "react";
import DropDown from "@weave-design/dropdown";
import Label from "@weave-design/label";
import "./inputs.css";

export type EnumPropertyOption = [number, string];

type Props = {
    items: EnumPropertyOption[];
    value: number;
    title: string;
    onChange: (newValue: number) => void;
};

export const EnumPropertySelector = ({ items, value, title, onChange }: Props) => {
    const [options, setOptions] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        const opts = enumOptionsToAray(items);
        setOptions(opts);
        setSelectedOption(opts[value]);
    }, [value, items]);

    const onSelect = (value: string) => {
        setSelectedOption(value);
        onChange(options.indexOf(value));
    };

    return <div className="property-input-container">
        <div className="property-input-title-container">
            <Label>{title}</Label>
        </div>
        <div className="property-input-value-conainer">
            <DropDown
                options={options}
                value={selectedOption}
                onChange={(value: string) => onSelect(value)}/>
        </div>
    </div>;
};

const enumOptionsToAray = (options: EnumPropertyOption[]) => {
    const items: string[] = [];

    for (const option of options) {
        items[option[0]] = option[1];
    }

    return items;
};