import { RevitModelContent } from "./content/revitModelContent";
import { DockingPanel, IDockingPanelOptions } from "./dockingPanel";

export class RevitModelDockingPanel {
    private readonly panel: DockingPanel;
    private _visible: boolean;

    constructor(container: HTMLElement, onVisibilityChanged: (visibility: boolean) => void) {
        this._visible = false;

        const component = <RevitModelContent />;

        const containerRect = container.getBoundingClientRect();

        const panelWidth = 500;

        const options: IDockingPanelOptions = {
            style: {
                resize: "both",
            },
            dimensionsControl: {
                preventOverlapsWithViewerToolbar: true,
                widthDimensionControl: { value: panelWidth, minValue: panelWidth },
                heightDimensionControl: { value: 200, minValue: 200 },
                leftOffset: 0.5 * (containerRect.width - panelWidth),
                bottomOffset: 2,
            },
            carefullBringToFront: true,
        };

        this.panel = new DockingPanel(container, "dextall-revit-hooks-model-docking-panel", 
            "Revit models", component, options);

        this.panel.addVisibilityListener((x: boolean) => {
            this._visible = x;
            onVisibilityChanged(x);
        });
    }

    get visible() {
        return this._visible;
    }

    setVisible(visibility: boolean) {
        if (this._visible === visibility) {
            return;
        }

        this._visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}