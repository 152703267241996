import { useState } from "react";
import Button from "@weave-design/button";
import { defaultOffsetY } from "../../defaults";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import eventBus from "../../eventBus/eventDispatcher";

export const HooksDefaultOffsetDockingPanelContent = () => {
    const [offsetY, setOffsetY] = useState<number>(defaultOffsetY);

    const updateHooksDefaultOffset = () => {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.ChangeDefaultOffset",
            payload: offsetY,
        });
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight">
                <ImperialAndMetricDimensionEditor
                    title="Offset Y:"
                    value={offsetY}
                    onChange={value => setOffsetY(value)}
                />
                <div style={{ marginTop: "16px", textAlign: "right" }}>
                    <Button
                        size="standard"
                        type="primary"
                        title="Apply"
                        onClick={updateHooksDefaultOffset}
                        style={{ width: 110 }}
                    />
                </div>
            </div>
        </div>
    );
};
