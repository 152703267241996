import { newPanelToolCursor, placePanelToolCursor } from "./toolCursors";
import { ToolInterface } from "./toolInterface";
import { CladdingCellExtrusionGeometryFactory } from "../geometry/claddingCellExtrusionGeometryFactory";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { WallFacesCollection } from "../panels/wallFacesCollection";
import { ICustomPanelType } from "../../../responses/customPanelTypes";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { SnapGrid } from "../geometry/snapGrid";
import { isAlmostEqual } from "@dextall/shared";
import customComponentColorsFactory from "../colors/customComponentColorsFactory";
import eventBus from "../eventBus/eventDispatcher";

export const newCustomPanelPlacementToolName = "dextall-editor-custom-panel-placement-tool";
const overlayName = "dextall-editor-new-custom-panel-overlay";

type PanelPlacement = {
    wallDbId: number;
    offsetX: number;
    offsetY: number;
    transform: THREE.Matrix4;
}

export class CustomPanelPlacementTool extends ToolInterface {
    private readonly material: THREE.ShaderMaterial = customComponentColorsFactory.getDefaultOverlayMaterial();
    private readonly geometryFactory = new CladdingCellExtrusionGeometryFactory();
    private readonly boundaryLoopsFactory = new BoundaryLoopsFactory();
    private wallFacesBoundaryLoops = new Map<number, BoundaryLoop>();
    private viewer: Autodesk.Viewing.Viewer3D | null = null;
    private customPanelType: ICustomPanelType | null = null;
    private mesh: THREE.Mesh | null = null;
    private panelPlacement: PanelPlacement | null = null;
    private suspended = false;

    constructor(private readonly wallFacesCollection: WallFacesCollection, private readonly snapGrid: SnapGrid) {
        super();

        this.names = [newCustomPanelPlacementToolName];
    }

    getPriority() {
        return 42; // Use any number higher than 0 (the priority of all default tools)
    }

    getCursor(): string | null {
        return this.panelPlacement !== null ? placePanelToolCursor : newPanelToolCursor;
    }

    setCustomPanelType(customPanelType: ICustomPanelType) {
        this.customPanelType = customPanelType;
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        if (!this.customPanelType)
            throw new Error("Custom panel type must be set before activating the extension!");

        this.viewer = viewer;
        this.viewer.impl.createOverlayScene(overlayName);
    }

    handleButtonDown(_event: MouseEvent, _button: number) {
        this.suspended = true;
        return false;
    }

    handleButtonUp(_event: MouseEvent, _button: number) {
        this.suspended = false;
        return false;
    }

    handleMouseMove(event: MouseEvent): boolean {
        if (this.suspended || !this.viewer)
            return false;

        const hitTestResults = this.viewer.impl.hitTest(event.canvasX, event.canvasY, true, this.wallFacesCollection.getAllDbIds(), [this.viewer.model.id]);

        const previousPlacement = this.panelPlacement;

        const canPlacePanel = hitTestResults && this.wallFacesCollection.findByDbId(hitTestResults.dbId).length > 0;

        if (!canPlacePanel) {
            this.cleanupPlacement();

            this.panelPlacement = null;

            return false;
        }

        const mesh = this.mesh = this.mesh || this.createMesh();

        const placement = this.getPlacement(hitTestResults);

        if (placement === this.panelPlacement)
            return true;

        this.panelPlacement = placement;

        mesh.matrix.copy(placement.transform);
        mesh.matrix.decompose(mesh.position, mesh.quaternion, mesh.scale);

        if (!previousPlacement)
            this.viewer.impl.addOverlay(overlayName, mesh);
        else
            this.viewer.impl.invalidate(true, false, true);

        return true;
    }

    handleSingleClick(event: MouseEvent, button: number): boolean {
        if (!(this.customPanelType && this.panelPlacement && button === 0))
            return false;

        const wallFaceId = this.wallFacesCollection.findByDbId(this.panelPlacement.wallDbId)[0].id;

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.CreateNew",
            payload: {
                wallFaceId,
                customPanelTypeId: this.customPanelType.id,
                offsetX: this.panelPlacement.offsetX - 0.5 * this.customPanelType.length,
                offsetY: this.panelPlacement.offsetY - 0.5 * this.customPanelType.height,
                single: !event.ctrlKey
            }
        });

        return true;
    }

    handleKeyDown(event: KeyboardEvent, _keyCode: number) {
        return event.code === "ControlLeft" || event.code === "ControlRight";
    }

    deactivate(): void {
        this.viewer?.impl.removeOverlayScene(overlayName);
        this.viewer = null;
        this.customPanelType = null;
        this.mesh = null;
        this.suspended = false;
        this.panelPlacement = null;
    }

    private createMesh(): THREE.Mesh {
        if (!this.customPanelType)
            throw new Error("Invalid state!");

        const box = new THREE.Box3(
            new THREE.Vector3(),
            new THREE.Vector3(this.customPanelType.length, this.customPanelType.height, 0)
        );

        const boundaryLoop = this.boundaryLoopsFactory.createLoopFromBox(box, identityMatrix);

        const geometry = this.geometryFactory.createCladdingCellExtrusion(boundaryLoop, { material: this.material, thickness: editorCladdingGeometryThickness });

        return new THREE.Mesh(geometry.geometry, geometry.material);
    }

    private getPlacement(hitTestResults: Autodesk.Viewing.Private.HitTestResult): PanelPlacement {
        if (!this.customPanelType)
            throw new Error("Invalid state!")

        const wallFaceBoundaryLoop = this.getWallFaceBoundaryLoop(hitTestResults.dbId);

        wallFaceBoundaryLoop.toLocalCoordinates(hitTestResults.intersectPoint, tempVector);

        tempVector.x = 0.5 * this.customPanelType.length + this.snapGrid.snap(tempVector.x - 0.5 * this.customPanelType.length);
        tempVector.y = 0.5 * this.customPanelType.height + this.snapGrid.snap(tempVector.y - 0.5 * this.customPanelType.height);

        const offsetX = tempVector.x;
        const offsetY = tempVector.y;

        if (this.panelPlacement && this.panelPlacement.wallDbId === hitTestResults.dbId
            && isAlmostEqual(this.panelPlacement.offsetX, tempVector.x)
            && isAlmostEqual(this.panelPlacement.offsetY, tempVector.y))
            return this.panelPlacement;

        tempVector.z = 0.5 * editorCladdingGeometryThickness + zFightingFixDistance;

        wallFaceBoundaryLoop.toWorldCoordinates(tempVector, tempVector);

        temporaryMatrix.copy(wallFaceBoundaryLoop.transform).setPosition(tempVector);

        return {
            wallDbId: hitTestResults.dbId,
            offsetX,
            offsetY,
            transform: temporaryMatrix
        };
    }

    private getWallFaceBoundaryLoop(dbId: number): BoundaryLoop {
        const existingBoundaryLoop = this.wallFacesBoundaryLoops.get(dbId);

        if (existingBoundaryLoop)
            return existingBoundaryLoop;

        const wallFace = this.wallFacesCollection.findByDbId(dbId)[0];

        tempVector.copy(this.viewer!.model.getGlobalOffset()).negate();

        const boundaryLoop = this.boundaryLoopsFactory.createLoop(wallFace, tempVector);

        this.wallFacesBoundaryLoops.set(dbId, boundaryLoop);

        return boundaryLoop;
    }

    private cleanupPlacement() {
        if (this.mesh)
            this.viewer?.impl.removeOverlay(overlayName, this.mesh);
    }
}

const identityMatrix = new THREE.Matrix4();
const temporaryMatrix = new THREE.Matrix4();
const tempVector = new THREE.Vector3();