import { isAlmostEqualToZero } from "@dextall/shared";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { intersectPlanes } from "../geometry/planesIntersection";
import { GeometryEqualityUtils } from "../viewer-utils/geometryEqualityUtils";
import { SelectionHook } from "./types";

const basisZ = new THREE.Vector3(0, 0, 1);

export class WallPlane {
    readonly plane: THREE.Plane;

    constructor(
        public readonly wallFaceId: string,
        boundaryLoop: BoundaryLoop,
    ) {
        const normal = new THREE.Vector3().setFromMatrixColumn(2, boundaryLoop.transform);
        const origin = new THREE.Vector3().setFromMatrixPosition(boundaryLoop.transform);

        this.plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, origin);
    }

    createReferencePlane(selectionHook: SelectionHook) {
        const hookMatrix = selectionHook.hook.matrix;
        const origin = new THREE.Vector3().setFromMatrixPosition(hookMatrix);
        const normal = new THREE.Vector3().setFromMatrixColumn(selectionHook.type === "horizontal" ? 1 : 0, hookMatrix);

        return new THREE.Plane().setFromNormalAndCoplanarPoint(normal, origin);
    }

    intersectWithPlane(plane: THREE.Plane): string | null {
        const planesIntersection = intersectPlanes(this.plane, plane);
        const { isVectorAlmostEqualToZero } = GeometryEqualityUtils;

        if (planesIntersection === null) {
            return null;
        }

        if (isAlmostEqualToZero(planesIntersection.direction.dot(basisZ))) {
            return this.wallFaceId;
        }

        if (isVectorAlmostEqualToZero(new THREE.Vector3().crossVectors(planesIntersection.direction, basisZ))) {
            return this.wallFaceId;
        }

        return null;
    }
}
