import { BasicResponse } from "../../../responses/basicResponses";
import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { CornerDockingPanel } from "../docking-panels/cornerDockingPanel";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { CreatePanelTypeOperationResult } from "../editors/panelsModelEditor";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import { ElementParameterChangedEventPayload } from "../eventBus/elementParameterChangedEventPayload";
import { RequestModelGenerationPayload } from "../eventBus/generatedModelsEventPayloads";
import {
    CreateNewPanelTypeEventPayload,
    PanelTypeSwitchedEventPayload,
    SwitchPanelTypeEventPayload,
    UpdatePanelTypeEventPayload,
} from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgeCornersEditorLoadOptions = {
    cornersEditor: CornersModelEditor;
};

export class ForgeCornersEditor extends Autodesk.Viewing.Extension {
    private readonly cornersEditor: CornersModelEditor;
    private editorPanel: CornerDockingPanel | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeCornersEditorLoadOptions) {
        super(viewer, options);
        this.cornersEditor = options.cornersEditor;
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onCornerParametersChanged = this.onCornerParametersChanged.bind(this);
        this.onUpdatePanelType = this.onUpdatePanelType.bind(this);
        this.onCreatePanelType = this.onCreatePanelType.bind(this);
        this.onSwitchPanelType = this.onSwitchPanelType.bind(this);
        this.onPanelTypeSwitched = this.onPanelTypeSwitched.bind(this);
        this.onStartPanelGeneration = this.onStartPanelGeneration.bind(this);
        this.onSwitchPanelCustomPanelType = this.onSwitchPanelCustomPanelType.bind(this);
        this.onRestoreRegularPanelType = this.onRestoreRegularPanelType.bind(this);
    }

    load() {
        this.cornersEditor.createCornerPanels();
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.addEventListener("Dextall.Corners.UI.ParametersChanged", this.onCornerParametersChanged);
        eventBus.addEventListener("Dextall.CornerTypes.Updated", this.onUpdatePanelType);
        eventBus.addEventListener("Dextall.CornerTypes.NewPanelType", this.onCreatePanelType);
        eventBus.addEventListener("Dextall.CornerTypes.SwitchPanelTypeRequested", this.onSwitchPanelType);
        eventBus.addEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.addEventListener("Dextall.GeneratedModel.GenerationRequested", this.onStartPanelGeneration);
        eventBus.addEventListener("Dextall.Corners.UI.SwitchToCustomPanelType", this.onSwitchPanelCustomPanelType);
        eventBus.addEventListener("Dextall.Corners.UI.RestoreRegularPanelType", this.onRestoreRegularPanelType);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.removeEventListener("Dextall.Corners.UI.ParametersChanged", this.onCornerParametersChanged);
        eventBus.removeEventListener("Dextall.CornerTypes.Updated", this.onUpdatePanelType);
        eventBus.removeEventListener("Dextall.CornerTypes.NewPanelType", this.onCreatePanelType);
        eventBus.removeEventListener("Dextall.CornerTypes.SwitchPanelTypeRequested", this.onSwitchPanelType);
        eventBus.removeEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.GeneratedModel.GenerationRequested", this.onStartPanelGeneration);
        eventBus.removeEventListener("Dextall.Corners.UI.SwitchToCustomPanelType", this.onSwitchPanelCustomPanelType);
        eventBus.removeEventListener("Dextall.Corners.UI.RestoreRegularPanelType", this.onRestoreRegularPanelType);

        this.editorPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        this.editorPanel = new CornerDockingPanel(this.viewer.container);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selection = event.selections.find(
            (x: { model: { id: number } }) => x.model.id === this.cornersEditor.modelId,
        );

        const elementDbId = selection?.dbIdArray[0];

        this.raiseSelectionChanged(elementDbId);
    }

    private async onCornerParametersChanged(event: IApplicationEvent<ElementParameterChangedEventPayload>) {
        const { id, elementName } = event.payload;
        let savedElementName = this.cornersEditor.findCornerById(id)?.elementName ?? "";

        const undoRedo = async () => {
            const temp = this.cornersEditor.findCornerById(id)?.elementName ?? "";
            const result = await this.cornersEditor.update(id, savedElementName);
            this.refreshSelection(result);
            savedElementName = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Update corner", undo: undoRedo, redo: undoRedo },
            },
        });

        const result = await this.cornersEditor.update(id, elementName);

        this.refreshSelection(result);
    }

    private async onUpdatePanelType(event: IApplicationEvent<UpdatePanelTypeEventPayload>) {
        const panelType = this.cornersEditor.findCornerPanelType(event.payload.id);
        if (!panelType) {
            throw new Error("Invalid state!");
        }

        let savedPanelProperties: UpdatePanelTypeEventPayload = {
            id: panelType.id,
            name: panelType.name,
            withoutCladdings: panelType.withoutCladdings,
        };

        const undoRedo = async () => {
            const panelType = this.cornersEditor.findCornerPanelType(savedPanelProperties.id);
            if (!panelType) {
                throw new Error("Invalid state!");
            }

            const temp: UpdatePanelTypeEventPayload = {
                id: panelType.id,
                name: panelType.name,
                withoutCladdings: panelType.withoutCladdings,
            };

            const result = await this.cornersEditor.updatePanelType(savedPanelProperties);
            this.refreshSelection(result);
            savedPanelProperties = temp;
        };

        const result = await this.cornersEditor.updatePanelType(event.payload);

        this.refreshSelection(result);

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Update corner panel type", undo: undoRedo, redo: undoRedo },
            },
        });

        eventBus.dispatchEvent({
            type: "Dextall.Panels.GeneratedModel.Loaded",
            payload: {
                model: { id: "", bubble: null, status: PanelTypeGenerationStatus.None },
                panelTypeId: event.payload.id,
                drawings: [],
            },
        });
    }

    private async onSwitchPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;
        let savedPanelTypeId = this.cornersEditor.findCornerById(panelId)?.panelTypeId ?? "";

        const undoRedo = async () => {
            const temp = this.cornersEditor.findCornerById(panelId)?.panelTypeId ?? "";
            const result = await this.cornersEditor.switchPanelType(panelId, savedPanelTypeId);
            this.refreshPanelType(result, panelId);
            savedPanelTypeId = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Switch panel type", undo: undoRedo, redo: undoRedo },
            },
        });


        const result = await this.cornersEditor.switchPanelType(panelId, targetTypeId);

        this.refreshPanelType(result, panelId);
    }

    private async onCreatePanelType(event: IApplicationEvent<CreateNewPanelTypeEventPayload>) {
        const { panelId } = event.payload;
        let savedPanelTypeId = this.cornersEditor.findCornerById(panelId)?.panelTypeId ?? "";

        const undoRedo = async () => {
            const temp = this.cornersEditor.findCornerById(panelId)?.panelTypeId ?? "";
            const result = await this.cornersEditor.switchPanelType(panelId, savedPanelTypeId);
            this.refreshPanelType(result, panelId);
            savedPanelTypeId = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Switch panel type to new type", undo: undoRedo, redo: undoRedo },
            },
        });

        const result = await this.cornersEditor.createPanelType(event.payload, panelId);

        this.refreshPanelType(result, panelId);
    }

    private async onStartPanelGeneration(event: IApplicationEvent<RequestModelGenerationPayload>) {
        const { isPanelModel, panelTypeId } = event.payload;

        if (isPanelModel) {
            return;
        }

        const generationStartResult = await this.cornersEditor.startPanelTypeGeneration(panelTypeId);

        if (generationStartResult.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.Corners.GeneratedModel.GenerationStarted",
                payload: panelTypeId,
            });

            eventBus.dispatchEvent({
                type: "Dextall.Corners.GeneratedModel.Loaded",
                payload: {
                    model: {
                        id: generationStartResult.item,
                        bubble: null,
                        status: PanelTypeGenerationStatus.ModelGenerationInProgress,
                    },
                    panelTypeId,
                    drawings: [],
                },
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.GeneratedModel.GenerationRequestFailed",
                payload: generationStartResult.message,
            });
        }
    }

    private onPanelTypeSwitched(event: IApplicationEvent<PanelTypeSwitchedEventPayload>) {
        this.cornersEditor.updatePanelTypeTexts(event.payload.panelId);
    }

    private async onSwitchPanelCustomPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;

        const undo = async () => {
            const result = await this.cornersEditor.switchPanelCustomType(panelId, null);
            this.refreshPanelType(result, panelId);
        };

        const redo = async () => {
            const result = await this.cornersEditor.switchPanelCustomType(panelId, targetTypeId);
            this.refreshPanelType(result, panelId);
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Assign custom type to corner", undo, redo },
            },
        });


        const result = await this.cornersEditor.switchPanelCustomType(panelId, targetTypeId);

        this.refreshPanelType(result, panelId);
    }

    private async onRestoreRegularPanelType(event: IApplicationEvent<string>) {
        const panelId = event.payload;
        const customPanelTypeId = this.cornersEditor.findCornerById(panelId)?.customPanelTypeId;

        const undo = async () => {
            if (customPanelTypeId) {
                const result = await this.cornersEditor.switchPanelCustomType(panelId, customPanelTypeId);
                this.refreshPanelType(result, panelId);
            }
        };

        const redo = async () => {
            const result = await this.cornersEditor.switchPanelCustomType(panelId, null);
            this.refreshPanelType(result, panelId);
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Reset to standard type", undo, redo },
            },
        });


        const result = await this.cornersEditor.switchPanelCustomType(panelId, null);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        this.refreshPanelType(result, panelId);
    }

    private refreshPanelType(result: CreatePanelTypeOperationResult, panelId: string) {
        if (result.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.CornerTypes.PanelTypeSwitched",
                payload: { panelId, removedHooksDbIds: result.removedHooksDbIds },
            });
        }

        this.refreshSelection(result);
    }

    private refreshSelection(result: BasicResponse) {
        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        const selection = this.viewer.getAggregateSelection().find(x => x.model.id === this.cornersEditor.modelId);

        this.raiseSelectionChanged(selection?.selection[0]);
    }

    private raiseSelectionChanged(elementDbId: number | undefined) {
        const corner = elementDbId !== undefined ? this.cornersEditor.findCorner(elementDbId) : undefined;

        const family = corner && this.cornersEditor.findPanelFamily(corner.panelTypeId);

        eventBus.dispatchEvent({
            type: "Dextall.Corners.SelectionChanged",
            payload: (corner && family)
                ? {
                    panel: corner,
                    family,
                    customPanelTypes: this.cornersEditor.findValidCustomCornerTypesForPanel(corner.id),
                }
                : null,
        });

        this.editorPanel?.setVisible(corner !== undefined);

        this.cornersEditor.loadPanelTypeModel(corner);
    }

    private notifyError(message: string) {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message,
        });
    }
}

export const forgeCornersEditorName = "Dextall.ForgeCornersEditor" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeCornersEditorName, ForgeCornersEditor);
