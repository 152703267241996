import { useEffect, useRef } from "react";
import { ICornerPanelSource } from "../responses/cornerPanelSource";
import { ICustomCornerSource } from "../responses/customCornerSource";
import { ICustomPanelSource } from "../responses/customPanelSource";
import { ICustomCornerType, ICustomPanelType, ICustomZShapeType } from "../responses/customPanelTypes";
import { ICustomZShapedPanelSource } from "../responses/customZShapedPanelSource";
import { IInsulatedGlassUnit } from "../responses/insulatedGlassUnit";
import { IModelCorner } from "../responses/modelCorner";
import { IPanelGeneratedModelDto } from "../responses/panelGeneratedModelDto";
import { IPanelSource } from "../responses/panelSource";
import { IFamily } from "../responses/panelType";
import { ISourceModel } from "../responses/sourceModel";
import { SystemSettings } from "../responses/systemSettings";
import { IWallFace } from "../responses/wallFace";
import { ModelEditorsFactory } from "./forge/editors/modelEditorsFactory";
import { forgeCornersEditorName } from "./forge/extensions/forgeCornersEditor";
import { forgeCustomCornersEditorExtensionName } from "./forge/extensions/forgeCustomCornersEditorExtension";
import { forgeCustomPanelsEditorExtensionName } from "./forge/extensions/forgeCustomPanelsEditorExtension";
import { forgeCustomZShapedPanelsExtensionName } from "./forge/extensions/forgeCustomZShapedPanelsEditorExtension";
import { forgeHooksAlignmentName } from "./forge/extensions/forgeHooksAlignment";
import { forgeHooksDefaultOffsetName } from "./forge/extensions/forgeHooksDefaultOffset";
import { forgeHooksEditorName } from "./forge/extensions/forgeHooksEditor";
import { forgeModelsGenerationListName } from "./forge/extensions/forgeModelsGenerationList";
import { forgePanel2DEditorExtensionName } from "./forge/extensions/forgePanel2DEditorExtension";
import { forgePanelsEditorName } from "./forge/extensions/forgePanelsEditor";
import { forgeRevitModelsGeneratorName } from "./forge/extensions/forgeRevitModeslGeneratorExtension";
import { forgeSearchElementsByIdExtensionName } from "./forge/extensions/forgeSearchElementsByIdExtension";
import { forgeStickyNotesQRCodesExtensionName } from "./forge/extensions/forgeStickyNotesQRCodesExtension";
import { forgeUndoRedoExtensionName } from "./forge/extensions/forgeUndoRedoExtension";
import { forgeWallsExtensionName } from "./forge/extensions/forgeWallsExtension";
import { ModelBufferedGeometry } from "./forge/geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "./forge/geometry/textGeometryFactory";
import { WallFacesCollection } from "./forge/panels/wallFacesCollection";
import { RevitFacadeModel } from "./forge/revit-facade-model/revitFacadeModel";
import { newCustomCornerPlacementToolName } from "./forge/viewer-tools/customCornerPlacementTool";
import { newCustomPanelPlacementToolName } from "./forge/viewer-tools/customPanelPlacementTool";
import { newCustomZShapePanelToolName } from "./forge/viewer-tools/customZShapedPanelsPlacementTool";
import { hooksAlignmentToolName } from "./forge/viewer-tools/hooksAlignmentTool";
import { newHookPlacementToolName } from "./forge/viewer-tools/newHookPlacementTool";
import { Category, ViewerModelCategoriesMap } from "./forge/viewer-utils/viewerModelCollector";
import { createModelSelectionLock } from "./forge/viewer-utils/viewerModelSelectionLock";
import { setViewerToolsModality } from "./forge/viewer-utils/viewerToolsModality";
import { LoadingExtension, loadViewerExtensions } from "./forgeExtensionsLoader";

type Props = {
    model: ISourceModel;
    modelFaces: IWallFace[];
    modelCorners: IModelCorner[];
    panels: IPanelSource[];
    cornerPanels: ICornerPanelSource[];
    panelFamilies: IFamily[];
    cornerFamilies: IFamily[];
    systemSetting: SystemSettings;
    insulationGlassUnits: IInsulatedGlassUnit[];
    customPanelTypes: ICustomPanelType[];
    customCornerTypes: ICustomCornerType[];
    customZShapedTypes: ICustomZShapeType[];
    customPanels: ICustomPanelSource[];
    customCorners: ICustomCornerSource[];
    customZShapedPanels: ICustomZShapedPanelSource[];
    textGeometryFactory: TextGeometryFactory;
    generatedModels: IPanelGeneratedModelDto[];
};

export const ModelEditorViewer = ({ model, modelFaces, modelCorners, systemSetting, panels, cornerPanels, panelFamilies,
    cornerFamilies, textGeometryFactory, customPanelTypes, customCornerTypes, customZShapedTypes,
    customPanels, customCorners, customZShapedPanels, generatedModels }: Props) => {
    const viewerDiv = useRef<HTMLDivElement>(null);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();

    const extendViewer = async (forgeViewer: Autodesk.Viewing.GuiViewer3D) => {
        const sceneBuilder = forgeViewer.getExtension("Autodesk.Viewing.SceneBuilder") as
            Autodesk.Viewing.SceneBuilder | undefined | null;
        const renderingExtension = forgeViewer.getExtension("Autodesk.NPR") as
            Autodesk.Extensions.NPR.NPRExtension | undefined | null;

        if (!(sceneBuilder && renderingExtension)) {
            setTimeout(() => extendViewer(forgeViewer), 100);
            return;
        }

        renderingExtension.setParameter("style", "edging");
        renderingExtension.setParameter("edges", false);
        renderingExtension.setParameter("preserveColor", false);

        const modelBuilder = await sceneBuilder.addNewModel({
            modelNameOverride: "dextall",
            conserveMemory: true,
            createWireframe: false,
        });

        await window.Autodesk.Viewing.Document.getAecModelData(forgeViewer.model.getDocumentNode());

        const modelBufferedGeometry = new ModelBufferedGeometry(forgeViewer, modelBuilder);

        const wallFacesCollection = await WallFacesCollection.create(modelFaces, forgeViewer.model);

        const editorsFactory = new ModelEditorsFactory(forgeViewer, model.id, wallFacesCollection, modelCorners,
            panelFamilies, panels, cornerFamilies, cornerPanels, customPanelTypes, customCornerTypes,
            customZShapedTypes, customPanels, customCorners, customZShapedPanels, systemSetting,
            model.offsetFromWall, modelBufferedGeometry, textGeometryFactory);

        const wallsEditor = editorsFactory.createWallsEditor();

        const panelsEditor = editorsFactory.createPanelsEditor();

        const customPanelsEditor = editorsFactory.createCustomPanelsEditor();

        const cornersEditor = editorsFactory.createCornersEditor();

        const customCornersEditor = editorsFactory.createCustomCornersEditor();

        const customZShapedPanelsModelEditor = editorsFactory.createCustomZShapedPanelsEditor();

        const hooksEditor = editorsFactory.createHooksEditor();

        const hooksGeneratedModelEditor = editorsFactory.createHooksGeneratedModelEditor();

        const revitFacadeModel = new RevitFacadeModel(model.id);

        await import("./forge/extensions/index");

        const extensions: LoadingExtension[] = [{
            id: forgeWallsExtensionName,
            loadOptions: {
                wallsEditor,
            },
        }, {
            id: forgePanelsEditorName,
            loadOptions: { panelsEditor },
        }, {
            id: forgeCustomPanelsEditorExtensionName,
            loadOptions: {
                customPanelsEditor,
                wallFacesCollection,
            },
        }, {
            id: forgeCornersEditorName,
            loadOptions: { cornersEditor },
        }, {
            id: forgeCustomCornersEditorExtensionName,
            loadOptions: {
                customCornersEditor,
                modelCorners,
            },
        }, {
            id: forgeCustomZShapedPanelsExtensionName,
            loadOptions: {
                customZShapedPanelsModelEditor,
                wallFacesCollection,
                modelCorners,
            },
        }, {
            id: forgeHooksEditorName,
            loadOptions: {
                hooksEditor,
                panelsEditor,
                cornersEditor,
            },
        }, {
            id: forgeHooksDefaultOffsetName,
            loadOptions: {},
        }, {
            id: forgeHooksAlignmentName,
            loadOptions: {
                hooksEditor,
                modelFaces,
            },
        }, {
            id: forgeSearchElementsByIdExtensionName,
            loadOptions: {
                panelsEditor,
                cornersEditor,
                customPanelsEditor,
                customCornersEditor,
                customZShapedPanelsModelEditor,
            },
        }, {
            id: forgePanel2DEditorExtensionName,
            loadOptions: {
                panelsEditor,
                cornersEditor,
                hooksEditor,
                customPanelsEditor,
                customCornersEditor,
                customZShapedPanelsModelEditor,
            },
        }, {
            id: forgeRevitModelsGeneratorName,
            loadOptions: {
                hooksGeneratedModelEditor,
                revitFacadeModel,
            },
        }, {
            id: forgeStickyNotesQRCodesExtensionName,
            loadOptions: {},
        }, {
            id: forgeModelsGenerationListName,
            loadOptions: {
                panelsEditor,
                cornersEditor,
                customPanelTypes,
                model: modelBufferedGeometry.model,
                generatedModels,
            },
        }, {
            id: forgeUndoRedoExtensionName,
            loadOptions: {},
        }];

        await loadViewerExtensions(forgeViewer, extensions);

        setViewerToolsModality(forgeViewer, [
            hooksAlignmentToolName,
            newHookPlacementToolName,
            newCustomPanelPlacementToolName,
            newCustomCornerPlacementToolName,
            newCustomZShapePanelToolName]);

        forgeViewer.prefs.set(Autodesk.Viewing.Private.Prefs.PROGRESSIVE_RENDERING, false);
    };

    const onModelLoaded = async (
        forgeViewer: Autodesk.Viewing.GuiViewer3D,
        viewerDocument: Autodesk.Viewing.Document) => {
        try {
            const root = viewerDocument.getRoot();

            const threeDModels = root.get3DModelNodes();

            const model3D = threeDModels.find(x => x.data.name === "Forge")
                || threeDModels.find(x => x)
                || root.getDefaultGeometry();

            const createLoadOptions = (hiddenCategories: Category[]): Autodesk.Viewing.ModelLoadOptions | undefined => {
                if (hiddenCategories.length === 0) {
                    return undefined;
                }

                const viewerModelCategoriesMap = new ViewerModelCategoriesMap();

                const categories: Autodesk.Viewing.ModelPropertyFilterExpression[] = [];

                for (const category of hiddenCategories) {
                    const categoryId = viewerModelCategoriesMap.findCategoryId(category);

                    if (categoryId === undefined) {
                        continue;
                    }

                    categories.push({ "?CategoryId": categoryId });
                }

                return {
                    filter: {
                        property_query: {
                            "$not": {
                                "$or": categories,
                            },
                        },
                    },
                };
            };

            const viewerModel = await forgeViewer.loadDocumentNode(
                viewerDocument, model3D, createLoadOptions(["Levels", "Windows"]));

            await forgeViewer.waitForLoadDone();

            const selectionLocker = await createModelSelectionLock(forgeViewer, viewerModel)();

            selectionLocker.lock();

            await extendViewer(forgeViewer);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (viewerDiv.current === null) {
            return;
        }

        if (viewer.current) {
            viewer.current.finish();
        }

        const config: Autodesk.Viewing.Viewer3DConfig = {
            extensions: ["Autodesk.Viewing.SceneBuilder", "Autodesk.NPR"],
            disabledExtensions: {
                modelBrowser: true,
                propertiesPanel: true,
                explode: true,
                bimwalk: true,
            },
            theme: "light-theme",
        };

        const forgeViewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv.current, config);

        viewer.current = forgeViewer;

        const errorCode = forgeViewer.start();

        if (errorCode !== 0) {
            console.error(`Failed to initialize the viewer. Error code = ${errorCode}`);
            return;
        }

        const urnToLoad = `urn:${model.urn}`;

        Autodesk.Viewing.Document.load(
            urnToLoad,
            onModelLoaded.bind(null, forgeViewer),
            code => console.error(`Failed to load. Error code =${code}`));

        return () => {
            if (viewer.current) {
                viewer.current.finish();
            }
        };
    }, []);

    return <div className="viewer-container" ref={viewerDiv}></div>;
};
