import { UpdateCustomCornerOffsetCommand } from "../../../commands/updateCustomCornerOffsetCommand";
import { SnapGrid } from "../geometry/snapGrid";
import { CustomCorner } from "../panels/customCorner";
import { GizmoBase } from "./gizmoBase";
import eventBus from "../eventBus/eventDispatcher";

export class CustomWallCornerPanelOffsetGizmo extends GizmoBase {
    private readonly panelOffset: UpdateCustomCornerOffsetCommand;
    constructor(viewer: Autodesk.Viewing.Viewer3D, private readonly panel: CustomCorner, private readonly snapGrid: SnapGrid) {
        const transformControl = new THREE.TransformControls(viewer.impl.camera, viewer.impl.canvas, "translate", "X");

        super(transformControl);

        const origin = panel.getGeometryCenter(tempVector);

        this.panelOffset = { offset: panel.offset };

        this.mesh.applyMatrix(matrix.setPosition(origin));

        const boundingBox = viewer.model.getBoundingBox();
        this.transformControl.setSize(boundingBox.getBoundingSphere().radius * 5);
        this.transformControl.attach(this.mesh);
        this.transformControl.setSpace("local");

        this.onGizmoChanged = this.onGizmoChanged.bind(this);
        this.transformControl.addEventListener("change", this.onGizmoChanged);
    }

    restore() {
        this.apply(matrix.setPosition(this.panel.getGeometryCenter(tempVector)));
    }

    dispose() {
        this.transformControl.removeEventListener("change", this.onGizmoChanged);
    }

    startDraggingAt(event: MouseEvent): boolean {
        const result = this.handleButtonDown(event);

        if (result)
            this.panelOffset.offset = this.panel.offset;

        return result;
    }

    endDraggingAt(event: MouseEvent): boolean {
        const isDragging = this.isDraggingGizmo();

        const result = this.handleButtonUp(event);

        if (isDragging) {
            const offset = this.convertGizmoPositionToOffset();

            eventBus.dispatchEvent({
                type: "Dextall.CustomCorners.Gizmo.DraggingCompleted",
                payload: {
                    id: this.panel.id,
                    initialOffset: this.panelOffset,
                    targetOffset: offset
                }
            });
        }

        return isDragging || result;
    }

    private onGizmoChanged(event: any) {
        if (!event.target.isDragging())
            return;

        const offset = this.convertGizmoPositionToOffset();

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.Gizmo.Update",
            payload: { id: this.panel.id, offset }
        });

        this.restore();
    }

    private convertGizmoPositionToOffset(): UpdateCustomCornerOffsetCommand {
        const offset = this.panel.getTargetOffsetFor(this.getPosition());

        return { offset: this.snapGrid.snap(offset) };
    }
}

const matrix = new THREE.Matrix4().makeRotationY(0.5 * Math.PI);
const tempVector = new THREE.Vector3();