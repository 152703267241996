import { isMoreThan } from "@dextall/shared";
import { CornerHook } from "../../../panels/cornerHook";
import { Hook } from "../../../panels/hook";
import { Props as DimensionDrawingSource, Dimension2D, DimensionType } from "./dimension2D";

type Props = {
    hook: Hook | CornerHook;
    panelLength: number;
    panelHeight: number;
    isMetric: boolean;
    selectedDimension: DimensionType | undefined;
    selectDimension: (dimension: DimensionType, position: { x: number, y: number }) => void;
};

export const Panel2DHooksDimensions = (props: Props) => {
    const dimensions = createDimensionSources(props);

    return <g>
        {dimensions.map((x, i) => <Dimension2D {...x} key={`panel-hook-2d-dimension-${i + 1}`} />)}
    </g>;
};

const createDimensionSources = (props: Props): DimensionDrawingSource[] => {
    const dimensionsSources: DimensionDrawingSource[] = [];

    const { hook, panelLength, panelHeight, isMetric } = props;

    const sourceUnits = "decimal-ft";
    const units = isMetric ? "mm" : "ft-and-fractional-in";
    const precision = isMetric ? 1 : 3;

    const leftDimensionDrawingLength = hook.x;

    if (isMoreThan(leftDimensionDrawingLength, 0)) {
        const value = Autodesk.Viewing.Private.convertUnits(sourceUnits, units, 1, leftDimensionDrawingLength);

        dimensionsSources.push({
            length: leftDimensionDrawingLength,
            position: { x: 0.5 * leftDimensionDrawingLength, y: hook.y },
            text: Autodesk.Viewing.Private.formatValueWithUnits(value, units, 3, precision),
            type: "horizontal",
            dimensionType: "left",
            isSelected: props.selectedDimension === "left",
            setSelected: () => props.selectDimension("left", { x: 0.5 * leftDimensionDrawingLength, y: hook.y }),
        });
    }

    const rightDimensionDrawingLength = panelLength - hook.x;

    if (isMoreThan(rightDimensionDrawingLength, 0)) {
        const value = Autodesk.Viewing.Private.convertUnits(sourceUnits, units, 1, rightDimensionDrawingLength);

        dimensionsSources.push({
            length: rightDimensionDrawingLength,
            position: { x: hook.x + 0.5 * rightDimensionDrawingLength, y: hook.y },
            text: Autodesk.Viewing.Private.formatValueWithUnits(value, units, 3, precision),
            type: "horizontal",
            dimensionType: "right",
            isSelected: props.selectedDimension === "right",
            setSelected: () => props.selectDimension("right", { x: hook.x + 0.5 * rightDimensionDrawingLength, y: hook.y }),
        });
    }

    const bottomDimensionDrawingLength = hook.y;

    if (isMoreThan(bottomDimensionDrawingLength, 0)) {
        const value = Autodesk.Viewing.Private.convertUnits(sourceUnits, units, 1, bottomDimensionDrawingLength);

        dimensionsSources.push({
            length: bottomDimensionDrawingLength,
            position: { x: hook.x, y: 0.5 * bottomDimensionDrawingLength },
            text: Autodesk.Viewing.Private.formatValueWithUnits(value, units, 3, precision),
            type: "vertical",
            dimensionType: "bottom",
            isSelected: props.selectedDimension === "bottom",
            setSelected: () => props.selectDimension("bottom", { x: hook.x, y: 0.5 * bottomDimensionDrawingLength }),
        });
    }

    const topDimensionDrawingLength = panelHeight - hook.y;

    if (isMoreThan(topDimensionDrawingLength, 0)) {
        const value = Autodesk.Viewing.Private.convertUnits(sourceUnits, units, 1, topDimensionDrawingLength);

        dimensionsSources.push({
            length: topDimensionDrawingLength,
            position: { x: hook.x, y: hook.y + 0.5 * topDimensionDrawingLength },
            text: Autodesk.Viewing.Private.formatValueWithUnits(value, units, 3, precision),
            type: "vertical",
            dimensionType: "top",
            isSelected: props.selectedDimension === "top",
            setSelected: () => props.selectDimension("top", { x: hook.x, y: hook.y + 0.5 * topDimensionDrawingLength }),
        });
    }

    return dimensionsSources;
};