import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { RevitModelDockingPanel } from "../docking-panels/revitModelDockingPanel";
import { HooksGeneratedModelEditor } from "../editors/hooksGeneratedModelEditor";
import { RevitFacadeModel } from "../revit-facade-model/revitFacadeModel";
import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import eventBus from "../eventBus/eventDispatcher";

export type ForgeHooksModelGeneratorLoadOptions = {
    hooksGeneratedModelEditor: HooksGeneratedModelEditor;
    revitFacadeModel: RevitFacadeModel;
};

export class ForgeRevitModeslGeneratorExtension extends Autodesk.Viewing.Extension {
    private readonly hooksGeneratedModelEditor: HooksGeneratedModelEditor;
    private readonly revitFacadeModel: RevitFacadeModel;
    private dockingPanel: RevitModelDockingPanel | null = null;
    private generateRevitModelsButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeHooksModelGeneratorLoadOptions) {
        super(viewer, options);

        this.hooksGeneratedModelEditor = options.hooksGeneratedModelEditor;
        this.revitFacadeModel = options.revitFacadeModel;

        this.onToggleHooksFacadeGeneration = this.onToggleHooksFacadeGeneration.bind(this);
        this.onDockingPanelVisibilityChanged = this.onDockingPanelVisibilityChanged.bind(this);
        this.onHooksModelGenerationRequested = this.onHooksModelGenerationRequested.bind(this);
        this.onRevitFacadeModelGenerationRequested = this.onRevitFacadeModelGenerationRequested.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.addEventListener("Dextall.HooksRevitModel.GenerationRequested", this.onHooksModelGenerationRequested);
        eventBus.addEventListener("Dextall.RevitFacadeModel.UI.StartGeneration", this.onRevitFacadeModelGenerationRequested); // eslint-disable-line

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.removeEventListener("Dextall.HooksRevitModel.GenerationRequested", this.onHooksModelGenerationRequested); // eslint-disable-line
        eventBus.removeEventListener("Dextall.RevitFacadeModel.UI.StartGeneration", this.onRevitFacadeModelGenerationRequested); // eslint-disable-line

        this.dockingPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as
                (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools) {
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });
            }

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.generateRevitModelsButton = new Autodesk.Viewing.UI.Button("dextall-generate-revit-models-button");
        this.generateRevitModelsButton.setToolTip("Generate Revit models");
        this.generateRevitModelsButton.setIcon("viewer-editor-generate-revit-models");

        this.generateRevitModelsButton.addEventListener("click", this.onToggleHooksFacadeGeneration);

        group.addControl(this.generateRevitModelsButton);

        this.dockingPanel = new RevitModelDockingPanel(this.viewer.container, this.onDockingPanelVisibilityChanged);
    }

    private onToggleHooksFacadeGeneration() {
        if (!this.dockingPanel) {
            return;
        }

        const newState = !this.dockingPanel.visible;

        if (newState) {
            this.hooksGeneratedModelEditor.loadHooksModel();
            this.revitFacadeModel.load();
        }

        this.dockingPanel.setVisible(newState);
    }

    private async onHooksModelGenerationRequested() {
        const generationStartResult = await this.hooksGeneratedModelEditor.startHooksModelGeneration();

        if (generationStartResult.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.HooksRevitModel.Loaded",
                payload: {
                    model: {
                        id: generationStartResult.item,
                        status: PanelTypeGenerationStatus.ModelGenerationInProgress,
                    },
                },
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.HooksRevitModel.GenerationRequestFailed",
                payload: generationStartResult.message,
            });
        }
    }

    private onRevitFacadeModelGenerationRequested() {
        this.revitFacadeModel.generateRevitFacadeModel();
    }

    private onDockingPanelVisibilityChanged(visibility: boolean) {
        const newState = visibility
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.generateRevitModelsButton?.setState(newState);
    }

    private onEscape() {
        this.dockingPanel?.setVisible(false);
    }
}

export const forgeRevitModelsGeneratorName = "Dextall.ForgeRevitModeslGenerator" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeRevitModelsGeneratorName, ForgeRevitModeslGeneratorExtension);