import { Component } from "react";
import { ProcessingProgressBar } from "./processingProgressBar";
import "./processingPane.css";

export class ProcessingIndicator extends Component<{ message?: string }> {
    render() {
        return (<div className="processingProgressPane">
            <div className="processingProgressContent">
                <div className="processingProgressImage" style={{ backgroundImage: "url('/Assembly_icon.svg')" }} />
                <ProcessingProgressBar />
            </div>
            <div className="processingProgressTimePrompt">This operation could take several minutes</div>
            {this.props.message &&
                <div className="processingProgressStatusPane">
                    <strong>Status</strong>: {this.props.message}
                </div>}
        </div>);
    }
}