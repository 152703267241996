import { ICustomPanelType } from "../../../responses/customPanelTypes";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { CustomPanel } from "../panels/customPanel";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { UpdateCustomPanelOffsetCommand } from "../../../commands/updateCustomPanelOffsetCommand";
import { BasicItemResponse, BasicResponse } from "../../../responses/basicResponses";
import { CustomPanelPlacementRequestEventPayload } from "../eventBus/customPanelsEventPayloads";
import repo from "../../../Repository";

export class CustomPanelsModelEditor {
    private readonly fragmentsByPanelDbId = new Map<number, number>();
    private readonly panelsTitlesFragments = new Map<number, number>();

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {
    }

    get model(): Autodesk.Viewing.Model {
        return this.modelBufferedGeometry.model;
    }

    createPanels() {
        for (const panel of this.panelFacadeDocument.customPanels)
            this.addPanel(panel, false);

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    findPanel(dbId: number): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanel(dbId);
    }

    findPanelById(id: string): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanelById(id);
    }

    findPanelByInternalId(id: string): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanelByInternalId(id);
    }

    findPanelByUserId(userUniqueId: number): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanelByUserId(userUniqueId);
    }

    findPanelByTypeName(typeName: string): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanelByTypeName(typeName);
    }

    findPanelByElementName(elementName: string): CustomPanel | undefined {
        return this.panelFacadeDocument.findCustomPanelByElementName(elementName);
    }

    findValidCustomPanelTypesForCustomPanel(panelId: string): ICustomPanelType[] {
        return this.panelFacadeDocument.findValidCustomPanelTypesForCustomPanel(panelId);
    }

    findCustomPanelType(panelTypeId: string): ICustomPanelType | undefined {
        return this.panelFacadeDocument.findCustomPanelType(panelTypeId);
    }

    async switchPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        const panel = this.panelFacadeDocument.findCustomPanelById(panelId);

        if (!panel)
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` }

        const result = await repo.changeCustomPanelType(panelId, targetTypeId);

        if (result.isSuccess) {
            panel.setPanelType(targetTypeId);

            this.addPanelTypeTextGeometry(panel);
        }

        return result;
    }

    async setElementName(panelId: string, elementName: string): Promise<BasicResponse> {
        const panel = this.panelFacadeDocument.findCustomPanelById(panelId);

        if (!panel)
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` }

        const result = await repo.updateCustomPanelElementName(panelId, { elementName });

        if (result.isSuccess)
            panel.setElementName(elementName);

        return result;
    }

    async setOffset(panelId: string, offset: UpdateCustomPanelOffsetCommand): Promise<BasicResponse> {
        const panel =
            this.panelFacadeDocument.findCustomPanelById(panelId) ||
            this.panelFacadeDocument.findCustomPanelByInternalId(panelId);

        if (!panel) {
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` };
        }

        const result = await repo.updateCustomPanelOffset(panel.id, offset);

        if (result.isSuccess) {
            this.setOffsetLocal(panel, offset);
        }

        return result;
    }

    async createNewCustomPanel(
        command: CustomPanelPlacementRequestEventPayload,
    ): Promise<BasicItemResponse<CustomPanel>> {
        const response = await repo.createCustomPanel({ ...command, modelId: this.panelFacadeDocument.modelId });

        if (!response.isSuccess) {
            return response;
        }

        const panel = this.panelFacadeDocument.addCustomPanel(response.item, command.internalId);

        this.addPanel(panel);

        return { isSuccess: true, message: null, item: panel };
    }

    setOffsetLocal(panelId: string | CustomPanel, offset: UpdateCustomPanelOffsetCommand): BasicResponse {
        const panel = typeof panelId === "string"
            ? this.panelFacadeDocument.findCustomPanelById(panelId)
            : panelId;

        if (!panel)
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` }

        panel.setOffset(offset);

        this.updatePanelGeometry(panel);

        return { isSuccess: true, message: null };
    }

    async removePanel(panelId: string | CustomPanel): Promise<BasicResponse> {
        const panel = typeof panelId === "string" ? this.panelFacadeDocument.findCustomPanelById(panelId) : panelId;

        if (!panel) {
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` };
        }

        this.removePanelGeometry(panel);

        const result = await repo.removeCustomPanel(panel.id);

        if (result.isSuccess) {
            this.panelFacadeDocument.removeCustomPanel(panel);
        } else {
            this.addPanel(panel);
        }

        return result;
    }

    private addPanel(panel: CustomPanel, makeFix = true) {
        this.addPanelGeometry(panel);
        this.addPanelTypeTextGeometry(panel);

        if (makeFix)
            this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    private removePanelGeometry(panel: CustomPanel) {
        const geometryFragment = this.fragmentsByPanelDbId.get(panel.panelDbId);

        if (geometryFragment !== undefined)
            this.modelBufferedGeometry.remove(geometryFragment);

        const textFragment = this.panelsTitlesFragments.get(panel.panelDbId);

        if (textFragment !== undefined)
            this.modelBufferedGeometry.remove(textFragment);

        this.fragmentsByPanelDbId.delete(panel.panelDbId);
        this.panelsTitlesFragments.delete(panel.panelDbId);
    }

    private updatePanelGeometry(panel: CustomPanel) {
        const geometryFragment = this.fragmentsByPanelDbId.get(panel.panelDbId)!;

        const targetGeometryPositionTransform = panel.getPanelGeometryCoordinateSystem();

        this.modelBufferedGeometry.changeFragmentTransform(geometryFragment, targetGeometryPositionTransform);

        const textFragment = this.panelsTitlesFragments.get(panel.panelDbId);

        if (textFragment !== undefined) {
            const targetTitleTransform = this.computePanelTypeTitleMatrix(panel);

            this.modelBufferedGeometry.changeFragmentTransform(textFragment, targetTitleTransform);
        }
    }

    private addPanelGeometry(panel: CustomPanel) {
        const geometry = panel.createGeometry();

        const fragmentId = this.modelBufferedGeometry.add(geometry);

        this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, panel.panelDbId);

        this.fragmentsByPanelDbId.set(panel.panelDbId, fragmentId);
    }

    private addPanelTypeTextGeometry(panel: CustomPanel) {
        const existingTextFragment = this.panelsTitlesFragments.get(panel.panelDbId);

        if (existingTextFragment) {
            this.modelBufferedGeometry.remove(existingTextFragment);

            this.panelsTitlesFragments.delete(panel.panelDbId);
        }

        const typeText = this.getPanelTypeText(panel);

        if (!typeText)
            return;

        const matrix = this.computePanelTypeTitleMatrix(panel);

        const geometry = this.textGeometryFactory.createTextGeometry(typeText, matrix);

        const fragmentId = this.modelBufferedGeometry.add(geometry);

        this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, panel.panelDbId);

        this.panelsTitlesFragments.set(panel.panelDbId, fragmentId);
    }

    private computePanelTypeTitleMatrix(panel: CustomPanel) {
        const typeText = this.getPanelTypeText(panel) || "";

        return panel.computePanelTypeTitleMatrix(this.textGeometryFactory.computeSize(typeText));
    }

    private getPanelTypeText(panel: CustomPanel) {
        const customPanelType = this.panelFacadeDocument.findCustomPanelType(panel.customPanelTypeId);

        return customPanelType?.name;
    }
}