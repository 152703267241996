import { CornerHookType } from "../../../responses/hookSource";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { Corner } from "../panels/corner";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { Panel } from "../panels/panel";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { IModelHook } from "../panels/modelHook";
import { PanelType } from "../panels/panelType";
import { IPanelSource } from "../../../responses/panelSource";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";

export class HooksModelEditor {
    private readonly hooksFragments = new Map<number, number[]>();

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry) {
    }

    get hooksModelId(): number {
        return this.modelBufferedGeometry.model.id;
    }

    createHooks() {
        for (const hook of this.panelFacadeDocument.hooks)
            this.createHookGeometry(hook);
    }

    createNewPanelHook(panelOrId: Panel | string, localPoint: THREE.Vector2): Hook[] {
        const panel = typeof panelOrId === "string"
            ? this.panelFacadeDocument.findPanelById(panelOrId)!
            : panelOrId;

        const hooks = this.panelFacadeDocument.createNewPanelHook(panel, localPoint);

        for (const hook of hooks) {
            this.createHookGeometry(hook);
        }

        return hooks;
    }

    createNewCornerHook(cornerOrId: Corner | string, onLeftWing: boolean, localPoint: THREE.Vector2): CornerHook[] {
        const corner = typeof cornerOrId === "string"
            ? this.panelFacadeDocument.findCornerById(cornerOrId)!
            : cornerOrId;

        const hooks = this.panelFacadeDocument.createNewCornerPanelHook(corner, onLeftWing, localPoint);

        for (const hook of hooks)
            this.createHookGeometry(hook);

        return hooks;
    }

    findHook(dbId: number): Hook | CornerHook | undefined {
        return this.panelFacadeDocument.findHook(dbId);
    }

    findWallFaceHooks(wallFaceId: string): (Hook | CornerHook)[] {
        return this.panelFacadeDocument.findWallFaceHooks(wallFaceId);
    }

    getHookIds(): number[] {
        return this.panelFacadeDocument.hooks.map(x => x.dbId);
    }

    removeHook(hook: Hook | CornerHook) {
        const hooksDbIds = this.panelFacadeDocument.removeHook(hook);

        for (const dbId of hooksDbIds)
            this.cleanupHookGeometry(dbId);
    }

    updateHooksDefaultOffset(offset: number) {
        this.panelFacadeDocument.updateAllHooksOffset(offset);
    }

    updateHook(hookSource: IModelHook): Hook | CornerHook {
        const changedHooks = this.panelFacadeDocument.updateHookFrom(hookSource);

        for (const changedHook of changedHooks)
            this.updateHookGeometry(changedHook);

        return this.panelFacadeDocument.findHook(hookSource.dbId)!;
    }

    updateHookGeometry(hook: Hook | CornerHook) {
        this.cleanupHookGeometry(hook.dbId);

        this.createHookGeometry(hook);
    }

    save(hook: Hook | CornerHook) {
        const changedHooks = this.panelFacadeDocument.updateHook(hook);

        for (const changedHook of changedHooks)
            this.updateHookGeometry(changedHook);
    }

    findPanelHooks(panel: Panel): Hook[] {
        return this.panelFacadeDocument.findPanelHooks(panel.id) as Hook[];
    }

    findCornerPanelHooks(panel: Corner, hookType: Exclude<CornerHookType, CornerHookType.None>): CornerHook[] {
        return this.panelFacadeDocument.findPanelHooks(panel.id).filter(x => x.cornerHookType === hookType) as CornerHook[];
    }

    findPanel(hook: Hook | CornerHook): Panel | Corner {
        return hook instanceof Hook
            ? this.panelFacadeDocument.findPanelById(hook.panelId)!
            : this.panelFacadeDocument.findCornerById(hook.panelId)!;
    }

    findPanelType(hook: Hook | CornerHook): PanelType<IPanelSource | ICornerPanelSource> {
        const panel = this.findPanel(hook);

        return hook instanceof Hook
            ? this.panelFacadeDocument.findPanelType(panel.panelTypeId)!
            : this.panelFacadeDocument.findCornerPanelType(panel.panelTypeId)!;
    }

    recreatePanelHooks(panelId: string, removedHooksDbIds: number[]) {
        for (const dbId of removedHooksDbIds)
            this.cleanupHookGeometry(dbId);

        if (this.panelFacadeDocument.isCustomPanelHook(panelId))
            return;

        const hooks = this.panelFacadeDocument.findPanelHooks(panelId);

        for (const hook of hooks)
            this.createHookGeometry(hook);
    }

    // TODO: extract geometry methods to their own class
    createHookGeometry(hook: Hook | CornerHook) {
        if (this.panelFacadeDocument.isCustomPanelHook(hook.panelId)) {
            return;
        }

        const geometries = hook.createGeometry();

        const fragments: number[] = [];

        for (const geometry of geometries) {
            const fragmentId = this.modelBufferedGeometry.add(geometry);

            this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, hook.dbId);

            fragments.push(fragmentId);
        }

        this.modelBufferedGeometry.makeFixOnObjectRecreation();

        this.hooksFragments.set(hook.dbId, fragments);
    }

    cleanupHookGeometry(hookDbId: number) {
        const fragments = this.hooksFragments.get(hookDbId);

        if (fragments === undefined)
            return;

        this.modelBufferedGeometry.removeFragments(fragments);

        this.hooksFragments.delete(hookDbId);
    }
}
