import { CladdingCellStyle, IPanelSourceCladdingCell } from "../../../responses/panelSource";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import customPanelsColorFactory from "../colors/customComponentColorsFactory";
import { createInversedColor } from "../colors/inversedColorFactory";
import ralColorsFactory from "../colors/ralColorsFactory";
import { claddingCellGap, editorCladdingGeometryThickness } from "../defaults";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { CornerCladdingCellGeometry } from "../geometry/cornerCladdingCellGeometry";

export class WallCornerCladdingCell {
    private readonly thickness = editorCladdingGeometryThickness;
    private readonly gap = claddingCellGap;
    private _dbId: number | undefined;

    constructor(
        private readonly leftWingBoundaryLoop: BoundaryLoop,
        private readonly rightWingBoundaryLoop: BoundaryLoop,
        private readonly leftWing: IPanelSourceCladdingCell,
        private readonly rightWing: IPanelSourceCladdingCell,
        private readonly angle: number) {
        this.leftWingTitleColor = createInversedColor(
            new THREE.Color(ralColorsFactory.getColor(leftWing.color))).getHexString();
        this.rightWingTitleColor = createInversedColor(
            new THREE.Color(ralColorsFactory.getColor(rightWing.color))).getHexString();
    }

    get leftBox(): THREE.Box3 {
        return this.leftWingBoundaryLoop.box;
    }

    get rightBox(): THREE.Box3 {
        return this.rightWingBoundaryLoop.box;
    }

    get leftWingColor(): string {
        return this.leftWing.color;
    }

    get rightWingColor(): string {
        return this.rightWing.color;
    }

    get leftWingSheetMetalThickness(): number {
        return this.leftWing.thickness;
    }

    get rightWingSheetMetalThickness(): number {
        return this.rightWing.thickness;
    }

    get leftWingDepth(): number {
        return this.leftWing.depth;
    }

    get rightWingDepth(): number {
        return this.rightWing.depth;
    }

    get style(): CladdingCellStyle {
        return this.rightWing.style;
    }

    get dbId(): number | undefined {
        return this._dbId;
    }

    assignId(dbId: number) {
        this._dbId = dbId;
    }

    readonly leftWingTitleColor: string;

    readonly rightWingTitleColor: string;

    public isCustomMaterial = false;

    createGeometry(): ExtrusionGeometry[] {
        const isReversed = this.angle > 180;

        const leftWing = (isReversed ? this.getRightWingLength() : this.getLeftWingLength()) - this.gap;
        const rightWing = (isReversed ? this.getLeftWingLength() : this.getRightWingLength()) - this.gap;
        const height = this.getHeight() - 2 * this.gap;

        const leftWingMatrix = isReversed ? this.rightWingBoundaryLoop.transform : this.leftWingBoundaryLoop.transform;

        const boxMin = isReversed ? this.rightBox.min : this.leftBox.min;

        const origin = new THREE.Vector3().copy(boxMin)
            .add(new THREE.Vector3(0, this.gap, 0))
            .applyMatrix4(leftWingMatrix);

        const leftWingDirection = new THREE.Vector3().setFromMatrixColumn(0, leftWingMatrix);

        const cornerCladdingCellGeometry = new CornerCladdingCellGeometry(
            THREE.Math.degToRad(this.angle), leftWing, rightWing, height, this.thickness, leftWingDirection);

        const leftWingMaterial = isReversed
            ? getMaterial(this.isCustomMaterial, this.rightWing.color)
            : getMaterial(this.isCustomMaterial, this.leftWing.color);

        const rightWingMaterial = isReversed
            ? getMaterial(this.isCustomMaterial, this.leftWing.color)
            : getMaterial(this.isCustomMaterial, this.rightWing.color);

        return cornerCladdingCellGeometry.createGeometry(origin, leftWingMaterial, rightWingMaterial);
    }

    private getLeftWingLength(): number {
        return this.leftBox.max.x - this.leftBox.min.x;
    }

    private getRightWingLength(): number {
        return this.rightBox.max.x - this.rightBox.min.x;
    }

    private getHeight(): number {
        return this.leftBox.max.y - this.leftBox.min.y;
    }
}

const getMaterial = (isCustomPanel: boolean, color: string): THREE.ShaderMaterial => {
    return isCustomPanel
        ? customPanelsColorFactory.createCornerCladdingCellMaterial(color)
        : customPanelsColorFactory.createCladdingCellMaterial(color);
};