import { fitToIntervalBounds } from "@dextall/shared";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { HooksModelEditor } from "../editors/hooksModelEditor";
import { PanelsModelEditor } from "../editors/panelsModelEditor";
import { HooksGeometryFactory } from "../geometry/hooksGeometryFactory";
import { SnapGrid } from "../geometry/snapGrid";
import { Corner } from "../panels/corner";
import { Panel } from "../panels/panel";
import { newHookCursor } from "./toolCursors";
import { ToolInterface } from "./toolInterface";

export const newHookPlacementToolName = "dextall-editor-new-hook-tool";
const overlayName = "dextall-editor-new-hooks-overlay";

export class NewHookPlacementTool extends ToolInterface {
    private viewer: Autodesk.Viewing.GuiViewer3D | null = null;
    private snapGrid: SnapGrid | null = null;
    private suspended = false;
    private hookPlacement: HookPlacement | null = null;
    private readonly hookPlacementPreviewMeshes: THREE.Mesh[] = [];
    private readonly hookSymbolElementTransforms: THREE.Matrix4[] = [];
    private hasOverlayPreview = false;

    constructor(private readonly panelsEditor: PanelsModelEditor,
        private readonly cornersEditor: CornersModelEditor,
        private readonly hooksEditor: HooksModelEditor) {
        super();
        this.names = [newHookPlacementToolName];
    }

    register(): void {
        const hookGeometryFactory = new HooksGeometryFactory();

        const hookGeometry = hookGeometryFactory.createGeometry();

        for (const geomElement of hookGeometry) {
            this.hookSymbolElementTransforms.push(geomElement.matrix);

            const mesh = new THREE.Mesh(geomElement.geometry, geomElement.material);

            this.hookPlacementPreviewMeshes.push(mesh);
        }
    }

    deregister(): void {
        this.hookPlacementPreviewMeshes.length = 0;
        this.hookSymbolElementTransforms.length = 0;
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        this.viewer = viewer;
        this.snapGrid = new SnapGrid(viewer.model);
        this.suspended = false;
        this.hookPlacement = null;
        this.viewer.impl.createOverlayScene(overlayName);
        this.hasOverlayPreview = false;
    }

    deactivate(): void {
        this.viewer?.impl.removeOverlayScene(overlayName);
        this.viewer = null;
        this.hookPlacement = null;
        this.hasOverlayPreview = false;
    }

    handleButtonDown(_event: MouseEvent, _button: number) {
        this.suspended = true;
        return false;
    }

    handleButtonUp(_event: MouseEvent, _button: number) {
        this.suspended = false;
        return false;
    }

    getPriority(): number {
        return 42;
    }

    getCursor(): string | null {
        return this.hookPlacement ? newHookCursor : null;
    }

    handleMouseMove(event: MouseEvent): boolean {
        if (this.suspended || this.viewer === null)
            return false;

        this.hookPlacement = this.findHitTestedHookPlacement(event);

        this.draw();

        return false;
    }

    handleSingleClick(_event: MouseEvent, button: number): boolean {
        if (this.hookPlacement === null || button !== 0)
            return false;

        if (this.hookPlacement.type === "panel")
            this.hooksEditor.createNewPanelHook(this.hookPlacement.panel, this.hookPlacement.localHookPoint);
        else
            this.hooksEditor.createNewCornerHook(this.hookPlacement.panel, this.hookPlacement.isLeftWing, this.hookPlacement.localHookPoint);

        return true;
    }

    private findHitTestedHookPlacement(event: MouseEvent): HookPlacement | null {
        const hitTest = this.viewer!.impl.hitTest(event.canvasX, event.canvasY, false, undefined, [this.hooksEditor.hooksModelId]);

        if (!hitTest)
            return null;

        const panel = this.panelsEditor.findPanel(hitTest.dbId);

        if (panel) {
            const matrix = new THREE.Matrix4().getInverse(panel.wallFaceTransform);

            const localPanelPoint = new THREE.Vector3().copy(hitTest.intersectPoint).applyMatrix4(matrix);

            const x = fitToIntervalBounds(this.snapGrid!.snap(localPanelPoint.x - panel.box.min.x), 0, panel.length);
            const y = fitToIntervalBounds(this.snapGrid!.snap(localPanelPoint.y - panel.box.min.y), 0, panel.height);

            const worldHookTransform = new THREE.Matrix4()
                .multiplyMatrices(panel.wallFaceTransform,
                    new THREE.Matrix4().setPosition(new THREE.Vector3(panel.box.min.x + x, panel.box.min.y + y, editorCladdingGeometryThickness + 2 * zFightingFixDistance)));

            return {
                type: "panel",
                panel,
                localHookPoint: new THREE.Vector2(x, y),
                worldHookTransform
            }
        }

        const corner = this.cornersEditor.findCorner(hitTest.dbId);

        if (corner) {
            const distanceToLeftWing = Math.abs(corner.getSignedDistanceToLeftWing(hitTest.intersectPoint));
            const distanceToRightWing = Math.abs(corner.getSignedDistanceToRightWing(hitTest.intersectPoint));

            const isLeftWingPoint = distanceToLeftWing < distanceToRightWing;

            const wingLength = isLeftWingPoint ? corner.leftWing : corner.rightWing;

            const wingFaceTransform = isLeftWingPoint ? corner.leftWingHooksCoordinateSystem : corner.rightWingHooksCoordinateSystem;

            const localWingPoint = this.snap(new THREE.Vector3().copy(hitTest.intersectPoint).applyMatrix4(new THREE.Matrix4().getInverse(wingFaceTransform)), wingLength, corner.height);

            const worldHookTransform = new THREE.Matrix4()
                .multiplyMatrices(wingFaceTransform,
                    new THREE.Matrix4().setPosition(new THREE.Vector3(localWingPoint.x, localWingPoint.y, editorCladdingGeometryThickness + 2 * zFightingFixDistance)));

            return {
                type: "corner",
                panel: corner,
                isLeftWing: isLeftWingPoint,
                localHookPoint: new THREE.Vector2(localWingPoint.x, localWingPoint.y),
                worldHookTransform
            }
        }

        return null;
    }

    private draw() {
        if (!this.viewer)
            return;

        if (!this.hookPlacement) {
            if (this.hasOverlayPreview)
                this.viewer.impl.clearOverlay(overlayName);

            this.hasOverlayPreview = false;

            return;
        }

        for (let i = 0; i < this.hookPlacementPreviewMeshes.length; ++i) {
            const mesh = this.hookPlacementPreviewMeshes[i];

            const matrix = new THREE.Matrix4().multiplyMatrices(this.hookPlacement.worldHookTransform, this.hookSymbolElementTransforms[i]);

            mesh.setRotationFromMatrix(matrix);
            mesh.position.setFromMatrixPosition(matrix);
            mesh.updateMatrix();

            if (!this.hasOverlayPreview)
                this.viewer.impl.addOverlay(overlayName, mesh);
        }

        this.hasOverlayPreview = true;

        this.viewer.impl.invalidate(true, false, true);
    }

    private snap(point: THREE.Vector3, maxX: number, maxY: number): THREE.Vector2 {
        const x = fitToIntervalBounds(this.snapGrid!.snap(point.x), 0, maxX);
        const y = fitToIntervalBounds(this.snapGrid!.snap(point.y), 0, maxY);

        return new THREE.Vector2(x, y);
    }
}

type PanelHookPlacement = {
    type: "panel",
    panel: Panel;
    localHookPoint: THREE.Vector2;
    worldHookTransform: THREE.Matrix4;
}

type CornerHookPlacement = {
    type: "corner",
    panel: Corner;
    localHookPoint: THREE.Vector2;
    worldHookTransform: THREE.Matrix4;
    isLeftWing: boolean
}

type HookPlacement = PanelHookPlacement | CornerHookPlacement;
