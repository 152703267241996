/** In docking panels mini-react apps we should not store
 * objects from the domain, e.g. panel, corner, panelType, ...
 * to avoid memory leaks issues which are usually hard to 
 * investigate and fix.
 */
export type EditorPanelType = {
    /** id could be omitted to make "New panel type" dropdown items */
    id?: string;
    name: string;
    withoutCladdings: boolean;
};

export const newPanelTypeDropdownItem: EditorPanelType = {
    name: "New panel type", 
    withoutCladdings: false,
};