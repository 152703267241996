import { CustomPanelType, ICustomCornerType, ICustomPanelType, ICustomZShapeType } from "../../../responses/customPanelTypes";
import { RevitFacadeGeneratedModel } from "../../../responses/revitGeneratedModel";
import { CustomComponentViewerType } from "../custom-component-library/navigation/customComponentViewerType";
import { ModelInstanceTreeNode } from "../extensions/forgeCustomComponentNavigationExtension";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { IModelHook } from "../panels/modelHook";
import { RevitFacadeModelGenerationStage } from "../revit-facade-model/revitFacadeModelWorker";
import { ICustomLibraryViewerNavigationEventPayload } from "./customLibraryNavigationEventPayload";
import { ChangeCustomCornerOffsetEventPayload, ChangeCustomPanelOffsetEventPayload, CustomCornerGizmoDraggingCompletedEventPayload, CustomCornerGizmoDraggingPayload, CustomCornerPlacementRequestEventPayload, CustomPanelGizmoDraggingCompletedEventPayload, CustomPanelGizmoDraggingPayload, CustomPanelPlacementRequestEventPayload, CustomZShapedPanelPlacementRequestPayload } from "./customPanelsEventPayloads";
import { ElementParameterChangedEventPayload } from "./elementParameterChangedEventPayload";
import { GeneratedPanelModelLoadedPayload, RequestModelGenerationPayload } from "./generatedModelsEventPayloads";
import { ActivateCornerPanelHooksDesignerEventPayload, ActivateCustomPanelHooksDesignerEventPayload, ActivatePanelHooksDesignerEventPayload, RefreshEventPayload } from "./hooksDesignerEventPayloads";
import { HooksGeneratedModelLoadedPayload } from "./hooksGeneratedModelsEventPayloads";
import { CreateCornerPanelHookEventPayload, CreatePanelHookEventPayload } from "./hooksPlacementEventPayloads";
import { FitToViewEventPayload, PanelGenerationModelPayload, StatusesUpdatedEventPayload } from "./modelsGenerationsListEventPayloads";
import { CornerPanelSelectionChangedEventPayload, CustomCornerSelectionChangedEventPayload, CustomPanelSelectionChangedEventPayload, CustomZShapedPanelSelectionChangedEventPayload, HookSelectionChangedEventPayload, PanelSelectionChangedEventPayload } from "./selectionChangedEventsPayloads";
import { CreateNewPanelTypeEventPayload, PanelTypeSelectedEventPayload, PanelTypeSwitchedEventPayload, SwitchPanelTypeEventPayload, UpdatePanelTypeEventPayload } from "./typesEditionEventsPayload";
import { IPushUndoableActionEventPayload } from "./undoRedoActionsEventsPayloads";

export interface IApplicationEvent<T> {
    type: string;
    payload: T;
}

type EventListenerManipulationInternal =
    /* panels */
    ((event: "Dextall.Panels.SelectionChanged", listener: (eventData: IApplicationEvent<PanelSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.Panels.UI.ParametersChanged", listener: (eventData: IApplicationEvent<ElementParameterChangedEventPayload>) => void) => void)
    | ((event: "Dextall.Panels.UI.SwitchToCustomPanelType", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.Panels.UI.RestoreRegularPanelType", listener: (eventData: IApplicationEvent<string>) => void) => void)
    /* custom panels */
    | ((event: "Dextall.CustomPanels.SelectionChanged", listener: (eventData: IApplicationEvent<CustomPanelSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.CustomPanels.UI.SwitchType", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.UI.SetElementName", listener: (eventData: IApplicationEvent<ElementParameterChangedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.UI.SetOffset", listener: (eventData: IApplicationEvent<ChangeCustomPanelOffsetEventPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.Gizmo.Update", listener: (eventData: IApplicationEvent<CustomPanelGizmoDraggingPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.Gizmo.DraggingCompleted", listener: (eventData: IApplicationEvent<CustomPanelGizmoDraggingCompletedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.OpenLibrarySelector", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.CustomPanels.PromptPanelPlacement", listener: (eventData: IApplicationEvent<CustomPanelType>) => void) => void)
    | ((event: "Dextall.CustomPanels.CreateNew", listener: (eventData: IApplicationEvent<CustomPanelPlacementRequestEventPayload>) => void) => void)
    | ((event: "Dextall.CustomPanels.Created", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* panel types */
    | ((event: "Dextall.PanelTypes.Updated", listener: (eventData: IApplicationEvent<UpdatePanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.PanelTypes.NewPanelType", listener: (eventData: IApplicationEvent<CreateNewPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.PanelTypes.SwitchPanelTypeRequested", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.PanelTypes.PanelTypeSwitched", listener: (eventData: IApplicationEvent<PanelTypeSwitchedEventPayload>) => void) => void)
    | ((event: "Dextall.PanelTypes.UI.PanelTypeSelected", listener: (eventData: IApplicationEvent<PanelTypeSelectedEventPayload>) => void) => void)
    /* corners */
    | ((event: "Dextall.Corners.SelectionChanged", listener: (eventData: IApplicationEvent<CornerPanelSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.Corners.UI.ParametersChanged", listener: (eventData: IApplicationEvent<ElementParameterChangedEventPayload>) => void) => void)
    | ((event: "Dextall.Corners.UI.SwitchToCustomPanelType", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.Corners.UI.RestoreRegularPanelType", listener: (eventData: IApplicationEvent<string>) => void) => void)
    /* custom corners */
    | ((event: "Dextall.CustomCorners.SelectionChanged", listener: (eventData: IApplicationEvent<CustomCornerSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.CustomCorners.UI.SwitchType", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.UI.SetElementName", listener: (eventData: IApplicationEvent<ElementParameterChangedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.UI.SetOffset", listener: (eventData: IApplicationEvent<ChangeCustomCornerOffsetEventPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.Gizmo.Update", listener: (eventData: IApplicationEvent<CustomCornerGizmoDraggingPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.Gizmo.DraggingCompleted", listener: (eventData: IApplicationEvent<CustomCornerGizmoDraggingCompletedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.OpenLibrarySelector", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.CustomCorners.PromptCornerPlacement", listener: (eventData: IApplicationEvent<CustomPanelType>) => void) => void)
    | ((event: "Dextall.CustomCorners.CreateNew", listener: (eventData: IApplicationEvent<CustomCornerPlacementRequestEventPayload>) => void) => void)
    | ((event: "Dextall.CustomCorners.Created", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* custom z-shaped panels */
    | ((event: "Dextall.CustomZShapedPanels.SelectionChanged", listener: (eventData: IApplicationEvent<CustomZShapedPanelSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.UI.SwitchType", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.UI.SetElementName", listener: (eventData: IApplicationEvent<ElementParameterChangedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.UI.SetOffset", listener: (eventData: IApplicationEvent<ChangeCustomCornerOffsetEventPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.Gizmo.Update", listener: (eventData: IApplicationEvent<CustomCornerGizmoDraggingPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.Gizmo.DraggingCompleted", listener: (eventData: IApplicationEvent<CustomCornerGizmoDraggingCompletedEventPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.OpenLibrarySelector", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.PromptPlacement", listener: (eventData: IApplicationEvent<CustomPanelType>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.CreateNew", listener: (eventData: IApplicationEvent<CustomZShapedPanelPlacementRequestPayload>) => void) => void)
    | ((event: "Dextall.CustomZShapedPanels.Created", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* corner types */
    | ((event: "Dextall.CornerTypes.Updated", listener: (eventData: IApplicationEvent<UpdatePanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CornerTypes.NewPanelType", listener: (eventData: IApplicationEvent<CreateNewPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CornerTypes.SwitchPanelTypeRequested", listener: (eventData: IApplicationEvent<SwitchPanelTypeEventPayload>) => void) => void)
    | ((event: "Dextall.CornerTypes.PanelTypeSwitched", listener: (eventData: IApplicationEvent<PanelTypeSwitchedEventPayload>) => void) => void)
    /* hooks */
    | ((event: "Dextall.Hooks.SelectionChanged", listener: (eventData: IApplicationEvent<HookSelectionChangedEventPayload | null>) => void) => void)
    | ((event: "Dextall.Hooks.UI.PanelHook.ParameterChanged", listener: (eventData: IApplicationEvent<IModelHook>) => void) => void)
    | ((event: "Dextall.Hooks.UI.CornerHook.ParameterChanged", listener: (eventData: IApplicationEvent<IModelHook>) => void) => void)
    | ((event: "Dextall.Hooks.CreateHooksGeometry", listener: (eventData: IApplicationEvent<(Hook | CornerHook)[]>) => void) => void)
    | ((event: "Dextall.Hooks.UpdateHookGeometry", listener: (eventData: IApplicationEvent<Hook | CornerHook>) => void) => void)
    | ((event: "Dextall.Hooks.UpdateHooksGeometry", listener: (eventData: IApplicationEvent<(Hook | CornerHook)[]>) => void) => void)
    | ((event: "Dextall.Hooks.ChangeDefaultOffset", listener: (eventData: IApplicationEvent<number>) => void) => void)
    | ((event: "Dextall.Hooks.RaiseHookChanged", listener: (eventData: IApplicationEvent<Hook | CornerHook>) => void) => void)
    | ((event: "Dextall.Hooks.CleanupHooksGeometry", listener: (eventData: IApplicationEvent<number[]>) => void) => void)
    | ((event: "Dextall.Hooks.Unselect", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.Hooks.ForceSave", listener: (eventData: IApplicationEvent<Hook | CornerHook>) => void) => void)
    | ((event: "Dextall.Hooks.CreatePanelHookRequested", listener: (eventData: IApplicationEvent<CreatePanelHookEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.CreateCornerPanelHookRequested", listener: (eventData: IApplicationEvent<CreateCornerPanelHookEventPayload>) => void) => void)
    /* hooks designer */
    | ((event: "Dextall.Hooks.Designer.Panel.SelectionChanged", listener: (eventData: IApplicationEvent<ActivatePanelHooksDesignerEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.Corner.SelectionChanged", listener: (eventData: IApplicationEvent<ActivateCornerPanelHooksDesignerEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.CustomPanel.SelectionChanged", listener: (eventData: IApplicationEvent<ActivateCustomPanelHooksDesignerEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.CustomCorner.SelectionChanged", listener: (eventData: IApplicationEvent<ActivateCustomPanelHooksDesignerEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.CustomZShapedPanel.SelectionChanged", listener: (eventData: IApplicationEvent<ActivateCustomPanelHooksDesignerEventPayload>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.SelectHook", listener: (eventData: IApplicationEvent<Hook | CornerHook>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.ModelHookUpdated", listener: (eventData: IApplicationEvent<Hook | CornerHook>) => void) => void)
    | ((event: "Dextall.Hooks.Designer.Refresh", listener: (eventData: IApplicationEvent<RefreshEventPayload>) => void) => void)
    /* common */
    | ((event: "Dextall.Common.RemoveSelectedEntityRequested", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.Common.Notify.Error", listener: (eventData: IApplicationEvent<string>) => void) => void)
    /* Search by id tool */
    | ((event: "Dextall.SearchByIdTool.VisibilityChanged", listener: (eventData: IApplicationEvent<boolean>) => void) => void)
    | ((event: "Dextall.SearchByIdTool.SearchRequested", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.SearchByIdTool.SearchFailed", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* generated models */
    | ((event: "Dextall.Panels.GeneratedModel.Loaded", listener: (eventData: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.Panels.GeneratedModel.GenerationStarted", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.Panels.GeneratedModel.Generated", listener: (eventData: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.Corners.GeneratedModel.Loaded", listener: (eventData: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.Corners.GeneratedModel.GenerationStarted", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.Corners.GeneratedModel.Generated", listener: (eventData: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.GeneratedModel.GenerationRequested", listener: (eventData: IApplicationEvent<RequestModelGenerationPayload>) => void) => void)
    | ((event: "Dextall.GeneratedModel.GenerationRequestFailed", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.QRCode.Download", listener: (eventData: IApplicationEvent<string>) => void) => void)
    /* models generation list */
    | ((event: "Dextall.GeneratedModel.RequestAllGenerations", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.GeneratedModel.Download", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.GeneratedModel.PanelId.Click", listener: (eventData: IApplicationEvent<FitToViewEventPayload>) => void) => void)
    | ((event: "Dextall.GeneratedModel.StatusesUpdated", listener: (eventData: IApplicationEvent<StatusesUpdatedEventPayload>) => void) => void)
    | ((event: "Dextall.GeneratedModel.PanelModelsLoaded", listener: (eventData: IApplicationEvent<PanelGenerationModelPayload>) => void) => void)
    /* hooks generated models */
    | ((event: "Dextall.HooksRevitModel.Loaded", listener: (eventData: IApplicationEvent<HooksGeneratedModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.HooksRevitModel.Generated", listener: (eventData: IApplicationEvent<HooksGeneratedModelLoadedPayload>) => void) => void)
    | ((event: "Dextall.HooksRevitModel.GenerationRequested", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.HooksRevitModel.GenerationRequestFailed", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.HooksRevitModel.Download", listener: (eventData: IApplicationEvent<string>) => void) => void)
    /* custom component library */
    | ((event: "Dextall.CustomComponentLibrary.Model.Ready", listener: (eventData: IApplicationEvent<CustomComponentViewerType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Model.InstancesReady", listener: (eventData: IApplicationEvent<ModelInstanceTreeNode[]>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Model.SelectionChanged", listener: (eventData: IApplicationEvent<number[]>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Model.UI.ShowInstanceTreeNode", listener: (eventData: IApplicationEvent<ModelInstanceTreeNode>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Model.UI.HideInstanceTreeNode", listener: (eventData: IApplicationEvent<ModelInstanceTreeNode>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Viewers.Navigation", listener: (eventData: IApplicationEvent<ICustomLibraryViewerNavigationEventPayload>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Panel.UI.Changed", listener: (eventData: IApplicationEvent<ICustomPanelType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Panel.Editor.Changed", listener: (eventData: IApplicationEvent<ICustomPanelType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed", listener: (eventData: IApplicationEvent<THREE.Vector3>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Corner.UI.Changed", listener: (eventData: IApplicationEvent<ICustomCornerType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.Corner.Editor.Changed", listener: (eventData: IApplicationEvent<ICustomCornerType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.ZShape.UI.Changed", listener: (eventData: IApplicationEvent<ICustomZShapeType>) => void) => void)
    | ((event: "Dextall.CustomComponentLibrary.ZShape.Editor.Changed", listener: (eventData: IApplicationEvent<ICustomZShapeType>) => void) => void)
    /* sticky notes */
    | ((event: "Dextall.QRCode.Generate", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* undo/redo actions events */
    | ((event: "Dextall.UndoRedo.PushAction", listener: (eventData: IApplicationEvent<IPushUndoableActionEventPayload>) => void) => void)
    | ((event: "Dextall.UndoRedo.Undo", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.UndoRedo.Redo", listener: (eventData: IApplicationEvent<null>) => void) => void)
    /* Revit facade model events */
    | ((event: "Dextall.RevitFacadeModel.Loaded", listener: (eventData: IApplicationEvent<RevitFacadeGeneratedModel | null>) => void) => void)
    | ((event: "Dextall.RevitFacadeModel.Failed", listener: (eventData: IApplicationEvent<string>) => void) => void)
    | ((event: "Dextall.RevitFacadeModel.GenerationStage", listener: (eventData: IApplicationEvent<RevitFacadeModelGenerationStage>) => void) => void)
    | ((event: "Dextall.RevitFacadeModel.UI.StartGeneration", listener: (eventData: IApplicationEvent<null>) => void) => void)
    | ((event: "Dextall.RevitFacadeModel.UI.Download", listener: (eventData: IApplicationEvent<string>) => void) => void)
    ;

type EventListenerInvokeArgument<T extends (event: any, listener: (event: IApplicationEvent<any>) => void) => void>
    = T extends (event: infer P, listener: (event: IApplicationEvent<infer L>) => void) => void ? { type: P, payload: L } : never;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

type SubscribeToEvent = UnionToIntersection<EventListenerManipulationInternal>;
type DispatchEvent = (event: EventListenerInvokeArgument<EventListenerManipulationInternal>) => void;

class EventDispatcher {
    private readonly eventDispatcher = new Autodesk.Viewing.EventDispatcher();

    debugEvents = false;

    addEventListener: SubscribeToEvent = (event, listener) => {
        if (this.debugEvents)
            console.log(`Add a new event listener of ${event}`);

        this.eventDispatcher.addEventListener(event, listener);
    }

    removeEventListener: SubscribeToEvent = (event, listener) => {
        if (this.debugEvents)
            console.log(`Event listener removed: ${event}`);

        this.eventDispatcher.removeEventListener(event, listener);
    }

    dispatchEvent: DispatchEvent = (event) => {
        if (this.debugEvents) {
            console.log(`Dispatch event ${event.type}`)
            console.log(event.payload);
        }

        this.eventDispatcher.dispatchEvent(event);
    }

    clearListeners() {
        this.eventDispatcher.clearListeners();
    }
}

const dispatcher = new EventDispatcher();

export default dispatcher;