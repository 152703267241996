import Modal from "@weave-design/modal";
import { CustomPanelType, CustomPanelTypeShape } from "../../responses/customPanelTypes";
import { ContentNotReady } from "../contentNotReady";
import { rootElement } from "../shared";
import { WeaveDesignStylesheet } from "../sharedTypes";
import { CustomCornerComponentCard } from "./customCornerComponentCard";
import { CustomPanelComponentCard } from "./customPanelComponentCard";
import { CustomZShapedComponentCard } from "./customZShapedComponentCard";

type Props = {
    customPanelTypes: CustomPanelType[];
    selectLibraryComponent: (component: CustomPanelType) => void;
    closeDialog: () => void;
    currentCustomPanelTypeId?: string;
};

export const ComponentsLibraryDialog = ({
    customPanelTypes,
    selectLibraryComponent,
    closeDialog,
    currentCustomPanelTypeId,
}: Props) => {
    const createCustomComponentCard = (panelType: CustomPanelType) => {
        switch (panelType.shapeType) {
            case CustomPanelTypeShape.Panel:
                return (
                    <CustomPanelComponentCard
                        panel={panelType}
                        selectLibraryComponent={selectLibraryComponent}
                        currentCustomPanelTypeId={currentCustomPanelTypeId}
                        key={`custom-inventor-panel-component-${panelType.id}`}
                    />
                );

            case CustomPanelTypeShape.Corner:
                return (
                    <CustomCornerComponentCard
                        panel={panelType}
                        selectLibraryComponent={selectLibraryComponent}
                        currentCustomPanelTypeId={currentCustomPanelTypeId}
                        key={`custom-inventor-corner-component-${panelType.id}`}
                    />
                );

            case CustomPanelTypeShape.ZShape:
                return (
                    <CustomZShapedComponentCard
                        panel={panelType}
                        selectLibraryComponent={selectLibraryComponent}
                        currentCustomPanelTypeId={currentCustomPanelTypeId}
                        key={`custom-inventor-z-shaped-component-${panelType.id}`}
                    />
                );

            default:
                throw new Error("Shape type not supported");
        }
    };

    return (
        <div className="library-components-dialog-container">
            <Modal open={true} title="Library" onCloseClick={closeDialog} stylesheet={modalStyles}>
                {customPanelTypes.length === 0 ? (
                    <div style={{ height: "100%" }}>
                        <ContentNotReady />
                    </div>
                ) : (
                    customPanelTypes.map(createCustomComponentCard)
                )}
            </Modal>
        </div>
    );
};

const modalStyles = (styles: WeaveDesignStylesheet) => {
    const bounds = rootElement.getBoundingClientRect();
    const boundsHeight = bounds.height > 0 ? bounds.height : window.screen.height;
    const windowSize = Math.min(bounds.width, boundsHeight) - 160;

    return {
        ...styles,
        modal: {
            ...styles.modal,
            window: {
                ...styles.modal.window,
                width: windowSize,
                height: windowSize,
                top: 40,
            },
            bodyContent: {
                ...styles.modal.bodyContent,
                overflowX: "hidden",
            },
        },
    };
};
