import { isAlmostEqualToZero } from "@dextall/shared";

const direction = new THREE.Vector3();
const perpendicular = new THREE.Vector3();
const ray = new THREE.Ray();

type Result = {
    origin: THREE.Vector3;
    direction: THREE.Vector3;
};

export const intersectPlanes = (x: THREE.Plane, y: THREE.Plane): Result | null => {
    direction.crossVectors(x.normal, y.normal);

    if (isAlmostEqualToZero(direction.lengthSq())) {
        return null;
    }

    direction.normalize();
    perpendicular.crossVectors(x.normal, direction);

    const xOrigin = x.coplanarPoint();

    ray.set(xOrigin, perpendicular);

    const originAtDirection = ray.intersectPlane(y);

    if (originAtDirection) {
        return { origin: originAtDirection, direction: new THREE.Vector3().copy(direction) };
    }

    perpendicular.negate();
    ray.set(xOrigin, perpendicular);

    const originAtNegatedDirection = ray.intersectPlane(y);

    if (originAtNegatedDirection) {
        return { origin: originAtNegatedDirection, direction: new THREE.Vector3().copy(direction) };
    }

    return null;
};
