import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ProcessingFailure } from "../../../../../processing-failure/processingFailure";
import { rootElement } from "../../../../../shared";
import eventBus, { IApplicationEvent } from "../../../../eventBus/eventDispatcher";

export const GenerationStageFailure = () => {
    const [failureMessage, setFailureMessage] = useState<string>();

    useEffect(() => {
        const onRevitModelGenerationFailure = (event: IApplicationEvent<string>) => setFailureMessage(event.payload);

        eventBus.addEventListener("Dextall.RevitFacadeModel.Failed", onRevitModelGenerationFailure);

        return () => {
            eventBus.removeEventListener("Dextall.RevitFacadeModel.Failed", onRevitModelGenerationFailure);
        };
    }, []);

    return <>
        {failureMessage !== undefined && ReactDOM.createPortal(<ProcessingFailure
            closeProcessingErrorDialog={() => setFailureMessage(undefined)}
            isVisible={true}
            title="Error"
            message={failureMessage} />, rootElement)}
    </>;
};