import { findPredefinedThicknessIndex, predefinedPanelThicknesses, zFightingFixDistance }
    from "@dextall/panels-defaults";
import { isMoreThan } from "@dextall/shared";
import { CladdingCellStyle, IPanelSource } from "../../../responses/panelSource";
import { IPanelType } from "../../../responses/panelType";
import { SystemSettings } from "../../../responses/systemSettings";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { editorCladdingGeometryThickness } from "../defaults";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry }
    from "../geometry/claddingCellExtrusionGeometryFactory";
import { PanelWindow } from "./panelWindow";
import { WallPanelCladdingCell } from "./wallPanelCladdingCell";

export class Panel {
    private readonly windows: PanelWindow[];
    private _elementName: string;

    constructor(private readonly panelSource: IPanelSource,
        private readonly panelBoundaryLoop: BoundaryLoop,
        systemSetting: SystemSettings,
        public readonly panelDbId: number,
        claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory,
        boundaryLoopsFactory: BoundaryLoopsFactory,
        glassMaterial: THREE.Material,
        private readonly panelBoundaryPlaneMaterial: THREE.Material) {

        this.claddingCells = panelSource
            .claddings
            .map(x => new WallPanelCladdingCell(x, panelBoundaryLoop.transform,
                claddingExtrusionFactory, boundaryLoopsFactory));

        this.setCladdingCellsCustomMaterial(!!panelSource.customPanelTypeId);

        this.windows = panelSource
            .openings.map(x => new PanelWindow(x, systemSetting, panelBoundaryLoop.transform,
                claddingExtrusionFactory, boundaryLoopsFactory, glassMaterial));

        this._elementName = panelSource.elementName;

        for (const claddingCell of this.claddingCells) {
            claddingCell.assignId(this.panelDbId);
        }
    }

    get id(): string {
        return this.panelSource.id;
    }

    get panelTypeId(): string {
        return this.panelSource.panelTypeId;
    }

    get customPanelTypeId(): string | null {
        return this.panelSource.customPanelTypeId;
    }

    get userUniqueId(): number {
        return this.panelSource.userUniqueId;
    }

    get elementName(): string {
        return this._elementName;
    }

    get wallFaceTransform(): THREE.Matrix4 {
        return this.panelBoundaryLoop.transform;
    }

    get box(): THREE.Box3 {
        return this.panelBoundaryLoop.box;
    }

    get length(): number {
        return this.box.getSize().x;
    }

    get height(): number {
        return this.box.getSize().y;
    }

    get wallFaceId(): string {
        return this.panelSource.wallFaceId;
    }

    get offsetX(): number {
        return this.box.min.x;
    }

    get offsetY(): number {
        return this.box.min.y;
    }

    get thicknessLabel(): string {
        const index = findPredefinedThicknessIndex(this.panelSource.thickness);

        const predefinedThickness = predefinedPanelThicknesses[index];

        return predefinedThickness.name;
    }

    get style(): CladdingCellStyle {
        return this.claddingCells[0].style;
    }

    get isParapetPanel(): boolean {
        return this.panelSource.isParapetPanel;
    }

    public readonly claddingCells: WallPanelCladdingCell[] = [];

    createGeometry(): PanelGeometry {
        const claddingCellsGeometry = this.claddingCells.map(x => x.createGeometry());
        const windowsGeometry = this.windows.flatMap(x => x.createGeometry());

        return {
            claddingCellsGeometry,
            windowsGeometry,
            panelBoundaryGeometry: this.createPanelBoundaryGeometry(),
        };
    }

    update(elementName: string) {
        this._elementName = elementName;
    }

    setPanelType(newPanelType: IPanelType) {
        this.panelSource.panelTypeId = newPanelType.id;

        this.setCladdingCellsCustomMaterial(!!this.customPanelTypeId || newPanelType.withoutCladdings);
    }

    setCustomPanelType(customPanelTypeId: string | null) {
        this.panelSource.customPanelTypeId = customPanelTypeId;

        this.setCladdingCellsCustomMaterial(!!customPanelTypeId);
    }

    computePanelTypeTitleMatrix(titleSize: THREE.Vector3) {
        const panelTransform = this.panelBoundaryLoop.transform;

        const panelBox = this.panelBoundaryLoop.box;

        const position = new THREE.Vector3(
            0.5 * (panelBox.min.x + panelBox.max.x - titleSize.x),
            0.5 * (panelBox.min.y + panelBox.max.y - titleSize.y),
            editorCladdingGeometryThickness + zFightingFixDistance,
        ).applyMatrix4(panelTransform);

        return new THREE.Matrix4()
            .copy(panelTransform)
            .setPosition(position);
    }

    hasWindows(): boolean {
        return this.windows.length > 0;
    }

    getCurrentLocalCoordinateSystem(): THREE.Matrix4 {
        const coordinateSystem = new THREE.Matrix4().copy(this.panelBoundaryLoop.transform);

        const lowerLeftPoint = new THREE.Vector3()
            .copy(this.panelBoundaryLoop.box.min)
            .applyMatrix4(coordinateSystem);

        coordinateSystem.setPosition(lowerLeftPoint);

        return coordinateSystem;
    }

    findRightTopMostCladdingCell(): WallPanelCladdingCell {
        let cell = this.claddingCells[0];

        for (const claddingCell of this.claddingCells) {
            const cellCorner = cell.getTopRightCorner();
            const claddingCellCorner = claddingCell.getTopRightCorner();

            if (isMoreThan(claddingCellCorner.x, cellCorner.x) || isMoreThan(claddingCellCorner.y, cellCorner.y)) {
                cell = claddingCell;
            }
        }

        return cell;
    }

    private setCladdingCellsCustomMaterial(isCustomMaterial: boolean) {
        if (this.claddingCells[0]?.isCustomMaterial === isCustomMaterial) {
            return;
        }

        for (const claddingCell of this.claddingCells) {
            claddingCell.isCustomMaterial = isCustomMaterial;
        }
    }

    private createPanelBoundaryGeometry(): ExtrusionGeometry {
        const size = this.panelBoundaryLoop.box.getSize();

        const geometry = new THREE.PlaneBufferGeometry(size.x, size.y);

        const panelBoxCenter = this.panelBoundaryLoop.box.getCenter();

        const matrix = new THREE.Matrix4().multiplyMatrices(this.panelBoundaryLoop.transform,
            new THREE.Matrix4()
                .setPosition(new THREE.Vector3()
                    .addVectors(panelBoxCenter, new THREE.Vector3(0, 0, zFightingFixDistance))));

        return {
            type: "box",
            geometry,
            matrix,
            material: this.panelBoundaryPlaneMaterial,
            size,
        };
    }
}

export type PanelGeometry = {
    claddingCellsGeometry: ExtrusionGeometry[];
    windowsGeometry: ExtrusionGeometry[];
    panelBoundaryGeometry: ExtrusionGeometry;
};