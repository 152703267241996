import { CustomZShapedPanelDockingPanelContent } from "./content/customZShapedPanelDockingPanelContent";
import { DockingPanel, IDockingPanelOptions } from "./dockingPanel";

export class CustomZShapedPanelDockingPanel {
    private readonly panel: DockingPanel;
    private visible: boolean;

    constructor(container: HTMLElement) {
        this.visible = false;

        const component = (<CustomZShapedPanelDockingPanelContent />);

        const options: IDockingPanelOptions = {
            style: {
                width: "360px",
                height: "465px",
                minWidth: "360px",
                minHeight: "465px",
                resize: "both",
                left: "43px",
                top: "2px",
            },
        };

        this.panel = new DockingPanel(container, "dextall-studio-wall-custom-z-shaped-panel-editor",
            "Z-panel properties", component, options);

        this.panel.addVisibilityListener((x: boolean) => this.visible = x);
    }

    setVisible(visibility: boolean) {
        if (this.visible === visibility) {
            return;
        }

        this.visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}