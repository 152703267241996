import React from "react";

type Styles = {
    container: React.CSSProperties;
    scrollableContent: React.CSSProperties;
    categoryTitle: React.CSSProperties;
    groupTitle: React.CSSProperties;
    groupName: React.CSSProperties;
    groupHeader: React.CSSProperties;
    panelInfo: React.CSSProperties;
    generateButtonContainer: React.CSSProperties;
    chevron: React.CSSProperties;
    statusWrapper: React.CSSProperties;
    statusBox: React.CSSProperties;
    statusInProgress: React.CSSProperties;
    statusGenerated: React.CSSProperties;
    statusFailed: React.CSSProperties;
    iconDot: React.CSSProperties;
};

export const styles: Styles = {
    container: {
        position: 'relative',
        paddingBottom: 100,
        marginLeft: 17
    },
    scrollableContent: {
        height: "355px",
        overflowY: "scroll",
        paddingRight: 17
    },
    categoryTitle: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginBottom: 0,
        marginTop: 10,
    },
    groupTitle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        marginLeft: 10,
    },
    groupName: {
        maxWidth: '65px', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap'
    },
    groupHeader: {
        minWidth: '80px',
        maxWidth: '80px',
        cursor: "pointer",
    },
    panelInfo: {
        fontSize: "11px",
        marginLeft: 10,
        marginBottom: 5,
        borderRadius: "5px",
        padding: "5px",
        maxHeight: "15.5px",
        cursor: "pointer",
        gap: "10px",
    },
    generateButtonContainer: {
        position: "absolute",
        bottom: 20,
        right: "15px",
    },
    chevron: {
        marginLeft: 8,
        cursor: "pointer"
    },
    statusWrapper: {
        minWidth: "72px",
        marginLeft: "10px",
        marginRight: "10px",
        textAlign: "right",
    },
    statusBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px 10px",
        borderRadius: "20px",
        backgroundColor: "#c5c5c51a",
        fontSize: "9px",
        color: "#6f7172",
        border: "1px solid #f0f0f0",
    },
    statusInProgress: {
        color: "#f4c353",
    },
    statusGenerated: {
        color: "#64df83",
    },
    statusFailed: {
        color: "red",
        cursor: "pointer"
    },
    iconDot: {
        marginRight: "5px",
    }
};
