import { useEffect, useRef, useState } from "react";
import { PanelTypeGenerationStatus } from "../../../../responses/panelGeneratedModelDto";
import { Editor2DMenu, TabsVisibility } from "./editor2DMenu";
import { GeneratedPanelModel } from "./generatedPanelModel";
import { ActivateCustomPanelHooksDesignerEventPayload } from "../../eventBus/hooksDesignerEventPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";
import "./editor2DContent.css";

export const CustomPanel2DEditorDockingPanelContent = () => {
    const [selectedModel, _setSelectedModel] = useState<ActivateCustomPanelHooksDesignerEventPayload>({ id: "", customTypeId: "", modelBubbleUrl: "" });
    const [loaded, setLoaded] = useState(false);
    const selectedModelRef = useRef<ActivateCustomPanelHooksDesignerEventPayload>();

    const setSelectedModel = (model: ActivateCustomPanelHooksDesignerEventPayload) => {
        _setSelectedModel(model);
        selectedModelRef.current = model;
    };

    useEffect(() => {
        const onSelectModel = (event: IApplicationEvent<ActivateCustomPanelHooksDesignerEventPayload>) => {
            setSelectedModel(event.payload);
            setLoaded(true);
        };

        eventBus.addEventListener("Dextall.Hooks.Designer.CustomPanel.SelectionChanged", onSelectModel);

        return () => eventBus.removeEventListener("Dextall.Hooks.Designer.CustomPanel.SelectionChanged", onSelectModel);
    }, []);

    return <div className="editor-2d-container">
        <Editor2DMenu selectedTab={"model"} setSelectedTab={() => { }} tabVisibility={tabsVisibility} />
        <div className="editor-2d-content">
            {loaded && <GeneratedPanelModel
                contentType="custom-panel"
                isLoading={false}
                panelTypeId={selectedModel.customTypeId}
                model={{
                    id: selectedModel.customTypeId,
                    bubble: selectedModel.modelBubbleUrl,
                    status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully,
                }} />}
        </div>
    </div>;
};

const tabsVisibility: TabsVisibility = {
    model: true,
    editor: false,
    drawings: false,
};