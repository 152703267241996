import { CladdingCellStyle, IPanelSourceCladdingCell } from "../../../responses/panelSource";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import customPanelsColorFactory from "../colors/customComponentColorsFactory";
import { createInversedColor } from "../colors/inversedColorFactory";
import ralColorsFactory from "../colors/ralColorsFactory";
import { claddingCellGap, editorCladdingGeometryThickness } from "../defaults";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry }
    from "../geometry/claddingCellExtrusionGeometryFactory";

export class WallPanelCladdingCell {
    private readonly boundaryLoop: BoundaryLoop;
    private readonly thickness = editorCladdingGeometryThickness;
    private _dbId: number | undefined;

    constructor(private readonly claddingCellDefinition: IPanelSourceCladdingCell, transform: THREE.Matrix4,
        private readonly claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory,
        boundaryLoopsFactory: BoundaryLoopsFactory) {

        const cellBox = new THREE.Box3(
            new THREE.Vector3(
                claddingCellDefinition.box.min.x + claddingCellGap,
                claddingCellDefinition.box.min.y + claddingCellGap,
                0),
            new THREE.Vector3(
                claddingCellDefinition.box.max.x - claddingCellGap,
                claddingCellDefinition.box.max.y - claddingCellGap,
                0));

        this.boundaryLoop = boundaryLoopsFactory.createLoopFromBox(cellBox, transform);

        const color = new THREE.Color(ralColorsFactory.getColor(claddingCellDefinition.color) || 0xf5f033);

        this.titleColor = createInversedColor(color).getHexString();
    }

    get box(): THREE.Box3 {
        return this.claddingCellDefinition.box;
    }

    get color(): string {
        return this.claddingCellDefinition.color;
    }

    get sheetMetalThickness(): number {
        return this.claddingCellDefinition.thickness;
    }

    get depth(): number {
        return this.claddingCellDefinition.depth;
    }

    get style(): CladdingCellStyle {
        return this.claddingCellDefinition.style;
    }

    get material() {
        return getMaterial(this.isCustomMaterial, this.color);
    }

    get dbId(): number | undefined {
        return this._dbId;
    }

    assignId(dbId: number) {
        this._dbId = dbId;
    }

    readonly titleColor: string;

    public isCustomMaterial = false;

    getTopRightCorner(): THREE.Vector3 {
        const box = this.box;

        return new THREE.Vector3(box.max.x, box.max.y, 0);
    }

    createGeometry(): ExtrusionGeometry {
        return this.claddingExtrusionFactory.createCladdingCellExtrusion(this.boundaryLoop, {
            thickness: this.thickness,
            material: this.material,
        });
    }
}

const getMaterial = (customPanelMaterial: boolean, color: string): THREE.ShaderMaterial => {
    return customPanelMaterial
        ? customPanelsColorFactory.createMaterial(color)
        : customPanelsColorFactory.createCladdingCellMaterial(color);
};