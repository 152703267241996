import { SnapGrid } from "../geometry/snapGrid";
import { HookGizmo } from "../gizmos/hookGizmo";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { ToolInterface } from "./toolInterface";
import eventBus from "../eventBus/eventDispatcher";

const overlayName = "dextall-hook-gizmo";

export class PanelHookGizmoTool extends ToolInterface {
    private viewer: Autodesk.Viewing.Viewer3D | null = null;
    private hookGizmo: HookGizmo | null = null;

    constructor() {
        super();

        this.names = ["dextall-hook-gizmo-editor-tool"];
        this.updateGizmo = this.updateGizmo.bind(this);
    }

    setHook(hook: Hook | CornerHook) {
        if (!this.viewer)
            throw new Error("The tool must be activated before setting the hook!");

        this.disposeGizmo();

        const snapGrid = new SnapGrid(this.viewer.model);

        this.hookGizmo = new HookGizmo(this.viewer, hook, snapGrid);

        this.viewer.impl.addOverlay(overlayName, this.hookGizmo.transformControl);
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        this.viewer = viewer;
        this.viewer.impl.createOverlayScene(overlayName);
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
    }

    deactivate(): void {
        this.disposeGizmo();
        if (this.viewer) {
            this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
            this.viewer.impl.removeOverlayScene(overlayName);
        }

        this.viewer = null;
    }

    getPriority() {
        return 42; // Use any number higher than 0 (the priority of all default tools)
    }

    handleButtonDown(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.hookGizmo && button === 0))
            return false;

        const result = this.hookGizmo.handleButtonDown(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        return result;
    }

    handleButtonUp(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.hookGizmo && button === 0))
            return false;

        const isDragging = this.hookGizmo.isDraggingGizmo()

        const result = this.hookGizmo.handleButtonUp(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        if (isDragging)
            eventBus.dispatchEvent({
                type: "Dextall.Hooks.ForceSave",
                payload: this.hookGizmo.hook
            });

        return result;
    }

    handleMouseMove(event: MouseEvent): boolean {
        if (!(this.viewer && this.hookGizmo))
            return false;

        const result = this.hookGizmo.handleMouseMove(event);

        if (result)
            this.viewer.impl.invalidate(false, false, true);

        return result;
    }

    disposeGizmo() {
        if (this.viewer && this.hookGizmo)
            this.viewer.impl.removeOverlay(overlayName, this.hookGizmo.transformControl);

        this.hookGizmo?.dispose();
        this.hookGizmo = null;
    }

    private updateGizmo() {
        this.hookGizmo?.update();
    }
}