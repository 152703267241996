import { useEffect, useState } from "react";
import { fitToIntervalBounds } from "@dextall/shared";
import { ImperialAndMetricDimensionEditor } from "../../../editor-inputs/imperialAndMetricDimensionEditor";
import { CornerHook } from "../../../panels/cornerHook";
import { Hook } from "../../../panels/hook";
import { IModelHook } from "../../../panels/modelHook";
import { DimensionType } from "./dimension2D";
import eventBus from "../../../eventBus/eventDispatcher";

type Props = {
    hook: Hook | CornerHook;
    panelLength: number;
    panelHeight: number;
    dimensionType: DimensionType;
    position: { x: number, y: number };
    panelRect: SVGRectElement
};

const width = 250;
const height = 55;

export const HookDimension2DEditor = (props: Props) => {
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);

    const onResize = () => {
        const { panelRect, panelLength, position } = props;

        const panelSize = panelRect.getBoundingClientRect();

        const editor = panelRect.parentNode as SVGSVGElement;
        const editorRect = editor.getBoundingClientRect();

        const offsetX = panelSize.x - editorRect.x;
        const offsetY = panelSize.y - editorRect.y;

        const scale = panelSize.width / panelLength;

        const targetLeft = fitToIntervalBounds(offsetX + position.x * scale, 0.5 * width, editorRect.width - 0.5 * width) - 0.5 * width;
        const targetBottom = fitToIntervalBounds(offsetY + position.y * scale, 0.5 * height, editorRect.height - 0.5 * height) - 0.5 * height;

        setLeft(targetLeft);
        setBottom(targetBottom);
    };

    useEffect(() => {
        const observer = new ResizeObserver(() => onResize());

        observer.observe(props.panelRect.parentElement!.parentElement!);

        onResize();

        return () => {
            observer.disconnect();
        };
    }, [props]);

    const onChangeDimensionValue = (value: number) => {
        const { dimensionType, hook, panelLength, panelHeight } = props;

        let position: { x: number, y: number };

        switch (dimensionType) {
            case "left":
                position = { x: value, y: hook.y };
                break;

            case "right":
                position = { x: panelLength - value, y: hook.y };
                break;

            case "bottom":
                position = { x: hook.x, y: value };
                break;

            case "top":
                position = { x: hook.x, y: panelHeight - value };
                break;

            default:
                throw new Error("Invalid state!");
        }

        const payload: IModelHook = {
            dbId: hook.dbId,
            panelId: hook.panelId,
            cornerHookType: hook.cornerHookType,
            hookType: hook.hookType,
            profileSide: hook.profileSide,
            ...position,
        };

        if (hook instanceof Hook) {
            eventBus.dispatchEvent({ type: "Dextall.Hooks.UI.PanelHook.ParameterChanged", payload });
        } else {
            eventBus.dispatchEvent({ type: "Dextall.Hooks.UI.CornerHook.ParameterChanged", payload });
        }
    };

    return <div style={{
        position: "absolute",
        left: left,
        bottom: bottom,
        width: width,
        height: height,
        backgroundColor: "white",
        borderRadius: 5,
        padding: 5,
    }}>
        <ImperialAndMetricDimensionEditor
            value={getEditableValue(props)}
            title={`${props.dimensionType.charAt(0).toUpperCase() + props.dimensionType.slice(1)}:`}
            onChange={value => onChangeDimensionValue(value)} />
    </div>;
};

const getEditableValue = (props: Props) => {
    const { hook, panelLength, panelHeight, dimensionType } = props;

    switch (dimensionType) {
        case "left":
            return hook.x;

        case "right":
            return panelLength - hook.x;

        case "bottom":
            return hook.y;

        case "top":
            return panelHeight - hook.y;

        default:
            throw new Error("Invalid state!");
    }
};