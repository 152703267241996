import { Corner } from "./panels/corner";
import { Panel } from "./panels/panel";

export const fitToViewPanel = (viewer: Autodesk.Viewing.Viewer3D, panel: Panel, model: Autodesk.Viewing.Model) => {
    const localCoordinateSystem = panel.getCurrentLocalCoordinateSystem();

    const panelNormal = new THREE.Vector3().setFromMatrixColumn(2, localCoordinateSystem);
    const panelOrigin = new THREE.Vector3().setFromMatrixPosition(localCoordinateSystem);

    const position = new THREE.Vector3().copy(panelOrigin).add(panelNormal);

    viewer.navigation.setView(position, panelOrigin);
    viewer.navigation.orientCameraUp(true);

    const claddinCellIds = [];

    for (const cell of panel.claddingCells)
        if (cell?.dbId)
            claddinCellIds.push(cell.dbId);

    viewer.fitToView(claddinCellIds, model, false);
}

export const fitToViewCorner = (viewer: Autodesk.Viewing.Viewer3D, corner: Corner, model: Autodesk.Viewing.Model) => {
    const leftWingNormal = new THREE.Vector3().setFromMatrixColumn(2, corner.getLeftWingWallCoordinateSystem());
    const rightWingNormal = new THREE.Vector3().setFromMatrixColumn(2, corner.getRightWingWallCoordinateSystem());

    const normal = new THREE.Vector3().addVectors(leftWingNormal, rightWingNormal).normalize();

    const origin = new THREE.Vector3().addVectors(corner.origin, new THREE.Vector3(0, 0, corner.offset));

    const position = new THREE.Vector3().addVectors(origin, normal);

    viewer.navigation.setView(position, origin);
    viewer.navigation.orientCameraUp(true);

    const claddinCellIds = corner.cornerCladdings.map(x => x.dbId!);

    viewer.fitToView(claddinCellIds, model, false);
}