import { useEffect, useRef, useState } from "react";
import { ICustomCornerType } from "../../../../responses/customPanelTypes";
import { CornerHookType } from "../../../../responses/hookSource";
import { IPanelGeneratedDrawingDto } from "../../../../responses/panelGeneratedDrawingDto";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../../responses/panelGeneratedModelDto";
import { Corner } from "../../panels/corner";
import { CornerHook } from "../../panels/cornerHook";
import { Corner2DSideSelector } from "./2d/corner2DSideSelector";
import { CladdingCell, PanelEditor2D, getCladdingCellMaterial } from "./2d/panelEditor2D";
import { Editor2DMenu, Tab, TabsVisibility } from "./editor2DMenu";
import { GeneratedPanelDrawings } from "./generatedPanelDrawings";
import { GeneratedPanelModel } from "./generatedPanelModel";
import { GeneratedPanelModelLoadedPayload } from "../../eventBus/generatedModelsEventPayloads";
import { ActivateCornerPanelHooksDesignerEventPayload } from "../../eventBus/hooksDesignerEventPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";
import "./editor2DContent.css";

type Corner2D = {
    id: string;
    panelTypeId: string;
    leftWingLength: number;
    rightWingLength: number;
    height: number;
    leftWingCladdingCells: CladdingCell[];
    rightWingCladdingCells: CladdingCell[];
    leftWingHooks: CornerHook[];
    rightWingHooks: CornerHook[];
    selectedHook: CornerHook | null;
};

type Props = {
    isMetric: boolean;
};

export const Corner2DEditorDockingPanelContent = ({ isMetric }: Props) => {
    const [selectedCornerSide, setSelectedCornerSide] = useState<Exclude<CornerHookType, CornerHookType.None>>(CornerHookType.CornerLeftWing);
    const [corner2D, setCorner2D] = useState<Corner2D>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [model, setModel] = useState<Omit<IPanelGeneratedModelDto, "typeId">>();
    const [drawings, setDrawings] = useState<IPanelGeneratedDrawingDto[]>([]);
    const [selectedDrawingIndex, setSelectedDrawingIndex] = useState(0);
    const [is2DHookPlacementActive, setIs2DHookPlacementActive] = useState(false);
    const [selectedTab, setSelectedTab] = useState<Tab>("editor");
    const [customModelBubbleUrl, setCustomModelBubbleUrl] = useState<string | null>();
    const cornerId = useRef<string>();
    const cornerTypeId = useRef<string>();
    const customPanelType = useRef<ICustomCornerType>();

    useEffect(() => {
        const onPanelSelected = (event: IApplicationEvent<ActivateCornerPanelHooksDesignerEventPayload>) => {
            const { panel, side, leftWingHooks, rightWingHooks, selectedHook } = event.payload;

            if (side !== CornerHookType.None) {
                setSelectedCornerSide(side);
            }

            if (cornerId.current !== panel.id) {
                setIs2DHookPlacementActive(false);
                setIsLoading(true);
                setSelectedDrawingIndex(0);
            }

            const selectedCustomPanelType = event.payload.customPanelType;

            if (selectedCustomPanelType) {
                setSelectedTab("model");
            } else if (cornerTypeId.current !== panel.panelTypeId) {
                setSelectedTab("editor");
            }

            cornerId.current = panel.id;
            cornerTypeId.current = panel.panelTypeId;
            customPanelType.current = selectedCustomPanelType;

            setCustomModelBubbleUrl(selectedCustomPanelType?.bubbleUrl);

            setCorner2D({
                id: panel.id,
                panelTypeId: panel.panelTypeId,
                leftWingLength: panel.isReversed ? panel.rightWing : panel.leftWing,
                rightWingLength: panel.isReversed ? panel.leftWing : panel.rightWing,
                height: panel.height,
                leftWingCladdingCells: createLeftWingCladdingCells(panel),
                rightWingCladdingCells: createRightWingCladdingCells(panel),
                leftWingHooks,
                rightWingHooks,
                selectedHook: selectedHook,
            });
        };

        const onLoaded = (event: IApplicationEvent<GeneratedPanelModelLoadedPayload>) => {
            if (cornerTypeId.current !== event.payload.panelTypeId) {
                return;
            }

            setIsLoading(false);
            setModel(event.payload.model);
            setDrawings(event.payload.drawings);
        };

        eventBus.addEventListener("Dextall.Hooks.Designer.Corner.SelectionChanged", onPanelSelected);
        eventBus.addEventListener("Dextall.Corners.GeneratedModel.Loaded", onLoaded);
        eventBus.addEventListener("Dextall.Corners.GeneratedModel.Generated", onLoaded);

        return () => {
            eventBus.removeEventListener("Dextall.Hooks.Designer.Corner.SelectionChanged", onPanelSelected);
            eventBus.removeEventListener("Dextall.Corners.GeneratedModel.Loaded", onLoaded);
            eventBus.removeEventListener("Dextall.Corners.GeneratedModel.Generated", onLoaded);
        };
    }, []);

    if (!corner2D) {
        return (null);
    }

    const onHookPlacementRequested = (position: THREE.Vector2) => {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.CreateCornerPanelHookRequested",
            payload: { panelId: corner2D.id, position, onLeftWing: selectedCornerSide === CornerHookType.CornerLeftWing },
        });
    };

    const tabsVisibility: TabsVisibility = {
        model: true,
        editor: !customModelBubbleUrl,
        drawings: !customModelBubbleUrl,
    };

    return <div className="editor-2d-container" style={{ height: "calc(100% - 50px)" }}>
        <Editor2DMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabVisibility={tabsVisibility} />
        {selectedTab === "editor" && <>
            <Corner2DSideSelector selectedCornerSide={selectedCornerSide} onToggleCornerSide={side => setSelectedCornerSide(side)} />
            <div className="editor-2d-content">
                <PanelEditor2D
                    length={selectedCornerSide === CornerHookType.CornerLeftWing ? corner2D.leftWingLength : corner2D.rightWingLength}
                    height={corner2D.height}
                    cells={selectedCornerSide === CornerHookType.CornerLeftWing ? corner2D.leftWingCladdingCells : corner2D.rightWingCladdingCells}
                    hooks={selectedCornerSide === CornerHookType.CornerLeftWing ? corner2D.leftWingHooks : corner2D.rightWingHooks}
                    selectedHook={corner2D.selectedHook?.cornerHookType === selectedCornerSide ? corner2D.selectedHook : null}
                    isMetric={isMetric}
                    is2DHookPlacementActive={is2DHookPlacementActive}
                    onHookPlacementRequested={onHookPlacementRequested}
                    setIs2DHookPlacementActive={setIs2DHookPlacementActive} />
            </div>
        </>}
        {selectedTab === "model" && !customModelBubbleUrl && <GeneratedPanelModel model={model} isLoading={isLoading} contentType="corner" panelTypeId={corner2D.panelTypeId} />}
        {selectedTab === "model" && customModelBubbleUrl && <GeneratedPanelModel
            model={{ bubble: customModelBubbleUrl, id: customPanelType.current!.id, status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully }}
            contentType="custom-corner"
            isLoading={false}
            panelTypeId={customPanelType.current!.id} />}
        {selectedTab === "drawing" && <GeneratedPanelDrawings
            model={model}
            drawings={drawings}
            isLoading={isLoading}
            selectedDrawingIndex={selectedDrawingIndex}
            setSelectedDrawingIndex={setSelectedDrawingIndex}
            panelTypeId={corner2D.panelTypeId}
            isPanelModel={false} />}
    </div>;
};

const createRightWingCladdingCells = (panel: Corner): CladdingCell[] => {
    const straightCladdings = panel.isReversed
        ? panel.leftWingCladdings
        : panel.rightWingCladdings;

    const straightCells: CladdingCell[] = straightCladdings.map(x => {
        return {
            box: x.box,
            color: x.color,
            material: getCladdingCellMaterial(x.style, x.sheetMetalThickness, x.depth),
            titleColor: x.titleColor,
        };
    });

    const cornerCells: CladdingCell[] = panel.cornerCladdings.map(x => {
        return panel.isReversed ? {
            box: x.leftBox,
            color: x.leftWingColor,
            material: getCladdingCellMaterial(x.style, x.leftWingSheetMetalThickness, x.leftWingDepth),
            titleColor: x.leftWingTitleColor,
        } : {
            box: x.rightBox,
            color: x.rightWingColor,
            material: getCladdingCellMaterial(x.style, x.rightWingSheetMetalThickness, x.rightWingDepth),
            titleColor: x.rightWingTitleColor,
        };
    });

    return straightCells.concat(cornerCells);
};

const createLeftWingCladdingCells = (panel: Corner): CladdingCell[] => {
    const wingLength = panel.isReversed ? panel.rightWing : panel.leftWing;

    const get2DCladdingBox = (box: THREE.Box3) => {
        return new THREE.Box3(
            new THREE.Vector3(wingLength - box.max.x, box.min.y, 0),
            new THREE.Vector3(wingLength - box.min.x, box.max.y, 0),
        );
    };

    const straightCladdings = panel.isReversed
        ? panel.rightWingCladdings
        : panel.leftWingCladdings;

    const straightCells: CladdingCell[] = straightCladdings.map(x => {
        return {
            box: get2DCladdingBox(x.box),
            color: x.color,
            material: getCladdingCellMaterial(x.style, x.sheetMetalThickness, x.depth),
            titleColor: x.titleColor,
        };
    });

    const cornerCells: CladdingCell[] = panel.cornerCladdings.map(x => {
        return panel.isReversed ? {
            box: get2DCladdingBox(x.rightBox),
            color: x.rightWingColor,
            material: getCladdingCellMaterial(x.style, x.rightWingSheetMetalThickness, x.rightWingDepth),
            titleColor: x.rightWingTitleColor,
        } : {
            box: get2DCladdingBox(x.leftBox),
            color: x.leftWingColor,
            material: getCladdingCellMaterial(x.style, x.leftWingSheetMetalThickness, x.leftWingDepth),
            titleColor: x.leftWingTitleColor,
        };
    });

    return straightCells.concat(cornerCells);
};