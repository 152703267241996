import { Error24 } from "@weave-design/icons";
import Typography from "@weave-design/typography";
import { GenerateNewPanelContent } from "./generateNewPanelContent";
import "../../../processing-failure/modalFail.css";

type Props = {
    panelTypeId: string;
    isPanelModel: boolean;
};

export const GeneratedPanelFailed = ({ panelTypeId, isPanelModel }: Props) => {
    return <div className="fullheight">
        <header id="customHeader">
            <div className="customHeaderContent">
                <div className="title">
                    <Error24 className="errorIcon" />
                    <Typography style={{
                        paddingLeft: "8px",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        lineHeight: "inherit",
                    }}>Panel generation failure</Typography>
                </div>
            </div>
        </header>

        <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <GenerateNewPanelContent panelTypeId={panelTypeId} isPanelModel={isPanelModel} isRegenerate={true} />
        </div>
    </div>;
};