import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { ICustomCornerSource } from "../../../responses/customCornerSource";
import { ICustomPanelSource } from "../../../responses/customPanelSource";
import { ICustomCornerType, ICustomPanelType, ICustomZShapeType } from "../../../responses/customPanelTypes";
import { ICustomZShapedPanelSource } from "../../../responses/customZShapedPanelSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { IPanelSource } from "../../../responses/panelSource";
import { IFamily } from "../../../responses/panelType";
import { SystemSettings } from "../../../responses/systemSettings";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { ModelBuilderObjectIds } from "../ids/modelBuilderObjectIds";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { WallFacesCollection } from "../panels/wallFacesCollection";
import { CornersModelEditor } from "./cornersModelEditor";
import { CustomCornersModelEditor } from "./customCornersModelEditor";
import { CustomPanelsModelEditor } from "./customPanelsModelEditor";
import { CustomZShapedPanelsModelEditor } from "./customZShapedPanelsModelEditor";
import { HooksGeneratedModelEditor } from "./hooksGeneratedModelEditor";
import { HooksModelEditor } from "./hooksModelEditor";
import { PanelsModelEditor } from "./panelsModelEditor";
import { WallsModelEditor } from "./wallsModelEditor";

export class ModelEditorsFactory {
    private readonly objectIds = new ModelBuilderObjectIds();
    private readonly panelsFacadeDocument: PanelFacadeDocument;
    private wallsEditor: WallsModelEditor | null = null;
    private panelsModelEditor: PanelsModelEditor | null = null;
    private cornersModelEditor: CornersModelEditor | null = null;
    private customPanelsModelEditor: CustomPanelsModelEditor | null = null;
    private customCornersModelEditor: CustomCornersModelEditor | null = null;
    private customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor | null = null;
    private hooksModelEditor: HooksModelEditor | null = null;
    private hooksGeneratedModelEditor: HooksGeneratedModelEditor | null = null;

    constructor(private readonly viewer: Autodesk.Viewing.GuiViewer3D,
        modelId: string,
        private readonly wallFacesCollection: WallFacesCollection,
        wallCorners: IModelCorner[],
        panelFamilies: IFamily[],
        panels: IPanelSource[],
        cornerFamilies: IFamily[],
        cornerPanels: ICornerPanelSource[],
        customPanelTypes: ICustomPanelType[],
        customCornerTypes: ICustomCornerType[],
        customZShapedTypes: ICustomZShapeType[],
        customPanels: ICustomPanelSource[],
        customCorners: ICustomCornerSource[],
        customZShapedPanels: ICustomZShapedPanelSource[],
        systemSettings: SystemSettings,
        private readonly facesOffset: number,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {

        this.panelsFacadeDocument = new PanelFacadeDocument(modelId, panelFamilies, panels, cornerFamilies,
            cornerPanels, viewer, wallFacesCollection, wallCorners, customPanelTypes, customCornerTypes,
            customZShapedTypes, customPanels, customCorners, customZShapedPanels, systemSettings, this.objectIds);
    }

    createWallsEditor(): WallsModelEditor {
        if (this.wallsEditor === null) {
            const packNormals = (geometry: THREE.BufferGeometry) => this.modelBufferedGeometry.packNormals(geometry);

            this.wallsEditor = new WallsModelEditor(this.viewer,
                this.wallFacesCollection, this.facesOffset, packNormals);
        }

        return this.wallsEditor;
    }

    createPanelsEditor(): PanelsModelEditor {
        if (this.panelsModelEditor === null) {
            this.panelsModelEditor = new PanelsModelEditor(this.panelsFacadeDocument,
                this.modelBufferedGeometry, this.textGeometryFactory);
        }

        return this.panelsModelEditor;
    }

    createCustomPanelsEditor(): CustomPanelsModelEditor {
        if (this.customPanelsModelEditor === null) {
            this.customPanelsModelEditor = new CustomPanelsModelEditor(this.panelsFacadeDocument,
                this.modelBufferedGeometry, this.textGeometryFactory);
        }

        return this.customPanelsModelEditor;
    }

    createCornersEditor(): CornersModelEditor {
        if (this.cornersModelEditor === null) {
            this.cornersModelEditor = new CornersModelEditor(this.panelsFacadeDocument,
                this.modelBufferedGeometry, this.textGeometryFactory);
        }

        return this.cornersModelEditor;
    }

    createCustomCornersEditor(): CustomCornersModelEditor {
        if (this.customCornersModelEditor === null) {
            this.customCornersModelEditor = new CustomCornersModelEditor(this.panelsFacadeDocument,
                this.modelBufferedGeometry, this.textGeometryFactory);
        }

        return this.customCornersModelEditor;
    }

    createCustomZShapedPanelsEditor(): CustomZShapedPanelsModelEditor {
        if (this.customZShapedPanelsModelEditor === null) {
            this.customZShapedPanelsModelEditor = new CustomZShapedPanelsModelEditor(this.panelsFacadeDocument,
                this.modelBufferedGeometry, this.textGeometryFactory);
        }

        return this.customZShapedPanelsModelEditor;
    }

    createHooksEditor(): HooksModelEditor {
        if (this.hooksModelEditor === null) {
            this.hooksModelEditor = new HooksModelEditor(this.panelsFacadeDocument, this.modelBufferedGeometry);
        }

        return this.hooksModelEditor;
    }

    createHooksGeneratedModelEditor() {
        if (this.hooksGeneratedModelEditor === null) {
            this.hooksGeneratedModelEditor = new HooksGeneratedModelEditor(this.panelsFacadeDocument);
        }

        return this.hooksGeneratedModelEditor;
    }
}