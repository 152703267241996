import { isAlmostEqualToZero } from "@dextall/shared";

export class GeometryEqualityUtils {
    static isVectorAlmostEqualToZero(vector: THREE.Vector3, tolerance = 1e-5): boolean {
        return (
            isAlmostEqualToZero(vector.x, tolerance) &&
            isAlmostEqualToZero(vector.y, tolerance) &&
            isAlmostEqualToZero(vector.z, tolerance)
        );
    }
}