import { claddingCellGap } from "../../../defaults";

export type DimensionType = "left" | "right" | "top" | "bottom";

export type Props = {
    type: "horizontal" | "vertical";
    dimensionType: DimensionType;
    length: number;
    position: { x: number, y: number }
    text: string;
    isSelected: boolean;
    setSelected: () => void;
}

const fontSize = 0.3;

export const Dimension2D = ({ type, position, length, text, isSelected, setSelected }: Props) => {
    const edgeH = 3 * claddingCellGap; // Edge height (p1 to p8 length)

    const color = isSelected ? "#0d6efd" : "black";
    const stroke = isSelected ? claddingCellGap : 0.5 * claddingCellGap;

    return <g transform={`translate (${position.x}, ${position.y})${type === "vertical" ? " rotate(90)" : ""}`} style={{ cursor: "pointer" }} 
        onClick={(e) => {setSelected(); e.stopPropagation()}}>
        <line x1={-0.5 * length} y1="0" x2={0.5 * length} y2="0" strokeWidth={stroke} stroke={color} />
        <line x1={-0.5 * length - edgeH} y1={-edgeH} x2={-0.5 * length + edgeH} y2={edgeH} strokeWidth={stroke} stroke={color} />
        <line x1={0.5 * length - edgeH} y1={-edgeH} x2={0.5 * length + edgeH} y2={edgeH} strokeWidth={stroke} stroke={color} />

        <text transform="scale(1 -1)"
            fontFamily="Arial"
            fontSize={fontSize}
            fill={color}
            y={(type === "horizontal" ? 0.25 : -1.25) * fontSize}
            dominantBaseline="hanging"
            textAnchor="middle"
            textRendering="auto">{text}</text>
    </g>;
};