import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CustomPanelType, CustomPanelTypeShape, ICustomCornerType } from "../../../../responses/customPanelTypes";
import { ComponentsLibraryDialog } from "../../../custom-components-library/componentsLibraryDialog";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { rootElement, noop } from "../../../shared";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import { ObjectUserUniqueId } from "../../editor-inputs/objectUserUniqueId";
import { PanelTypeSelector } from "../../editor-inputs/panelTypeSelector";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";
import { getCustomCornerUserUniqueIdObjectPrefix } from "../../ids/userObjectIds";
import { PanelStickyNote } from "./panelStickyNote";
import { CustomCornerSelectionChangedEventPayload } from "../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type CustomCornerFields = {
    id: string;
    userUniqueId: number;
    customPanelTypeId: string;
    customPanelTypeName: string;
    elementName: string;
    offset: number;
    leftWing: number;
    rightWing: number;
    height: number;
    customPanelTypes: ICustomCornerType[];
};

type AsyncOperation = {
    title: string;
    message: string;
};

export const CustomCornerEditorDockingPanelContent = () => {
    const [panel, setPanel] = useState<CustomCornerFields>({
        id: "",
        userUniqueId: 0,
        customPanelTypeId: "",
        customPanelTypeName: "",
        customPanelTypes: [],
        elementName: "",
        offset: 0,
        leftWing: 0,
        rightWing: 0,
        height: 0,
    });
    const [customComponentLibraryOpened, setCustomComponentLibraryOpened] = useState(false);
    const [asyncOperation, setAsyncOperation] = useState<AsyncOperation>();

    useEffect(() => {
        const onSelectionChanged = (event: IApplicationEvent<CustomCornerSelectionChangedEventPayload | null>) => {
            setCustomComponentLibraryOpened(false);
            setAsyncOperation(undefined);

            if (!event.payload) {
                return;
            }

            const { panel, customPanelTypes } = event.payload;

            const { id, userUniqueId, customPanelTypeId, elementName, offset, leftWing, rightWing, height } = panel;

            const customPanelTypeName = customPanelTypes.find(x => x.id === customPanelTypeId)!.name;

            setPanel({
                id,
                userUniqueId,
                customPanelTypeId,
                customPanelTypeName,
                elementName,
                offset,
                leftWing,
                rightWing,
                height,
                customPanelTypes,
            });
        };

        eventBus.addEventListener("Dextall.CustomCorners.SelectionChanged", onSelectionChanged);

        return () => {
            eventBus.removeEventListener("Dextall.CustomCorners.SelectionChanged", onSelectionChanged);
        };
    }, []);

    const onSelectCustomPanelType = (customPanelType: CustomPanelType) => {
        if (customPanelType.shapeType !== CustomPanelTypeShape.Corner) {
            throw new Error("Invalid state!");
        }

        setAsyncOperation({
            title: "Custom corner type",
            message: "Setting custom corner type...",
        });

        setCustomComponentLibraryOpened(false);

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.UI.SwitchType",
            payload: {
                panelId: panel.id,
                targetTypeId: customPanelType.id,
            },
        });
    };

    const onElementNameChanged = (newName: string) => {
        setAsyncOperation({
            title: "Element name",
            message: "Setting element name...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.UI.SetElementName",
            payload: {
                id: panel.id,
                elementName: newName,
            },
        });
    };

    const onOffsetChanged = (newOffset: number) => {
        setAsyncOperation({
            title: "Element offset",
            message: "Setting element offset...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.UI.SetOffset",
            payload: {
                id: panel.id,
                offset: newOffset,
            },
        });
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight">
                <ObjectUserUniqueId prefix={getCustomCornerUserUniqueIdObjectPrefix()} idValue={panel.userUniqueId} />
                <PanelTypeSelector
                    panelTypes={[]}
                    selectedPanelType={{ name: "fake", withoutCladdings: false }}
                    title="Type:"
                    selectCustomPanelTypeAvailable={true}
                    onChange={noop}
                    onEditTypeRequested={noop}
                    selectedCustomPanelTypeName={panel.customPanelTypeName}
                    onCustomPanelTypeRequested={() => setCustomComponentLibraryOpened(true)}
                />
                <TextPropertyEditor title="Elem name:" value={panel.elementName} onChange={onElementNameChanged} />
                <ImperialAndMetricDimensionEditor title="Offset:" value={panel.offset} onChange={onOffsetChanged} />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={panel.height}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Left:"
                    value={panel.leftWing}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Right:"
                    value={panel.rightWing}
                    onChange={noop}
                    disabled={true}
                />
                <PanelStickyNote id={panel.id} type="custom-corners" />

                {customComponentLibraryOpened &&
                    ReactDOM.createPortal(
                        <ComponentsLibraryDialog
                            closeDialog={() => setCustomComponentLibraryOpened(false)}
                            customPanelTypes={panel.customPanelTypes}
                            selectLibraryComponent={onSelectCustomPanelType}
                            currentCustomPanelTypeId={panel.customPanelTypeId}
                        />,
                        rootElement,
                    )}
                {asyncOperation &&
                    ReactDOM.createPortal(
                        <ProcessingPane
                            isVisible={true}
                            message={asyncOperation.message}
                            title={asyncOperation.title}
                        />,
                        rootElement,
                    )}
            </div>
        </div>
    );
};
