import React, { useState } from "react";
import { styles } from "../../../../../assets/generationsListStyles";
import { LuDot } from "react-icons/lu";
import { getCornerUserUniqueIdObjectPrefix, getPanelUserUniqueIdObjectPrefix } from "../../../ids/userObjectIds";

interface PanelSectionProps {
    panels: any[];
    isPanelModel: boolean;
    selectedPanelId: string | null;
    onPanelClick: (panel: any) => void;
}

const PanelSection: React.FC<PanelSectionProps> = ({ panels, selectedPanelId, isPanelModel, onPanelClick }) => {
    const [hoveredPanelId, setHoveredPanelId] = useState<number | null>(null);

    const handleMouseEnter = (panelId: number) => {
        setHoveredPanelId(panelId);
    };

    const handleMouseLeave = () => {
        setHoveredPanelId(null);
    };
    
    return (
        <div>
            {panels.map((panel) => (
                <div 
                key={panel.id} 
                style={{...styles.panelInfo, fontWeight: panel.id === selectedPanelId ? "bold" : "normal", boxShadow: hoveredPanelId === panel.id ? "0px 1.5px 2px rgba(0,0,0,0.2)" : "unset", backgroundColor: hoveredPanelId === panel.id ? "#d6d6d61a" : ""}} 
                onMouseEnter={() => handleMouseEnter(panel.id)}
                onMouseLeave={() => handleMouseLeave()}
                >
                    <p onClick={() => onPanelClick(panel)} style={{marginBottom: "4px", marginTop: 0}}>
                        <LuDot style={styles.iconDot} />
                        {isPanelModel ? getPanelUserUniqueIdObjectPrefix() : getCornerUserUniqueIdObjectPrefix()}{panel.userUniqueId}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default PanelSection;
