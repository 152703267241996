import { BasicResponse } from "../../../responses/basicResponses";
import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { PanelDockingPanel } from "../docking-panels/panelDockingPanel";
import { CreatePanelTypeOperationResult, PanelsModelEditor } from "../editors/panelsModelEditor";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import { ElementParameterChangedEventPayload } from "../eventBus/elementParameterChangedEventPayload";
import { RequestModelGenerationPayload } from "../eventBus/generatedModelsEventPayloads";
import {
    CreateNewPanelTypeEventPayload, PanelTypeSwitchedEventPayload,
    SwitchPanelTypeEventPayload, UpdatePanelTypeEventPayload,
} from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgePanelsEditorLoadOptions = {
    panelsEditor: PanelsModelEditor;
};

export class ForgePanelsEditor extends Autodesk.Viewing.Extension {
    private readonly panelsEditor: PanelsModelEditor;
    private editorPanel: PanelDockingPanel | null = null;
    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgePanelsEditorLoadOptions) {
        super(viewer, options);

        this.panelsEditor = options.panelsEditor;
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onPanelParametersChanged = this.onPanelParametersChanged.bind(this);
        this.onUpdatePanelType = this.onUpdatePanelType.bind(this);
        this.onCreatePanelType = this.onCreatePanelType.bind(this);
        this.onSwitchPanelType = this.onSwitchPanelType.bind(this);
        this.onPanelTypeSwitched = this.onPanelTypeSwitched.bind(this);
        this.onSwitchPanelCustomPanelType = this.onSwitchPanelCustomPanelType.bind(this);
        this.onRestoreRegularPanelType = this.onRestoreRegularPanelType.bind(this);
        this.onStartPanelGeneration = this.onStartPanelGeneration.bind(this);
    }

    load() {
        this.panelsEditor.createPanels();
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.addEventListener("Dextall.Panels.UI.ParametersChanged", this.onPanelParametersChanged);
        eventBus.addEventListener("Dextall.PanelTypes.Updated", this.onUpdatePanelType);
        eventBus.addEventListener("Dextall.PanelTypes.NewPanelType", this.onCreatePanelType);
        eventBus.addEventListener("Dextall.PanelTypes.SwitchPanelTypeRequested", this.onSwitchPanelType);
        eventBus.addEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.addEventListener("Dextall.GeneratedModel.GenerationRequested", this.onStartPanelGeneration);
        eventBus.addEventListener("Dextall.Panels.UI.SwitchToCustomPanelType", this.onSwitchPanelCustomPanelType);
        eventBus.addEventListener("Dextall.Panels.UI.RestoreRegularPanelType", this.onRestoreRegularPanelType);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.removeEventListener("Dextall.Panels.UI.ParametersChanged", this.onPanelParametersChanged);
        eventBus.removeEventListener("Dextall.PanelTypes.Updated", this.onUpdatePanelType);
        eventBus.removeEventListener("Dextall.PanelTypes.NewPanelType", this.onCreatePanelType);
        eventBus.removeEventListener("Dextall.PanelTypes.SwitchPanelTypeRequested", this.onSwitchPanelType);
        eventBus.removeEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.GeneratedModel.GenerationRequested", this.onStartPanelGeneration);
        eventBus.removeEventListener("Dextall.Panels.UI.SwitchToCustomPanelType", this.onSwitchPanelCustomPanelType);
        eventBus.removeEventListener("Dextall.Panels.UI.RestoreRegularPanelType", this.onRestoreRegularPanelType);

        this.editorPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        this.editorPanel = new PanelDockingPanel(this.viewer.container);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selection = event
            .selections
            .find((x: { model: { id: number; }; }) => x.model.id === this.panelsEditor.modelId);

        const elementDbId = selection?.dbIdArray[0];

        this.raiseSelectionChanged(elementDbId);
    }

    private async onPanelParametersChanged(event: IApplicationEvent<ElementParameterChangedEventPayload>) {
        const { id, elementName } = event.payload;
        let savedElementName = this.panelsEditor.findPanelById(id)?.elementName ?? "";

        const undoRedo = async () => {
            const temp = this.panelsEditor.findPanelById(id)?.elementName ?? "";
            const result = await this.panelsEditor.update(id, savedElementName);
            this.refreshSelection(result);
            savedElementName = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Update panel", undo: undoRedo, redo: undoRedo },
            },
        });

        const result = await this.panelsEditor.update(id, elementName);
        this.refreshSelection(result);
    }

    private async onUpdatePanelType(event: IApplicationEvent<UpdatePanelTypeEventPayload>) {
        const panelType = this.panelsEditor.findPanelType(event.payload.id);
        if (!panelType) {
            throw new Error("Invalid state!");
        }

        let savedPanelProperties: UpdatePanelTypeEventPayload = {
            id: panelType.id,
            name: panelType.name,
            withoutCladdings: panelType.withoutCladdings,
        };

        const undoRedo = async () => {
            const panelType = this.panelsEditor.findPanelType(savedPanelProperties.id);
            if (!panelType) {
                throw new Error("Invalid state!");
            }

            const temp: UpdatePanelTypeEventPayload = {
                id: panelType.id,
                name: panelType.name,
                withoutCladdings: panelType.withoutCladdings,
            };

            const result = await this.panelsEditor.updatePanelType(savedPanelProperties);
            this.refreshSelection(result);
            savedPanelProperties = temp;
        };

        const result = await this.panelsEditor.updatePanelType(event.payload);

        this.refreshSelection(result);

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Update panel type", undo: undoRedo, redo: undoRedo },
            },
        });

        eventBus.dispatchEvent({
            type: "Dextall.Panels.GeneratedModel.Loaded",
            payload: {
                model: { id: "", bubble: null, status: PanelTypeGenerationStatus.None },
                panelTypeId: event.payload.id,
                drawings: [],
            },
        });
    }

    private async onSwitchPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;
        let savedPanelTypeId = this.panelsEditor.findPanelById(panelId)?.panelTypeId ?? "";

        const undoRedo = async () => {
            const temp = this.panelsEditor.findPanelById(panelId)?.panelTypeId ?? "";
            const result = await this.panelsEditor.switchPanelType(panelId, savedPanelTypeId);
            this.refreshPanelType(result, panelId);
            savedPanelTypeId = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Switch panel type", undo: undoRedo, redo: undoRedo },
            },
        });

        const result = await this.panelsEditor.switchPanelType(panelId, targetTypeId);
        this.refreshPanelType(result, panelId);
    }

    private async onSwitchPanelCustomPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;

        const undo = async () => {
            const result = await this.panelsEditor.switchPanelCustomType(panelId, null);
            this.refreshPanelType(result, panelId);
        };

        const redo = async () => {
            const result = await this.panelsEditor.switchPanelCustomType(panelId, targetTypeId);
            this.refreshPanelType(result, panelId);
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Assign custom type to panel", undo, redo },
            },
        });

        const result = await this.panelsEditor.switchPanelCustomType(panelId, targetTypeId);
        this.refreshPanelType(result, panelId);
    }

    private async onRestoreRegularPanelType(event: IApplicationEvent<string>) {
        const panelId = event.payload;
        const customPanelTypeId = this.panelsEditor.findPanelById(panelId)?.customPanelTypeId;

        const undo = async () => {
            if (customPanelTypeId) {
                const result = await this.panelsEditor.switchPanelCustomType(panelId, customPanelTypeId);
                this.refreshPanelType(result, panelId);
            }
        };

        const redo = async () => {
            const result = await this.panelsEditor.switchPanelCustomType(panelId, null);
            this.refreshPanelType(result, panelId);
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Reset to standard type", undo, redo },
            },
        });

        const result = await this.panelsEditor.switchPanelCustomType(panelId, null);

        this.refreshPanelType(result, panelId);
    }

    private async onCreatePanelType(event: IApplicationEvent<CreateNewPanelTypeEventPayload>) {
        const { panelId } = event.payload;
        let savedPanelTypeId = this.panelsEditor.findPanelById(panelId)?.panelTypeId ?? "";

        const undoRedo = async () => {
            const temp = this.panelsEditor.findPanelById(panelId)?.panelTypeId ?? "";
            const result = await this.panelsEditor.switchPanelType(panelId, savedPanelTypeId);
            this.refreshPanelType(result, panelId);
            savedPanelTypeId = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Switch panel type to new type", undo: undoRedo, redo: undoRedo },
            },
        });

        const result = await this.panelsEditor.createPanelType(event.payload, panelId);

        this.refreshPanelType(result, panelId);
    }

    private async onStartPanelGeneration(event: IApplicationEvent<RequestModelGenerationPayload>) {
        const { isPanelModel, panelTypeId } = event.payload;

        if (!isPanelModel) {
            return;
        }

        const generationStartResult = await this.panelsEditor.startPanelTypeGeneration(panelTypeId);

        if (generationStartResult.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.Panels.GeneratedModel.GenerationStarted",
                payload: panelTypeId,
            });

            eventBus.dispatchEvent({
                type: "Dextall.Panels.GeneratedModel.Loaded",
                payload: {
                    model: {
                        id: generationStartResult.item,
                        bubble: null,
                        status: PanelTypeGenerationStatus.ModelGenerationInProgress,
                    },
                    panelTypeId,
                    drawings: [],
                },
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.GeneratedModel.GenerationRequestFailed",
                payload: generationStartResult.message,
            });
        }
    }

    private onPanelTypeSwitched(event: IApplicationEvent<PanelTypeSwitchedEventPayload>) {
        this.panelsEditor.updatePanelTypeTexts(event.payload.panelId);
    }

    private refreshPanelType(result: CreatePanelTypeOperationResult, panelId: string) {
        if (result.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.PanelTypes.PanelTypeSwitched",
                payload: { panelId, removedHooksDbIds: result.removedHooksDbIds },
            });
        }

        this.refreshSelection(result);
    }

    private refreshSelection(operationResult: BasicResponse) {
        if (!operationResult.isSuccess) {
            this.notifyError(operationResult.message);
        }

        const selection = this.viewer.getAggregateSelection().find(x => x.model.id === this.panelsEditor.modelId);

        this.raiseSelectionChanged(selection?.selection[0]);
    }

    private raiseSelectionChanged(elementDbId: number | undefined) {
        const panel = elementDbId !== undefined ? this.panelsEditor.findPanel(elementDbId) : undefined;

        const family = panel && this.panelsEditor.findPanelFamily(panel.panelTypeId);

        eventBus.dispatchEvent({
            type: "Dextall.Panels.SelectionChanged",
            payload: (panel && family) ? {
                panel,
                family,
                customPanelTypes: this.panelsEditor.findValidCustomPanelTypesForPanel(panel.id),
            } : null,
        });

        this.editorPanel?.setVisible(panel !== undefined);

        this.panelsEditor.loadPanelTypeModel(panel);
    }

    private notifyError(message: string) {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message,
        });
    }
}

export const forgePanelsEditorName = "Dextall.ForgePanelsEditor" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgePanelsEditorName, ForgePanelsEditor);
