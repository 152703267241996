import { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { ContentNotReady } from './components/contentNotReady';
import { BasicItemResponse } from './responses/basicResponses';
import { ISourceModel } from "./responses/sourceModel";
import { IInsulatedGlassUnit } from './responses/insulatedGlassUnit';
import { AppLoadingSpinner } from './components/appLoadingSpinner';
import { ModelEditorViewer } from './components/modelEditorViewer';
import { CustomPanelComponentSelector } from './components/forge/custom-component-library/customPanelComponentSelector';
import { makeFix } from './components/forge/fixes/fixes.js';
import { IWallFace } from './responses/wallFace';
import { SystemSettings } from './responses/systemSettings';
import { IPanelSource } from './responses/panelSource';
import { IModelCorner } from './responses/modelCorner';
import { ICornerPanelSource } from './responses/cornerPanelSource';
import { IFamily } from './responses/panelType';
import { ICustomCornerType, ICustomPanelType, ICustomZShapeType } from './responses/customPanelTypes';
import { ICustomPanelSource } from './responses/customPanelSource';
import { ICustomCornerSource } from './responses/customCornerSource';
import { ICustomZShapedPanelSource } from './responses/customZShapedPanelSource';
import { TextGeometryFactory } from './components/forge/geometry/textGeometryFactory';
import { ContentDownload } from './components/content-download/contentDownload';
import { ApplicationNotifications } from './components/notifications/applicationNotifications';
import { CustomCornerComponentSelector } from './components/forge/custom-component-library/customCornerComponentSelector';
import { CustomZShapedPanelComponentSelector } from './components/forge/custom-component-library/customZShapedPanelComponentSelector';
import { AppPanelsStickyNotesGenerator } from './components/appPanelsStickyNotesGenerator';
import customComponentColorsFactory from "./components/forge/colors/customComponentColorsFactory";
import { IPanelGeneratedModelDto } from './responses/panelGeneratedModelDto';
import repo from "./Repository";

export const App = () => {
  const [sourceModel, setSourceModel] = useState<BasicItemResponse<ISourceModel>>();
  const generatedModels = useRef<IPanelGeneratedModelDto[]>([]);
  const insulatedGlassUnits = useRef<IInsulatedGlassUnit[]>([]);
  const modelFaces = useRef<IWallFace[]>([]);
  const modelCorners = useRef<IModelCorner[]>([]);
  const panels = useRef<IPanelSource[]>([]);
  const cornerPanels = useRef<ICornerPanelSource[]>([]);
  const panelFamilies = useRef<IFamily[]>([]);
  const cornerFamilies = useRef<IFamily[]>([]);
  const customPanelTypes = useRef<ICustomPanelType[]>([]);
  const customCornerTypes = useRef<ICustomCornerType[]>([]);
  const customZShapedTypes = useRef<ICustomZShapeType[]>([]);
  const customPanels = useRef<ICustomPanelSource[]>([]);
  const customCorners = useRef<ICustomCornerSource[]>([]);
  const customZShapedPanels = useRef<ICustomZShapedPanelSource[]>([]);
  const systemSettings = useRef<SystemSettings>();
  const textGeometryFactory = useRef<TextGeometryFactory>();
  const { id } = useParams();
  const { hash } = useLocation();

  useEffect(() => {
    if (!id)
      return;

    repo.setAccessToken(hash.substring(1));

    const initializeViewer = () => {
      return new Promise<void>((resolve) => {
        const options: Autodesk.Viewing.InitializerOptions = {
          getAccessToken: (callback) => {
            let attempt = 0;

            const queryToken = () => {
              repo.getForgeToken().then(x => {
                if (typeof callback !== "function")
                  return;

                if (x.isSuccess) {
                  callback(x.item.access_token, x.item.expires_in);
                  return;
                }

                ++attempt;

                setTimeout(queryToken, 1000 * Math.pow(2, attempt));
              });
            }

            queryToken();
          },
          env: 'AutodeskProduction2',
          api: 'streamingV2'
        }

        Autodesk.Viewing.Initializer(options, () => {
          makeFix();
          resolve();
        })
      });
    }

    const fetchData = async () => {
      const responses = await Promise.all([
        repo.findModel(id),
        repo.loadInsulatedGlassUnits(),
        repo.findModelFaces(id),
        repo.findModelSystemSettings(id),
        repo.findModelPanels(id),
        repo.findModelCorners(id),
        repo.findModelCornerPanels(id),
        repo.findModelPanelFamilies(id),
        repo.findModelCornerPanelFamilies(id),
        TextGeometryFactory.create(),
        repo.loadCustomComponentMaterialVertexShader(),
        repo.loadCustomComponentMaterialFragmentShader(),
        repo.loadCustomCornerComponentMaterialVertexShader(),
        repo.loadCustomCornerComponentMaterialFragmentShader(),
        repo.findPublishedCustomPanelTypes(),
        repo.findPublishedCustomCornerTypes(),
        repo.findPublishedCustomZShapedTypes(),
        repo.findModelCustomPanels(id),
        repo.findModelCustomCorners(id),
        repo.findModelCustomZShapedPanels(id),
        repo.findAllPanelGeneratedModels(id),
        initializeViewer()
      ]);

      const [
        modelResponse,
        insulatedGlassUnitsResponse,
        modelFacesResponse,
        systemSettingsResponse,
        panelsResponse,
        modelCornersResponse,
        cornersResponse,
        panelsFamiliesResponse,
        cornerPanelFamiliesResponse,
        textGeometry,
        vertexShader,
        fragmentShader,
        cornerVertexShader,
        cornerFragmentShader,
        publishedCustomPanelTypesResponse,
        publishedCustomCornerPanelTypes,
        publishedCustomZShapedTypes,
        customPanelsResponse,
        customCornersResponse,
        customZShapedPanelsResponse,
        generatedModelsResponse
      ] = responses;

      setSourceModel(modelResponse);

      insulatedGlassUnits.current = insulatedGlassUnitsResponse.items || [];
      modelFaces.current = modelFacesResponse.items || [];
      systemSettings.current = systemSettingsResponse.item!;
      panels.current = panelsResponse.items || [];
      modelCorners.current = modelCornersResponse.items || [];
      cornerPanels.current = cornersResponse.items || [];
      panelFamilies.current = panelsFamiliesResponse.items || [];
      cornerFamilies.current = cornerPanelFamiliesResponse.items || [];
      customPanelTypes.current = publishedCustomPanelTypesResponse.items || [];
      customCornerTypes.current = publishedCustomCornerPanelTypes.items || [];
      customZShapedTypes.current = publishedCustomZShapedTypes.items || [];
      customPanels.current = customPanelsResponse.items || [];
      customCorners.current = customCornersResponse.items || [];
      customZShapedPanels.current = customZShapedPanelsResponse.items || [];
      generatedModels.current = generatedModelsResponse.items || [];
      textGeometryFactory.current = textGeometry;

      if (vertexShader && fragmentShader)
        customComponentColorsFactory.initialize(vertexShader, fragmentShader, cornerVertexShader, cornerFragmentShader);
    };

    fetchData();

    return () => {
      Autodesk.Viewing.shutdown();
    }
  }, [setSourceModel]);

  if (!(id && hash) || (sourceModel && !sourceModel.isSuccess))
    return <div style={{ height: "100vh" }}><ContentNotReady /></div>

  if (!sourceModel)
    return <AppLoadingSpinner />

  return <>
    <CustomPanelComponentSelector customPanelTypes={customPanelTypes.current} />
    <CustomCornerComponentSelector customPanelTypes={customCornerTypes.current} />
    <CustomZShapedPanelComponentSelector customPanelTypes={customZShapedTypes.current} />
    {id && <AppPanelsStickyNotesGenerator id={id} />}
    <ModelEditorViewer
      model={sourceModel.item!}
      modelFaces={modelFaces.current}
      modelCorners={modelCorners.current}
      panels={panels.current}
      cornerPanels={cornerPanels.current}
      panelFamilies={panelFamilies.current}
      cornerFamilies={cornerFamilies.current}
      insulationGlassUnits={insulatedGlassUnits.current}
      customPanelTypes={customPanelTypes.current}
      customCornerTypes={customCornerTypes.current}
      customZShapedTypes={customZShapedTypes.current}
      customPanels={customPanels.current}
      customCorners={customCorners.current}
      customZShapedPanels={customZShapedPanels.current}
      systemSetting={systemSettings.current!}
      textGeometryFactory={textGeometryFactory.current!}
      generatedModels={generatedModels.current}
    />
    <ApplicationNotifications />
    <ContentDownload />
  </>
}
