import React, { StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';
import "./dockingPanel.css"

export type DockingPanelDimensionControlValue = {
    value: number;
    minValue?: number;
}

export type DockingPanelDimensionControl = {
    preventOverlapsWithViewerToolbar: boolean;
    widthDimensionControl?: DockingPanelDimensionControlValue;
    heightDimensionControl?: DockingPanelDimensionControlValue;
    topOffset?: number;
    bottomOffset?: number;
    leftOffset?: number;
    rightOffset?: number;
}

export interface IDockingPanelOptions {
    style: React.CSSProperties;
    dimensionsControl?: DockingPanelDimensionControl;
    carefullBringToFront?: boolean;
}

type TitleButton = {
    title: string;
    iconClass: string;
    onClick: () => void;
}

export class PanelTitleButton {
    private visible = true;
    constructor(private readonly buttonComponent: HTMLElement, private readonly buttonIconComponent: HTMLElement, private iconCLass: string) {

    }

    setTitle(title: string) {
        this.buttonComponent.title = title;
    }

    setIconClass(className: string) {
        this.buttonIconComponent.classList.replace(this.iconCLass, className);
        this.iconCLass = className;
    }

    setVisible(visibility: boolean) {
        if (this.visible === visibility)
            return;

        this.visible = visibility;

        this.buttonIconComponent.style.display = visibility ? "" : "none";
    }
}

class PanelMaxSizeToggle {
    private parentContainer?: HTMLElement;
    private maximized = false;
    private width = 0;
    private height = 0;
    private top = 0;
    private left = 0;
    private button?: PanelTitleButton;
    private resizeObserver?: ResizeObserver;

    constructor(dockingPanel: DockingPanel,
        private readonly container: HTMLElement,
        private readonly dimensionsControl: DockingPanelDimensionControl) {
        this.parentContainer = dockingPanel.parentContainer;

        this.toggle = this.toggle.bind(this);
        this.onResize = this.onResize.bind(this);

        this.button = dockingPanel.addTitleButton({
            iconClass: "docking-panel-title-maximize-icon",
            onClick: this.toggle,
            title: "Maximize"
        });
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(container);
    }

    dispose() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.parentContainer!);
            this.resizeObserver = undefined;
        }

        delete this.parentContainer;
        delete this.button;
    }

    private toggle() {
        if (this.maximized) {
            this.container.style.top = `${this.top}px`;
            this.container.style.left = `${this.left}px`;
            this.container.style.width = `${this.width}px`;
            this.container.style.height = `${this.height}px`;

            this.setMinimizedState();
        } else {
            this.width = parseInt(this.container.style.width);
            this.height = parseInt(this.container.style.height);
            this.top = this.container.style.top
                ? parseInt(this.container.style.top)
                : this.container.getBoundingClientRect().top - this.parentContainer!.getBoundingClientRect().top;
            this.left = this.container.style.left
                ? parseInt(this.container.style.left)
                : this.container.getBoundingClientRect().left - this.parentContainer!.getBoundingClientRect().left;

            this.container.style.top = "2px";
            this.container.style.left = "2px";
            this.container.style.maxWidth = `${this.getMaximizedWidth()}px`;
            this.container.style.width = `${this.getMaximizedWidth()}px`;
            this.container.style.maxHeight = `${this.getMaximizedHeight()}px`;
            this.container.style.height = `${this.getMaximizedHeight()}px`;

            this.setMaximizedState();
        }
    }

    private onResize() {
        if (this.maximized && !this.isMaximized())
            this.setMinimizedState();
    }

    private setMinimizedState() {
        this.button?.setIconClass("docking-panel-title-maximize-icon");
        this.button?.setTitle("Maximize");
        this.maximized = false;
    }

    private setMaximizedState() {
        this.button?.setIconClass("docking-panel-title-minimize-icon");
        this.button?.setTitle("Minimize");
        this.maximized = true;
    }

    private isMaximized() {
        return this.container.clientWidth === this.getMaximizedWidth()
            && this.container.clientHeight === this.getMaximizedHeight();
    }

    private getMaximizedWidth() {
        return this.parentContainer!.clientWidth - 4;
    }

    private getMaximizedHeight() {
        const bottomOffset = getDockingPanelContainerBottomOffset(this.parentContainer!, this.dimensionsControl);

        return this.parentContainer!.clientHeight - bottomOffset - 4;
    }
}

export class DockingPanel extends Autodesk.Viewing.UI.DockingPanel {
    private readonly dimensionsControl: DockingPanelDimensionControl;
    private readonly root: Root;
    private readonly carefullBringToFront: boolean;
    private titleComponent: HTMLElement | undefined;
    private maxSizeToggle?: PanelMaxSizeToggle;
    private firstTime = true;
    constructor(parentContainer: HTMLElement, id: string, title: string, component: React.ReactNode, options: IDockingPanelOptions) {
        super(parentContainer, id, title);

        this.dimensionsControl = options.dimensionsControl || { preventOverlapsWithViewerToolbar: false };

        Object.assign(this.container.style, options.style);

        this.createScrollContainer({ heightAdjustment: 50 });

        this.root = createRoot(this.scrollContainer!);

        this.root.render(<StrictMode>{component}</StrictMode>);

        this.carefullBringToFront = !!options.carefullBringToFront;
    }

    static getScrollContainerId(id: string): string {
        return `${id}-scroll-container`;
    }

    initialize(): void {
        this.title = this.createTitleBar();
        this.container.appendChild(this.title);
        this.initializeMoveHandlers(this.title);
        this.setTitle(this.titleLabel || this.container.id);

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);
    }

    shutdown() {
        this.root.unmount();
        this.container.removeChild(this.scrollContainer!);
        this.container.removeChild(this.title);
        this.container.removeChild(this.closer);
        this.scrollContainer = undefined;
        this.maxSizeToggle?.dispose();
        this.maxSizeToggle = undefined;
        this.uninitialize();
    }

    setTitle(text: string): void {
        const titleDiv = this.titleComponent || this.createTitleComponent();

        titleDiv.innerText = text;
    }

    addTitleButton(button: TitleButton) {
        const buttonComponent = document.createElement("div");
        buttonComponent.title = button.title;
        buttonComponent.classList.add("adsk-control", "adsk-button", "inactive", "docking-panel-title-button");
        this.addEventListener(buttonComponent, "mousedown", e => {
            e.stopPropagation();
            e.preventDefault();
            button.onClick();
        });

        const buttonIcon = document.createElement("div");
        buttonIcon.classList.add("adsk-button-icon", "docking-panel-title-button-icon", button.iconClass);

        buttonComponent.appendChild(buttonIcon);

        this.title.appendChild(buttonComponent);

        return new PanelTitleButton(buttonComponent, buttonIcon, button.iconClass);
    }

    addToggleFullSizeButton() {
        if (this.maxSizeToggle)
            return;

        this.maxSizeToggle = new PanelMaxSizeToggle(this, this.container, this.dimensionsControl);
    }

    createTitleBar(): HTMLElement {
        const titleBar = document.createElement("div");
        titleBar.classList.add("docking-panel-title");

        const titleBarStyles: React.CSSProperties = {
            display: "flex",
            flexDirection: "row",
            paddingRight: "39px"
        }

        Object.assign(titleBar.style, titleBarStyles);

        this.addEventListener(titleBar, "mousedown", () => this.bringToFront());

        return titleBar;
    }

    allowHorizontalScroll() {
        if (!this.scrollContainer)
            return;

        this.scrollContainer.style.overflowX = "auto";
    }

    setVisible(show: boolean): void {
        if (show && this.firstTime) {
            this.firstTime = false;

            this.applyDimensionFromDimensionControl();
        }

        super.setVisible(show);
    }

    bringToFront() {
        if (this.carefullBringToFront)
            this.bringToFrontWithoutReappending();
        else
            super.bringToFront();
    }

    private createTitleComponent() {
        const titleDiv = document.createElement("div");

        titleDiv.style.width = "100%";

        this.titleComponent = titleDiv;

        this.title.appendChild(titleDiv);

        return titleDiv;
    }

    private applyDimensionFromDimensionControl() {
        const containerBottomOffset = getDockingPanelContainerBottomOffset(this.parentContainer, this.dimensionsControl);

        const maxContainerHeight = this.parentContainer.clientHeight - containerBottomOffset
            - (this.dimensionsControl.bottomOffset || 0) - (this.dimensionsControl.topOffset || 0);

        const maxContainerWidth = this.parentContainer.clientWidth
            - (this.dimensionsControl.rightOffset || 0) - (this.dimensionsControl.leftOffset || 0);

        if (this.dimensionsControl.heightDimensionControl) {
            const targetHeight = Math.min(this.dimensionsControl.heightDimensionControl.value, maxContainerHeight);

            this.container.style.height = `${targetHeight}px`;

            if (this.dimensionsControl.heightDimensionControl.minValue) {
                const targetMinHeightValue = Math.min(this.dimensionsControl.heightDimensionControl.minValue, maxContainerHeight);

                this.container.style.minHeight = `${targetMinHeightValue}px`;
            }
        }

        if (this.dimensionsControl.widthDimensionControl) {
            const targetWidth = Math.min(this.dimensionsControl.widthDimensionControl.value, maxContainerWidth);

            this.container.style.width = `${targetWidth}px`;

            if (this.dimensionsControl.widthDimensionControl.minValue) {
                const targetMinWidhtValue = Math.min(this.dimensionsControl.widthDimensionControl.minValue, maxContainerWidth);

                this.container.style.minWidth = `${targetMinWidhtValue}px`;
            }
        }

        if (this.dimensionsControl.bottomOffset !== undefined) {
            const targetOffset = containerBottomOffset + this.dimensionsControl.bottomOffset;

            this.container.style.bottom = `${targetOffset}px`;
        }

        if (this.dimensionsControl.topOffset !== undefined)
            this.container.style.top = `${this.dimensionsControl.topOffset}px`;

        if (this.dimensionsControl.leftOffset !== undefined)
            this.container.style.left = `${this.dimensionsControl.leftOffset}px`;

        if (this.dimensionsControl.rightOffset !== undefined)
            this.container.style.right = `${this.dimensionsControl.rightOffset}px`;
    }

    private bringToFrontWithoutReappending() {
        const elementsToMove = this.findElementsToMoveWithoutReappending();

        const parent = this.container.parentElement!;

        for (const element of elementsToMove)
            parent.insertBefore(element, this.container);
    }

    private findElementsToMoveWithoutReappending(): Element[] {
        const elements: Element[] = [];

        const parent = this.container.parentElement!;

        for (let i = parent.children.length - 1; i >= 0; --i) {
            const element = parent.children[i];
            
            if (element !== this.container)
                elements.push(element);
            else
                break;
        }

        return elements;
    }
}

const getDockingPanelContainerBottomOffset = (viewerContainer: HTMLElement, dimensionsControl: DockingPanelDimensionControl) => {
    if (!dimensionsControl.preventOverlapsWithViewerToolbar)
        return 0;

    const toolbarContainer = viewerContainer.querySelector("#guiviewer3d-toolbar")!;

    return toolbarContainer.clientHeight + viewerContainer.getBoundingClientRect().bottom - toolbarContainer.getBoundingClientRect().bottom;
}