import { AlertTriangle, Download } from "react-feather";
import { RevitFacadeGeneratedModel } from "../../../../../../responses/revitGeneratedModel";
import { GenerateNewRevitFacadeModelControls } from "./generateNewRevitFacadeModelControls";
import eventBus from "../../../../eventBus/eventDispatcher";

type Props = {
    model: RevitFacadeGeneratedModel;
};

export const GeneratedFacadeModel = ({ model }: Props) => {
    return <>
        {model.isOutdated &&
            <>
                <div className="generated-revit-model-content-button-container">
                    <AlertTriangle size={16} color="red" />
                </div>
                <div className="generated-revit-model-content-button-container">
                    <span style={{ color: "red" }}>outdated</span>
                </div>
            </>}

        <div className="generated-revit-model-content-button-container" title="Download Revit facade model">
            <Download
                size={16}
                color="#0796D8"
                onClick={() => eventBus.dispatchEvent({
                    type: "Dextall.RevitFacadeModel.UI.Download",
                    payload: model.id,
                })} />
        </div>

        <GenerateNewRevitFacadeModelControls />
    </>;
};