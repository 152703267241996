import { CornerHookType, HookProfileSide, HookType, IHookSource } from "../../../responses/hookSource";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { IModelHook } from "./modelHook";

export class CornerHook implements IModelHook {
    private _x: number;
    private _y: number;
    private _hookType: HookType;
    private _profileSide: HookProfileSide;


    constructor(private readonly hookSource: IHookSource,
        private readonly wingTransform: THREE.Matrix4,
        private readonly basicGeometry: ExtrusionGeometry[],
        public readonly panelId: string,
        public readonly wallFaceId: string,
        public readonly alignmentWallFaceId: string,
        public readonly dbId: number,
        public readonly maxX: number,
        public readonly maxY: number) {
        this._x = hookSource.x;
        this._y = hookSource.y;
        this._hookType = hookSource.hookType;
        this._profileSide = hookSource.profileSide;
    }

    get hookType(): HookType {
        return this._hookType;
    }

    get profileSide(): HookProfileSide {
        return this._profileSide;
    }

    get cornerHookType(): Exclude<CornerHookType, CornerHookType.None> {
        if (this.hookSource.cornerHookType === CornerHookType.None)
            throw new Error("Something is terribly wrong!");

        return this.hookSource.cornerHookType;
    }

    get x(): number {
        return this._x;
    }

    get y(): number {
        return this._y;
    }

    get matrix(): THREE.Matrix4 {
        return this.computeGeometryTransform();
    }

    get originMatrix(): THREE.Matrix4 {
        return this.wingTransform;
    }

    createGeometry(): ExtrusionGeometry[] {
        const transform = this.computeGeometryTransform();

        return this.basicGeometry.map(x => { return { ...x, matrix: new THREE.Matrix4().multiplyMatrices(transform, x.matrix) } });
    }

    update(hookSource: IHookSource) {
        this.setPosition(hookSource.x, hookSource.y);
        this._hookType = hookSource.hookType;
        this._profileSide = hookSource.profileSide;
    }

    setPosition(x: number, y: number) {
        this._x = x;
        this._y = y;
    }

    private computeGeometryTransform() {
        return new THREE.Matrix4()
            .multiplyMatrices(this.wingTransform, new THREE.Matrix4()
                .setPosition(new THREE.Vector3(this.x, this.y, editorCladdingGeometryThickness + 2 * zFightingFixDistance)))
    }
}
