import ProgressBar from "@weave-design/progress-bar";
import "./processingPane.css";

type Props = {
    style?: React.CSSProperties;
    tooltip?: {
        message: string;
        style?: React.CSSProperties;
    }
};

export const ProcessingProgressBar = ({ style, tooltip }: Props) => {
    return <div className="processingProgressAction" style={{
        ...style,
        fontWeight: "bold",
        position: tooltip ? "relative" : "static",
    }}>
        <ProgressBar className="processingProgressBar" />
        {tooltip && <div className="processingProgressBarTooltip" style={tooltip.style}>{tooltip.message}</div>}
    </div>;
};