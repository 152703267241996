import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { CustomPanelType, CustomPanelTypeShape, ICustomPanelType } from "../../../../responses/customPanelTypes";
import { CladdingCellStyle } from "../../../../responses/panelSource";
import { ComponentsLibraryDialog } from "../../../custom-components-library/componentsLibraryDialog";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { noop, rootElement } from "../../../shared";
import { BooleanPropertyEditor } from "../../editor-inputs/booleanPropertyEditor";
import { EditorPanelType, newPanelTypeDropdownItem } from "../../editor-inputs/editorPanelType";
import { ImperialAndMetricDimensionEditor } from "../../editor-inputs/imperialAndMetricDimensionEditor";
import { ObjectUserUniqueId } from "../../editor-inputs/objectUserUniqueId";
import { PanelTypeSelector } from "../../editor-inputs/panelTypeSelector";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";
import { getPanelUserUniqueIdObjectPrefix } from "../../ids/userObjectIds";
import { CladdingsMaterialTextField } from "./claddingsMaterialTextField";
import { EditedType, PanelTypeEditor } from "./panel-type-editor/panelTypeEditor";
import { PanelStickyNote } from "./panelStickyNote";
import { PanelSelectionChangedEventPayload } from "../../eventBus/selectionChangedEventsPayloads";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type PanelFields = {
    id: string;
    userUniqueId: number;
    elementName: string;
    length: number;
    height: number;
    offsetX: number;
    offsetY: number;
    panelTypeId: string;
    thicknessLabel: string;
    style: CladdingCellStyle;
    customPanelTypeName: string | undefined;
    isCustomPanel: boolean;
    isParapetPanel: boolean;
    customPanelTypes: ICustomPanelType[];
};

type AsyncOperation = {
    title: string;
    message: string;
};

export const PanelEditorDockingPanelContent = () => {
    const [panel, setPanel] = useState<PanelFields>({
        id: "",
        userUniqueId: 0,
        elementName: "",
        length: 0,
        height: 0,
        offsetX: 0,
        offsetY: 0,
        panelTypeId: "",
        thicknessLabel: "",
        style: CladdingCellStyle.Exposed,
        isCustomPanel: false,
        isParapetPanel: false,
        customPanelTypes: [],
        customPanelTypeName: undefined,
    });
    const [panelTypes, setPanelTypes] = useState<EditorPanelType[]>([]);
    const [selectedPanelType, setSelectedPanelType] = useState<EditorPanelType>({
        name: "",
        withoutCladdings: false,
    });
    const [editingType, setEditingType] = useState<EditorPanelType>();
    const [asyncOperation, setAsyncOperation] = useState<AsyncOperation>();
    const [customComponentLibraryOpened, setCustomComponentLibraryOpened] = useState(false);

    useEffect(() => {
        const onSelectionChanged = (event: IApplicationEvent<PanelSelectionChangedEventPayload | null>) => {
            setAsyncOperation(undefined);
            setCustomComponentLibraryOpened(false);

            if (!event.payload) {
                return;
            }

            const { panel, family, customPanelTypes } = event.payload;

            const {
                id,
                userUniqueId,
                elementName,
                length,
                height,
                offsetX,
                offsetY,
                panelTypeId,
                thicknessLabel,
                style,
                customPanelTypeId,
                isParapetPanel,
            } = panel;

            const customPanelTypeName = customPanelTypeId
                ? customPanelTypes.find(x => x.id === customPanelTypeId)?.name
                : undefined;

            setPanel({
                id,
                userUniqueId,
                elementName,
                length,
                height,
                offsetX,
                offsetY,
                panelTypeId,
                thicknessLabel,
                style,
                customPanelTypes,
                isParapetPanel,
                isCustomPanel: !!customPanelTypeId,
                customPanelTypeName,
            });

            const familyPanelTypes: EditorPanelType[] = family.panelTypes.map(x => {
                return { name: x.name, id: x.id, withoutCladdings: x.withoutCladdings };
            });
            familyPanelTypes.push(newPanelTypeDropdownItem);
            setPanelTypes(familyPanelTypes);

            const targetPanelType = familyPanelTypes.find(x => x.id === panelTypeId);
            if (!targetPanelType) {
                throw new Error("Invalid state!");
            }

            setSelectedPanelType(targetPanelType);
        };

        eventBus.addEventListener("Dextall.Panels.SelectionChanged", onSelectionChanged);

        return () => {
            eventBus.removeEventListener("Dextall.Panels.SelectionChanged", onSelectionChanged);
        };
    }, []);

    const updatePanel = (elementName: string) => {
        eventBus.dispatchEvent({
            type: "Dextall.Panels.UI.ParametersChanged",
            payload: {
                id: panel.id,
                elementName,
            },
        });

        setAsyncOperation({
            title: "Element name",
            message: "Setting element name...",
        });
    };

    const onPanelTypeEdited = (updatedType: EditedType) => {
        if (!editingType) {
            throw new Error("Invalid state!");
        }

        if (editingType.id) {
            eventBus.dispatchEvent({
                type: "Dextall.PanelTypes.Updated",
                payload: {
                    ...updatedType,
                    id: editingType.id,
                },
            });

            setAsyncOperation({
                title: "Updating panel type...",
                message: "Updating panel type...",
            });
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.PanelTypes.NewPanelType",
                payload: {
                    ...updatedType,
                    panelId: panel.id,
                    id: panel.panelTypeId,
                },
            });

            setAsyncOperation({
                title: "Creating a new panel type...",
                message: "Creating a new panel type...",
            });
        }

        setEditingType(undefined);
    };

    const onSelectPanelType = (selectedType: EditorPanelType) => {
        if (selectedType.id) {
            eventBus.dispatchEvent({
                type: "Dextall.PanelTypes.SwitchPanelTypeRequested",
                payload: {
                    panelId: panel.id,
                    targetTypeId: selectedType.id,
                },
            });

            setAsyncOperation({
                title: "Switching panel type...",
                message: "Switching panel type...",
            });
        } else {
            setEditingType({ name: `${selectedPanelType.name}_new`, withoutCladdings: false });
        }
    };

    const onSelectCustomPanelType = (customPanelType: CustomPanelType) => {
        if (customPanelType.shapeType !== CustomPanelTypeShape.Panel) {
            throw new Error("Invalid state");
        }

        setCustomComponentLibraryOpened(false);

        setAsyncOperation({
            title: "Custom component...",
            message: "Replacing with custom component...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.Panels.UI.SwitchToCustomPanelType",
            payload: {
                panelId: panel.id,
                targetTypeId: customPanelType.id,
            },
        });
    };

    const onResetCustomPanelType = () => {
        setAsyncOperation({
            title: "Custom component...",
            message: "Setting regular panel type...",
        });

        eventBus.dispatchEvent({
            type: "Dextall.Panels.UI.RestoreRegularPanelType",
            payload: panel.id,
        });
    };

    return (
        <div className="fullheight" style={{ display: "flex", flexDirection: "column" }}>
            <div className="parameters-pane fullheight">
                <ObjectUserUniqueId prefix={getPanelUserUniqueIdObjectPrefix()} idValue={panel.userUniqueId} />
                <PanelTypeSelector
                    panelTypes={panelTypes}
                    selectedPanelType={selectedPanelType}
                    title="Type:"
                    onChange={onSelectPanelType}
                    onEditTypeRequested={() => setEditingType({ ...selectedPanelType })}
                    selectedCustomPanelTypeName={panel.customPanelTypeName}
                    onCustomPanelTypeRequested={() => setCustomComponentLibraryOpened(true)}
                    onCustomPanelTypeReset={onResetCustomPanelType}
                />
                <TextPropertyEditor
                    title="Elem name:"
                    value={panel.elementName}
                    onChange={newValue => updatePanel(newValue)}
                />
                {!panel.isCustomPanel && (
                    <TextPropertyEditor
                        title="Thickness:"
                        value={panel.thicknessLabel}
                        onChange={noop}
                        disabled={true}
                    />
                )}
                {!panel.isCustomPanel && <CladdingsMaterialTextField claddingCellStyle={panel.style} />}
                <BooleanPropertyEditor
                    title="Parapet:"
                    value={panel.isParapetPanel}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Length:"
                    value={panel.length}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={panel.height}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="X offset:"
                    value={panel.offsetX}
                    onChange={noop}
                    disabled={true}
                />
                <ImperialAndMetricDimensionEditor
                    title="Y offset:"
                    value={panel.offsetY}
                    onChange={noop}
                    disabled={true}
                />
                <PanelStickyNote id={panel.id} type="panels" />
                {editingType &&
                    ReactDOM.createPortal(
                        <PanelTypeEditor
                            title="Edit panel type"
                            panelType={editingType}
                            onConfirm={onPanelTypeEdited}
                            onCancel={() => setEditingType(undefined)} />,
                        rootElement,
                    )}
                {asyncOperation &&
                    ReactDOM.createPortal(
                        <ProcessingPane
                            isVisible={true}
                            message={asyncOperation.message}
                            title={asyncOperation.title}
                        />,
                        rootElement,
                    )}
                {customComponentLibraryOpened &&
                    ReactDOM.createPortal(
                        <ComponentsLibraryDialog
                            closeDialog={() => setCustomComponentLibraryOpened(false)}
                            customPanelTypes={panel.customPanelTypes}
                            selectLibraryComponent={onSelectCustomPanelType}
                        />,
                        rootElement,
                    )}
            </div>
        </div>
    );
};
