import { Alert24 } from "@weave-design/icons";
import "./no-data.css";

interface IComponentProps {
    label?: string;
}

export const ContentNotReady = (props: IComponentProps) => {
    return <div className="fullheight" style={{ width: "100%" }}>
        <div className="no-data">
            {props.label && <div className="title">
                <Alert24 />&nbsp;{props.label}
            </div>}
            <div className="image"></div>
        </div>
    </div>;
};