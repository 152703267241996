import { IUndoRedoAction } from "./undoRedoAction";

type UndoRedoActionCallbackType =
    | "before-undo"
    | "before-redo"
    | "after-undo"
    | "after-redo"
    | "action-pushed"
    | "stacked-cleared";

export class UndoRedo {
    private currentCompositeAction: CompositeUndoRedoAction | null = null;
    private readonly eventDispatcher = new Autodesk.Viewing.EventDispatcher();
    private readonly redoActions: IUndoRedoAction[] = [];
    private readonly undoActions: IUndoRedoAction[] = [];

    getUndoActionName(): string | undefined {
        return this.undoActions.at(-1)?.name;
    }

    getRedoActionName(): string | undefined {
        return this.redoActions.at(-1)?.name;
    }

    addEventListener(type: UndoRedoActionCallbackType, callback: () => void) {
        this.eventDispatcher.addEventListener(type, callback);
    }

    canRedo(): boolean {
        return this.redoActions.length > 0;
    }

    canUndo(): boolean {
        return this.undoActions.length > 0;
    }

    clear() {
        this.undoActions.length = 0;
        this.redoActions.length = 0;
        this.currentCompositeAction = null;

        this.dispatchEvent("stacked-cleared");
    }

    dispose() {
        this.clear();
        this.eventDispatcher.clearListeners();
    }

    finishCompositeAction() {
        if (this.currentCompositeAction === null)
            throw new Error("Invalid state. Current composite action doesn't exist");

        if (this.currentCompositeAction.isNotEmpty()) {
            this.undoActions.push(this.currentCompositeAction);
            this.redoActions.length = 0;

            this.dispatchEvent("action-pushed");
        }

        this.currentCompositeAction = null;
    }

    push(action: IUndoRedoAction) {
        if (this.currentCompositeAction !== null) this.currentCompositeAction.push(action);
        else this.undoActions.push(action);

        this.redoActions.length = 0;

        if (this.currentCompositeAction === null) this.dispatchEvent("action-pushed");
    }

    async redo() {
        this.dispatchEvent("before-redo");

        const action = this.redoActions.pop();

        if (!action) return;

        const redoResult = action.redo();

        if (redoResult instanceof Promise) await redoResult;

        this.undoActions.push(action);

        this.dispatchEvent("after-redo");
    }

    removeEventListener(type: UndoRedoActionCallbackType, callback: () => void) {
        this.eventDispatcher.removeEventListener(type, callback);
    }

    startCompositeAction(actionName: string) {
        this.currentCompositeAction = new CompositeUndoRedoAction(actionName);
    }

    async undo() {
        this.dispatchEvent("before-undo");

        const action = this.undoActions.pop();

        if (!action) return;

        const undoResult = action.undo();

        if (undoResult instanceof Promise) await undoResult;

        this.redoActions.push(action);

        this.dispatchEvent("after-undo");
    }

    private dispatchEvent(type: UndoRedoActionCallbackType) {
        this.eventDispatcher.dispatchEvent({ type });
    }
}

class CompositeUndoRedoAction implements IUndoRedoAction {
    private readonly actions: IUndoRedoAction[] = [];

    constructor(public readonly name: string) {}

    isNotEmpty(): boolean {
        return this.actions.length > 0;
    }

    push(action: IUndoRedoAction) {
        this.actions.push(action);
    }

    redo(): void {
        for (const action of this.actions) action.redo();
    }

    undo(): void {
        for (let i = this.actions.length - 1; i >= 0; --i) this.actions[i].undo();
    }
}
