import { useMemo } from "react";
import { CladdingCellStyle } from "../../../../responses/panelSource";
import { TextPropertyEditor } from "../../editor-inputs/textPropertyEditor";

type Props = {
    claddingCellStyle: CladdingCellStyle;
};

export const CladdingsMaterialTextField = ({ claddingCellStyle }: Props) => {
    const title = useMemo<string>(() => {
        return claddingCellStyle === CladdingCellStyle.Acm
            ? "ACM"
            : "Aluminium";
    }, [claddingCellStyle]);

    return <TextPropertyEditor title="Claddings:" value={title} onChange={() => { }} disabled={true} />;
};