import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "./App";
import { getLocalApplicationMetadata } from "./appMetadata";
import { rootElement } from "./components/shared";
import { LibraryApp } from "./LibraryApp";
import { PanelModelApp } from "./PanelModelApp";
import "./index.css";

const appMetadata = getLocalApplicationMetadata();

if (appMetadata.environment === "production") {
    Sentry.init({
        dsn: "https://b5dd3f9943861e8be35c4108faf2ce22@o4506003617808384.ingest.sentry.io/4506019377119232",
        environment: "webapp",
        defaultIntegrations: false,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `dextall-studio-engineering@${appMetadata.version}`,
        integrations: [
            Sentry.breadcrumbsIntegration({ console: false }),
            Sentry.globalHandlersIntegration(),
            Sentry.dedupeIntegration(),
            Sentry.functionToStringIntegration(),
            Sentry.replayIntegration({ mutationLimit: 500 }),
            Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
        ],
    });
}

const root = createRoot(rootElement);

// TODO: enable strict mode
// Currently this issue: https://github.com/Autodesk/hig/issues/3053 prevents us from enabling it right now

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/:id" element={<App />} />
            <Route path="/panel-model/:id" element={<PanelModelApp />} />
            <Route path="/library" element={<LibraryApp />} />
        </Routes>
    </BrowserRouter>,
);
