import { fitToIntervalBounds } from "@dextall/shared";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { GizmoBase } from "./gizmoBase";
import { SnapGrid } from "../geometry/snapGrid";
import eventBus from "../eventBus/eventDispatcher";

export class HookGizmo extends GizmoBase {
    constructor(viewer: Autodesk.Viewing.Viewer3D, public readonly hook: Hook | CornerHook, private readonly snapGrid: SnapGrid) {
        const transformControl = new THREE.TransformControls(viewer.impl.camera, viewer.impl.canvas, "translate", "XY");

        super(transformControl);

        this.mesh.applyMatrix(this.hook.matrix);

        const boundingBox = viewer.model.getBoundingBox();
        this.transformControl.setSize(boundingBox.getBoundingSphere().radius * 5);
        this.transformControl.attach(this.mesh);
        this.transformControl.setSpace("local");

        this.onGizmoChanged = this.onGizmoChanged.bind(this);
        this.transformControl.addEventListener("change", this.onGizmoChanged)
    }

    restore() {
        this.apply(this.hook.matrix);
    }

    dispose() {
        this.transformControl.removeEventListener("change", this.onGizmoChanged);
    }

    private onGizmoChanged(event: any) {
        if (!event.target.isDragging())
            return;

        const { xOffset, yOffset } = this.convertMeshPositionToHookPosition();

        this.hook.setPosition(xOffset, yOffset);

        this.apply(this.hook.matrix);

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.UpdateHookGeometry",
            payload: this.hook
        });
    }

    private convertMeshPositionToHookPosition() {
        const origin = new THREE.Vector3().setFromMatrixPosition(this.hook.originMatrix);
        const basisX = new THREE.Vector3().setFromMatrixColumn(0, this.hook.originMatrix);
        const basisY = new THREE.Vector3().setFromMatrixColumn(1, this.hook.originMatrix);

        const meshPosition = this.getPosition();

        const worldOffset = new THREE.Vector3().subVectors(meshPosition, origin);

        const xOffset = this.snapAndFitOffset(basisX.dot(worldOffset), this.hook.maxX);
        const yOffset = this.snapAndFitOffset(basisY.dot(worldOffset), this.hook.maxY);

        return { xOffset, yOffset }
    }

    private snapAndFitOffset(value: number, maxValue: number) {
        return fitToIntervalBounds(this.snapGrid.snap(value), 0, maxValue);
    }
}