import { CornerGeometry } from "@dextall/corner-geometry";
import { Options } from "@dextall/corner-geometry/build/types/src/options";
import { ExtrusionGeometry } from "./claddingCellExtrusionGeometryFactory";

export class CornerCladdingCellGeometry {
    private readonly wingGeometries: THREE.BufferGeometry[];
    constructor(
        private readonly angle: number,
        private readonly leftWing: number,
        private readonly rightWing: number,
        private readonly height: number,
        thickness: number,
        private readonly leftWingDirection: THREE.Vector3,
    ) {
        const options: Options = {
            angle,
            leftWing,
            rightWing,
            height,
            offsetDirection: "out",
            separateWingGeometries: true,
            thickness,
        };

        const cornerGeometry = new CornerGeometry(options);

        this.wingGeometries = cornerGeometry.createGeometry();

        if (this.wingGeometries.length === 2) {
            createCustomTextureOffsetAttribute(this.wingGeometries[0], cornerGeometry, true);
            createCustomTextureOffsetAttribute(this.wingGeometries[1], cornerGeometry, false);
        }
    }

    createGeometry(
        origin: THREE.Vector3,
        leftWingMaterial: THREE.Material,
        rightWingMaterial: THREE.Material,
    ): ExtrusionGeometry[] {
        if (this.wingGeometries.length !== 2) {
            return [];
        }

        const matrix = this.getCornerCoordinateSystem(origin);

        const leftWingGeometry: ExtrusionGeometry = {
            type: "named-shape",
            name: "corner-left-wing",
            geometry: this.wingGeometries[0],
            material: leftWingMaterial,
            matrix,
            size: new THREE.Vector3(this.leftWing, this.height, this.angle),
        };

        const rightWingGeometry: ExtrusionGeometry = {
            type: "named-shape",
            name: "corner-right-wing",
            geometry: this.wingGeometries[1],
            material: rightWingMaterial,
            matrix,
            size: new THREE.Vector3(this.rightWing, this.height, this.angle),
        };

        return [leftWingGeometry, rightWingGeometry];
    }

    getCornerCoordinateSystem(origin: THREE.Vector3) {
        basisX.copy(this.leftWingDirection).negate();
        basisY.crossVectors(basisX, down);

        return new THREE.Matrix4().makeBasis(basisX, basisY, basisZ)
            .setPosition(origin);
    }
}

const basisX = new THREE.Vector3();
const basisY = new THREE.Vector3();
const basisZ = new THREE.Vector3(0, 0, 1);
const down = new THREE.Vector3(0, 0, -1);

const createCustomTextureOffsetAttribute = (
    geometry: THREE.BufferGeometry,
    cornerGeometry: CornerGeometry,
    forLeftWing: boolean,
) => {
    const normals = geometry.attributes.normal;
    const positions = geometry.attributes.position;
    const count = normals.count;

    const wingCenter = new Float32Array(count * 2);
    const rightWingDirectionData = new Float32Array(count * 2);
    const pointTypeData = new Float32Array(count);
    const shelfLengthData = new Float32Array(count);

    const outerCornerPoint = cornerGeometry.getOuterCornerPoint();
    const rightWingDirection = cornerGeometry.getRightWingDirection();

    for (let i = 0; i < count; ++i) {
        wingCenter[2 * i] = outerCornerPoint.x;
        wingCenter[2 * i + 1] = outerCornerPoint.y;
        rightWingDirectionData[2 * i] = rightWingDirection.x;
        rightWingDirectionData[2 * i + 1] = rightWingDirection.y;

        const normal = new THREE.Vector3(normals.array![3 * i], normals.array![3 * i + 1], normals.array![3 * i + 2]);
        const position = new THREE.Vector3(
            positions.array![3 * i],
            positions.array![3 * i + 1],
            positions.array![3 * i + 2],
        );

        const pointType = forLeftWing
            ? cornerGeometry.getLeftWingSurfacePointType(position, normal)
            : cornerGeometry.getRightWingSurfacePointType(position, normal);

        switch (pointType) {
            case "front":
                pointTypeData[i] = forLeftWing ? 1 : 2;
                break;
            case "top":
                pointTypeData[i] = forLeftWing ? 3 : 4;
                break;
            case "bottom":
                pointTypeData[i] = forLeftWing ? 5 : 6;
                break;
            case "side":
                pointTypeData[i] = forLeftWing ? 7 : 8;
                break;
            default:
                pointTypeData[i] = 0;
                break;
        }
    }

    geometry.setAttribute("cornerWingCenter", new THREE.Float32BufferAttribute(wingCenter, 2, false));
    geometry.setAttribute(
        "cornerRightWingDirection",
        new THREE.Float32BufferAttribute(rightWingDirectionData, 2, false),
    );
    geometry.setAttribute("cornerWingSurfaceType", new THREE.Float32BufferAttribute(pointTypeData, 1, false));
    geometry.setAttribute("shapeShelfLength", new THREE.Float32BufferAttribute(shelfLengthData, 1, false));
};
