import { IPanelGeneratedDrawingDto } from "../../../../responses/panelGeneratedDrawingDto";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../../responses/panelGeneratedModelDto";
import { ProcessingIndicator } from "../../../processing-pane/processingIndicator";
import { GeneratedDrawingsSelector } from "./generatedDrawingsSelector";
import { GeneratedModelFrame } from "./generatedModelFrame";
import { GeneratedPanelFailed } from "./generatedPanelFailed";
import { GenerateNewPanelContent } from "./generateNewPanelContent";

type Props = {
    isLoading: boolean;
    model?: Omit<IPanelGeneratedModelDto, "typeId">;
    drawings: IPanelGeneratedDrawingDto[];
    selectedDrawingIndex: number;
    isPanelModel: boolean;
    panelTypeId: string;
    setSelectedDrawingIndex: (index: number) => void;
};

export const GeneratedPanelDrawings = ({ isLoading, model, drawings, selectedDrawingIndex, isPanelModel, panelTypeId, setSelectedDrawingIndex }: Props) => {
    if (isLoading) {
        return <div className="editor-processing-indicator-container"><ProcessingIndicator /></div>;
    }

    switch (model?.status) {
        case PanelTypeGenerationStatus.None:
            return <GenerateNewPanelContent isPanelModel={isPanelModel} panelTypeId={panelTypeId} />;

        case PanelTypeGenerationStatus.ModelGenerationInProgress:
            return <div className="editor-processing-indicator-container"><ProcessingIndicator message="Generating panel model" /></div>;

        case PanelTypeGenerationStatus.ModelGeneratedSuccessfully:
            return <>
                <GeneratedDrawingsSelector drawings={drawings} selectedDrawingIndex={selectedDrawingIndex} setSelectedDrawingIndex={setSelectedDrawingIndex} />
                <GeneratedModelFrame
                    id={drawings[selectedDrawingIndex].id}
                    contentType={isPanelModel ? "panel-drawing" : "corner-drawing"}
                    modelId={model.id}
                    panelTypeId={panelTypeId} />
            </>;

        default:
            return <GeneratedPanelFailed isPanelModel={isPanelModel} panelTypeId={panelTypeId} />;
    }
};