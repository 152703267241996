import { FindObjectByIdPanelContent } from "./content/findObjectByIdPanelContent";
import { DockingPanel, IDockingPanelOptions } from "./dockingPanel";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export class FindObjectByIdPanel {
    private readonly panel: DockingPanel;
    private visible: boolean;

    constructor(container: HTMLElement) {
        this.visible = false;

        const component = <FindObjectByIdPanelContent />;

        const containerSize = container.getBoundingClientRect();

        const width = 370;
        const height = 170;

        const options: IDockingPanelOptions = {
            style: {},
            dimensionsControl: {
                preventOverlapsWithViewerToolbar: true,
                leftOffset: 0.5 * (containerSize.width - width),
                topOffset: 0.5 * (containerSize.height - height),
                widthDimensionControl: {
                    value: width,
                    minValue: width,
                },
                heightDimensionControl: {
                    value: height,
                    minValue: height,
                },
            },
        };

        this.panel = new DockingPanel(container, "dextall-find-component-by-id-docking panel",
            "Select component by id", component, options);

        this.panelVisibilityChanged = this.panelVisibilityChanged.bind(this);
        this.onExternalPanelVisibilityChanged = this.onExternalPanelVisibilityChanged.bind(this);
        this.panel.addVisibilityListener(this.panelVisibilityChanged);

        eventBus.addEventListener("Dextall.SearchByIdTool.VisibilityChanged", this.onExternalPanelVisibilityChanged);
    }

    get isVisible(): boolean {
        return this.visible;
    }

    setVisible(visibility: boolean) {
        if (this.visible === visibility) {
            return;
        }

        this.visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }

    private onExternalPanelVisibilityChanged(event: IApplicationEvent<boolean>) {
        this.setVisible(event.payload);
    }

    private panelVisibilityChanged(visible: boolean) {
        this.visible = visible;

        eventBus.dispatchEvent({
            type: "Dextall.SearchByIdTool.VisibilityChanged",
            payload: visible,
        });
    }
}