import { IWallFace } from "../../../responses/wallFace";
import { HooksModelEditor } from "../editors/hooksModelEditor";
import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { HooksAlignmentTool } from "../viewer-tools/hooksAlignmentTool";
import { ViewerToolChangedEventType } from "../viewer-utils/viewerToolChangedEventType";

export type ForgeHooksAlignmentLoadOptions = {
    hooksEditor: HooksModelEditor;
    modelFaces: IWallFace[];
};

export class ForgeHooksAlignment extends Autodesk.Viewing.Extension {
    private readonly alignmentTool: HooksAlignmentTool;
    private toggleAlignmentButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeHooksAlignmentLoadOptions) {
        super(viewer, options);

        this.alignmentTool = new HooksAlignmentTool(
            options.hooksEditor,
            options.modelFaces,
            viewer.model.getGlobalOffset(),
        );

        this.onToggleHooksAlignment = this.onToggleHooksAlignment.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.viewer.toolController.registerTool(this.alignmentTool);

        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        return true;
    }

    unload() {
        this.viewer.toolController.deregisterTool(this.alignmentTool);

        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleAlignmentButton = new Autodesk.Viewing.UI.Button("dextall-toggle-hooks-alignment-tool");
        this.toggleAlignmentButton.setToolTip("Hooks alignment");
        this.toggleAlignmentButton.setIcon("viewer-editor-alignment");
        this.toggleAlignmentButton.addEventListener("click", this.onToggleHooksAlignment);

        group.addControl(this.toggleAlignmentButton);
    }

    private onToggleHooksAlignment() {
        const toolController = this.viewer.toolController;
        const toolName = this.alignmentTool.getName();

        const newState = !toolController.isToolActivated(toolName);

        if (newState)
            toolController.activateTool(toolName);
        else
            toolController.deactivateTool(toolName);
    }

    private onViewToolChanged(event: ViewerToolChangedEventType) {
        if (event.toolName !== this.alignmentTool.getName())
            return;

        const newButtonState = event.active
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleAlignmentButton?.setState(newButtonState);
    }

    private onEscape() {
        if (!this.viewer.toolController.isToolActivated(this.alignmentTool.getName()))
            return;

        this.onToggleHooksAlignment();
    }
}

export const forgeHooksAlignmentName = "Dextall.ForgeHooksAlignment" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeHooksAlignmentName, ForgeHooksAlignment);