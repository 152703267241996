import Button from "@weave-design/button";
import IconButton from "@weave-design/icon-button";
import { CloseMUI, Error24 } from "@weave-design/icons";
import Modal from "@weave-design/modal";
import Typography from "@weave-design/typography";
import "./modalFail.css";

interface IComponentProps {
    isVisible: boolean;
    message: string | null;
    title: string | null;

    closeProcessingErrorDialog: () => void;
}

export const ProcessingFailure = (props: IComponentProps) => {
    if (!props.isVisible) {
        return (null);
    }

    const modalStyles = /* istanbul ignore next */ (styles: any) => {
        return {
            ...styles,
            modal: {
                ...styles.modal,
                window: {
                    ...styles.modal?.window,
                    width: "371px",
                    height: "263px",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "thick",
                    borderLeftColor: "#ec4a41", // by design
                }
            },
        };
    };

    return (<Modal
        open={true}
        title="Error"
        onCloseClick={() => props.closeProcessingErrorDialog()}
        stylesheet={modalStyles}
        headerChildren={
            <header id="customHeader">
                <div className="customHeaderContent">
                    <div className="title">
                        <Error24 className="errorIcon" />
                        <Typography style={{
                            paddingLeft: "8px",
                            fontSize: "inherit",
                            fontWeight: "inherit",
                            lineHeight: "inherit",
                        }}>Error</Typography>
                    </div>
                    <IconButton style={{ width: "24px", height: "24px", marginLeft: "auto", marginTop: "7px" }}
                        icon={<CloseMUI />}
                        onClick={() => props.closeProcessingErrorDialog()}
                        title=""
                    />
                </div>
            </header>
        }>
        <div className="modalFailContent">
            <div>
                <Typography><span className="errorMessageTitle">{props.title}</span></Typography>
                <div className="errorMessage">
                    {props.message}
                </div>
            </div>
        </div>
        <div className="modalFailButtonsContainer">
            <Button className="button" style={
                { width: "102px", height: "36px", borderRadius: "2px", marginLeft: "12px" }}
                type="primary"
                size="small"
                title="Ok"
                onClick={() => props.closeProcessingErrorDialog()}
            />
        </div>
    </Modal>);
};