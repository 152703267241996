import { WallsModelEditor } from "../editors/wallsModelEditor";
import { ViewerKeyboardEventsTransmitterTool } from "../viewer-tools/viewerKeyboardEventsTransmitterTool";

export type ForgeWallsExtensionLoadOptions = {
    wallsEditor: WallsModelEditor;
};

export class ForgeWallsExtension extends Autodesk.Viewing.Extension {
    private readonly wallsEditor: WallsModelEditor;
    private readonly keyboardEventsTransmitterTool = new ViewerKeyboardEventsTransmitterTool();

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeWallsExtensionLoadOptions) {
        super(viewer, options);
        this.wallsEditor = options.wallsEditor;
    }

    async load() {
        await this.wallsEditor.init();

        this.viewer.toolController.registerTool(this.keyboardEventsTransmitterTool);
        this.viewer.toolController.activateTool(this.keyboardEventsTransmitterTool.getName());

        return true;
    }

    unload() {
        this.viewer.toolController.deregisterTool(this.keyboardEventsTransmitterTool);

        return true;
    }
}

export const forgeWallsExtensionName = "Dextall.ForgeWallsExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeWallsExtensionName, ForgeWallsExtension);