import React, { useState, useCallback } from "react";
import { ChevronDown } from "react-feather";
import { Category } from "../../../../../responses/modelsGenerationsListGroups";
import { styles } from "../../../../../assets/generationsListStyles";

interface CategorySectionProps {
    category: Category;
    children: React.ReactNode;
}

const CategorySection: React.FC<CategorySectionProps> = ({ category, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleCategory = useCallback(() => setIsExpanded(prevState => !prevState), []);

    return (
        <div>
            <h4 onClick={toggleCategory} style={styles.categoryTitle}>
                {category.name}
                <ChevronDown
                    size={16}
                    style={{
                        transform: isExpanded ? 'rotate(180deg)' : '',
                        transition: 'transform 0.3s ease',
                        marginLeft: 8
                    }}
                />
            </h4>
            {isExpanded && children}
        </div>
    );
};

export default CategorySection;
