import { useEffect, useRef, useState } from "react";
import { isAlmostEqualToZero } from "@dextall/shared";
import Label from "@weave-design/label";
import { CustomPanelTypeStatus, ICustomPanelType } from "../../responses/customPanelTypes";
import { CustomComponentViewerType } from "../forge/custom-component-library/navigation/customComponentViewerType";
import { ImperialAndMetricDimensionEditor } from "../forge/editor-inputs/imperialAndMetricDimensionEditor";
import { TextPropertyEditor } from "../forge/editor-inputs/textPropertyEditor";
import { CustomComponentPublishContol } from "./customComponentPublishContol";
import { CustomComponentVisibilityEditor } from "./customComponentVisibilityEditor";
import eventBus, { IApplicationEvent } from "../forge/eventBus/eventDispatcher";
import repo from "../../Repository";
import "./customComponentPropertiesEditor.css";

type Props = {
    component: ICustomPanelType;
    setPublished: (component: ICustomPanelType) => void;
};

export const CustomPanelComponentPropertiesEditor = ({ component, setPublished }: Props) => {
    const [currentComponentState, setCurrentComponentState] = useState<ICustomPanelType>({ ...component });
    const [name, setName] = useState<string>(component.name);
    const [disabled, setDisabled] = useState(true);
    const readyModels = useRef<CustomComponentViewerType[]>([]);

    useEffect(() => {
        setCurrentComponentState(component);
        setName(component.name);
        setDisabled(true);
        readyModels.current = [];

        const onModelReady = (event: IApplicationEvent<CustomComponentViewerType>) => {
            readyModels.current.push(event.payload);

            if (readyModels.current.length === 4) {
                setDisabled(false);
            }
        };

        const onComponentChangedInEditor = (event: IApplicationEvent<ICustomPanelType>) => setCurrentComponentState({ ...event.payload });

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.Ready", onModelReady);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.Panel.Editor.Changed", onComponentChangedInEditor);

        return () => {
            eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.Ready", onModelReady);
            eventBus.removeEventListener("Dextall.CustomComponentLibrary.Panel.Editor.Changed", onComponentChangedInEditor);
        };
    }, [component]);

    const onChange = (newState: ICustomPanelType) => {
        setCurrentComponentState(newState);
        eventBus.dispatchEvent({
            type: "Dextall.CustomComponentLibrary.Panel.UI.Changed",
            payload: newState,
        });
    };

    const onChangeOffset = (x: number, y: number, z: number) => onChange({ ...currentComponentState, offset: new THREE.Vector3(x, y, z) });

    const publishingDisabled = disabled || isAlmostEqualToZero(currentComponentState.length) || isAlmostEqualToZero(currentComponentState.height);

    const publish = async () => {
        const result = await repo.publishCustomPanelTypeComponent({ ...currentComponentState, name: name });

        if (result.isSuccess) {
            setPublished({ ...currentComponentState, name: name, status: CustomPanelTypeStatus.Published });
        }

        return result;
    };

    return <>
        <div className="custom-component-properties-content-container">
            <div className="parameters-pane">
                <TextPropertyEditor title="Name:" value={name} onChange={setName} />
                <ImperialAndMetricDimensionEditor
                    title="Width:"
                    value={currentComponentState.length}
                    onChange={length => onChange({ ...currentComponentState, length })}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={currentComponentState.height}
                    onChange={height => onChange({ ...currentComponentState, height })}
                    disabled={disabled} />

                <div style={{ paddingTop: 5 }}><Label>Offset:</Label></div>
                <ImperialAndMetricDimensionEditor
                    title="x:"
                    value={currentComponentState.offset.x}
                    onChange={x => onChangeOffset(x, currentComponentState.offset.y, currentComponentState.offset.z)}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="y:"
                    value={currentComponentState.offset.y}
                    onChange={y => onChangeOffset(currentComponentState.offset.x, y, currentComponentState.offset.z)}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="z:"
                    value={currentComponentState.offset.z}
                    onChange={z => onChangeOffset(currentComponentState.offset.x, currentComponentState.offset.y, z)}
                    disabled={disabled} />
            </div>

            <CustomComponentVisibilityEditor componentId={component.id} disabled={disabled} />
        </div>

        <CustomComponentPublishContol disabled={publishingDisabled} publishComponent={publish} />
    </>;
};