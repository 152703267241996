import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "@weave-design/button";
import { Settings24 } from "@weave-design/icons";
import { ProcessingFailure } from "../../../processing-failure/processingFailure";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { rootElement } from "../../../shared";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

type Props = {
    panelTypeId: string;
    isPanelModel: boolean;
    isRegenerate?: boolean;
};

export const GenerateNewPanelContent = ({ panelTypeId, isPanelModel, isRegenerate }: Props) => {
    const [generationStarted, setGenerationStarted] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();

    useEffect(() => {
        setGenerationStarted(false);
        setFailureMessage(undefined);
    }, [panelTypeId, isPanelModel]);

    useEffect(() => {
        const onGenerationFailed = (event: IApplicationEvent<string>) => {
            setGenerationStarted(false);
            setFailureMessage(event.payload);
        };

        eventBus.addEventListener("Dextall.GeneratedModel.GenerationRequestFailed", onGenerationFailed);

        return () => {
            eventBus.removeEventListener("Dextall.GeneratedModel.GenerationRequestFailed", onGenerationFailed);
        };
    }, []);

    const onClick = () => {
        eventBus.dispatchEvent({
            type: "Dextall.GeneratedModel.GenerationRequested",
            payload: { panelTypeId, isPanelModel },
        });
        setGenerationStarted(true);
    };

    const title = isRegenerate ? "Regenerate" : "Generate";

    const buttonType = isRegenerate ? "secondary" : "primary";

    return (
        <div style={{ margin: 17 }}>
            <Button title={title} icon={<Settings24 />} onClick={onClick} type={buttonType} />
            {generationStarted &&
                ReactDOM.createPortal(
                    <ProcessingPane
                        isVisible={true}
                        message="Starting generation..."
                        title="Generating the panel..."
                    />,
                    rootElement,
                )}
            {failureMessage !== undefined &&
                ReactDOM.createPortal(
                    <ProcessingFailure
                        closeProcessingErrorDialog={() => setFailureMessage(undefined)}
                        isVisible={true}
                        title="Error"
                        message={failureMessage}
                    />,
                    rootElement,
                )}
        </div>
    );
};
