import { ZShapeGeometryType } from "@dextall/corner-geometry";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { CustomZShapedPanel } from "../panels/customZShapedPanel";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { ICustomZShapeType } from "../../../responses/customPanelTypes";
import { BasicItemResponse, BasicResponse } from "../../../responses/basicResponses";
import { UpdateCustomCornerOffsetCommand } from "../../../commands/updateCustomCornerOffsetCommand";
import { CustomZShapedPanelPlacementRequestPayload } from "../eventBus/customPanelsEventPayloads";
import repo from "../../../Repository";

export class CustomZShapedPanelsModelEditor {
    private readonly fragmentsByPanelDbId = new Map<number, number[]>();
    private readonly titlesFragments = new Map<number, number[]>();

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {
    }

    get model(): Autodesk.Viewing.Model {
        return this.modelBufferedGeometry.model;
    }

    createCustomZShapedPanels() {
        for (const customPanel of this.panelFacadeDocument.customZShapedPanels)
            this.addCustomPanel(customPanel, false);

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    findCustomPanel(dbId: number): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanel(dbId);
    }

    findCustomPanelById(id: string): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelById(id);
    }

    findCustomZShapedPanelByInternalId(id: string): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelByInternalId(id);
    }

    findCustomPanelByUserId(userUniqueId: number): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelByUserId(userUniqueId);
    }

    findCustomPanelByTypeName(typeName: string): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelByTypeName(typeName);
    }

    findCustomPanelByElementName(elementName: string): CustomZShapedPanel | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelByElementName(elementName);
    }

    findCustomTypesForPanel(panelId: string): ICustomZShapeType[] {
        return this.panelFacadeDocument.findValidCustomZShapePanelType(panelId);
    }

    findCustomPanelType(customPanelTypeId: string): ICustomZShapeType | undefined {
        return this.panelFacadeDocument.findCustomZShapedPanelType(customPanelTypeId);
    }

    async switchPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        const panel = this.panelFacadeDocument.findCustomZShapedPanelById(panelId);

        if (!panel)
            return { isSuccess: false, message: `Failed to find custom z-panel with id=${panelId}` };

        const result = await repo.changeCustomZShapedPanelType(panelId, targetTypeId);

        if (result.isSuccess) {
            panel.setPanelType(targetTypeId);

            this.addCustomPanelTextGeometry(panel);
        }

        return result;
    }

    async setElementName(panelId: string, elementName: string): Promise<BasicResponse> {
        const panel = this.panelFacadeDocument.findCustomZShapedPanelById(panelId);

        if (!panel)
            return { isSuccess: false, message: `Failed to find custom z-panel with id=${panelId}` };

        const result = await repo.changeCustomZShapedPanelElementName(panelId, { elementName });

        if (result.isSuccess)
            panel.setElementName(elementName);

        return result;
    }

    async setOffset(panelId: string, offset: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        const panel =
            this.panelFacadeDocument.findCustomZShapedPanelById(panelId) ||
            this.panelFacadeDocument.findCustomZShapedPanelByInternalId(panelId);

        if (!panel)
            return { isSuccess: false, message: `Failed to find custom z-panel with id=${panelId}` };

        const result = await repo.updateCustomZShapedPanelOffset(panel.id, offset);

        if (result.isSuccess)
            this.setOffsetLocal(panel, offset.offset);

        return result;
    }

    async createPanel(
        command: CustomZShapedPanelPlacementRequestPayload
    ): Promise<BasicItemResponse<CustomZShapedPanel>> {
        const response = await repo.createCustomZShapedPanel({ ...command, modelId: this.panelFacadeDocument.modelId });

        if (!response.isSuccess)
            return response;

        const panel = this.panelFacadeDocument.addCustomZShapedPanel(response.item, command.internalId);

        this.addCustomPanel(panel);

        return { isSuccess: true, item: panel, message: null };
    }

    async removePanel(panelId: CustomZShapedPanel | string): Promise<BasicResponse> {
        const panel = typeof panelId === "string"
            ? this.findCustomPanelById(panelId)
            : panelId;

        if (!panel)
            return { isSuccess: false, message: `Failed to find custom z-panel with id=${panelId}` };

        this.removeCustomPanelGeometry(panel);

        const result = await repo.removeCustomZShapedPanel(panel.id);

        if (result.isSuccess)
            this.panelFacadeDocument.removeCustomZShapedPanel(panel);
        else
            this.addCustomPanel(panel);

        return result;
    }

    setOffsetLocal(panelId: string | CustomZShapedPanel, offset: number) {
        const panel = typeof panelId === "string"
            ? this.panelFacadeDocument.findCustomZShapedPanelById(panelId)
            : panelId;

        if (!panel)
            return;

        panel.setOffset(offset);

        this.updatePanelGeometry(panel);
    }

    private addCustomPanel(panel: CustomZShapedPanel, makeFix = true) {
        this.addCustomPanelGeometry(panel);
        this.addCustomPanelTextGeometry(panel);

        if (makeFix)
            this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    private addCustomPanelGeometry(panel: CustomZShapedPanel) {
        const geometries = panel.createGeometry();

        const geometryFragments: number[] = [];

        for (const geometryChunk of geometries) {
            const fragmentId = this.modelBufferedGeometry.add(geometryChunk);

            this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, panel.panelDbId);

            geometryFragments.push(fragmentId);
        }

        this.fragmentsByPanelDbId.set(panel.panelDbId, geometryFragments);
    }

    private removeCustomPanelGeometry(panel: CustomZShapedPanel) {
        const geometryFragments = this.fragmentsByPanelDbId.get(panel.panelDbId) || [];

        this.modelBufferedGeometry.removeFragments(geometryFragments);

        const titlesFragments = this.titlesFragments.get(panel.panelDbId) || [];

        this.modelBufferedGeometry.removeFragments(titlesFragments);

        this.fragmentsByPanelDbId.delete(panel.panelDbId);
        this.titlesFragments.delete(panel.panelDbId);
    }

    private updatePanelGeometry(panel: CustomZShapedPanel) {
        const geometryFragments = this.fragmentsByPanelDbId.get(panel.panelDbId) || [];

        const targetTransform = panel.getCoordinateSystem();

        for (const fragmentId of geometryFragments)
            this.modelBufferedGeometry.changeFragmentTransform(fragmentId, targetTransform);

        const textFrafments = this.titlesFragments.get(panel.panelDbId) || [];

        if (textFrafments.length !== 3)
            return;

        const [leftWingTextFragmentId, shelfTextFragmentId, rightWingTextFragmentId] = textFrafments;

        const typeText = this.getTypeText(panel)!;

        const textSize = this.textGeometryFactory.computeSize(typeText);

        const leftWingMatrix = panel.computeTitleMatrix(textSize, "left-wing");
        const shelfWingMatrix = panel.computeTitleMatrix(textSize, "shelf");
        const rightWingMatrix = panel.computeTitleMatrix(textSize, "right-wing");

        this.modelBufferedGeometry.changeFragmentTransform(leftWingTextFragmentId, leftWingMatrix);
        this.modelBufferedGeometry.changeFragmentTransform(shelfTextFragmentId, shelfWingMatrix);
        this.modelBufferedGeometry.changeFragmentTransform(rightWingTextFragmentId, rightWingMatrix);
    }

    private addCustomPanelTextGeometry(panel: CustomZShapedPanel) {
        const existingTextFragments = this.titlesFragments.get(panel.panelDbId);

        if (existingTextFragments) {
            this.modelBufferedGeometry.removeFragments(existingTextFragments);

            this.titlesFragments.delete(panel.panelDbId);
        }

        const typeText = this.getTypeText(panel);

        if (!typeText)
            return;

        const leftWingTextFragmentId = this.addWingTypeText(panel, "left-wing");
        const shelfTextFragmentId = this.addWingTypeText(panel, "shelf");
        const rightWingTextFragmentId = this.addWingTypeText(panel, "right-wing");

        this.titlesFragments.set(panel.panelDbId, [leftWingTextFragmentId, shelfTextFragmentId, rightWingTextFragmentId]);
    }

    private addWingTypeText(panel: CustomZShapedPanel, geometryType: ZShapeGeometryType): number {
        const typeText = this.getTypeText(panel)!;

        const textSize = this.textGeometryFactory.computeSize(typeText);

        const wingMatrix = panel.computeTitleMatrix(textSize, geometryType);

        const geometry = this.textGeometryFactory.createTextGeometry(typeText, wingMatrix);

        const fragmentId = this.modelBufferedGeometry.add(geometry);

        this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, panel.panelDbId);

        return fragmentId;
    }

    private getTypeText(panel: CustomZShapedPanel): string | undefined {
        const customPanelType = this.panelFacadeDocument.findCustomZShapedPanelType(panel.customPanelTypeId);

        return customPanelType?.name;
    }
}