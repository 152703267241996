import { useEffect, useRef, useState } from "react";
import { isAlmostEqualToZero } from "@dextall/shared";
import Label from "@weave-design/label";
import { BasicResponse } from "../../responses/basicResponses";
import { CustomPanelTypeStatus, ICustomZShapeType } from "../../responses/customPanelTypes";
import { CustomComponentViewerType } from "../forge/custom-component-library/navigation/customComponentViewerType";
import { ImperialAndMetricDimensionEditor } from "../forge/editor-inputs/imperialAndMetricDimensionEditor";
import { NumericPropertyEditor } from "../forge/editor-inputs/numericPropertyEditor";
import { TextPropertyEditor } from "../forge/editor-inputs/textPropertyEditor";
import { CustomComponentPublishContol } from "./customComponentPublishContol";
import { CustomComponentVisibilityEditor } from "./customComponentVisibilityEditor";
import eventBus, { IApplicationEvent } from "../forge/eventBus/eventDispatcher";
import repo from "../../Repository";
import "./customComponentPropertiesEditor.css";

type Props = {
    component: ICustomZShapeType;
    setPublished: (component: ICustomZShapeType) => void;
};

export const CustomZShapedComponentPropertiesEditor = ({ component, setPublished }: Props) => {
    const [currentComponentState, setCurrentComponentState] = useState<ICustomZShapeType>({ ...component });
    const [name, setName] = useState<string>(component.name);
    const [disabled, setDisabled] = useState(true);
    const readyModels = useRef<CustomComponentViewerType[]>([]);

    useEffect(() => {
        setCurrentComponentState(component);
        setName(component.name);
        setDisabled(true);
        readyModels.current = [];

        const onModelReady = (event: IApplicationEvent<CustomComponentViewerType>) => {
            readyModels.current.push(event.payload);

            if (readyModels.current.length === 4) {
                setDisabled(false);
            }
        };

        const onComponentChangedInEditor = (event: IApplicationEvent<ICustomZShapeType>) => setCurrentComponentState({ ...event.payload });

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.Ready", onModelReady);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.ZShape.Editor.Changed", onComponentChangedInEditor);

        return () => {
            eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.Ready", onModelReady);
            eventBus.removeEventListener("Dextall.CustomComponentLibrary.ZShape.Editor.Changed", onComponentChangedInEditor);
        };
    }, [component]);

    const onChange = (newState: ICustomZShapeType) => {
        setCurrentComponentState(newState);
        eventBus.dispatchEvent({
            type: "Dextall.CustomComponentLibrary.ZShape.UI.Changed",
            payload: newState,
        });
    };

    const onChangeOffset = (x: number, y: number, z: number) => onChange({ ...currentComponentState, offset: new THREE.Vector3(x, y, z) });

    const publishingDisabled = disabled
        || isAlmostEqualToZero(currentComponentState.leftWingLength)
        || isAlmostEqualToZero(currentComponentState.shelfLength)
        || isAlmostEqualToZero(currentComponentState.rightWingLength)
        || isAlmostEqualToZero(currentComponentState.height);

    const publish = async (): Promise<BasicResponse> => {
        const result = await repo.publishCustomZShapePanelType({ ...currentComponentState, name: name });

        if (result.isSuccess) {
            setPublished({ ...currentComponentState, name: name, status: CustomPanelTypeStatus.Published });
        }

        return result;
    };

    return <>
        <div className="custom-component-properties-content-container">
            <div className="parameters-pane">
                <TextPropertyEditor title="Name:" value={name} onChange={setName} />
                <NumericPropertyEditor
                    title="Angle 1:"
                    value={currentComponentState.angle1}
                    onChange={angle1 => onChange({ ...currentComponentState, angle1 })}
                    disabled={disabled} />
                <NumericPropertyEditor
                    title="Angle 2:"
                    value={currentComponentState.angle2}
                    onChange={angle2 => onChange({ ...currentComponentState, angle2 })}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="Left wing:"
                    value={currentComponentState.leftWingLength}
                    onChange={leftWingLength => onChange({ ...currentComponentState, leftWingLength })}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="Shelf:"
                    value={currentComponentState.shelfLength}
                    onChange={shelfLength => onChange({ ...currentComponentState, shelfLength })}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="Right wing:"
                    value={currentComponentState.rightWingLength}
                    onChange={rightWingLength => onChange({ ...currentComponentState, rightWingLength })}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="Height:"
                    value={currentComponentState.height}
                    onChange={height => onChange({ ...currentComponentState, height })}
                    disabled={disabled} />

                <div style={{ paddingTop: 5 }}><Label>Offset:</Label></div>
                <ImperialAndMetricDimensionEditor
                    title="x:"
                    value={currentComponentState.offset.x}
                    onChange={x => onChangeOffset(x, currentComponentState.offset.y, currentComponentState.offset.z)}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="y:"
                    value={currentComponentState.offset.y}
                    onChange={y => onChangeOffset(currentComponentState.offset.x, y, currentComponentState.offset.z)}
                    disabled={disabled} />
                <ImperialAndMetricDimensionEditor
                    title="z:"
                    value={currentComponentState.offset.z}
                    onChange={z => onChangeOffset(currentComponentState.offset.x, currentComponentState.offset.y, z)}
                    disabled={disabled} />
            </div>

            <CustomComponentVisibilityEditor componentId={component.id} disabled={disabled} />
        </div>

        <CustomComponentPublishContol disabled={publishingDisabled} publishComponent={publish} />
    </>;
};