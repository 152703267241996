import { zFightingFixDistance } from "@dextall/panels-defaults";
import { isAlmostEqual } from "@dextall/shared";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IModelCorner, IModelCornerWing } from "../../../responses/modelCorner";
import { IPanelSourceCladdingCell } from "../../../responses/panelSource";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry }
    from "../geometry/claddingCellExtrusionGeometryFactory";
import { getLeftHandedTransformForViewer } from "../geometry/matricesUtils";
import { WallCornerCladdingCell } from "./wallCornerCladdingCell";
import { WallPanelCladdingCell } from "./wallPanelCladdingCell";

export interface ICornerCladdings {
    cornerCladdingCells: WallCornerCladdingCell[];
    leftWingCladdingCells: WallPanelCladdingCell[];
    rightWingCladdingCells: WallPanelCladdingCell[];
}

interface ICornerCellsPair {
    left: IPanelSourceCladdingCell;
    right: IPanelSourceCladdingCell
}

export class CornerCladdingsFactory {
    private readonly boundaryLoopsFactory = new BoundaryLoopsFactory();
    private readonly claddingExtrusionFactory = new CladdingCellExtrusionGeometryFactory();

    createFromSource(cornerSource: ICornerPanelSource, modelCorner: IModelCorner,
        globalOffset: THREE.Vector3): ICornerCladdings {
        const cornerCladdingCells: WallCornerCladdingCell[] = [];
        const leftWingCladdingCells: WallPanelCladdingCell[] = [];
        const rightWingCladdingCells: WallPanelCladdingCell[] = [];

        const cornerOrigin = this.getCornerOrigin(cornerSource, modelCorner, globalOffset);

        const leftWingMatrix = this.createWingMatrix(cornerOrigin, modelCorner.left);
        const rightWingMatrix = this.createWingMatrix(cornerOrigin, modelCorner.right);

        const cornerCellPairs = this.findCornerCellsSource(cornerSource);

        const cornerCellsSource: Set<IPanelSourceCladdingCell> = new Set();

        const leftWingBoxOffset = this.getSourceToModelOffset(cornerSource, cornerSource.leftWing);
        const rightWingBoxOffset = this.getSourceToModelOffset(cornerSource, cornerSource.rightWing);

        for (const cornerCellPair of cornerCellPairs) {
            const leftBox = this.transformSourceBox(cornerCellPair.left.box, leftWingBoxOffset);
            const rightBox = this.transformSourceBox(cornerCellPair.right.box, rightWingBoxOffset);

            const leftCellLoop = this.boundaryLoopsFactory.createLoopFromBox(leftBox, leftWingMatrix);
            const rightCellLoop = this.boundaryLoopsFactory.createLoopFromBox(rightBox, rightWingMatrix);

            const cornerCladdingCell = new WallCornerCladdingCell(leftCellLoop, rightCellLoop,
                cornerCellPair.left, cornerCellPair.right, modelCorner.angle);

            cornerCladdingCells.push(cornerCladdingCell);

            cornerCellsSource.add(cornerCellPair.left);
            cornerCellsSource.add(cornerCellPair.right);
        }

        for (const leftPanelSource of cornerSource.leftWingCladdings) {
            if (cornerCellsSource.has(leftPanelSource)) {
                continue;
            }

            const claddingCell = this.createStraightPanelCladdingCell(
                leftPanelSource, leftWingMatrix, leftWingBoxOffset);

            if (cornerSource.customPanelTypeId) {
                claddingCell.isCustomMaterial = true;
            }

            leftWingCladdingCells.push(claddingCell);
        }

        for (const rightPanelSource of cornerSource.rightWingCladdings) {
            if (cornerCellsSource.has(rightPanelSource)) {
                continue;
            }

            const claddingCell = this.createStraightPanelCladdingCell(
                rightPanelSource, rightWingMatrix, rightWingBoxOffset);

            if (cornerSource.customPanelTypeId) {
                claddingCell.isCustomMaterial = true;
            }

            rightWingCladdingCells.push(claddingCell);
        }

        return { cornerCladdingCells, leftWingCladdingCells, rightWingCladdingCells };
    }

    createWingsBoundaryLoops(cornerSource: ICornerPanelSource, modelCorner: IModelCorner, globalOffset: THREE.Vector3) {
        const cornerOrigin = this.getCornerOrigin(cornerSource, modelCorner, globalOffset);

        const leftWingMatrix = this.createWingMatrix(cornerOrigin, modelCorner.left);
        const rightWingMatrix = this.createWingMatrix(cornerOrigin, modelCorner.right);

        const leftWingBox = new THREE.Box3(
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(cornerSource.leftWing, cornerSource.height, 0),
        );

        const rightWingBox = new THREE.Box3(
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(cornerSource.rightWing, cornerSource.height, 0),
        );

        const leftWingLoop = this.boundaryLoopsFactory.createLoopFromBox(leftWingBox, leftWingMatrix);
        const rightWingLoop = this.boundaryLoopsFactory.createLoopFromBox(rightWingBox, rightWingMatrix);

        return { leftWingLoop, rightWingLoop };
    }

    createWingPlanesGeometry(cornerSource: ICornerPanelSource, modelCorner: IModelCorner,
        globalOffset: THREE.Vector3, material: THREE.Material): ExtrusionGeometry[] {
        const { leftWingLoop, rightWingLoop } = this.createWingsBoundaryLoops(cornerSource, modelCorner, globalOffset);

        return [
            this.createPlaneGeometry(leftWingLoop, material),
            this.createPlaneGeometry(rightWingLoop, material),
        ];
    }

    private createPlaneGeometry(boundaryLoop: BoundaryLoop, material: THREE.Material): ExtrusionGeometry {
        const size = boundaryLoop.box.getSize();

        const geometry = new THREE.PlaneBufferGeometry(size.x, size.y);

        const panelBoxCenter = boundaryLoop.box.getCenter();

        const matrix = new THREE.Matrix4().multiplyMatrices(getLeftHandedTransformForViewer(boundaryLoop, 0),
            new THREE.Matrix4()
                .setPosition(new THREE.Vector3()
                    .addVectors(panelBoxCenter, new THREE.Vector3(0, 0, zFightingFixDistance))));

        return {
            type: "box",
            geometry,
            matrix,
            material,
            size,
        };
    }

    private findCornerCellsSource(cornerSource: ICornerPanelSource): ICornerCellsPair[] {
        const leftWingCornerCladdings = cornerSource
            .leftWingCladdings
            .filter(x => isAlmostEqual(x.box.min.x, -0.5 * cornerSource.leftWing));

        const rightWignCornerCladdings = cornerSource
            .rightWingCladdings
            .filter(x => isAlmostEqual(x.box.min.x, -0.5 * cornerSource.rightWing));

        const cornerCellsPairs: ICornerCellsPair[] = [];

        for (const leftWingCladdingCell of leftWingCornerCladdings) {
            const cellBox = leftWingCladdingCell.box;
            const rightWingCladdingCell = rightWignCornerCladdings
                .find(x => isAlmostEqual(x.box.min.y, cellBox.min.y) && isAlmostEqual(x.box.max.y, cellBox.max.y));

            if (rightWingCladdingCell) {
                cornerCellsPairs.push({ left: leftWingCladdingCell, right: rightWingCladdingCell });
            }
        }

        return cornerCellsPairs;
    }

    private createStraightPanelCladdingCell(panelSource: IPanelSourceCladdingCell,
        transform: THREE.Matrix4, offset: THREE.Vector2): WallPanelCladdingCell {
        const straightPanelSource: IPanelSourceCladdingCell = {
            ...panelSource,
            box: this.transformSourceBox(panelSource.box, offset),
        };

        return new WallPanelCladdingCell(straightPanelSource, transform,
            this.claddingExtrusionFactory, this.boundaryLoopsFactory);
    }

    private getSourceToModelOffset(cornerSource: ICornerPanelSource, wingLength: number): THREE.Vector2 {
        return new THREE.Vector2(0.5 * wingLength, cornerSource.offset + 0.5 * cornerSource.height);
    }

    private transformSourceBox(box: THREE.Box3, offset: THREE.Vector2): THREE.Box3 {
        return new THREE.Box3(
            new THREE.Vector3(box.min.x + offset.x, box.min.y + offset.y, 0),
            new THREE.Vector3(box.max.x + offset.x, box.max.y + offset.y, 0),
        );
    }

    private createWingMatrix(cornerOrigin: THREE.Vector3, wing: IModelCornerWing): THREE.Matrix4 {
        const basisX = new THREE.Vector3().copy(wing.direction);
        const basisY = new THREE.Vector3(0, 0, 1);
        const basisZ = new THREE.Vector3().copy(wing.wallOrientation);

        const origin = new THREE.Vector3()
            .copy(cornerOrigin)
            .add(new THREE.Vector3()
                .copy(basisZ)
                .multiplyScalar(zFightingFixDistance));

        const wingMatrix = new THREE.Matrix4().makeBasis(basisX, basisY, basisZ);
        wingMatrix.setPosition(origin);

        return wingMatrix;
    }

    private getCornerOrigin(cornerSource: ICornerPanelSource,
        modelCorner: IModelCorner, globalOffset: THREE.Vector3): THREE.Vector3 {
        return new THREE.Vector3()
            .copy(modelCorner.origin)
            .add(new THREE.Vector3()
                .copy(globalOffset)
                .negate())
            .add(new THREE.Vector3(0, 0, modelCorner.heights[cornerSource.heightIndex]));
    }
}