import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { AppLoadingSpinner } from "./components/appLoadingSpinner";
import { ContentNotReady } from "./components/contentNotReady";
import { PanelGeneratedContentViewer } from "./components/panelGeneratedContentViewer";
import { BasicItemResponse } from "./responses/basicResponses";
import { IPanelGeneratedDrawingDto } from "./responses/panelGeneratedDrawingDto";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "./responses/panelGeneratedModelDto";
import repo from "./Repository";

export type ApplicationType = | "panel"
    | "corner"
    | "panel-drawing"
    | "corner-drawing"
    | "custom-panel"
    | "custom-corner"
    | "custom-z-panel";

export const PanelModelApp = () => {
    const [sourceModel, setSourceModel] = useState<BasicItemResponse<Omit<IPanelGeneratedModelDto, "typeId"> | IPanelGeneratedDrawingDto>>();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const applicationType = searchParams.get("type") as ApplicationType | null;
    const { hash } = useLocation();

    useEffect(() => {
        if (!(id && applicationType !== null)) {
            return;
        }

        repo.setAccessToken(hash.substring(1));

        const initializeViewer = () => {
            return new Promise<void>(resolve => {
                const options: Autodesk.Viewing.InitializerOptions = { env: "Local" };

                Autodesk.Viewing.Initializer(options, () => resolve());
            });
        };

        const getContent = (): Promise<BasicItemResponse<Omit<IPanelGeneratedModelDto, "typeId"> | IPanelGeneratedDrawingDto>> => {
            switch (applicationType) {
                case "panel":
                    return repo.findPanelGeneratedModel(id);

                case "corner":
                    return repo.findCornerPanelGeneratedModel(id);

                case "panel-drawing":
                    return repo.findPanelGeneratedDrawing(id);

                case "corner-drawing":
                    return repo.findCornerPanelGeneratedDrawing(id);

                case "custom-panel":
                    return new Promise<BasicItemResponse<Omit<IPanelGeneratedModelDto, "typeId"> | IPanelGeneratedDrawingDto>>(async resolve => {
                        const response = await repo.findCustomPanelType(id);

                        if (!response.isSuccess) {
                            resolve(response);

                            return;
                        }

                        resolve({
                            isSuccess: true,
                            message: null,
                            item: {
                                id,
                                bubble: response.item.bubbleUrl,
                                name: response.item.name,
                                status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully,
                            },
                        });
                    });

                case "custom-corner":
                    return new Promise<BasicItemResponse<Omit<IPanelGeneratedModelDto, "typeId"> | IPanelGeneratedDrawingDto>>(async resolve => {
                        const response = await repo.findCustomCornerType(id);

                        if (!response.isSuccess) {
                            resolve(response);

                            return;
                        }

                        resolve({
                            isSuccess: true,
                            message: null,
                            item: {
                                id,
                                bubble: response.item.bubbleUrl,
                                name: response.item.name,
                                status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully,
                            },
                        });
                    });

                case "custom-z-panel":
                    return new Promise<BasicItemResponse<Omit<IPanelGeneratedModelDto, "typeId"> | IPanelGeneratedDrawingDto>>(async resolve => {
                        const response = await repo.findCustomZShapedType(id);

                        if (!response.isSuccess) {
                            resolve(response);

                            return;
                        }

                        resolve({
                            isSuccess: true,
                            message: null,
                            item: {
                                id,
                                bubble: response.item.bubbleUrl,
                                name: response.item.name,
                                status: PanelTypeGenerationStatus.ModelGeneratedSuccessfully,
                            },
                        });
                    });

                default:
                    return new Promise<BasicItemResponse<IPanelGeneratedModelDto | IPanelGeneratedDrawingDto>>(resolve => resolve({ isSuccess: false, message: "wrong app type", item: null }));
            }
        };

        Promise.all([getContent(), initializeViewer()]).then(x => {
            const [modelResponse] = x;

            setSourceModel(modelResponse);
        });

        return () => {
            Autodesk.Viewing.shutdown();
        };
    }, []);

    if (!(id && hash)) {
        return <div style={{ height: "100vh" }}><ContentNotReady /></div>;
    }

    if (!sourceModel) {
        return <AppLoadingSpinner />;
    }

    if (!(sourceModel.isSuccess && sourceModel.item.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully)) {
        return <div style={{ height: "100vh" }}><ContentNotReady /></div>;
    }

    const isPdf = applicationType === "panel-drawing" || applicationType === "corner-drawing";

    return <PanelGeneratedContentViewer url={sourceModel.item.bubble!} isPdf={isPdf} />;
};