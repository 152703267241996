import { UndoRedo } from "./undoRedo";

export class UndoRedoToolbar {
    public onAfterUndoRedo: (() => void) | undefined;
    public onBeforeUndoRedo: (() => void) | undefined;

    private constructor(
        private readonly undoRedo: UndoRedo,
        private readonly undoButton: Autodesk.Viewing.UI.Button,
        private readonly redoButton: Autodesk.Viewing.UI.Button,
        public readonly undoRedoControlGroup: Autodesk.Viewing.UI.ControlGroup,
    ) {
        this.undo = this.undo.bind(this);
        this.redo = this.redo.bind(this);
        this.update = this.update.bind(this);
        this.preprocessUndoRedo = this.preprocessUndoRedo.bind(this);
        this.postprocessUndoRedo = this.postprocessUndoRedo.bind(this);

        undoButton.addEventListener("click", this.undo);
        redoButton.addEventListener("click", this.redo);

        undoRedo.addEventListener("before-undo", this.preprocessUndoRedo);
        undoRedo.addEventListener("before-redo", this.preprocessUndoRedo);
        undoRedo.addEventListener("after-undo", this.postprocessUndoRedo);
        undoRedo.addEventListener("after-redo", this.postprocessUndoRedo);
        undoRedo.addEventListener("action-pushed", this.update);
        undoRedo.addEventListener("stacked-cleared", this.update);

        this.update();
    }

    public static create(undoRedo: UndoRedo, controlGroup: Autodesk.Viewing.UI.ControlGroup, buttonsIdPrefix: string) {
        const undoButton = new Autodesk.Viewing.UI.Button(`${buttonsIdPrefix}-undo`);
        undoButton.setToolTip("Undo");
        undoButton.setIcon("viewer-undo-button");

        controlGroup.addControl(undoButton);

        const redoButton = new Autodesk.Viewing.UI.Button(`${buttonsIdPrefix}-redo`);

        redoButton.setToolTip("Redo");
        redoButton.setIcon("viewer-redo-button");

        controlGroup.addControl(redoButton);

        return new UndoRedoToolbar(undoRedo, undoButton, redoButton, controlGroup);
    }

    dispose() {
        this.undoButton.removeEventListener("click", this.undo);
        this.redoButton.removeEventListener("click", this.redo);

        this.onBeforeUndoRedo = undefined;
        this.onAfterUndoRedo = undefined;
    }

    enable(enable: boolean) {
        if (enable)
            this.update();
        else {
            this.undoButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
            this.redoButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
        }
    }

    private raiseAfterUndoRedo() {
        if (this.onAfterUndoRedo) 
            this.onAfterUndoRedo();
        
    }

    private raiseOnBeforeUndoRedo() {
        if (this.onBeforeUndoRedo) 
            this.onBeforeUndoRedo();
        
    }

    private async redo() {
        this.raiseOnBeforeUndoRedo();

        await this.undoRedo.redo();

        this.raiseAfterUndoRedo();
    }

    private async undo() {
        this.raiseOnBeforeUndoRedo();

        await this.undoRedo.undo();

        this.raiseAfterUndoRedo();
    }

    private preprocessUndoRedo() {
        this.enable(false);
    }

    private postprocessUndoRedo() {
        this.enable(true);
    }

    private update() {
        const undoButtonState = this.undoRedo.canUndo()
            ? Autodesk.Viewing.UI.Button.State.INACTIVE
            : Autodesk.Viewing.UI.Button.State.DISABLED;

        this.undoButton.setState(undoButtonState);
        const undoActionName = this.undoRedo.getUndoActionName();
        this.undoButton.setToolTip(undoActionName ? `Undo "${undoActionName}"` : "Undo");

        const redoButtonState = this.undoRedo.canRedo()
            ? Autodesk.Viewing.UI.Button.State.INACTIVE
            : Autodesk.Viewing.UI.Button.State.DISABLED;

        this.redoButton.setState(redoButtonState);
        const redoActionName = this.undoRedo.getRedoActionName();
        this.redoButton.setToolTip(redoActionName ? `Redo "${redoActionName}"` : "Redo");
    }
}
