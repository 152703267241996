import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Settings } from "react-feather";
import { ProcessingFailure } from "../../../../../processing-failure/processingFailure";
import { ProcessingPane } from "../../../../../processing-pane/processingPane";
import { rootElement } from "../../../../../shared";
import eventBus, { IApplicationEvent } from "../../../../eventBus/eventDispatcher";

type Props = {
    isRegenerate?: boolean;
};

export const GenerateNewRevitHooksModelControls = ({ isRegenerate }: Props) => {
    const [generationStarted, setGenerationStarted] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();
    const title = isRegenerate ? "Regenerate hooks model" : "Generate new hooks model";

    useEffect(() => {
        const onGenerationFailed = (event: IApplicationEvent<string>) => {
            setGenerationStarted(false);
            setFailureMessage(event.payload);
        };

        eventBus.addEventListener("Dextall.HooksRevitModel.GenerationRequestFailed", onGenerationFailed);

        return () => {
            eventBus.removeEventListener("Dextall.HooksRevitModel.GenerationRequestFailed", onGenerationFailed);
        };
    }, []);

    const onClick = () => {
        eventBus.dispatchEvent({ type: "Dextall.HooksRevitModel.GenerationRequested", payload: null });
        setGenerationStarted(true);
    };

    return (
        <div className="generated-revit-model-content-button-container" title={title}>
            <Settings size={16} color="#0796D8" onClick={onClick} />
            {generationStarted &&
                ReactDOM.createPortal(
                    <ProcessingPane
                        isVisible={true}
                        message="Starting generation..."
                        title="Generating hooks RVT..."
                    />,
                    rootElement,
                )}
            {failureMessage !== undefined &&
                ReactDOM.createPortal(
                    <ProcessingFailure
                        closeProcessingErrorDialog={() => setFailureMessage(undefined)}
                        isVisible={true}
                        title="Error"
                        message={failureMessage}
                    />,
                    rootElement,
                )}
        </div>
    );
};
