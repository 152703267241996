import Typography from "@weave-design/typography";
import { CustomPanelType, CustomPanelTypeStatus, ICustomCornerType } from "../../responses/customPanelTypes";
import { CustomComponentCard } from "./customComponentCard";
import { formatLength } from "./customComponentCommon";
import "./customPanelComponentCard.css";

type Props = {
    panel: ICustomCornerType;
    selectLibraryComponent: (component: CustomPanelType) => void;
    currentCustomPanelTypeId?: string;
};

export const CustomCornerComponentCard = ({ panel, selectLibraryComponent, currentCustomPanelTypeId }: Props) => {
    const leftWingDimension = panel.status === CustomPanelTypeStatus.Published
        ? formatLength(panel.leftWingLength)
        : "???";

    const rightWingDimension = panel.status === CustomPanelTypeStatus.Published
        ? formatLength(panel.rightWingLength)
        : "???";

    const height = panel.status === CustomPanelTypeStatus.Published
        ? formatLength(panel.height)
        : "???";

    return <CustomComponentCard
        panel={panel}
        selectLibraryComponent={selectLibraryComponent}
        currentCustomPanelTypeId={currentCustomPanelTypeId}
        typeIcon="custom-panel-component-card-stats-custom-corner-type"
        panelProperties={<>
            <div>
                <Typography>Angle: {panel.angle}°</Typography>
            </div>
            <div>
                <Typography>Left wing: {leftWingDimension}</Typography>
            </div>
            <div>
                <Typography>Right wing: {rightWingDimension}</Typography>
            </div>
            <div>
                <Typography>Height: {height}</Typography>
            </div>
        </>} />;
};