import { Settings } from "react-feather";
import eventBus from "../../../../eventBus/eventDispatcher";

type Props = {
    isRegenerate?: boolean;
};

export const GenerateNewRevitFacadeModelControls = ({ isRegenerate }: Props) => {
    const onClick = () => eventBus.dispatchEvent({
        type: "Dextall.RevitFacadeModel.UI.StartGeneration",
        payload: null,
    });

    const title = isRegenerate ? "Regenerate facade model" : "Generate new facade model";

    return <div className="generated-revit-model-content-button-container" title={title}>
        <Settings size={16} color="#0796D8" onClick={onClick} />
    </div>;
};