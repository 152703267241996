import { BasicItemResponse } from "../../../responses/basicResponses";
import { StickyNote, StickyNoteGenerationStatus, StickyNoteSourceType } from "../../../responses/stickyNote";
import repo from "../../../Repository";

export const generatePanelQrCode = async (
    type: StickyNoteSourceType,
    id: string,
    signal: AbortSignal,
): Promise<BasicItemResponse<string> | null> => {
    const stickyNoteResponse = await repo.getStickyNote(type, id);

    if (!stickyNoteResponse.isSuccess) {
        return { ...stickyNoteResponse };
    }

    let stickyNote = stickyNoteResponse.item;

    if (stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully) {
        return { isSuccess: true, message: null, item: stickyNote.downloadableUrl };
    }

    if (
        stickyNote.status === StickyNoteGenerationStatus.NotGenerated ||
        stickyNote.status === StickyNoteGenerationStatus.GenerationFailed
    ) {
        const startQrCodeGenerationResponse = await repo.startStickyNoteGeneration(type, id);

        if (!startQrCodeGenerationResponse.isSuccess) {
            return { ...startQrCodeGenerationResponse, item: null };
        }
    }

    const generatedStickyNote = await waitForGeneration(type, id, signal);

    if (generatedStickyNote === null) {
        return null;
    }

    stickyNote = generatedStickyNote;

    return stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully
        ? { isSuccess: true, message: null, item: stickyNote.downloadableUrl }
        : { isSuccess: false, message: "Failed to generate panel QR code", item: null };
};

export const generateModelQrCode = async (
    modelId: string,
    signal: AbortSignal,
): Promise<BasicItemResponse<string> | null> => {
    const startModelStickyNotesGenerationResponse = await repo.startModelStickyNoteGeneration(modelId);

    if (!startModelStickyNotesGenerationResponse.isSuccess) {
        return { ...startModelStickyNotesGenerationResponse };
    }

    const id = startModelStickyNotesGenerationResponse.item;

    const stickyNote = await waitForGeneration("models", id, signal);

    if (stickyNote === null) {
        return null;
    }

    return stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully
        ? { isSuccess: true, message: null, item: stickyNote.downloadableUrl }
        : { isSuccess: false, message: "Failed to generate panel QR code", item: null };
};

const waitForGeneration = (type: StickyNoteSourceType | "models", id: string, signal: AbortSignal) => {
    return new Promise<StickyNote | null>(resolve => {
        const interval = 7000;
        let handle: ReturnType<typeof setTimeout> | undefined;

        const checkStickyNote = async () => {
            const stickyNoteResponse = await repo.getStickyNote(type, id);

            if (stickyNoteResponse.isSuccess &&
                stickyNoteResponse.item.status !== StickyNoteGenerationStatus.InProgress) {
                resolve(stickyNoteResponse.item);
            } else {
                handle = setTimeout(checkStickyNote, interval);
            }
        };

        signal.onabort = () => {
            clearTimeout(handle);
            resolve(null);
        };

        handle = setTimeout(checkStickyNote, interval);
    });
};