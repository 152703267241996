import { SurfaceType, ZShapeGeometry, ZShapeGeometryType } from "@dextall/corner-geometry";

export class ZShapeCladdingGeometryAttributes {
    constructor(private readonly geometry: ZShapeGeometry) {}

    apply(geometry: THREE.BufferGeometry, geometryType: ZShapeGeometryType) {
        const normals = geometry.attributes.normal;
        const positions = geometry.attributes.position;
        const count = normals.count;

        const wingCenter = new Float32Array(count * 2);
        const rightWingDirectionData = new Float32Array(count * 2);
        const pointTypeData = new Float32Array(count);
        const shelfLengthData = new Float32Array(count);

        const outerCornerPoint = this.getOuterCornerPoint(geometryType);
        const rightWingDirection = this.getRightWingDirection(geometryType);
        const shelfLength = this.geometry.getShelfStartOuterPoint().distanceTo(this.geometry.getShelfEndOuterPoint());

        for (let i = 0; i < count; ++i) {
            wingCenter[2 * i] = outerCornerPoint.x;
            wingCenter[2 * i + 1] = outerCornerPoint.y;
            rightWingDirectionData[2 * i] = rightWingDirection.x;
            rightWingDirectionData[2 * i + 1] = rightWingDirection.y;
            shelfLengthData[i] = shelfLength;

            const normal = new THREE.Vector3(
                normals.array![3 * i],
                normals.array![3 * i + 1],
                normals.array![3 * i + 2],
            );
            const position = new THREE.Vector3(
                positions.array![3 * i],
                positions.array![3 * i + 1],
                positions.array![3 * i + 2],
            );

            const surfaceType = this.geometry.getSurfacePointType(geometryType, position, normal);

            pointTypeData[i] = this.getSurfaceTypeIndex(geometryType, surfaceType);
        }

        geometry.setAttribute("cornerWingCenter", new THREE.Float32BufferAttribute(wingCenter, 2, false));
        geometry.setAttribute(
            "cornerRightWingDirection",
            new THREE.Float32BufferAttribute(rightWingDirectionData, 2, false),
        );
        geometry.setAttribute("cornerWingSurfaceType", new THREE.Float32BufferAttribute(pointTypeData, 1, false));
        geometry.setAttribute("shapeShelfLength", new THREE.Float32BufferAttribute(shelfLengthData, 1, false));
    }

    private getOuterCornerPoint(geometryType: ZShapeGeometryType): THREE.Vector2 {
        return geometryType === "right-wing"
            ? this.geometry.getShelfEndOuterPoint()
            : this.geometry.getShelfStartOuterPoint();
    }

    private getRightWingDirection(geometryType: ZShapeGeometryType): THREE.Vector2 {
        return geometryType === "right-wing"
            ? this.geometry.getRightWingDirection()
            : this.geometry.getShelfDirection();
    }

    private getSurfaceTypeIndex(geometryType: ZShapeGeometryType, surfaceType: SurfaceType) {
        if (geometryType === "left-wing") {
            switch (surfaceType) {
                case "front":
                    return 1;

                case "top":
                    return 3;

                case "bottom":
                    return 5;

                case "side":
                    return 7;

                default:
                    return 0;
            }
        }

        if (geometryType === "shelf") {
            switch (surfaceType) {
                case "front":
                    return 2;

                case "top":
                    return 4;

                case "bottom":
                    return 6;

                default:
                    return 0;
            }
        }

        switch (surfaceType) {
            case "front":
                return 9;

            case "top":
                return 10;

            case "bottom":
                return 11;

            case "side":
                return 12;

            default:
                return 0;
        }
    }
}
