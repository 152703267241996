import { UpdateCustomPanelOffsetCommand } from "../../../commands/updateCustomPanelOffsetCommand";
import { CustomPanel } from "../panels/customPanel";
import { GizmoBase } from "./gizmoBase";
import { SnapGrid } from "../geometry/snapGrid";
import eventBus from "../eventBus/eventDispatcher";

export class CustomWallPanelOffsetGizmo extends GizmoBase {
    private readonly panelOffset: UpdateCustomPanelOffsetCommand;
    constructor(viewer: Autodesk.Viewing.Viewer3D, private readonly panel: CustomPanel, private readonly snapGrid: SnapGrid) {
        const transformControl = new THREE.TransformControls(viewer.impl.camera, viewer.impl.canvas, "translate", "XY");

        super(transformControl);

        this.panelOffset = { offsetX: panel.offsetX, offsetY: panel.offsetY };

        this.mesh.applyMatrix(panel.getOffsetGizmoCoordinateSystem());

        const boundingBox = viewer.model.getBoundingBox();
        this.transformControl.setSize(boundingBox.getBoundingSphere().radius * 5);
        this.transformControl.attach(this.mesh);
        this.transformControl.setSpace("local");

        this.onGizmoChanged = this.onGizmoChanged.bind(this);
        this.transformControl.addEventListener("change", this.onGizmoChanged);
    }

    restore() {
        this.apply(this.panel.getOffsetGizmoCoordinateSystem());
    }

    dispose() {
        this.transformControl.removeEventListener("change", this.onGizmoChanged);
    }

    startDraggingAt(event: MouseEvent): boolean {
        const result = this.handleButtonDown(event);

        if (result) {
            this.panelOffset.offsetX = this.panel.offsetX;
            this.panelOffset.offsetY = this.panel.offsetY;
        }

        return result;
    }

    endDraggingAt(event: MouseEvent) {
        const isDragging = this.isDraggingGizmo();

        const result = this.handleButtonUp(event);

        if (isDragging) {
            const offset = this.convertGizmoPositionToOffset();

            eventBus.dispatchEvent({
                type: "Dextall.CustomPanels.Gizmo.DraggingCompleted",
                payload: {
                    id: this.panel.id,
                    initialOffset: this.panelOffset,
                    targetOffset: offset
                }
            })
        }

        return isDragging || result;
    }

    private onGizmoChanged(event: any) {
        if (!event.target.isDragging())
            return;

        const offset = this.convertGizmoPositionToOffset();

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.Gizmo.Update",
            payload: { id: this.panel.id, offset }
        });

        this.restore();
    }

    private convertGizmoPositionToOffset(): UpdateCustomPanelOffsetCommand {
        const localPoint = this.panel.panelBoundaryLoop.toLocalCoordinates(this.getPosition());

        return { offsetX: this.snapGrid.snap(localPoint.x), offsetY: this.snapGrid.snap(localPoint.y) };
    }
}