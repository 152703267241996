import { GeneratedFacadeRevitModel } from "./generated-revit-models/generatedFacadeRevitModel";
import { GeneratedHooksRevitModel } from "./generated-revit-models/generatedHooksRevitModel";
import "./editor2DContent.css";

export const RevitModelContent = () => {
    return <div className="editor-2d-container">
        <GeneratedHooksRevitModel />
        <hr />
        <GeneratedFacadeRevitModel />
    </div>;
};