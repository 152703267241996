import { CustomCorner2DEditorDockingPanelContent } from "./content/customCorner2DEditorDockingPanelContent";
import { DockingPanel } from "./dockingPanel";

export class CustomCorner2DEditorDockingPanel {
    private readonly panel: DockingPanel;
    private _visible: boolean;

    constructor(container: HTMLElement) {
        this._visible = false;

        const component = <CustomCorner2DEditorDockingPanelContent />;

        const containerRect = container.getBoundingClientRect();

        this.panel = new DockingPanel(container, "dextall-custom-corner-2d-editor", "Corner type", component, {
            style: {
                resize: "both",
            },
            dimensionsControl: {
                preventOverlapsWithViewerToolbar: true,
                widthDimensionControl: { value: 0.25 * containerRect.width, minValue: 560 },
                heightDimensionControl: { value: 0.5 * containerRect.height, minValue: 560 },
                topOffset: 2,
                rightOffset: 2,
            },
            carefullBringToFront: true,
        });

        this.panel.addToggleFullSizeButton();

        this.panel.addVisibilityListener((x: boolean) => this._visible = x);
    }

    get visible() {
        return this._visible;
    }

    setVisible(visibility: boolean) {
        if (this._visible === visibility) {
            return;
        }

        this._visible = visibility;
        this.panel.setVisible(visibility);
    }

    shutdown() {
        this.panel.shutdown();
    }
}