import { BasicItemResponse, BasicResponse } from "../../../responses/basicResponses";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { ICustomCornerType } from "../../../responses/customPanelTypes";
import { IPanelType } from "../../../responses/panelType";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { Corner } from "../panels/corner";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { PanelFamily } from "../panels/panelFamily";
import { PanelType } from "../panels/panelType";
import { CreatePanelTypeOperationResult } from "./panelsModelEditor";
import repo from "../../../Repository";

export class CornersModelEditor {
    private readonly panelsTitlesFragments = new Map<number, [number, number]>();
    private readonly panelsCladdingCellsFragments = new Map<number, number[]>;

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {
    }

    get modelId(): number {
        return this.modelBufferedGeometry.model.id;
    }

    get corners(): Corner[] {
        return this.panelFacadeDocument.cornerPanels;
    }

    get facadeModelId(): string {
        return this.panelFacadeDocument.modelId;
    }

    get panelTypes(): PanelType<ICornerPanelSource>[] {
        return this.panelFacadeDocument.cornerTypes;
    }

    createCornerPanels() {
        for (const corner of this.panelFacadeDocument.cornerPanels) {
            this.addPanel(corner);
        }

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    findCorner(dbId: number): Corner | undefined {
        return this.panelFacadeDocument.findCorner(dbId);
    }

    findCornerById(id: string): Corner | undefined {
        return this.panelFacadeDocument.findCornerById(id);
    }

    findCornerByUserId(userUniqueId: number): Corner | undefined {
        return this.panelFacadeDocument.findCornerByUserId(userUniqueId);
    }

    findCornerByElementName(elementName: string): Corner | undefined {
        return this.panelFacadeDocument.findCornerByElementName(elementName);
    }

    findCornerByTypeName(typeName: string): Corner | undefined {
        return this.panelFacadeDocument.findCornerByTypeName(typeName);
    }

    findCornerByCustomTypeName(typeName: string): Corner | undefined {
        return this.panelFacadeDocument.findCornerByCustomTypeName(typeName);
    }

    findPanelFamily(panelTypeId: string): PanelFamily<ICornerPanelSource> | undefined {
        return this.panelFacadeDocument.findCornerPanelFamily(panelTypeId);
    }

    findValidCustomCornerTypesForPanel(panelId: string): ICustomCornerType[] {
        return this.panelFacadeDocument.findValidCustomCornerTypesForPanel(panelId);
    }

    findCornerPanelType(panelTypeId: string): PanelType<ICornerPanelSource> | undefined {
        return this.panelFacadeDocument.findCornerPanelType(panelTypeId);
    }

    findCornerCustomPanelType(panelId: string): ICustomCornerType | undefined {
        const corner = this.panelFacadeDocument.findCornerById(panelId);

        if (!corner?.customPanelTypeId) {
            return undefined;
        }

        return this.panelFacadeDocument.findCustomCornerType(corner.customPanelTypeId);
    }

    async update(cornerId: string, elementName: string): Promise<BasicResponse> {
        const corner = this.findCornerById(cornerId);

        if (!corner) {
            return { isSuccess: false, message: `Cant' find the corner with id=${cornerId}` };
        }

        const response = await repo.updateCorner(cornerId, { elementName });

        if (response.isSuccess) {
            corner.update(elementName);
        }

        return response;
    }

    async switchPanelCustomType(panelId: string,
        targetCustomPanelTypeId: string | null): Promise<CreatePanelTypeOperationResult> {
        const panel = this.panelFacadeDocument.findCornerById(panelId);

        if (!panel || panel.customPanelTypeId === targetCustomPanelTypeId) {
            return { isSuccess: true, removedHooksDbIds: [], message: null };
        }

        const result = targetCustomPanelTypeId !== null
            ? await repo.setCornerCustomType(panelId, { targetTypeId: targetCustomPanelTypeId })
            : await repo.resetCornerCustomType(panelId);

        if (!result.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: result.message };
        }

        this.setPanelCustomType(panel, targetCustomPanelTypeId);

        const removedHooksDbIds = targetCustomPanelTypeId
            ? this.panelFacadeDocument.findPanelHooks(panel.id).map(x => x.dbId)
            : [];

        return { isSuccess: true, removedHooksDbIds, message: null };
    }

    updatePanelTypeTexts(panelId: string) {
        const cornerPanel = this.panelFacadeDocument.findCornerById(panelId);
        if (!cornerPanel) {
            throw new Error("Invalid state!");
        }

        const panelType = this.panelFacadeDocument.findCornerPanelType(cornerPanel.panelTypeId);
        if (!panelType) {
            throw new Error("Invalid state!");
        }

        for (const panel of panelType.panels) {
            this.addPanelTypeText(panel);
            this.recreatePanelGeometry(panel);
        }

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    loadPanelTypeModel(panel: Corner | undefined) {
        this.panelFacadeDocument.loadCornerTypeModel(panel);
    }

    async updatePanelType(panelTypeProperties: Omit<IPanelType, "hooks">): Promise<BasicResponse> {
        const panelType = this.panelFacadeDocument.findCornerPanelType(panelTypeProperties.id);

        if (!panelType) {
            return { isSuccess: false, message: `Can't find corner panel type with id=${panelTypeProperties.id}` };
        }

        const result = await repo.updateCornerPanelType(panelType.id, {
            typeName: panelTypeProperties.name,
            withoutCladdings: panelTypeProperties.withoutCladdings,
        });

        if (result.isSuccess) {
            panelType.name = panelTypeProperties.name;
            panelType.withoutCladdings = panelTypeProperties.withoutCladdings;

            for (const panel of panelType.panels) {
                this.addPanelTypeText(panel);
                this.recreatePanelGeometry(panel);
            }

            this.modelBufferedGeometry.makeFixOnObjectRecreation();
        }

        return result;
    }

    async switchPanelType(panelId: string, targetTypeId: string): Promise<CreatePanelTypeOperationResult> {
        const switchPanelTypeResult = await repo.setCornerPanelType(panelId, { targetTypeId });

        if (!switchPanelTypeResult.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: switchPanelTypeResult.message };
        }

        const removedHooksDbIds = this.panelFacadeDocument.setCornerPanelType(panelId, targetTypeId);

        return { isSuccess: true, removedHooksDbIds, message: null };
    }

    async createPanelType(panelType: Omit<IPanelType, "hooks">,
        panelId: string): Promise<CreatePanelTypeOperationResult> {
        const { id: panelTypeId, name: typeName, withoutCladdings } = panelType;
        const family = this.panelFacadeDocument.findCornerPanelFamily(panelTypeId);
        if (!family) {
            throw new Error("Invalid state!");
        }

        const panelTypeCreationResult = await repo.duplicateCornerType(family.id, {
            sourcePanelTypeId: panelTypeId,
            typeName,
            withoutCladdings,
        });

        if (!panelTypeCreationResult.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: panelTypeCreationResult.message };
        }

        const typeId = panelTypeCreationResult.item;

        this.panelFacadeDocument.addCornerType(typeId, panelTypeId, typeName, withoutCladdings);

        return await this.switchPanelType(panelId, typeId);
    }

    async startPanelTypeGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        const result = await repo.startCornerPanelTypeModelGeneration(panelTypeId);

        if (result.isSuccess) {
            this.panelFacadeDocument.loadCornerTypeModel(panelTypeId);
        }

        return result;
    }

    private addPanel(cornerPanel: Corner) {
        const geometries = cornerPanel.createGeometry();

        const claddingCellsFragments: number[] = [];

        for (const geometry of geometries) {
            const fragmentId = this.modelBufferedGeometry.add(geometry);

            this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, cornerPanel.panelDbId);

            claddingCellsFragments.push(fragmentId);
        }

        this.panelsCladdingCellsFragments.set(cornerPanel.panelDbId, claddingCellsFragments);

        this.addPanelTypeText(cornerPanel);
    }

    private setPanelCustomType(panel: Corner, targetCustomPanelTypeId: string | null) {
        panel.setCustomPanelType(targetCustomPanelTypeId);

        this.recreatePanelGeometry(panel);

        this.addPanelTypeText(panel);
    }

    private recreatePanelGeometry(panel: Corner) {
        const claddingCellsGeometries = panel.createGeometry();

        const fragments = this.panelsCladdingCellsFragments.get(panel.panelDbId) || [];

        for (let i = 0; i < fragments.length; ++i) {
            this.modelBufferedGeometry.changeFragmentMaterial(fragments[i], claddingCellsGeometries[i].material);
        }
    }

    private addPanelTypeText(cornerPanel: Corner) {
        const typeText = this.getPanelTypeText(cornerPanel);

        const existingTextFragments = this.panelsTitlesFragments.get(cornerPanel.panelDbId);

        if (existingTextFragments) {
            this.modelBufferedGeometry.remove(existingTextFragments[0]);
            this.modelBufferedGeometry.remove(existingTextFragments[1]);

            this.panelsTitlesFragments.delete(cornerPanel.panelDbId);
        }

        if (typeText) {
            const textSize = this.textGeometryFactory.computeSize(typeText);

            const leftWingTitleMatrix = cornerPanel.computeLeftWingPanelTypeTitleMatrix(textSize);

            const leftWingTextGeometry = this.textGeometryFactory.createTextGeometry(typeText, leftWingTitleMatrix);

            const leftWingFragmentId = this.modelBufferedGeometry.add(leftWingTextGeometry);

            const rightWingTitleMatrix = cornerPanel.computeRightWingPanelTypeTitleMatrix(textSize);

            const rightWingTextGeometry = this.textGeometryFactory.createTextGeometry(typeText, rightWingTitleMatrix);

            const rightWingFragmentId = this.modelBufferedGeometry.add(rightWingTextGeometry);

            this.modelBufferedGeometry.changeFragmentsDbId(
                [leftWingFragmentId, rightWingFragmentId], cornerPanel.panelDbId);

            this.panelsTitlesFragments.set(cornerPanel.panelDbId, [leftWingFragmentId, rightWingFragmentId]);
        }
    }

    private getPanelTypeText(cornerPanel: Corner): string {
        if (cornerPanel.customPanelTypeId) {
            const customPanelType = this.panelFacadeDocument.findCustomCornerType(cornerPanel.customPanelTypeId);

            return customPanelType?.name || "";
        }

        const panelType = this.panelFacadeDocument.findCornerPanelType(cornerPanel.panelTypeId);

        return panelType?.name || "";
    }
}