import { UpdateCustomCornerOffsetCommand } from "../../../commands/updateCustomCornerOffsetCommand";
import { BasicItemResponse, BasicResponse } from "../../../responses/basicResponses";
import { ICustomCornerType } from "../../../responses/customPanelTypes";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { CustomCorner } from "../panels/customCorner";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { CustomCornerPlacementRequestEventPayload } from "../eventBus/customPanelsEventPayloads";
import repo from "../../../Repository";

export class CustomCornersModelEditor {
    private readonly fragmentsByPanelDbId = new Map<number, number[]>();
    private readonly titlesFragments = new Map<number, number[]>();

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {
    }

    get model(): Autodesk.Viewing.Model {
        return this.modelBufferedGeometry.model;
    }

    createCustomCorners() {
        for (const customCorner of this.panelFacadeDocument.customCorners)
            this.addCorner(customCorner, false);

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    findCustomCorner(dbId: number): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCorner(dbId);
    }

    findCustomCornerById(id: string): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCornerById(id);
    }

    findCustomCornerByInternalId(id: string): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCornerByInternalId(id);
    }

    findValidCustomCornerTypesForPanel(panelId: string): ICustomCornerType[] {
        return this.panelFacadeDocument.findValidCustomCornerTypesForCustomCorner(panelId);
    }

    findCustomCornerByUserId(userUniqueId: number): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCornerByUserId(userUniqueId);
    }

    findCustomCornerByTypeName(typeName: string): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCornerByTypeName(typeName);
    }

    findCustomCornerByElementName(elementName: string): CustomCorner | undefined {
        return this.panelFacadeDocument.findCustomCornerByElementName(elementName);
    }

    findCustomCornerType(id: string): ICustomCornerType | undefined {
        return this.panelFacadeDocument.findCustomCornerType(id);
    }

    async switchPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        const corner = this.panelFacadeDocument.findCustomCornerById(panelId);

        if (!corner)
            return { isSuccess: false, message: `Failed to find custom corner with id=${panelId}` };

        const result = await repo.changeCustomCornerType(panelId, targetTypeId);

        if (result.isSuccess) {
            corner.setPanelType(targetTypeId);

            this.addCornerTypeTextGeometry(corner);
        }

        return result;
    }

    async setElementName(panelId: string, elementName: string): Promise<BasicResponse> {
        const corner = this.panelFacadeDocument.findCustomCornerById(panelId);

        if (!corner)
            return { isSuccess: false, message: `Failed to find custom corner with id=${panelId}` };

        const result = await repo.updateCustomCornerElementName(panelId, { elementName });

        if (result.isSuccess)
            corner.setElementName(elementName);

        return result;
    }

    async setOffset(panelId: string, offset: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        const corner =
            this.panelFacadeDocument.findCustomCornerById(panelId) ||
            this.panelFacadeDocument.findCustomCornerByInternalId(panelId);

        if (!corner) {
            return { isSuccess: false, message: `Failed to find custom corner with id=${panelId}` };
        }

        const result = await repo.updateCustomCornerOffset(corner.id, offset);

        if (result.isSuccess) {
            this.setCornerOffsetLocal(corner, offset.offset);
        }

        return result;
    }

    async createNewCustomCorner(
        command: CustomCornerPlacementRequestEventPayload,
    ): Promise<BasicItemResponse<CustomCorner>> {
        const response = await repo.createCustomCorner({ ...command, modelId: this.panelFacadeDocument.modelId });

        if (!response.isSuccess) {
            return response;
        }

        const corner = this.panelFacadeDocument.addCustomCorner(response.item, command.internalId);

        this.addCorner(corner);

        return { isSuccess: true, message: null, item: corner };
    }

    async removeCustomCorner(cornerId: string | CustomCorner): Promise<BasicResponse> {
        const corner = typeof cornerId === "string"
            ? this.findCustomCornerById(cornerId)
            : cornerId;

        if (!corner) {
            return { isSuccess: false, message: `Failed to find corner with id=${cornerId}` };
        }

        this.removeCornerGeometry(corner);

        const result = await repo.removeCustomCorner(corner.id);

        if (result.isSuccess) {
            this.panelFacadeDocument.removeCustomCorner(corner);
        } else {
            this.addCorner(corner);
        }

        return result;
    }

    setCornerOffsetLocal(cornerId: string | CustomCorner, offset: number) {
        const corner = typeof cornerId === "string"
            ? this.panelFacadeDocument.findCustomCornerById(cornerId)
            : cornerId;

        if (!corner)
            return;

        corner.setOffset(offset);

        this.updateCornerGeometry(corner);
    }

    private addCorner(corner: CustomCorner, makeFix = true) {
        this.addCornerGeometry(corner);
        this.addCornerTypeTextGeometry(corner);

        if (makeFix)
            this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    private addCornerGeometry(corner: CustomCorner) {
        const geometries = corner.createGeometry();

        const geometryFragments: number[] = [];

        for (const geometryChunk of geometries) {
            const fragmentId = this.modelBufferedGeometry.add(geometryChunk);

            this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, corner.panelDbId);

            geometryFragments.push(fragmentId);
        }

        this.fragmentsByPanelDbId.set(corner.panelDbId, geometryFragments);
    }

    private updateCornerGeometry(corner: CustomCorner) {
        const geometryFragments = this.fragmentsByPanelDbId.get(corner.panelDbId) || [];

        const targetTransform = corner.getCornerCoordinateSystem();

        for (const fragmentId of geometryFragments)
            this.modelBufferedGeometry.changeFragmentTransform(fragmentId, targetTransform);

        const textFrafments = this.titlesFragments.get(corner.panelDbId) || [];

        if (textFrafments.length !== 2)
            return;

        const [leftWingTextFragmentId, rightWingTextFragmentId] = textFrafments;

        const typeText = this.getTypeText(corner)!;

        const textSize = this.textGeometryFactory.computeSize(typeText);

        const leftWingMatrix = corner.computeTitleMatrix(textSize, true);
        const rightWingMatrix = corner.computeTitleMatrix(textSize, false);

        this.modelBufferedGeometry.changeFragmentTransform(leftWingTextFragmentId, leftWingMatrix);
        this.modelBufferedGeometry.changeFragmentTransform(rightWingTextFragmentId, rightWingMatrix);
    }

    private removeCornerGeometry(corner: CustomCorner) {
        const geometryFragments = this.fragmentsByPanelDbId.get(corner.panelDbId) || [];

        this.modelBufferedGeometry.removeFragments(geometryFragments);

        const textFragments = this.titlesFragments.get(corner.panelDbId) || [];

        this.modelBufferedGeometry.removeFragments(textFragments);

        this.fragmentsByPanelDbId.delete(corner.panelDbId);
        this.titlesFragments.delete(corner.panelDbId);
    }

    private addCornerTypeTextGeometry(corner: CustomCorner) {
        const existingTextFragments = this.titlesFragments.get(corner.panelDbId) || [];

        this.modelBufferedGeometry.removeFragments(existingTextFragments);
        this.titlesFragments.delete(corner.panelDbId);

        const typeText = this.getTypeText(corner);

        if (!typeText)
            return;

        const leftWingTextFragmentId = this.addWingTypeText(corner, true);
        const rightWingTextFragmentId = this.addWingTypeText(corner, false);

        this.titlesFragments.set(corner.panelDbId, [leftWingTextFragmentId, rightWingTextFragmentId]);
    }

    private addWingTypeText(corner: CustomCorner, leftWing: boolean): number {
        const typeText = this.getTypeText(corner)!;

        const textSize = this.textGeometryFactory.computeSize(typeText);

        const wingMatrix = corner.computeTitleMatrix(textSize, leftWing);

        const geometry = this.textGeometryFactory.createTextGeometry(typeText, wingMatrix);

        const fragmentId = this.modelBufferedGeometry.add(geometry);

        this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, corner.panelDbId);

        return fragmentId;
    }

    private getTypeText(corner: CustomCorner): string | undefined {
        const customCornerType = this.panelFacadeDocument.findCustomCornerType(corner.customPanelTypeId);

        return customCornerType?.name;
    }
}