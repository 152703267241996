import { UpdateCustomCornerOffsetCommand } from "../../../commands/updateCustomCornerOffsetCommand";
import { BasicItemResponse } from "../../../responses/basicResponses";
import { CustomPanelType, CustomPanelTypeShape } from "../../../responses/customPanelTypes";
import { IModelCorner } from "../../../responses/modelCorner";
import { CustomZShapedPanelDockingPanel } from "../docking-panels/customZShapedPanelDockingPanel";
import { CustomZShapedPanelsModelEditor } from "../editors/customZShapedPanelsModelEditor";
import { CustomZShapedPanel } from "../panels/customZShapedPanel";
import { WallFacesCollection } from "../panels/wallFacesCollection";
import { ZShapeCornersCollection } from "../panels/z-shape/zShapeCornersCollection";
import { customComponentsToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { CustomWallZShapedPanelOffsetTool } from "../viewer-tools/customWallZShapedPanelOffsetTool";
import { CustomZShapedPanelsPlacementTool } from "../viewer-tools/customZShapedPanelsPlacementTool";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import {
    ChangeCustomCornerOffsetEventPayload,
    CustomCornerGizmoDraggingCompletedEventPayload,
    CustomCornerGizmoDraggingPayload,
    CustomZShapedPanelPlacementRequestPayload,
} from "../eventBus/customPanelsEventPayloads";
import { ElementParameterChangedEventPayload } from "../eventBus/elementParameterChangedEventPayload";
import { SwitchPanelTypeEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgeCustomZShapedPanelsEditorExtensionLoadOptions = {
    customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
    wallFacesCollection: WallFacesCollection;
    modelCorners: IModelCorner[];
};

export class ForgeCustomZShapedPanelsEditorExtension extends Autodesk.Viewing.Extension {
    private readonly customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
    private readonly offsetGizmoTool = new CustomWallZShapedPanelOffsetTool();
    private readonly customPanelsPlacementTool = new CustomZShapedPanelsPlacementTool();
    private readonly zCorners: ZShapeCornersCollection;
    private editorPanel: CustomZShapedPanelDockingPanel | null = null;
    private toggleCustomZShapePanelsPlacementButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeCustomZShapedPanelsEditorExtensionLoadOptions) {
        super(viewer, options);

        this.customZShapedPanelsModelEditor = options.customZShapedPanelsModelEditor;
        this.zCorners = ZShapeCornersCollection.create(options.wallFacesCollection, options.modelCorners)

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSwitchPanelType = this.onSwitchPanelType.bind(this);
        this.onChangeElementName = this.onChangeElementName.bind(this);
        this.onChangeOffset = this.onChangeOffset.bind(this);
        this.onOffsetChangingByGizmo = this.onOffsetChangingByGizmo.bind(this);
        this.onOffsetChangingByGizmoCompleted = this.onOffsetChangingByGizmoCompleted.bind(this);
        this.onToggleCustomPanelPlacement = this.onToggleCustomPanelPlacement.bind(this);
        this.onSelectCustomPanelToPlace = this.onSelectCustomPanelToPlace.bind(this);
        this.onCustomZShapedPanelCreateRequest = this.onCustomZShapedPanelCreateRequest.bind(this);
        this.onEntityRemoveRequested = this.onEntityRemoveRequested.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        this.customZShapedPanelsModelEditor.createCustomZShapedPanels();

        this.viewer.toolController.registerTool(this.offsetGizmoTool);
        this.viewer.toolController.activateTool(this.offsetGizmoTool.getName());

        this.viewer.toolController.registerTool(this.customPanelsPlacementTool);

        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.addEventListener("Dextall.CustomZShapedPanels.UI.SwitchType", this.onSwitchPanelType);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.UI.SetElementName", this.onChangeElementName);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.UI.SetOffset", this.onChangeOffset);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.PromptPlacement", this.onSelectCustomPanelToPlace);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.CreateNew", this.onCustomZShapedPanelCreateRequest);
        eventBus.addEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        return true;
    }

    unload() {
        this.editorPanel?.shutdown();

        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        this.viewer.toolController.deactivateTool(this.offsetGizmoTool.getName());
        this.viewer.toolController.deregisterTool(this.offsetGizmoTool);

        this.viewer.toolController.deregisterTool(this.customPanelsPlacementTool);

        eventBus.removeEventListener("Dextall.CustomZShapedPanels.UI.SwitchType", this.onSwitchPanelType);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.UI.SetElementName", this.onChangeElementName);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.UI.SetOffset", this.onChangeOffset);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.Gizmo.Update", this.onOffsetChangingByGizmo);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.Gizmo.DraggingCompleted", this.onOffsetChangingByGizmoCompleted);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.PromptPlacement", this.onSelectCustomPanelToPlace);
        eventBus.removeEventListener("Dextall.CustomZShapedPanels.CreateNew", this.onCustomZShapedPanelCreateRequest);
        eventBus.removeEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onEntityRemoveRequested);

        this.toggleCustomZShapePanelsPlacementButton?.removeEventListener("click", this.onToggleCustomPanelPlacement);
        this.toggleCustomZShapePanelsPlacementButton = null;

        return true;
    }

    onToolbarCreated() {
        this.editorPanel = new CustomZShapedPanelDockingPanel(this.viewer.container);

        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(customComponentsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(customComponentsToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleCustomZShapePanelsPlacementButton = new Autodesk.Viewing.UI.Button("dextall-place-custom-z-panel-button");
        this.toggleCustomZShapePanelsPlacementButton.setToolTip("Add custom z-shaped panel");
        this.toggleCustomZShapePanelsPlacementButton.setIcon("viewer-z-shape-editor-button");
        this.toggleCustomZShapePanelsPlacementButton.addEventListener("click", this.onToggleCustomPanelPlacement);

        group.addControl(this.toggleCustomZShapePanelsPlacementButton);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selection = event.selections.find((x: { model: { id: number; }; }) => x.model.id === this.customZShapedPanelsModelEditor.model.id);

        const elementDbId = selection?.dbIdArray[0];

        this.raiseSelectionChanged(elementDbId);
    }

    private async switchPanelType(panelId: string, targetTypeId: string, panelDbId?: number) {
        const result = await this.customZShapedPanelsModelEditor.switchPanelType(panelId, targetTypeId);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        this.raiseSelectionChanged(panelDbId);

        return result;
    }

    private async onSwitchPanelType(event: IApplicationEvent<SwitchPanelTypeEventPayload>) {
        const { panelId, targetTypeId } = event.payload;
        const panel = this.customZShapedPanelsModelEditor.findCustomPanelById(panelId);
        let savedPanelType = panel?.customPanelTypeId;
        const savedInternalId = panel?.internalId;

        const undoRedo = async () => {
            if (savedPanelType && savedInternalId) {
                const panel = this.customZShapedPanelsModelEditor.findCustomZShapedPanelByInternalId(savedInternalId);
                const temp = panel?.customPanelTypeId;

                await this.switchPanelType(panel?.id || panelId, savedPanelType, panel?.panelDbId);

                savedPanelType = temp;
            }
        };

        const result = await this.switchPanelType(panelId, targetTypeId, panel?.panelDbId);

        if (result.isSuccess) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Switch panel type", undo: undoRedo, redo: undoRedo },
                },
            });
        }
    }

    private async renameElement(panelId: string, elementName: string) {
        const result = await this.customZShapedPanelsModelEditor.setElementName(panelId, elementName);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }

        const panel = this.customZShapedPanelsModelEditor.findCustomPanelById(panelId);

        this.raiseSelectionChanged(panel?.panelDbId);
    }

    private async onChangeElementName(event: IApplicationEvent<ElementParameterChangedEventPayload>) {
        const { id, elementName } = event.payload;
        const panel = this.customZShapedPanelsModelEditor.findCustomPanelById(id);
        let savedElementName = panel?.elementName ?? "";
        const savedInternalId = panel?.internalId;

        const undoRedo = async () => {
            const panel = this.customZShapedPanelsModelEditor.findCustomZShapedPanelByInternalId(
                savedInternalId || id,
            );
            const temp = panel?.elementName ?? "";

            await this.renameElement(panel?.id || id, savedElementName);

            savedElementName = temp;
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Rename element", undo: undoRedo, redo: undoRedo },
            },
        });

        await this.renameElement(id, elementName);
    }

    private onOffsetChangingByGizmo(event: IApplicationEvent<CustomCornerGizmoDraggingPayload>) {
        const { id, offset } = event.payload;

        this.customZShapedPanelsModelEditor.setOffsetLocal(id, offset.offset);

        const panel = this.customZShapedPanelsModelEditor.findCustomPanelById(id);

        this.raiseSelectionChanged(panel?.panelDbId, true);
    }

    private async moveCustomZShapePanel(
        panelId: string,
        targetOffset: UpdateCustomCornerOffsetCommand,
        initialOffset?: UpdateCustomCornerOffsetCommand,
    ) {
        const panel = this.customZShapedPanelsModelEditor.findCustomPanelById(panelId);
        const savedInternalId = panel?.internalId;
        let savedOffset: UpdateCustomCornerOffsetCommand = initialOffset
            ? structuredClone(initialOffset)
            : {
                offset: panel?.offset ?? targetOffset.offset,
            };

        const undoRedo = async () => {
            const panel = this.customZShapedPanelsModelEditor.findCustomZShapedPanelByInternalId(
                savedInternalId || panelId,
            );
            const temp: UpdateCustomCornerOffsetCommand = {
                offset: panel?.offset ?? targetOffset.offset,
            };

            await this.customZShapedPanelsModelEditor.setOffset(panelId, savedOffset);

            savedOffset = temp;
            this.raiseSelectionChanged(panel?.panelDbId);
        };

        const result = await this.customZShapedPanelsModelEditor.setOffset(panelId, targetOffset);

        if (!result.isSuccess) {
            this.notifyError(result.message);

            this.customZShapedPanelsModelEditor.setOffsetLocal(panelId, savedOffset.offset);
        } else {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Move custom panel", undo: undoRedo, redo: undoRedo },
                },
            });
        }

        this.raiseSelectionChanged(panel?.panelDbId);
    }

    private async onOffsetChangingByGizmoCompleted(
        event: IApplicationEvent<CustomCornerGizmoDraggingCompletedEventPayload>,
    ) {
        await this.moveCustomZShapePanel(event.payload.id, event.payload.targetOffset, event.payload.initialOffset);
    }

    private async onChangeOffset(event: IApplicationEvent<ChangeCustomCornerOffsetEventPayload>) {
        await this.moveCustomZShapePanel(event.payload.id, event.payload);
    }

    private onToggleCustomPanelPlacement() {
        if (!this.isCustomPanelPlacementActive())
            eventBus.dispatchEvent({ type: "Dextall.CustomZShapedPanels.OpenLibrarySelector", payload: null });
        else
            this.viewer.toolController.deactivateTool(this.customPanelsPlacementTool.getName());
    }

    private onSelectCustomPanelToPlace(event: IApplicationEvent<CustomPanelType>) {
        const customPanelType = event.payload;

        if (customPanelType.shapeType !== CustomPanelTypeShape.ZShape)
            return;

        const availableCornerPlacements = this.zCorners.findModelCornersForCustomZShapedPanelType(customPanelType);

        if (availableCornerPlacements.length === 0) {
            this.notifyError(`Can't place a custom z-shaped panel. There are no model Z-shaped walls where ${customPanelType.name} could be placed`);

            return;
        }

        this.viewer.toolController.activateTool(this.customPanelsPlacementTool.getName());

        this.customPanelsPlacementTool.setCorners(availableCornerPlacements, customPanelType);
    }

    private async createCustomZShapedPanel(
        panelData: CustomZShapedPanelPlacementRequestPayload,
    ): Promise<BasicItemResponse<CustomZShapedPanel>> {
        const result = await this.customZShapedPanelsModelEditor.createPanel(panelData);

        eventBus.dispatchEvent({ type: "Dextall.CustomZShapedPanels.Created", payload: null });

        if (!result.isSuccess) {
            this.notifyError(result.message);

            return result;
        }

        if (panelData.single) {
            this.viewer.toolController.deactivateTool(this.customPanelsPlacementTool.getName());

            this.viewer.select(result.item.panelDbId, this.customZShapedPanelsModelEditor.model);
        }

        return result;
    }

    private async onCustomZShapedPanelCreateRequest(
        event: IApplicationEvent<CustomZShapedPanelPlacementRequestPayload>,
    ) {
        const undo = () => {
            if (savedInternalId) {
                const panel = this.customZShapedPanelsModelEditor.findCustomZShapedPanelByInternalId(savedInternalId);
                if (panel) {
                    this.removeCustomZShapedPanel(panel);
                }
            }
        };

        const redo = async () => {
            await this.createCustomZShapedPanel({ ...event.payload, internalId: savedInternalId });
        };

        const result = await this.createCustomZShapedPanel(event.payload);
        const savedInternalId = result.item?.internalId;

        if (result.item) {
            eventBus.dispatchEvent({
                type: "Dextall.UndoRedo.PushAction",
                payload: {
                    action: { name: "Add custom z-shaped panel", undo, redo },
                },
            });
        }
    }

    private async removeCustomZShapedPanel(panel: CustomZShapedPanel) {
        this.viewer.select([]);

        const result = await this.customZShapedPanelsModelEditor.removePanel(panel);

        if (!result.isSuccess) {
            this.notifyError(result.message);
        }
    }

    private async removeCustomZShapedPanelWithUndo(panel: CustomZShapedPanel) {
        const savedInternalId = panel.internalId;
        const panelData: CustomZShapedPanelPlacementRequestPayload = {
            wallCornerId: panel.wallCornerId,
            shelfEndWallCornerId: panel.shelfEndWallCornerId,
            customZShapedPanelTypeId: panel.customPanelTypeId,
            heightIndex: panel.heightIndex,
            offset: panel.offset,
            single: true,
        };

        const undo = async () => {
            await this.createCustomZShapedPanel({ ...panelData, internalId: savedInternalId });
        };

        const redo = () => {
            if (savedInternalId) {
                const panel = this.customZShapedPanelsModelEditor.findCustomZShapedPanelByInternalId(savedInternalId);
                if (panel) {
                    this.removeCustomZShapedPanel(panel);
                }
            }
        };

        eventBus.dispatchEvent({
            type: "Dextall.UndoRedo.PushAction",
            payload: {
                action: { name: "Remove custom z-shaped panel", undo, redo },
            },
        });
        await this.removeCustomZShapedPanel(panel);
    }

    private async onEntityRemoveRequested() {
        const selectedDbId = this.viewer
            .getAggregateSelection()
            .find(x => x.model.id === this.customZShapedPanelsModelEditor.model.id)?.selection[0];

        const panel = selectedDbId !== undefined
            ? this.customZShapedPanelsModelEditor.findCustomPanel(selectedDbId)
            : undefined;

        if (!panel) {
            return;
        }

        await this.removeCustomZShapedPanelWithUndo(panel);
    }

    private onViewToolChanged(event: { toolName: string; active: boolean }) {
        if (event.toolName !== this.customPanelsPlacementTool.getName())
            return;

        const newState = event.active
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleCustomZShapePanelsPlacementButton?.setState(newState);
    }

    private onEscape() {
        const toolName = this.customPanelsPlacementTool.getName();

        const toolController = this.viewer.toolController;

        if (toolController.isToolActivated(toolName))
            toolController.deactivateTool(toolName);
    }

    private isCustomPanelPlacementActive() {
        return this.viewer.toolController.isToolActivated(this.customPanelsPlacementTool.getName());
    }

    private raiseSelectionChanged(elementDbId: number | undefined, skipGizmoUpdate = false) {
        const panel = elementDbId !== undefined ? this.customZShapedPanelsModelEditor.findCustomPanel(elementDbId) : undefined;

        eventBus.dispatchEvent({
            type: "Dextall.CustomZShapedPanels.SelectionChanged",
            payload: panel !== undefined
                ? { panel, customPanelTypes: this.customZShapedPanelsModelEditor.findCustomTypesForPanel(panel.id) }
                : null
        });

        this.editorPanel?.setVisible(panel !== undefined);

        if (skipGizmoUpdate)
            return;

        if (panel)
            this.offsetGizmoTool.setPanel(panel);
        else
            this.offsetGizmoTool.disposeGizmo();
    }

    private notifyError(message: string) {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message
        })
    }
}

export const forgeCustomZShapedPanelsExtensionName = "Dextall.ForgeCustomZShapedPanelsEditorExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeCustomZShapedPanelsExtensionName,
    ForgeCustomZShapedPanelsEditorExtension,
);
