import { FindObjectByIdPanel } from "../docking-panels/findObjectByIdPanel";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { CustomCornersModelEditor } from "../editors/customCornersModelEditor";
import { CustomPanelsModelEditor } from "../editors/customPanelsModelEditor";
import { CustomZShapedPanelsModelEditor } from "../editors/customZShapedPanelsModelEditor";
import { PanelsModelEditor } from "../editors/panelsModelEditor";
import { Corner } from "../panels/corner";
import { CustomCorner } from "../panels/customCorner";
import { CustomPanel } from "../panels/customPanel";
import { CustomZShapedPanel } from "../panels/customZShapedPanel";
import { Panel } from "../panels/panel";
import { settingsToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

type IdRegexGroups = {
    type: "DXP" | "DXC" | "CDXP" | "CDXC" | "CDXZ";
    id: string;
}

type ModelPanel = Panel | Corner | CustomPanel | CustomCorner | CustomZShapedPanel;

export type ForgeSearchElementsByIdExtensionLoadOptions = {
    panelsEditor: PanelsModelEditor;
    cornersEditor: CornersModelEditor;
    customPanelsEditor: CustomPanelsModelEditor;
    customCornersEditor: CustomCornersModelEditor;
    customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
};

export class ForgeSearchElementsByIdExtension extends Autodesk.Viewing.Extension {
    private readonly panelsEditor: PanelsModelEditor;
    private readonly cornersEditor: CornersModelEditor;
    private readonly customPanelsEditor: CustomPanelsModelEditor;
    private readonly customCornersEditor: CustomCornersModelEditor;
    private readonly customZShapedPanelsModelEditor: CustomZShapedPanelsModelEditor;
    private readonly idRegex = /^(?<type>(DXP|DXC|CDXP|CDXC|CDXZ))-(?<id>[\d]+)$/
    private toggleObjectsSearchByIdPanelButton: Autodesk.Viewing.UI.Button | null = null;
    private searchByIdPanel: FindObjectByIdPanel | null = null;
    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeSearchElementsByIdExtensionLoadOptions) {
        super(viewer, options);

        this.panelsEditor = options.panelsEditor;
        this.cornersEditor = options.cornersEditor;
        this.customPanelsEditor = options.customPanelsEditor;
        this.customCornersEditor = options.customCornersEditor;
        this.customZShapedPanelsModelEditor = options.customZShapedPanelsModelEditor;

        this.toggleSearchById = this.toggleSearchById.bind(this);
        this.onSearchRequested = this.onSearchRequested.bind(this);
        this.onToggleSearchByIdPanelVisibilityChanged = this.onToggleSearchByIdPanelVisibilityChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
    }

    load() {
        eventBus.addEventListener("Dextall.SearchByIdTool.SearchRequested", this.onSearchRequested);
        eventBus.addEventListener("Dextall.SearchByIdTool.VisibilityChanged", this.onToggleSearchByIdPanelVisibilityChanged);

        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        return true;
    }

    unload() {
        eventBus.removeEventListener("Dextall.SearchByIdTool.SearchRequested", this.onSearchRequested);
        eventBus.removeEventListener("Dextall.SearchByIdTool.VisibilityChanged", this.onToggleSearchByIdPanelVisibilityChanged);

        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        this.searchByIdPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        this.searchByIdPanel = new FindObjectByIdPanel(this.viewer.container);

        const toolbar = this.viewer.toolbar;

        const group = toolbar.getControl(settingsToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group)
            return;

        this.toggleObjectsSearchByIdPanelButton = new Autodesk.Viewing.UI.Button("dextall-editor-search-object-by-id");

        this.toggleObjectsSearchByIdPanelButton.setToolTip("Find by id");
        this.toggleObjectsSearchByIdPanelButton.setIcon("viewer-find-object-by-id");
        this.toggleObjectsSearchByIdPanelButton.addEventListener("click", this.toggleSearchById);

        group.addControl(this.toggleObjectsSearchByIdPanelButton, { index: 0 });
    }

    private toggleSearchById() {
        if (!this.searchByIdPanel)
            return;

        this.searchByIdPanel.setVisible(!this.searchByIdPanel.isVisible);
    }

    private reportSearchFailure() {
        eventBus.dispatchEvent({
            type: "Dextall.SearchByIdTool.SearchFailed",
            payload: null
        });
    }

    private onSearchRequested(event: IApplicationEvent<string>) {
        const match = event.payload.toUpperCase().match(this.idRegex);

        const matchGroups = match?.groups as IdRegexGroups | undefined;

        const searchResult = this.trySearch(matchGroups, event.payload);

        if (!searchResult) {
            this.reportSearchFailure();
            return;
        }

        const model = this.viewer.getAllModels().find(x => x.id === this.panelsEditor.modelId)!;

        this.viewer.select(searchResult.panelDbId, model);
    }

    private trySearch(userIdResults: IdRegexGroups | undefined, searchString: string): ModelPanel | undefined {
        if (userIdResults?.type === "DXP") {
            const panel = this.panelsEditor.findPanelByUserId(parseInt(userIdResults.id));

            if (panel)
                return panel;
        }

        if (userIdResults?.type === "DXC") {
            const corner = this.cornersEditor.findCornerByUserId(parseInt(userIdResults.id));

            if (corner)
                return corner;
        }

        if (userIdResults?.type === "CDXP") {
            const customPanel = this.customPanelsEditor.findPanelByUserId(parseInt(userIdResults.id));

            if (customPanel)
                return customPanel;
        }

        if (userIdResults?.type === "CDXC") {
            const customCorner = this.customCornersEditor.findCustomCornerByUserId(parseInt(userIdResults.id));

            if (customCorner)
                return customCorner;
        }

        if (userIdResults?.type === "CDXZ") {
            const customPanel = this.customZShapedPanelsModelEditor.findCustomPanelByUserId(parseInt(userIdResults.id));

            if (customPanel)
                return customPanel;
        }

        return this.panelsEditor.findPanelByTypeName(searchString)
            || this.panelsEditor.findPanelByCustomTypeName(searchString)
            || this.cornersEditor.findCornerByTypeName(searchString)
            || this.cornersEditor.findCornerByCustomTypeName(searchString)
            || this.customPanelsEditor.findPanelByTypeName(searchString)
            || this.customCornersEditor.findCustomCornerByTypeName(searchString)
            || this.panelsEditor.findPanelByElementName(searchString)
            || this.cornersEditor.findCornerByElementName(searchString)
            || this.customPanelsEditor.findPanelByElementName(searchString)
            || this.customCornersEditor.findCustomCornerByElementName(searchString)
            || this.customZShapedPanelsModelEditor.findCustomPanelByTypeName(searchString)
            || this.customZShapedPanelsModelEditor.findCustomPanelByElementName(searchString);
    }

    private onToggleSearchByIdPanelVisibilityChanged(event: IApplicationEvent<boolean>) {
        const newButtonState = event.payload
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleObjectsSearchByIdPanelButton?.setState(newButtonState);
    }

    private onEscape() {
        this.searchByIdPanel?.setVisible(false);
    }
}

export const forgeSearchElementsByIdExtensionName = "Dextall.ForgeSearchElementsByIdExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeSearchElementsByIdExtensionName,
    ForgeSearchElementsByIdExtension,
);
