import React from "react"
import { CornerHookType } from "../../../../../responses/hookSource"

type Props = {
    selectedCornerSide: Exclude<CornerHookType, CornerHookType.None>
    onToggleCornerSide: (side: Exclude<CornerHookType, CornerHookType.None>) => void;
}

export const Corner2DSideSelector = ({ selectedCornerSide, onToggleCornerSide }: Props) => {
    return <div style={{ display: "flex", flexDirection: "row", marginLeft: 17 }}>
        <div style={selectedCornerSide === CornerHookType.CornerLeftWing ? selectedStyle : style} onClick={() => onToggleCornerSide(CornerHookType.CornerLeftWing)}>Left</div>
        <div style={selectedCornerSide === CornerHookType.CornerRightWing ? selectedStyle : style} onClick={() => onToggleCornerSide(CornerHookType.CornerRightWing)}>Right</div>
    </div>
}

const style: React.CSSProperties = { fontSize: "16px", lineHeight: "20px", fontWeight: 700, color: "#A2A2A2", cursor: "pointer", marginRight: 17 }
const selectedStyle: React.CSSProperties = { fontSize: "16px", lineHeight: "20px", fontWeight: 700, color: "#333333", marginRight: 17 }