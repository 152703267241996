import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import eventBus from "../eventBus/eventDispatcher";

export type ForgeStickyNotesQRCodesExtensionLoadOptions = Record<string, never>;

export class ForgeStickyNotesQRCodesExtension extends Autodesk.Viewing.Extension {
    private generatePanelsStickyNotesButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeStickyNotesQRCodesExtensionLoadOptions) {
        super(viewer, options);
        this.generateStickyNotes = this.generateStickyNotes.bind(this);
    }

    load() {
        return true;
    }

    unload() {
        return true;
    }

    onToolbarCreated(): void {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.generatePanelsStickyNotesButton = new Autodesk.Viewing.UI.Button("dextall-generate-model-sticky-notes-button");
        this.generatePanelsStickyNotesButton.setToolTip("Generate QR-codes for the entire model");
        this.generatePanelsStickyNotesButton.setIcon("viewer-qr-codes-button");

        this.generatePanelsStickyNotesButton.addEventListener("click", this.generateStickyNotes);

        group.addControl(this.generatePanelsStickyNotesButton);
    }

    private generateStickyNotes() {
        eventBus.dispatchEvent({
            type: "Dextall.QRCode.Generate",
            payload: null
        });
    }
}

export const forgeStickyNotesQRCodesExtensionName = "Dextall.ForgeStickyNotesQRCodesExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(
    forgeStickyNotesQRCodesExtensionName,
    ForgeStickyNotesQRCodesExtension,
);
