import React, { useState } from "react";
import { ChevronDown, Download, RotateCcw, Settings } from "react-feather";
import { PanelTypeGenerationStatus } from "../../../../../responses/panelGeneratedModelDto";
import { styles } from "../../../../../assets/generationsListStyles";
import { PanelGroup } from "../../../../../responses/modelsGenerationsListGroups";

interface GroupSectionProps {
    group: PanelGroup;
    isSelected: boolean;
    onPanelTypeClick: () => void;
    onGenerateSinglePanel: () => void;
    onDownloadPanelModel: () => void;
    children: React.ReactNode;
}

const GroupSection: React.FC<GroupSectionProps> = ({ group, isSelected, onPanelTypeClick, onGenerateSinglePanel, onDownloadPanelModel, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleGroup = () => setIsExpanded(prevState => !prevState);

    const handleButtonClick = () => {
        if (group.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully)
            onDownloadPanelModel();
        else
            onGenerateSinglePanel();
    };

    return (
        <div>
            <h5 style={styles.groupTitle}>
                <span
                    style={{
                        ...styles.groupHeader,
                        fontWeight: isSelected || group.isSelected ? "bold" : "normal",
                    }}
                    onClick={() => {
                        onPanelTypeClick();
                    }}
                >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={styles.groupName}>
                            {group.name}
                        </span>
                        <span style={{ marginLeft: '3px' }}>
                            ({group.panels.length})
                        </span>
                    </span>
                </span>
                <span style={styles.statusWrapper}>
                    {group.status === PanelTypeGenerationStatus.ModelGenerationFailed && <span style={{ ...styles.statusBox, ...styles.statusFailed, cursor: "pointer" }}>Failed</span>}
                    {group.status === PanelTypeGenerationStatus.None && <span style={styles.statusBox}>None</span>}
                    {group.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully && <span style={{ ...styles.statusBox, ...styles.statusGenerated }}>Generated</span>}
                    {group.status === PanelTypeGenerationStatus.ModelGenerationInProgress && <span style={{ ...styles.statusBox, ...styles.statusInProgress }}>In Progress..</span>}
                    {group.status === null && <span>Info Loading..</span>}
                </span>
                <p onClick={handleButtonClick} style={{ cursor: "pointer" }}>
                    {group.status === PanelTypeGenerationStatus.ModelGenerationFailed && <RotateCcw size={16} color={"lightgray"} />}
                    {group.status === PanelTypeGenerationStatus.None && <Settings size={16} color={"#0796D8"} />}
                    {group.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully && <Download size={16} color={"#0796D8"} />}
                    {group.status === PanelTypeGenerationStatus.ModelGenerationInProgress && <Download size={16} color="gray" style={{ cursor: "not-allowed" }} />}
                </p>
                <ChevronDown size={16} style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)', ...styles.chevron }} onClick={toggleGroup} />
            </h5>
            {isExpanded && children}
        </div>
    );
};

export default GroupSection;
