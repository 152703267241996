import { ICustomCornerType } from "../../../responses/customPanelTypes";
import { IModelCorner } from "../../../responses/modelCorner";
import { ICornerSelectionAimGeometryMaterials } from "../geometry/cornerSelectionAimGeometryMaterials";
import { ViewerOverlayCornerSelectorGeometry } from "../geometry/viewerOverlayCornerSelectorGeometry";
import { newCornerToolCursor, placePanelToolCursor } from "./toolCursors";
import { ToolInterface } from "./toolInterface";
import eventBus from "../eventBus/eventDispatcher";

export const newCustomCornerPlacementToolName = "dextall-editor-custom-corner-placement-tool";
const overlayName = "dextall-editor-new-custom-corner-overlay";

export class CustomCornerPlacementTool extends ToolInterface {
    private readonly cornerSelectors: ViewerOverlayCornerSelectorGeometry[] = [];
    private viewer: Autodesk.Viewing.Viewer3D | null = null;
    private selectedCustomType: ICustomCornerType | null = null;
    private activeCorner: ViewerOverlayCornerSelectorGeometry | null = null;
    private suspended = false;

    constructor() {
        super();

        this.names = [newCustomCornerPlacementToolName];
    }

    debugCornerDirections = false;

    setCorners(modelCorners: IModelCorner[], selectedCustomType: ICustomCornerType) {
        if (this.viewer === null)
            throw new Error("Invalid state! The tool MUST be activated before setting the corners!");

        this.selectedCustomType = selectedCustomType;

        this.cornerSelectors.length = 0;

        const materials = this.createCornerAimMaterials();

        for (const modelCorner of modelCorners) {
            for (let i = 0; i < modelCorner.heights.length - 1; ++i) {
                const viewerCorner = new ViewerOverlayCornerSelectorGeometry(this.viewer, modelCorner, i, materials, overlayName, this.debugCornerDirections);

                viewerCorner.draw();

                this.cornerSelectors.push(viewerCorner);
            }
        }
    }

    activate(_name: string, viewer: Autodesk.Viewing.Viewer3D) {
        this.viewer = viewer;
        this.viewer.impl.createOverlayScene(overlayName);
        this.viewer.select([]);
        this.suspended = false;
        this.activeCorner = null;
    }

    deactivate() {
        this.viewer?.impl.removeOverlayScene(overlayName);
        this.viewer = null;
        this.cornerSelectors.length = 0;
        this.selectedCustomType = null;
        this.activeCorner = null;
    }

    getPriority() {
        return 42; // Use any number higher than 0 (the priority of all default tools)
    }

    getCursor(): string | null {
        return this.activeCorner ? placePanelToolCursor : newCornerToolCursor;
    }

    handleButtonDown(_event: MouseEvent, _button: number) {
        this.suspended = true;
        return false;
    }

    handleButtonUp(_event: MouseEvent, _button: number) {
        this.suspended = false;
        return false;
    }

    handleMouseMove(event: MouseEvent) {
        if (this.suspended)
            return false;

        const newActiveCorner = this.findCorner(event.canvasX, event.canvasY);

        if (newActiveCorner === this.activeCorner || (newActiveCorner === null && this.activeCorner === null))
            return true;

        if (this.activeCorner)
            this.activeCorner.setSelected(false);

        if (newActiveCorner) {
            newActiveCorner.setSelected(true);

            this.activeCorner = newActiveCorner;

        } else
            this.activeCorner = null;

        this.viewer?.impl.invalidate(false, false, true);

        return true;
    }

    handleSingleClick(event: MouseEvent, button: number): boolean {
        if (!(this.activeCorner && this.selectedCustomType && button === 0))
            return true;

        eventBus.dispatchEvent({
            type: "Dextall.CustomCorners.CreateNew",
            payload: {
                wallCornerId: this.activeCorner.corner.id,
                customCornerTypeId: this.selectedCustomType.id,
                heightIndex: this.activeCorner.heightIndex,
                offset: 0,
                single: !event.ctrlKey
            }
        });

        return true;
    }

    handleKeyDown(event: KeyboardEvent, _keyCode: number) {
        return event.code === "ControlLeft" || event.code === "ControlRight";
    }

    private findCorner(clientX: number, clientY: number): ViewerOverlayCornerSelectorGeometry | null {
        if (!this.viewer)
            throw new Error("Invalid state");

        const viewportVector = this.viewer.impl.clientToViewport(clientX, clientY);

        const ray = new THREE.Ray();

        this.viewer.impl.viewportToRay(viewportVector, ray)

        return this.cornerSelectors.find(x => x.intersects(ray)) || null;
    }

    private createCornerAimMaterials(): ICornerSelectionAimGeometryMaterials {
        if (!this.viewer)
            throw new Error("Invalid state");

        const presetIndex = Math.max(0, this.viewer.impl.currentLightPreset());
        const bgColor = Autodesk.Viewing.Private.LightPresets[presetIndex].bgColorGradient;
        // TODO: these calculations can lead to float colors, which are ignored by three.js and instead interpreted as white
        // In r125 the float colors generate an "Unknown color" warning
        const lineColor = "rgb(" + (255 - bgColor[0]) + "," + (255 - bgColor[1]) + "," + (255 - bgColor[2]) + ")";

        return {
            cornerPointMaterial: new THREE.MeshBasicMaterial({ color: 0x0000ff }),
            selectedCornerPointMaterial: new THREE.MeshBasicMaterial({ color: 0xff0000 }),
            planeMaterial: new THREE.MeshBasicMaterial({
                opacity: 0,
                color: 0x287EEA,
                side: THREE.DoubleSide,
                depthTest: false,
                depthWrite: false,
                transparent: true
            }),
            lineMaterial: new THREE.LineBasicMaterial({ color: lineColor, linewidth: 1, depthTest: false, depthWrite: false, transparent: true })
        }
    }
}