import { useEffect, useState } from "react";
import { CustomPanelType, CustomPanelTypeShape } from "../../../responses/customPanelTypes";
import { ComponentsLibraryDialog } from "../../custom-components-library/componentsLibraryDialog";
import { ProcessingPane } from "../../processing-pane/processingPane";
import eventBus from "../eventBus/eventDispatcher";

type Props = {
    customPanelTypes: CustomPanelType[]
};

export const CustomZShapedPanelComponentSelector = ({ customPanelTypes }: Props) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const [customPanelCreationInProgress, setCustomPanelCreationInProgress] = useState(false);

    useEffect(() => {
        const onOpenLibrary = () => setDialogOpened(true);
        const onCustomPanelCreationPrompt = () => setCustomPanelCreationInProgress(true);
        const onCustomPanelCreated = () => setCustomPanelCreationInProgress(false);

        eventBus.addEventListener("Dextall.CustomZShapedPanels.OpenLibrarySelector", onOpenLibrary);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.CreateNew", onCustomPanelCreationPrompt);
        eventBus.addEventListener("Dextall.CustomZShapedPanels.Created", onCustomPanelCreated);

        return () => {
            eventBus.removeEventListener("Dextall.CustomZShapedPanels.OpenLibrarySelector", onOpenLibrary);
            eventBus.removeEventListener("Dextall.CustomZShapedPanels.CreateNew", onCustomPanelCreationPrompt);
            eventBus.removeEventListener("Dextall.CustomZShapedPanels.Created", onCustomPanelCreated);
        };
    }, []);

    const selectLibraryComponent = (component: CustomPanelType) => {
        if (component.shapeType !== CustomPanelTypeShape.ZShape) {
            throw new Error("Invalid state!");
        }

        setDialogOpened(false);

        eventBus.dispatchEvent({
            type: "Dextall.CustomZShapedPanels.PromptPlacement",
            payload: component,
        });
    };

    return <>
        {dialogOpened && <ComponentsLibraryDialog
            customPanelTypes={customPanelTypes}
            closeDialog={() => setDialogOpened(false)}
            selectLibraryComponent={selectLibraryComponent} />}
        {customPanelCreationInProgress &&
            <ProcessingPane title="Custom corner" message="Creating a custom z-panel..." isVisible={true} />}
    </>;
};