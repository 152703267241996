import { Error16 } from "@weave-design/icons";
import { GenerateNewRevitFacadeModelControls } from "./generateNewRevitFacadeModelControls";

export const GeneratedFacadeModelFailed = () => {
    return <>
        <div className="generated-revit-model-content-button-container">
            <Error16 className="errorIcon" />
        </div>

        <div className="generated-revit-model-content-button-container">
            <span style={{ color: "red" }}>Generation failed</span>
        </div>

        <GenerateNewRevitFacadeModelControls isRegenerate={true} />
    </>;
};