import { measureToolsToolbarGroupId, undoRedoToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { UndoRedo } from "../userActions/undoRedo";
import { UndoRedoToolbar } from "../userActions/undoRedoToolbar";
import { IPushUndoableActionEventPayload } from "../eventBus/undoRedoActionsEventsPayloads";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export type ForgeUndoRedoExtensionLoadOptions = Record<string, never>;

export class ForgeUndoRedoExtension extends Autodesk.Viewing.Extension {
    private readonly undoRedo = new UndoRedo();
    private undoRedoToolbar: UndoRedoToolbar | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeUndoRedoExtensionLoadOptions) {
        super(viewer, options);

        this.undo = this.undo.bind(this);
        this.redo = this.redo.bind(this);
        this.onPushAction = this.onPushAction.bind(this);
    }

    load() {
        eventBus.addEventListener("Dextall.UndoRedo.PushAction", this.onPushAction);
        eventBus.addEventListener("Dextall.UndoRedo.Undo", this.undo);
        eventBus.addEventListener("Dextall.UndoRedo.Redo", this.redo);

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(undoRedoToolsToolbarGroupId) as Autodesk.Viewing.UI.ControlGroup | undefined;

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as
                | Autodesk.Viewing.UI.ControlGroup
                | undefined;

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(undoRedoToolsToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.undoRedoToolbar = UndoRedoToolbar.create(this.undoRedo, group, "dextall-editor");
    }

    unload() {
        this.undoRedoToolbar?.dispose();

        eventBus.removeEventListener("Dextall.UndoRedo.PushAction", this.onPushAction);
        eventBus.removeEventListener("Dextall.UndoRedo.Undo", this.undo);
        eventBus.removeEventListener("Dextall.UndoRedo.Redo", this.redo);

        return true;
    }

    private onPushAction(event: IApplicationEvent<IPushUndoableActionEventPayload>) {
        this.undoRedo.push(event.payload.action);
    }

    private async redo() {
        await this.undoRedo.redo();
    }

    private async undo() {
        await this.undoRedo.undo();
    }
}

export const forgeUndoRedoExtensionName = "Dextall.ForgeUndoRedoExtension" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeUndoRedoExtensionName, ForgeUndoRedoExtension);
