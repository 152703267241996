import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import Button from "@weave-design/button";
import { StickyNoteSourceType } from "../../../../responses/stickyNote";
import { ProcessingPane } from "../../../processing-pane/processingPane";
import { rootElement } from "../../../shared";
import { generatePanelQrCode } from "../../panels/stickyNoteGenerator";
import eventBus from "../../eventBus/eventDispatcher";

type Props = {
    id: string;
    type: StickyNoteSourceType;
};

export const PanelStickyNote = ({ id, type }: Props) => {
    const [inProgress, setInProgress] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    const notifyError = (message: string) => {
        eventBus.dispatchEvent({
            type: "Dextall.Common.Notify.Error",
            payload: message,
        });
    };

    const downloadQrCode = (downloadableUrl: string) => {
        eventBus.dispatchEvent({
            type: "Dextall.QRCode.Download",
            payload: downloadableUrl,
        });
    };

    const generateQrCode = async () => {
        setInProgress(true);
        abortControllerRef.current = new AbortController();

        const result = await generatePanelQrCode(type, id, abortControllerRef.current.signal);

        setInProgress(false);

        if (result === null) {
            return;
        } else if (result.isSuccess) {
            downloadQrCode(result.item);
        } else {
            notifyError(result.message);
        }
    };

    const cancelGeneration = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setInProgress(false);
    };

    return (
        <div style={{ display: "flex", paddingTop: "16px", flexFlow: "column" }}>
            <div style={{ alignSelf: "flex-end", flexFlow: "row" }}>
                <Button
                    size="standard"
                    type="secondary"
                    title="QR"
                    onClick={generateQrCode}
                    icon={<img src={"/qr.svg"} alt="qr" width={24} height={24} />}
                    style={{ width: 110 }}
                />
            </div>
            {inProgress &&
                ReactDOM.createPortal(
                    <ProcessingPane
                        isVisible={true}
                        message="Generating QR code..."
                        title="QR"
                        onClose={cancelGeneration}
                    />,
                    rootElement,
                )}
        </div>
    );
};
