import { HookEditorDockingPanel } from "../docking-panels/hookEditorDockingPanel";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { HooksModelEditor } from "../editors/hooksModelEditor";
import { PanelsModelEditor } from "../editors/panelsModelEditor";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { IModelHook } from "../panels/modelHook";
import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { NewHookPlacementTool } from "../viewer-tools/newHookPlacementTool";
import { PanelHookGizmoTool } from "../viewer-tools/panelHookGizmoTool";
import { IViewerAggregateSelectionChangedEventPayload } from "../viewer-utils/viewerEventPayloads";
import { ViewerToolChangedEventType } from "../viewer-utils/viewerToolChangedEventType";
import {
    CreateCornerPanelHookEventPayload,
    CreatePanelHookEventPayload,
} from "../eventBus/hooksPlacementEventPayloads";
import { PanelTypeSwitchedEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";
import "../tools.css";

export type ForgeHooksEditorLoadOptions = {
    hooksEditor: HooksModelEditor;
    panelsEditor: PanelsModelEditor;
    cornersEditor: CornersModelEditor;
};

export class ForgeHooksEditor extends Autodesk.Viewing.Extension {
    private readonly hooksEditor: HooksModelEditor;
    private readonly newHookPlacementTool: NewHookPlacementTool;
    private readonly hooksGizmoTool: PanelHookGizmoTool;
    private toggleNewHookPlacementButton: Autodesk.Viewing.UI.Button | null = null;
    private editorPanel: HookEditorDockingPanel | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeHooksEditorLoadOptions) {
        super(viewer, options);
        this.hooksEditor = options.hooksEditor;
        this.newHookPlacementTool = new NewHookPlacementTool(options.panelsEditor, options.cornersEditor, this.hooksEditor);
        this.hooksGizmoTool = new PanelHookGizmoTool();

        this.onToggleNewHookPlacementTool = this.onToggleNewHookPlacementTool.bind(this);
        this.onEscape = this.onEscape.bind(this);
        this.onRemoveItemRequested = this.onRemoveItemRequested.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onHookChanged = this.onHookChanged.bind(this);
        this.onCreateHooksGeometry = this.onCreateHooksGeometry.bind(this);
        this.onDragHook = this.onDragHook.bind(this);
        this.onUpdateHooksGeometry = this.onUpdateHooksGeometry.bind(this);
        this.onChangeHooksDefaultOffset = this.onChangeHooksDefaultOffset.bind(this);
        this.onRaiseHookChanged = this.onRaiseHookChanged.bind(this);
        this.onCleanupHooksGeometry = this.onCleanupHooksGeometry.bind(this);
        this.unselectHooks = this.unselectHooks.bind(this);
        this.onHookDraggingCompleted = this.onHookDraggingCompleted.bind(this);
        this.onForceHookSelection = this.onForceHookSelection.bind(this);
        this.onPanelTypeSwitched = this.onPanelTypeSwitched.bind(this);
        this.onPanelHookCreationRequested = this.onPanelHookCreationRequested.bind(this);
        this.onCornerPanelHookCreationRequested = this.onCornerPanelHookCreationRequested.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
    }

    load() {
        this.hooksEditor.createHooks();

        this.viewer.toolController.registerTool(this.newHookPlacementTool);
        this.viewer.toolController.registerTool(this.hooksGizmoTool);
        this.viewer.toolController.activateTool(this.hooksGizmoTool.getName());

        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.addEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onRemoveItemRequested);
        eventBus.addEventListener("Dextall.Hooks.UI.PanelHook.ParameterChanged", this.onHookChanged);
        eventBus.addEventListener("Dextall.Hooks.UI.CornerHook.ParameterChanged", this.onHookChanged);
        eventBus.addEventListener("Dextall.Hooks.CreateHooksGeometry", this.onCreateHooksGeometry);
        eventBus.addEventListener("Dextall.Hooks.UpdateHookGeometry", this.onDragHook);
        eventBus.addEventListener("Dextall.Hooks.UpdateHooksGeometry", this.onUpdateHooksGeometry);
        eventBus.addEventListener("Dextall.Hooks.ChangeDefaultOffset", this.onChangeHooksDefaultOffset);
        eventBus.addEventListener("Dextall.Hooks.RaiseHookChanged", this.onRaiseHookChanged);
        eventBus.addEventListener("Dextall.Hooks.CleanupHooksGeometry", this.onCleanupHooksGeometry);
        eventBus.addEventListener("Dextall.Hooks.Unselect", this.unselectHooks);
        eventBus.addEventListener("Dextall.Hooks.ForceSave", this.onHookDraggingCompleted);
        eventBus.addEventListener("Dextall.Hooks.Designer.SelectHook", this.onForceHookSelection);
        eventBus.addEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.addEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.addEventListener("Dextall.Hooks.CreatePanelHookRequested", this.onPanelHookCreationRequested);
        eventBus.addEventListener("Dextall.Hooks.CreateCornerPanelHookRequested", this.onCornerPanelHookCreationRequested);

        return true;
    }

    unload() {
        this.viewer.toolController.deregisterTool(this.newHookPlacementTool);
        this.viewer.toolController.deactivateTool(this.hooksGizmoTool.getName());
        this.viewer.toolController.deregisterTool(this.hooksGizmoTool);

        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);
        this.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.removeEventListener("Dextall.Common.RemoveSelectedEntityRequested", this.onRemoveItemRequested);
        eventBus.removeEventListener("Dextall.Hooks.UI.PanelHook.ParameterChanged", this.onHookChanged);
        eventBus.removeEventListener("Dextall.Hooks.UI.CornerHook.ParameterChanged", this.onHookChanged);
        eventBus.removeEventListener("Dextall.Hooks.CreateHooksGeometry", this.onCreateHooksGeometry);
        eventBus.removeEventListener("Dextall.Hooks.UpdateHookGeometry", this.onDragHook);
        eventBus.removeEventListener("Dextall.Hooks.UpdateHooksGeometry", this.onUpdateHooksGeometry);
        eventBus.removeEventListener("Dextall.Hooks.ChangeDefaultOffset", this.onChangeHooksDefaultOffset);
        eventBus.removeEventListener("Dextall.Hooks.RaiseHookChanged", this.onRaiseHookChanged);
        eventBus.removeEventListener("Dextall.Hooks.CleanupHooksGeometry", this.onCleanupHooksGeometry);
        eventBus.removeEventListener("Dextall.Hooks.Unselect", this.unselectHooks);
        eventBus.removeEventListener("Dextall.Hooks.ForceSave", this.onHookDraggingCompleted);
        eventBus.removeEventListener("Dextall.Hooks.Designer.SelectHook", this.onForceHookSelection);
        eventBus.removeEventListener("Dextall.PanelTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.CornerTypes.PanelTypeSwitched", this.onPanelTypeSwitched);
        eventBus.removeEventListener("Dextall.Hooks.CreatePanelHookRequested", this.onPanelHookCreationRequested);
        eventBus.removeEventListener("Dextall.Hooks.CreateCornerPanelHookRequested", this.onCornerPanelHookCreationRequested);

        this.editorPanel?.shutdown();

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.toggleNewHookPlacementButton = new Autodesk.Viewing.UI.Button("dextall-new-hook");
        this.toggleNewHookPlacementButton.setToolTip("New hook");
        this.toggleNewHookPlacementButton.setIcon("viewer-tools-new-hook");

        this.toggleNewHookPlacementButton.addEventListener("click", this.onToggleNewHookPlacementTool);

        group.addControl(this.toggleNewHookPlacementButton);
        this.editorPanel = new HookEditorDockingPanel(this.viewer.container);
    }

    private onToggleNewHookPlacementTool() {
        const toolController = this.viewer.toolController;
        const toolName = this.newHookPlacementTool.getName();

        const newState = !toolController.isToolActivated(toolName);

        if (newState) {
            this.unselectHooks();
            toolController.activateTool(toolName);
        }
        else
            toolController.deactivateTool(toolName);
    }

    private onEscape() {
        if (!this.viewer.toolController.isToolActivated(this.newHookPlacementTool.getName()))
            return;

        this.onToggleNewHookPlacementTool();
    }

    private onRemoveItemRequested() {
        const selectedHooks = this.viewer
            .getAggregateSelection()
            .filter(x => x.model.id === this.hooksEditor.hooksModelId)
            .flatMap(x => x.selection)
            .map(x => this.hooksEditor.findHook(x))
            .filter((x): x is Exclude<ReturnType<typeof this.hooksEditor.findHook>, undefined> => !!x);

        if (selectedHooks.length === 0)
            return;

        this.unselectHooks();

        for (const hook of selectedHooks)
            this.hooksEditor.removeHook(hook);
    }

    private onSelectionChanged(event: IViewerAggregateSelectionChangedEventPayload) {
        const selectedHook = event.selections
            .filter(x => x.model.id === this.hooksEditor.hooksModelId)
            .flatMap(x => x.dbIdArray)
            .map(x => this.hooksEditor.findHook(x))
            .find(x => x);

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.SelectionChanged",
            payload: selectedHook ? {
                hook: selectedHook,
                panel: this.hooksEditor.findPanel(selectedHook),
                panelType: this.hooksEditor.findPanelType(selectedHook)
            } : null
        });

        this.editorPanel?.setVisible(selectedHook !== undefined);

        if (selectedHook)
            this.hooksGizmoTool.setHook(selectedHook);
        else
            this.hooksGizmoTool.disposeGizmo();
    }

    private onHookChanged(event: IApplicationEvent<IModelHook>) {
        const hook = this.hooksEditor.updateHook(event.payload);

        if (!hook)
            return;

        this.raiseHookChanged(hook);
    }

    private onChangeHooksDefaultOffset(event: IApplicationEvent<number>) {
        this.hooksEditor.updateHooksDefaultOffset(event.payload);
    }

    private onCreateHooksGeometry(event: IApplicationEvent<(Hook | CornerHook)[]>) {
        for (const hook of event.payload) {
            this.hooksEditor.createHookGeometry(hook);
        }
    }

    private unselectHooks() {
        this.viewer.select([]);
    }

    private onCleanupHooksGeometry(event: IApplicationEvent<number[]>) {
        this.unselectHooks();
        for (const dbId of event.payload) {
            this.hooksEditor.cleanupHookGeometry(dbId);
        }
    }

    private onDragHook(event: IApplicationEvent<Hook | CornerHook>) {
        this.hooksEditor.updateHookGeometry(event.payload);
    }

    private onUpdateHooksGeometry(event: IApplicationEvent<(Hook | CornerHook)[]>) {
        for (const hook of event.payload) {
            this.hooksEditor.updateHookGeometry(hook);
        }
    }

    private onHookDraggingCompleted(event: IApplicationEvent<Hook | CornerHook>) {
        this.hooksEditor.save(event.payload);

        this.raiseHookChanged(event.payload);
    }

    private onForceHookSelection(event: IApplicationEvent<Hook | CornerHook>) {
        const model = this.viewer.getAllModels().find(x => x.id === this.hooksEditor.hooksModelId);

        this.viewer.select(event.payload.dbId, model);
    }

    private onPanelTypeSwitched(event: IApplicationEvent<PanelTypeSwitchedEventPayload>) {
        const { panelId, removedHooksDbIds } = event.payload;

        this.hooksEditor.recreatePanelHooks(panelId, removedHooksDbIds);
    }

    private onPanelHookCreationRequested(event: IApplicationEvent<CreatePanelHookEventPayload>) {
        const { panelId, position } = event.payload;

        this.hooksEditor.createNewPanelHook(panelId, position);

        eventBus.dispatchEvent({ type: "Dextall.Hooks.Designer.Refresh", payload: { type: "panel", panelId } });
    }

    private onCornerPanelHookCreationRequested(event: IApplicationEvent<CreateCornerPanelHookEventPayload>) {
        const { panelId, position, onLeftWing } = event.payload;

        this.hooksEditor.createNewCornerHook(panelId, onLeftWing, position);

        eventBus.dispatchEvent({ type: "Dextall.Hooks.Designer.Refresh", payload: { type: "corner", panelId } });
    }

    private onViewToolChanged(event: ViewerToolChangedEventType) {
        if (event.toolName !== this.newHookPlacementTool.getName())
            return;

        const newState = event.active
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.toggleNewHookPlacementButton?.setState(newState);
    }

    private onRaiseHookChanged(event: IApplicationEvent<Hook | CornerHook>) {
        this.raiseHookChanged(event.payload);
    }

    private raiseHookChanged(hook: Hook | CornerHook) {
        eventBus.dispatchEvent({
            type: "Dextall.Hooks.Designer.ModelHookUpdated",
            payload: hook
        });

        const isHookSelected = !!this.viewer.getAggregateSelection()
            .filter(x => x.model.id === this.hooksEditor.hooksModelId)
            .flatMap(x => x.selection)
            .find(x => x === hook.dbId);

        if (isHookSelected)
            this.hooksGizmoTool.setHook(hook);

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.SelectionChanged",
            payload: hook ? {
                hook,
                panel: this.hooksEditor.findPanel(hook),
                panelType: this.hooksEditor.findPanelType(hook)
            } : null
        });
    }
}

export const forgeHooksEditorName = "Dextall.ForgeHooksEditor" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeHooksEditorName, ForgeHooksEditor);
