export interface IPanelGeneratedModelDto {
    id: string;
    typeId: string;
    bubble: string | null;
    status: PanelTypeGenerationStatus;
}

export enum PanelTypeGenerationStatus
{
    None = 0,

    ModelGenerationInProgress = 1,

    ModelGeneratedSuccessfully = 2,

    ModelGenerationFailed = 3,
}