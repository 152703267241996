import { ICustomPanelType } from "../../../responses/customPanelTypes";
import { Category } from "../../../responses/modelsGenerationsListGroups";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { ModelsGenerationListDockingPanel } from "../docking-panels/modelsGenerationListDockingPanel";
import { CornersModelEditor } from "../editors/cornersModelEditor";
import { PanelsModelEditor } from "../editors/panelsModelEditor";
import { fitToViewPanel, fitToViewCorner } from "../focusControl";
import { GeneratedPanelsContent } from "../generationList/generatedPanelsContent";
import { Corner } from "../panels/corner";
import { Panel } from "../panels/panel";
import { hooksToolbarGroupId, measureToolsToolbarGroupId } from "../toolbar/toolbarGroupIds";
import { FitToViewEventPayload, PanelGenerationModelPayload } from "../eventBus/modelsGenerationsListEventPayloads";
import { PanelTypeSelectedEventPayload } from "../eventBus/typesEditionEventsPayload";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";
import repo from "../../../Repository";

export type ForgeModelsGenerationListLoadOptions = {
    panelsEditor: PanelsModelEditor;
    cornersEditor: CornersModelEditor;
    customPanelTypes: ICustomPanelType[];
    model: Autodesk.Viewing.Model;
    generatedModels: IPanelGeneratedModelDto[];
};

export class ForgeModelsGenerationList extends Autodesk.Viewing.Extension {
    private readonly panelsEditor: PanelsModelEditor;
    private readonly cornersEditor: CornersModelEditor;
    private readonly model: Autodesk.Viewing.Model;
    private readonly generatedModels: IPanelGeneratedModelDto[];
    private readonly generatedPanelsContent = new GeneratedPanelsContent();
    private dockingPanel: ModelsGenerationListDockingPanel | null = null;
    private modelsGenerationButton: Autodesk.Viewing.UI.Button | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeModelsGenerationListLoadOptions) {
        super(viewer, options);

        this.panelsEditor = options.panelsEditor;
        this.cornersEditor = options.cornersEditor;
        this.model = options.model;
        this.generatedModels = options.generatedModels;

        this.onToggleModelsGenerationList = this.onToggleModelsGenerationList.bind(this);
        this.onDockingPanelVisibilityChanged = this.onDockingPanelVisibilityChanged.bind(this);
        this.onEscape = this.onEscape.bind(this);
        this.onStartPanelGeneration = this.onStartPanelGeneration.bind(this);
        this.onStartCornerPanelGeneration = this.onStartCornerPanelGeneration.bind(this);
        this.onStartAllModelGenerations = this.onStartAllModelGenerations.bind(this);
        this.onStatusesLoaded = this.onStatusesLoaded.bind(this);
        this.updateCategories = this.updateCategories.bind(this);
        this.getCategoryKey = this.getCategoryKey.bind(this);
        this.onPanelIdClick = this.onPanelIdClick.bind(this);
        this.isPanelType = this.isPanelType.bind(this);
        this.findPanels = this.findPanels.bind(this);
        this.onPanelTypeSelected = this.onPanelTypeSelected.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    load() {
        this.viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.addEventListener("Dextall.Panels.GeneratedModel.GenerationStarted", this.onStartPanelGeneration);
        eventBus.addEventListener("Dextall.Corners.GeneratedModel.GenerationStarted", this.onStartCornerPanelGeneration);
        eventBus.addEventListener("Dextall.GeneratedModel.RequestAllGenerations", this.onStartAllModelGenerations);
        eventBus.addEventListener("Dextall.GeneratedModel.PanelId.Click", this.onPanelIdClick);
        eventBus.addEventListener("Dextall.GeneratedModel.PanelModelsLoaded", this.onStatusesLoaded);
        eventBus.addEventListener("Dextall.PanelTypes.UI.PanelTypeSelected", this.onPanelTypeSelected);
        eventBus.addEventListener("Dextall.Panels.SelectionChanged", this.onSelectionChanged);
        eventBus.addEventListener("Dextall.Corners.SelectionChanged", this.onSelectionChanged);

        if (this.generatedModels && this.generatedModels.length > 0)
            this.updateCategories(this.generatedModels);

        this.generatedPanelsContent.loadAllModels(this.panelsEditor.facadeModelId);

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, this.onEscape);

        eventBus.removeEventListener("Dextall.Panels.GeneratedModel.GenerationStarted", this.onStartPanelGeneration);
        eventBus.removeEventListener("Dextall.Corners.GeneratedModel.GenerationStarted", this.onStartCornerPanelGeneration);
        eventBus.removeEventListener("Dextall.GeneratedModel.RequestAllGenerations", this.onStartAllModelGenerations);
        eventBus.removeEventListener("Dextall.GeneratedModel.PanelId.Click", this.onPanelIdClick);
        eventBus.removeEventListener("Dextall.GeneratedModel.PanelModelsLoaded", this.onStatusesLoaded);
        eventBus.removeEventListener("Dextall.PanelTypes.UI.PanelTypeSelected", this.onPanelTypeSelected);
        eventBus.removeEventListener("Dextall.Panels.SelectionChanged", this.onSelectionChanged);
        eventBus.removeEventListener("Dextall.Corners.SelectionChanged", this.onSelectionChanged);

        this.generatedPanelsContent.abortCheck();

        this.dockingPanel?.shutdown();

        this.modelsGenerationButton?.removeEventListener("click", this.onToggleModelsGenerationList);

        return true;
    }

    onToolbarCreated() {
        const toolbar = this.viewer.toolbar;

        let group = toolbar.getControl(hooksToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

        if (!group) {
            const measureTools = toolbar.getControl(measureToolsToolbarGroupId) as (Autodesk.Viewing.UI.ControlGroup | undefined);

            if (!measureTools)
                toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(measureToolsToolbarGroupId), { index: 1 });

            group = new Autodesk.Viewing.UI.ControlGroup(hooksToolbarGroupId);
            toolbar.addControl(group, { index: toolbar.indexOf(measureToolsToolbarGroupId) });
        }

        this.modelsGenerationButton = new Autodesk.Viewing.UI.Button("dextall-models-generation-list-button");
        this.modelsGenerationButton.setToolTip("Models Generation List");
        this.modelsGenerationButton.setIcon("viewer-editor-models-generation-list");

        this.modelsGenerationButton.addEventListener("click", this.onToggleModelsGenerationList);

        group.addControl(this.modelsGenerationButton);

        this.dockingPanel = new ModelsGenerationListDockingPanel(this.viewer.container, this.onDockingPanelVisibilityChanged);
    }

    private async onSelectionChanged() {
        await this.generatedPanelsContent.loadAllModels(this.panelsEditor.facadeModelId);
    }

    private async onStartPanelGeneration() {
        this.updateCategories(this.generatedModels);

        this.generatedPanelsContent.loadAllModels(this.panelsEditor.facadeModelId);
    }

    private async onStartCornerPanelGeneration() {
        this.generatedPanelsContent.loadAllModels(this.panelsEditor.facadeModelId);
    }

    private async onStartAllModelGenerations() {
        const generationStartResult = await repo.startEntireModelGeneration(this.panelsEditor.facadeModelId);

        if (!generationStartResult.isSuccess) {
            eventBus.dispatchEvent({ type: "Dextall.GeneratedModel.GenerationRequestFailed", payload: generationStartResult.message });

            return;
        }

        for (const panelTypeId of generationStartResult.item.panelTypesIds)
            eventBus.dispatchEvent({
                type: "Dextall.Panels.GeneratedModel.Loaded",
                payload: {
                    model: { id: panelTypeId, bubble: null, status: PanelTypeGenerationStatus.ModelGenerationInProgress },
                    panelTypeId: panelTypeId,
                    drawings: []
                }
            });

        for (const cornerPanelTypeId of generationStartResult.item.cornerPanelTypesIds)
            eventBus.dispatchEvent({
                type: "Dextall.Corners.GeneratedModel.Loaded",
                payload: {
                    model: { id: cornerPanelTypeId, bubble: null, status: PanelTypeGenerationStatus.ModelGenerationInProgress },
                    panelTypeId: cornerPanelTypeId,
                    drawings: []
                }
            });

        this.generatedPanelsContent.loadAllModels(this.panelsEditor.facadeModelId);
    }

    private updateCategories(models: IPanelGeneratedModelDto[]) {
        const panelTypes = this.panelsEditor.panelTypes;
        const cornerPanelTypes = this.cornersEditor.panelTypes;
        const categorizedPanelTypes: Record<string, Category> = {};
    
        const selectedCladdingId = this.viewer.getAggregateSelection().find(x => x.model.id === this.model.id)?.selection[0] || -1;
    
        models.forEach(model => {
            const categoryKey = this.getCategoryKey(model.typeId);
    
            if (!categorizedPanelTypes[categoryKey]) {
                categorizedPanelTypes[categoryKey] = {
                    name: categoryKey,
                    groups: []
                };
            }

            const panelType = categoryKey === "Corner"
                ? cornerPanelTypes.find(panelType => panelType.id === model.typeId)
                : panelTypes.find(panelType => panelType.id === model.typeId);
    
            if (panelType) {
                const panels = this.findPanels(model.typeId);
    
                const isCurrentlySelected = panels.some(panel => panel.panelDbId === selectedCladdingId);
    
                categorizedPanelTypes[categoryKey].groups.push({
                    panelTypeId: model.typeId,
                    name: panelType.name,
                    status: model.status,
                    isPanelModel: this.isPanelType(model.typeId),
                    panels: panels,
                    isSelected: isCurrentlySelected 
                });
            }
        });
        
        eventBus.dispatchEvent({
            type: "Dextall.GeneratedModel.StatusesUpdated",
            payload: { categories: categorizedPanelTypes, models }
        });
    }
    
    getCategoryKey(panelTypeId: string) {
        const panelType = this.panelsEditor.panelTypes.find(panelType => panelType.id === panelTypeId);

        if (!panelType || !panelType.panels || panelType.panels.length === 0) {
            return 'Corner';
        }

        const categoryKey = panelType.panels[0].hasWindows() ? 'Window' : 'Opaque';

        return categoryKey;
    }

    private isPanelType(typeId: string) {
        return this.panelsEditor.panelTypes.some(panelType => panelType.id === typeId);
    }

    private findPanels(typeId: string) {
        return this.isPanelType(typeId) ? this.panelsEditor.panelTypes.find(panelType => panelType.id === typeId)!.panels : this.cornersEditor.panelTypes.find(panelType => panelType.id === typeId)!.panels;
    }

    private onPanelTypeSelected(event: IApplicationEvent<PanelTypeSelectedEventPayload>) {
        const group = event.payload.group;

        const dbIds = group.panels.map(panel => panel.panelDbId);

        this.viewer.select(dbIds[0], this.model);
    }

    private onToggleModelsGenerationList() {
        const newState = !this.dockingPanel?.visible;

        this.dockingPanel?.setVisible(newState);
    }

    private onDockingPanelVisibilityChanged(visibility: boolean) {
        const newState = visibility
            ? Autodesk.Viewing.UI.Button.State.ACTIVE
            : Autodesk.Viewing.UI.Button.State.INACTIVE;

        this.modelsGenerationButton?.setState(newState);
    }

    private onEscape() {
        this.dockingPanel?.setVisible(false);
        this.generatedPanelsContent.abortCheck();
    }

    private zoomToPanel = (panel: Panel, dbId: number | undefined) => {
        fitToViewPanel(this.viewer, panel, this.model);
        this.viewer.select(dbId, this.model);
    };

    private zoomToCorner = (corner: Corner, dbId: number | undefined) => {
        fitToViewCorner(this.viewer, corner, this.model);
        this.viewer.select(dbId, this.model);
    };

    private onPanelIdClick(event: IApplicationEvent<FitToViewEventPayload>) {
        const { panel, dbId, isPanelModel } = event.payload;

        if (isPanelModel)
            this.zoomToPanel(panel as Panel, dbId);
        else
            this.zoomToCorner(panel as Corner, dbId);
    }

    private onStatusesLoaded(event: IApplicationEvent<PanelGenerationModelPayload>) {
        const models = event.payload.models;
        this.updateCategories(models);
    }
}

export const forgeModelsGenerationListName = "Dextall.ForgeModelsGenerationList" as const;

Autodesk.Viewing.theExtensionManager.registerExtension(forgeModelsGenerationListName, ForgeModelsGenerationList);