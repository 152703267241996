import ralColors from "./ralColorsFactory";

class CustomComponentColorsFactory {
    private readonly materials = new Map<string, THREE.ShaderMaterial>();
    private defaultMaterial: THREE.ShaderMaterial | null = null;
    private defaultOverlayMaterial: THREE.ShaderMaterial | null = null;
    private defaultCornerMaterial: THREE.ShaderMaterial | null = null;
    private defaultCornerOverlayMaterial: THREE.ShaderMaterial | null = null;
    private vertexShader: string | null = null;
    private fragmentShader: string | null = null;
    private cornerVertexShader: string | null = null;
    private cornerFragmentShader: string | null = null;

    createMaterial(ralCode: string): THREE.ShaderMaterial {
        const existingMaterial = this.materials.get(ralCode);

        if (existingMaterial)
            return existingMaterial;

        const ralColor = ralColors.getColor(ralCode);

        if (!ralColor)
            throw new Error("Invalid RAL code!");

        const color = new THREE.Color(ralColor);
        const alternativeColor = this.getAlternativeColor(ralCode);

        const material = this.createNewMaterial(color, alternativeColor, `dextall-studio-custom-material ${ralCode}`);

        this.materials.set(ralCode, material);

        return material;
    }

    createCladdingCellMaterial(ralCode: string): THREE.ShaderMaterial {
        const name = `dextall-studio-cladding-cell-material ${ralCode}`

        const existingMaterial = this.materials.get(name);

        if (existingMaterial)
            return existingMaterial;

        const ralColor = ralColors.getColor(ralCode);

        if (!ralColor)
            throw new Error("Invalid RAL code!");

        const color = new THREE.Color(ralColor);
        const alternativeColor = new THREE.Vector4(color.r, color.g, color.b, 1.0);

        const material = this.createNewMaterial(color, alternativeColor, name);

        this.materials.set(name, material);

        return material;
    }

    createCornerCladdingCellMaterial(ralCode: string): THREE.ShaderMaterial {
        const name = `dextall-studio-corner-cladding-cell-material ${ralCode}`

        const existingMaterial = this.materials.get(name);

        if (existingMaterial)
            return existingMaterial;

        const ralColor = ralColors.getColor(ralCode);

        if (!ralColor)
            throw new Error("Invalid RAL code!");

        const color = new THREE.Color(ralColor);
        const alternativeColor = this.getAlternativeColor(ralCode);

        const material = this.createNewMaterial(color, alternativeColor, name, true);

        this.materials.set(name, material);

        return material;
    }

    getDefaultMaterial(): THREE.ShaderMaterial {
        if (!this.defaultMaterial)
            throw new Error("Not initialized!");

        return this.defaultMaterial;
    }

    getDefaultOverlayMaterial(): THREE.ShaderMaterial {
        if (!this.defaultOverlayMaterial)
            throw new Error("Not initialized!");

        return this.defaultOverlayMaterial;
    }

    getDefaultCornerMaterial(): THREE.ShaderMaterial {
        if (!this.defaultCornerMaterial)
            throw new Error("Not initialized!");

        return this.defaultCornerMaterial;
    }

    getDefaultCornerOverlayMaterial(): THREE.ShaderMaterial {
        if (!this.defaultCornerOverlayMaterial)
            throw new Error("Not initialized!");

        return this.defaultCornerOverlayMaterial;
    }

    initialize(vertexShader: string, fragmentShader: string, cornerVertexShader: string, cornerFragmentShader: string) {
        this.vertexShader = vertexShader;
        this.fragmentShader = fragmentShader;
        this.cornerVertexShader = cornerVertexShader;
        this.cornerFragmentShader = cornerFragmentShader;

        this.defaultMaterial = this.createNewMaterial(new THREE.Color(0.85, 0.85, 0), defaultAlternativeColor, "dextall-studio-custom-material-default");
        this.defaultOverlayMaterial = this.createNewMaterial(new THREE.Color(0.75, 0.75, 0), defaultAlternativeColor, "dextall-studio-custom-overlay-material-default");

        this.defaultCornerMaterial = this.createNewMaterial(new THREE.Color(0.85, 0.85, 0), defaultAlternativeColor, "dextall-studio-custom-corner-material-default", true)
        this.defaultCornerOverlayMaterial = this.createNewMaterial(new THREE.Color(0.75, 0.75, 0), defaultAlternativeColor, "dextall-studio-custom-overlay-material-default", true);
    }

    private getAlternativeColor(ralCode: string): THREE.Vector4 {
        return alternativeColorsOverrides.has(ralCode) ? overriddenAlternativeColor : defaultAlternativeColor;
    }

    private createNewMaterial(color: THREE.Color, alternativeColor: THREE.Vector4, name: string, forCorner = false) {
        if (this.vertexShader === null || this.fragmentShader === null || this.cornerVertexShader === null || this.cornerFragmentShader === null)
            throw new Error("Not initialized!");

        // Viewer needs uniform values types
        // see webpack://LMV/src/wgs/render/WebGLRenderer.js loadUniformsGeneric
        const uniforms = {
            textureRotation: { value: textureRotationMatrix, type: "m3" },
            color: { value: new THREE.Vector4(color.r, color.g, color.b, 1.0), type: "v4" },
            alternativeColor: { value: alternativeColor, type: "v4" },
            size: { value: size, type: "f" },
            fogColor: { value: new THREE.Color(1, 1, 1), type: "c" },
            fogDensity: { type: 'f', value: 0.00025 },
            fogFar: { type: 'f', value: 2000 },
            fogNear: { type: 'f', value: 1 },
            exposureBias: { type: "f", value: 0.0078125 },
            envMap: { type: "t", value: null },
            envRotationCos: { type: 'f', value: 1 },
            envRotationSin: { type: 'f', value: 0 },
            reflMipIndex: { type: 'f', value: 0 },
            reflectivity: { type: 'f', value: 1 },
            refractionRatio: { type: "f", value: 0.98 },
            specular: { type: "c", value: new THREE.Color(0x111111) },
            ambientLightColor: { type: "c", value: new THREE.Color(0.25098, 0.25098, 0.25098) },
            irradianceMap: { type: 't', value: null },
            envMapExposure: { type: 'f', value: 1 },
            diffuse: { type: "c", value: color },
            emissive: { type: "c", value: new THREE.Color(0, 0, 0) },
            shininess: { type: "f", value: 7.5 },
            modelId: { "type": "v3", "value": { "x": 0, "y": 0, "z": 0 }, needsUpdate: true },
            dbId: { "type": "v3", "value": { "x": 0, "y": 0, "z": 0 }, needsUpdate: true }
        };

        const material = new THREE.ShaderMaterial({
            uniforms,
            vertexShader: forCorner ? this.cornerVertexShader : this.vertexShader,
            fragmentShader: forCorner ? this.cornerFragmentShader : this.fragmentShader,
            name,
            // @ts-ignore
            color: color,
            emissive: new THREE.Color(0x000000),
            specular: new THREE.Color(0x111111),
            shininess: 30,
            metal: false,
            wrapAround: false,
            wrapRGB: new THREE.Vector3(1, 1, 1),
            map: null,
            lightMap: null,
            bumpMap: null,
            bumpScale: 1,
            normalMap: null,
            normalScale: new THREE.Vector2(1, 1),
            specularMap: null,
            alphaMap: null,
            envMap: null,
            combine: THREE.MultiplyOperation,
            reflectivity: 1,
            refractionRatio: 0.98,
            fog: true,
            shading: THREE.SmoothShading
        });

        material.supportsMrtNormals = true;

        return material;
    }
}

export default new CustomComponentColorsFactory();

const size = 0.5;
// 0.25*PI rotation, there is no .rotate method in Forge THREEJS
const textureRotationMatrix = new THREE.Matrix3().set(0.7071067811865476, -0.7071067811865475, 0, 0.7071067811865475, 0.7071067811865476, 0, 0, 0, 1);
const defaultAlternativeColor = new THREE.Vector4(0, 0, 0, 1);
const overriddenAlternativeColor = new THREE.Vector4(0.85, 0.85, 0, 1);
const alternativeColorsOverrides = new Set([
    "RAL 6003",
    "RAL 6004",
    "RAL 6005",
    "RAL 6006",
    "RAL 6007",
    "RAL 6008",
    "RAL 6009",
    "RAL 6010",
    "RAL 6011",
    "RAL 6012",
    "RAL 6020",
    "RAL 6022",
    "RAL 6036",
    "RAL 7009",
    "RAL 7010",
    "RAL 7011",
    "RAL 7012",
    "RAL 7013",
    "RAL 7014",
    "RAL 7015",
    "RAL 7016",
    "RAL 7017",
    "RAL 7018",
    "RAL 7019",
    "RAL 7020",
    "RAL 7021",
    "RAL 7022",
    "RAL 7023",
    "RAL 7024",
    "RAL 7025",
    "RAL 7026",
    "RAL 7027",
    "RAL 7028",
    "RAL 7029",
    "RAL 7030",
    "RAL 7031",
    "RAL 7043",
    "RAL 8011",
    "RAL 8012",
    "RAL 8013",
    "RAL 8014",
    "RAL 8015",
    "RAL 8016",
    "RAL 8017",
    "RAL 8018",
    "RAL 8019",
    "RAL 8020",
    "RAL 8021",
    "RAL 8022",
    "RAL 8028",
    "RAL 9004",
    "RAL 9005",
    "RAL 9011",
    "RAL 9017",
]);