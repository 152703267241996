import React from "react";
import { CustomPanelType } from "../../responses/customPanelTypes";
import { CustomComponentForgeViewer } from "./customComponentForgeViewer";
import "./customComponentPropertiesEditor.css";

type Props = {
    customComponent: CustomPanelType;
    children: React.ReactNode;
};

export const CustomComponentEditor = ({ customComponent, children: propertiesEditor }: Props) => {
    return <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ width: 30 }}></div>
        <div style={{ width: "calc(100% - 460px)", height: "100%", position: "relative" }}>
            {customComponent.bubbleUrl && <CustomComponentForgeViewer customComponent={customComponent} />}
        </div>
        <div style={{ width: 30 }}></div>
        <div style={{ width: 400, height: "100%", borderLeft: "1px solid" }}>
            <div className="custom-component-properties-container">
                {propertiesEditor}
            </div>
        </div>
    </div >;
};