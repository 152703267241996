import { useEffect, useRef, useState } from "react";
import { generateModelQrCode } from "./forge/panels/stickyNoteGenerator";
import { ProcessingPane } from "./processing-pane/processingPane";
import eventBus from "./forge/eventBus/eventDispatcher";

type Props = {
    id: string;
};

export const AppPanelsStickyNotesGenerator = ({ id }: Props) => {
    const [inProgress, setInProgress] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        const notifyError = (message: string) => {
            eventBus.dispatchEvent({
                type: "Dextall.Common.Notify.Error",
                payload: message,
            });
        };

        const downloadQrCode = (downloadableUrl: string) => {
            eventBus.dispatchEvent({
                type: "Dextall.QRCode.Download",
                payload: downloadableUrl,
            });
        };

        const onGenerateQrCode = async () => {
            setInProgress(true);
            abortControllerRef.current = new AbortController();

            const result = await generateModelQrCode(id, abortControllerRef.current.signal);

            setInProgress(false);

            if (result === null) {
                return;
            } else if (result.isSuccess) {
                downloadQrCode(result.item);
            } else {
                notifyError(result.message);
            }
        };

        eventBus.addEventListener("Dextall.QRCode.Generate", onGenerateQrCode);

        return () => {
            eventBus.removeEventListener("Dextall.QRCode.Generate", onGenerateQrCode);
        };
    });

    const cancelGeneration = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setInProgress(false);
    };

    return <>
        {inProgress && <ProcessingPane
            isVisible={true}
            message="Generating QR code..."
            title="QR"
            onClose={cancelGeneration}
        />}
    </>;
};