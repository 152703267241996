import { isAlmostEqualToZero, roundToDecimalSigns } from "@dextall/shared";
import { ICustomCornerType } from "../../../responses/customPanelTypes";
import { GizmoDirections } from "../custom-component-library/gizmos/customComponentInsertionPointGizmo";
import { CustomComponentInsertionPointGizmoTool } from "../custom-component-library/gizmos/customComponentInsertionPointGizmoTool";
import { CustomComponentViewerNavigatorFactory } from "../custom-component-library/navigation/customComponentViewerNavigatorFactory";
import { CustomComponentViewerType } from "../custom-component-library/navigation/customComponentViewerType";
import { ExtendedSnapper } from "../custom-component-library/snapping/extendedSnapper";
import { libraryLengthsToleranceDecimalSigns } from "../defaults";
import { CornerGeometry } from "@dextall/corner-geometry";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export class ForgeCustomCornerComponentEditorExtension extends Autodesk.Viewing.Extension {
    private readonly customComponentViewerType: CustomComponentViewerType;
    private boundsMaterial: THREE.MeshBasicMaterial | null = null;
    private gizmoTool: CustomComponentInsertionPointGizmoTool | null = null;
    private centerPointMesh: THREE.Mesh | null = null;
    private cornerPointMesh: THREE.Mesh | null = null;
    private rightWingPointMesh: THREE.Mesh | null = null;
    private customComponent: ICustomCornerType;
    private snapper: ExtendedSnapper | null = null;
    private sectionActive = false;
    private sectionToolButton: Autodesk.Viewing.UI.Button | null = null;
    private showBoundsButton: Autodesk.Viewing.UI.Button | null = null;
    private boundsGeometryCalculator: CornerGeometry | null = null;
    private boundsMesh: THREE.Mesh | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
        super(viewer, options);

        this.customComponentViewerType = options.customComponentViewerType;
        this.customComponent = { ...options.customComponent };

        this.onModelAdded = this.onModelAdded.bind(this);
        this.onExtensionLoaded = this.onExtensionLoaded.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onCustomComponentPropertiesChanged = this.onCustomComponentPropertiesChanged.bind(this);
        this.onGizmoPositionUpdated = this.onGizmoPositionUpdated.bind(this);
        this.onToggleSection = this.onToggleSection.bind(this);
        this.onToggleBounds = this.onToggleBounds.bind(this);
    }

    load() {
        this.boundsMaterial = new THREE.MeshBasicMaterial({ color: "#757E82", opacity: 0.9 });
        this.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.onModelAdded, { once: true });
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Corner.UI.Changed", this.onCustomComponentPropertiesChanged);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed", this.onGizmoPositionUpdated);

        this.createToolbar();

        return true;
    }

    unload() {
        this.viewer.impl.removeOverlayScene(overlayName);
        this.centerPointMesh = null;
        this.cornerPointMesh = null;
        this.rightWingPointMesh = null;
        this.boundsGeometryCalculator = null;
        this.boundsMesh = null;
        this.boundsMaterial = null;
        this.snapper?.dispose();
        this.snapper = null;

        //@ts-ignore
        this.customComponent = null;

        this.sectionToolButton?.removeEventListener("click", this.onToggleSection);
        this.sectionToolButton = null;

        this.showBoundsButton?.removeEventListener("click", this.onToggleBounds);
        this.showBoundsButton = null;

        this.viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Corner.UI.Changed", this.onCustomComponentPropertiesChanged);
        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed", this.onGizmoPositionUpdated);

        return true;
    }

    private onModelAdded() {
        this.viewer.impl.createOverlayScene(overlayName);

        const geometry = new THREE.SphereBufferGeometry(this.getPointRadius());

        const material = new THREE.MeshBasicMaterial({ color: "#DC143C" });

        this.centerPointMesh = new THREE.Mesh(geometry, material);

        this.viewer.impl.addOverlay(overlayName, this.centerPointMesh);

        const pointsMaterial = new THREE.MeshBasicMaterial({ color: "#00008B" });

        this.cornerPointMesh = new THREE.Mesh(geometry, pointsMaterial);
        this.rightWingPointMesh = new THREE.Mesh(geometry, pointsMaterial);

        this.extendMeasureSnapper();

        this.createBoundsGeometryCalculator();

        if (this.viewer.getExtension("Autodesk.Section"))
            this.createGizmo();

        if (this.isValidBounds())
            this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
    }

    private onExtensionLoaded(event: { extensionId: string }) {
        if (event.extensionId === "Autodesk.Section") {
            this.createGizmo();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        }

        if (event.extensionId === "Autodesk.Measure")
            this.extendMeasureSnapper();
    }

    private onViewToolChanged(event: { toolName: string; active: boolean }) {
        const { toolName, active } = event;

        if (toolName !== "measure" || this.gizmoTool === null)
            return;

        if (active)
            this.viewer.toolController.deactivateTool(this.gizmoTool.getName());
        else {
            this.viewer.toolController.activateTool(this.gizmoTool.getName());
            this.gizmoTool.updatePosition(this.getCustomModelInsertPoint().insertPoint, true);
        }
    }

    private onCustomComponentPropertiesChanged(event: IApplicationEvent<ICustomCornerType>) {
        this.customComponent = { ...event.payload };

        this.updateInsertPointPosition();
        this.createBoundsGeometryCalculator();

        const newBoundsValid = this.isValidBounds();

        if (newBoundsValid) {
            if (this.isBoundsActive())
                this.activateBounds();
            else
                this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        } else {
            if (this.isBoundsActive())
                this.deactivateBounds();

            this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
        }
    }

    private createBoundsGeometryCalculator() {
        const modelUnits = this.getModelUnits();

        this.boundsGeometryCalculator = new CornerGeometry({
            angle: THREE.Math.degToRad(this.customComponent.angle),
            leftWing: Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, this.customComponent.leftWingLength),
            rightWing: Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, this.customComponent.rightWingLength),
            height: Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, this.customComponent.height),
            thickness: Autodesk.Viewing.Private.convertUnits("mm", modelUnits, 1, boundsDepthMm),
            offsetDirection: "in",
            separateWingGeometries: false
        });
    }

    private onToggleBounds() {
        if (this.isBoundsActive())
            this.deactivateBounds();
        else if (this.isValidBounds())
            this.activateBounds();
    }

    private activateBounds() {
        if (!(this.boundsGeometryCalculator))
            return;

        this.clearBounds();

        const geometry = this.boundsGeometryCalculator.createGeometry()[0];

        if (!geometry)
            return;

        this.boundsMesh = new THREE.Mesh(geometry, this.boundsMaterial!);

        const modelUnits = this.getModelUnits();

        const leftWingLength = Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, this.customComponent.leftWingLength);

        const insertPoint = this.getCustomModelInsertPoint().insertPoint.add(new THREE.Vector3(leftWingLength, 0, 0));

        this.boundsMesh.applyMatrix(new THREE.Matrix4().makeRotationX(-0.5 * Math.PI).setPosition(insertPoint));

        this.viewer.impl.addOverlay(overlayName, this.boundsMesh);

        this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
    }

    private deactivateBounds() {
        this.clearBounds();
        this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
    }

    private clearBounds() {
        if (!this.boundsMesh)
            return;

        this.viewer.impl.removeOverlay(overlayName, this.boundsMesh);

        this.boundsMesh = null;
    }


    private isValidBounds(): boolean {
        return !!this.boundsGeometryCalculator?.isValid();
    }

    private isBoundsActive(): boolean {
        return !!this.boundsMesh;
    }

    private onGizmoPositionUpdated(event: IApplicationEvent<THREE.Vector3>) {
        const modelPoint = event.payload;

        this.customComponent.offset = this.getCustomComponentOffset(modelPoint);

        this.updateInsertPointPosition();

        if (this.isBoundsActive())
            this.activateBounds();

        if (this.customComponentViewerType === "3D") // dispatch ony once
            eventBus.dispatchEvent({
                type: "Dextall.CustomComponentLibrary.Corner.Editor.Changed",
                payload: this.customComponent
            });
    }

    private onToggleSection() {
        if (!this.sectionActive) {
            this.showSection();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
        } else {
            this.clearSection();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        }

        this.sectionActive = !this.sectionActive;
    }

    private extendMeasureSnapper(): boolean {
        if (!this.viewer.model)
            return false;

        type MeasureExtension = Autodesk.Viewing.Extension & { snapper: Autodesk.Viewing.Extensions.Snapping.Snapper };

        const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension;

        if (!measureExtension)
            return false;

        this.snapper = new ExtendedSnapper(measureExtension.snapper, this.viewer, this.getPointRadius());

        this.setSnapperPoints(this.getCustomModelInsertPoint());

        return true;
    }

    private createToolbar() {
        const controlGroup = this.viewer.getToolbar(false)?.getControl(Autodesk.Viewing.TOOLBAR.MODELTOOLSID) as Autodesk.Viewing.UI.ControlGroup;

        if (!controlGroup)
            return;

        if (this.customComponentViewerType !== "3D") {
            this.sectionToolButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-section-button");
            this.sectionToolButton.setToolTip("Section analysis");
            this.sectionToolButton.setIcon("adsk-icon-section-analysis");
            this.sectionToolButton.addEventListener("click", this.onToggleSection);

            if (!this.getSectionTool())
                this.sectionToolButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);

            controlGroup.addControl(this.sectionToolButton);
        }

        this.showBoundsButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-toggle-bounds-button");
        this.showBoundsButton.setToolTip("Show bounds");
        this.showBoundsButton.setIcon("adsk-icon-box");
        this.showBoundsButton.addEventListener("click", this.onToggleBounds);
        this.showBoundsButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);

        controlGroup.addControl(this.showBoundsButton);
    }

    private getCustomModelInsertPoint(): CornerPoints {
        const modelUnits = this.getModelUnits();

        const scale = Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, 1);

        const viewerOffset = this.getViewerOffset();

        const insertPoint = new THREE.Vector3().copy(this.customComponent.offset).multiplyScalar(scale).add(viewerOffset);

        if (isAlmostEqualToZero(this.customComponent.leftWingLength) || isAlmostEqualToZero(this.customComponent.rightWingLength))
            return { insertPoint, full: false };

        const cornerOffset = new THREE.Vector3().addVectors(this.customComponent.offset, new THREE.Vector3(this.customComponent.leftWingLength, 0, 0));

        const cornerPoint = new THREE.Vector3()
            .copy(cornerOffset)
            .multiplyScalar(scale)
            .add(viewerOffset);

        const rightWingPointMatrix = new THREE.Matrix4()
            .makeRotationY(Math.PI - THREE.Math.degToRad(this.customComponent.angle))
            .setPosition(cornerOffset);

        const rightWingPoint = new THREE.Vector3(this.customComponent.rightWingLength, 0, 0)
            .applyMatrix4(rightWingPointMatrix)
            .multiplyScalar(scale)
            .add(viewerOffset);

        return { insertPoint, cornerPoint, rightWingPoint, full: true };
    }

    private createGizmo() {
        if (this.gizmoTool || !this.centerPointMesh)
            return;

        const gizmoDirections = this.createGizmoDirections();

        const position = this.getCustomModelInsertPoint();

        this.gizmoTool = new CustomComponentInsertionPointGizmoTool(position.insertPoint, gizmoDirections, this.centerPointMesh);

        this.viewer.toolController.registerTool(this.gizmoTool);
        this.viewer.toolController.activateTool(this.gizmoTool.getName());

        this.updatePointsPositions(position);
    }

    private createGizmoDirections(): GizmoDirections {
        const navigator = this.customComponentViewerType !== "3D"
            ? CustomComponentViewerNavigatorFactory.createNavigator(this.viewer, this.customComponentViewerType)
            : null;

        const basisX: THREE.Vector3 = navigator?.getPlaneX() || this.viewer.autocam.getWorldRightVector();
        const basisY = navigator?.getPlaneY() || new THREE.Vector3().copy(this.viewer.autocam.getWorldUpVector()).negate();

        const directions: GizmoDirections = [basisX, basisY];

        if (this.customComponentViewerType === "3D")
            directions.push(new THREE.Vector3().copy(this.viewer.autocam.getWorldFrontVector()).negate());

        return directions;
    }

    private updateInsertPointPosition() {
        if (!this.centerPointMesh)
            return;

        const position = this.getCustomModelInsertPoint();

        this.setSnapperPoints(position);

        this.gizmoTool?.updatePosition(position.insertPoint);

        this.updatePointsPositions(position);

        if (this.sectionActive)
            this.showSection();

        this.resetMeasurements();
    }

    private resetMeasurements() {
        type MeasureExtension = Autodesk.Viewing.Extension & {
            deleteMeasurements(): void;

            measureTool: {
                setSessionMeasurements(measurements: []): void;
            }
        }

        const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension;

        if (measureExtension.isActive(""))
            measureExtension.deleteMeasurements();
        else
            measureExtension.measureTool.setSessionMeasurements([]);
    }

    private updatePointsPositions(points: CornerPoints) {
        if (!points.full) {
            this.viewer.impl.removeOverlay(overlayName, this.cornerPointMesh);
            this.viewer.impl.removeOverlay(overlayName, this.rightWingPointMesh);

            return;
        }

        this.cornerPointMesh?.position.copy(points.cornerPoint);
        this.rightWingPointMesh?.position.copy(points.rightWingPoint);

        this.viewer.impl.addOverlay(overlayName, this.cornerPointMesh);
        this.viewer.impl.addOverlay(overlayName, this.rightWingPointMesh);
    }

    private setSnapperPoints(points: CornerPoints) {
        const snapperPoints = [points.insertPoint];

        if (points.full) {
            snapperPoints.push(points.cornerPoint);
            snapperPoints.push(points.rightWingPoint);
        }

        this.snapper?.setPoints(snapperPoints)
    }

    private getCustomComponentOffset(modelPoint: THREE.Vector3) {
        const modelUnits = this.getModelUnits();

        const scale = Autodesk.Viewing.Private.convertUnits(modelUnits, "ft", 1, 1);

        const offset = new THREE.Vector3().copy(modelPoint).sub(this.getViewerOffset()).multiplyScalar(scale);

        offset.x = roundToDecimalSigns(offset.x, libraryLengthsToleranceDecimalSigns);
        offset.y = roundToDecimalSigns(offset.y, libraryLengthsToleranceDecimalSigns);
        offset.z = roundToDecimalSigns(offset.z, libraryLengthsToleranceDecimalSigns);

        return offset;
    }

    private getPointRadius() {
        const modelUnits = this.getModelUnits();

        return Autodesk.Viewing.Private.convertUnits("mm", modelUnits, 1, 10);
    }

    private getModelUnits() {
        return this.viewer.model.getDisplayUnit();
    }

    private getViewerOffset() {
        return new THREE.Vector3().copy(this.viewer.model.getGlobalOffset()).negate();
    }

    private showSection() {
        if (this.customComponentViewerType === "3D")
            return;

        const sectionTool = this.getSectionTool();

        const position = this.getCustomModelInsertPoint();

        const normal = CustomComponentViewerNavigatorFactory.createNavigator(this.viewer, this.customComponentViewerType).getPlaneNormal();

        sectionTool?.setSectionPlane(normal, position.insertPoint, false);

        sectionTool?.deactivate(true);
    }

    private clearSection() {
        const sectionTool = this.getSectionTool();

        sectionTool?.setSectionFromPlane(null);
    }

    private getSectionTool(): Autodesk.Viewing.Extensions.Section.SectionExtension | undefined {
        return this.viewer.getExtension("Autodesk.Section") as Autodesk.Viewing.Extensions.Section.SectionExtension | undefined;
    }
}

const overlayName = "dextall-custom-component-editor-overlay";
const boundsDepthMm = 154;

type InsertPoint = { insertPoint: THREE.Vector3; };

type CornerPoints = InsertPoint & { full: false } | InsertPoint & {
    full: true;
    cornerPoint: THREE.Vector3;
    rightWingPoint: THREE.Vector3
}