import { BasicItemResponse, BasicResponse } from "../../../responses/basicResponses";
import { ICustomPanelType } from "../../../responses/customPanelTypes";
import { IPanelSource } from "../../../responses/panelSource";
import { IPanelType } from "../../../responses/panelType";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { ModelBufferedGeometry } from "../geometry/modelBufferedGeometry";
import { TextGeometryFactory } from "../geometry/textGeometryFactory";
import { Panel } from "../panels/panel";
import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { PanelFamily } from "../panels/panelFamily";
import { PanelType } from "../panels/panelType";
import repo from "../../../Repository";

export class PanelsModelEditor {
    private readonly panelsTitlesFragments = new Map<number, number>();
    private readonly panelsCladdingCellsFragments = new Map<number, number[]>;

    constructor(private readonly panelFacadeDocument: PanelFacadeDocument,
        private readonly modelBufferedGeometry: ModelBufferedGeometry,
        private readonly textGeometryFactory: TextGeometryFactory) {
    }

    get modelId(): number {
        return this.modelBufferedGeometry.model.id;
    }

    get panels(): Panel[] {
        return this.panelFacadeDocument.panels;
    }

    get facadeModelId(): string {
        return this.panelFacadeDocument.modelId;
    }

    get panelTypes(): PanelType<IPanelSource>[] {
        return this.panelFacadeDocument.panelTypes;
    }

    createPanels() {
        for (const panel of this.panelFacadeDocument.panels) {
            this.addPanel(panel);
        }

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    findPanel(dbId: number): Panel | undefined {
        return this.panelFacadeDocument.findPanel(dbId);
    }

    findPanelById(id: string): Panel | undefined {
        return this.panelFacadeDocument.findPanelById(id);
    }

    findPanelFamily(panelTypeId: string): PanelFamily<IPanelSource> | undefined {
        return this.panelFacadeDocument.findPanelFamily(panelTypeId);
    }

    async update(panelId: string, elementName: string): Promise<BasicResponse> {
        const panel = this.findPanelById(panelId);

        if (!panel) {
            return { isSuccess: false, message: `Failed to find panel with id=${panelId}` };
        }

        const response = await repo.updatePanel(panelId, { elementName });

        if (response.isSuccess) {
            panel.update(elementName);
        }

        return response;
    }

    findPanelByUserId(userUniqueId: number): Panel | undefined {
        return this.panelFacadeDocument.findPanelByUserId(userUniqueId);
    }

    findPanelByTypeName(typeName: string): Panel | undefined {
        return this.panelFacadeDocument.findPanelByTypeName(typeName);
    }

    findPanelByCustomTypeName(typeName: string): Panel | undefined {
        return this.panelFacadeDocument.findPanelByCustomTypeName(typeName);
    }

    findPanelByElementName(elementName: string): Panel | undefined {
        return this.panelFacadeDocument.findPanelByElementName(elementName);
    }

    findValidCustomPanelTypesForPanel(panelId: string): ICustomPanelType[] {
        return this.panelFacadeDocument.findValidCustomPanelTypesForPanel(panelId);
    }

    findPanelCustomPanelType(panelId: string): ICustomPanelType | undefined {
        const panel = this.findPanelById(panelId);

        if (!panel?.customPanelTypeId) {
            return undefined;
        }

        return this.panelFacadeDocument.findCustomPanelType(panel.customPanelTypeId);
    }

    findPanelType(panelTypeId: string): PanelType<IPanelSource> | undefined {
        return this.panelFacadeDocument.findPanelType(panelTypeId);
    }

    updatePanelTypeTexts(panelId: string) {
        const panel = this.panelFacadeDocument.findPanelById(panelId);
        if (!panel) {
            throw new Error("Invalid state!");
        }

        const panelType = this.panelFacadeDocument.findPanelType(panel.panelTypeId);
        if (!panelType) {
            throw new Error("Invalid state!");
        }

        for (const panel of panelType.panels) {
            this.addPanelTypeText(panel);
            this.recreatePanelGeometry(panel);
        }

        this.modelBufferedGeometry.makeFixOnObjectRecreation();
    }

    loadPanelTypeModel(panel: Panel | undefined) {
        this.panelFacadeDocument.loadPanelTypeModel(panel);
    }

    async updatePanelType(panelTypeProperties: Omit<IPanelType, "hooks">): Promise<BasicResponse> {
        const panelType = this.panelFacadeDocument.findPanelType(panelTypeProperties.id);

        if (!panelType) {
            return { isSuccess: false, message: `Failed to find panel type with id=${panelTypeProperties.id}` };
        }

        const result = await repo.updatePanelType(panelType.id, {
            typeName: panelTypeProperties.name,
            withoutCladdings: panelTypeProperties.withoutCladdings,
        });

        if (result.isSuccess) {
            panelType.name = panelTypeProperties.name;
            panelType.withoutCladdings = panelTypeProperties.withoutCladdings;

            for (const panel of panelType.panels) {
                this.addPanelTypeText(panel);
                this.recreatePanelGeometry(panel);
            }

            this.modelBufferedGeometry.makeFixOnObjectRecreation();
        }

        return result;
    }

    async switchPanelType(panelId: string, targetTypeId: string): Promise<CreatePanelTypeOperationResult> {
        const switchPanelTypeResult = await repo.setPanelType(panelId, { targetTypeId });

        if (!switchPanelTypeResult.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: switchPanelTypeResult.message };
        }

        const removedHooksDbIds = this.panelFacadeDocument.setPanelType(panelId, targetTypeId);

        return { isSuccess: true, removedHooksDbIds, message: null };
    }

    async createPanelType(panelType: Omit<IPanelType, "hooks">,
        panelId: string): Promise<CreatePanelTypeOperationResult> {
        const { id: panelTypeId, name: typeName, withoutCladdings } = panelType;
        const family = this.panelFacadeDocument.findPanelFamily(panelTypeId);

        if (!family) {
            throw new Error("Invalid state!");
        }

        const panelTypeCreationResult = await repo.duplicatePanelType(family.id, {
            sourcePanelTypeId: panelTypeId,
            typeName,
            withoutCladdings,
        });

        if (!panelTypeCreationResult.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: panelTypeCreationResult.message };
        }

        const typeId = panelTypeCreationResult.item;

        this.panelFacadeDocument.addPanelType(typeId, panelTypeId, typeName, withoutCladdings);

        return await this.switchPanelType(panelId, typeId);
    }

    async startPanelTypeGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        const result = await repo.startPanelTypeModelGeneration(panelTypeId);

        if (result.isSuccess) {
            this.panelFacadeDocument.loadPanelTypeModel(panelTypeId);
        }

        return result;
    }

    async switchPanelCustomType(panelId: string,
        targetCustomPanelTypeId: string | null): Promise<CreatePanelTypeOperationResult> {
        const panel = this.panelFacadeDocument.findPanelById(panelId);

        if (!panel || panel.customPanelTypeId === targetCustomPanelTypeId) {
            return { isSuccess: true, removedHooksDbIds: [], message: null };
        }

        const result = targetCustomPanelTypeId !== null
            ? await repo.setPanelCustomType(panelId, { targetTypeId: targetCustomPanelTypeId })
            : await repo.resetPanelCustomType(panelId);

        if (!result.isSuccess) {
            return { isSuccess: false, removedHooksDbIds: [], message: result.message };
        }

        this.setPanelCustomType(panel, targetCustomPanelTypeId);

        const removedHooksDbIds = targetCustomPanelTypeId
            ? this.panelFacadeDocument.findPanelHooks(panel.id).map(x => x.dbId)
            : [];

        return { isSuccess: true, removedHooksDbIds, message: null };
    }

    private addPanel(panel: Panel) {
        const geometries = panel.createGeometry();

        const claddingCellsFragments: number[] = [];

        for (const claddingCellGeometry of geometries.claddingCellsGeometry) {
            const fragmentId = this.addGeometryFragment(claddingCellGeometry, panel.panelDbId);

            claddingCellsFragments.push(fragmentId);
        }

        this.panelsCladdingCellsFragments.set(panel.panelDbId, claddingCellsFragments);

        for (const windowGeometry of geometries.windowsGeometry) {
            this.addGeometryFragment(windowGeometry, panel.panelDbId);
        }

        this.addGeometryFragment(geometries.panelBoundaryGeometry, panel.panelDbId);

        this.addPanelTypeText(panel);
    }

    private setPanelCustomType(panel: Panel, targetCustomPanelTypeId: string | null) {
        panel.setCustomPanelType(targetCustomPanelTypeId);

        this.recreatePanelGeometry(panel);

        this.addPanelTypeText(panel);
    }

    private recreatePanelGeometry(panel: Panel) {
        const claddingCellsGeometries = panel.createGeometry().claddingCellsGeometry;

        const fragments = this.panelsCladdingCellsFragments.get(panel.panelDbId) || [];

        for (let i = 0; i < fragments.length; ++i) {
            this.modelBufferedGeometry.changeFragmentMaterial(fragments[i], claddingCellsGeometries[i].material);
        }
    }

    private addGeometryFragment(geometry: ExtrusionGeometry, dbId: number): number {
        const fragmentId = this.modelBufferedGeometry.add(geometry);

        this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, dbId);

        return fragmentId;
    }

    private addPanelTypeText(panel: Panel) {
        const typeText = this.getPanelTypeText(panel);

        const existingTextFragment = this.panelsTitlesFragments.get(panel.panelDbId);

        if (existingTextFragment) {
            this.modelBufferedGeometry.remove(existingTextFragment);

            this.panelsTitlesFragments.delete(panel.panelDbId);
        }

        if (typeText) {
            const matrix = panel.computePanelTypeTitleMatrix(this.textGeometryFactory.computeSize(typeText));

            const geometry = this.textGeometryFactory.createTextGeometry(typeText, matrix);

            const fragmentId = this.modelBufferedGeometry.add(geometry);

            this.modelBufferedGeometry.changeFragmentsDbId(fragmentId, panel.panelDbId);

            this.panelsTitlesFragments.set(panel.panelDbId, fragmentId);
        }
    }

    private getPanelTypeText(panel: Panel): string {
        if (panel.customPanelTypeId) {
            const customPanelType = this.panelFacadeDocument.findCustomPanelType(panel.customPanelTypeId);

            return customPanelType?.name || "";
        }

        const panelType = this.panelFacadeDocument.findPanelType(panel.panelTypeId);

        return panelType?.name || "";
    }
}

export type CreatePanelTypeOperationResult = BasicResponse & {
    removedHooksDbIds: number[];
};