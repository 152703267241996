import { useEffect, useState } from "react";
import { PanelTypeGenerationStatus } from "../../../../../responses/panelGeneratedModelDto";
import { RevitFacadeGeneratedModel } from "../../../../../responses/revitGeneratedModel";
import { ProcessingProgressBar } from "../../../../processing-pane/processingProgressBar";
import { RevitFacadeModelGenerationStage } from "../../../revit-facade-model/revitFacadeModelWorker";
import { progressBarStyle } from "./constants";
import { GeneratedFacadeModel } from "./generated-facade-revit-model/generatedFacadeModel";
import { GeneratedFacadeModelFailed } from "./generated-facade-revit-model/generatedFacadeModelFailed";
import { GenerateNewRevitFacadeModelControls }
    from "./generated-facade-revit-model/generateNewRevitFacadeModelControls";
import { GenerationStageFailure } from "./generated-facade-revit-model/generationStageFailure";
import eventBus, { IApplicationEvent } from "../../../eventBus/eventDispatcher";
import "../editor2DContent.css";
import "../revitModelContent.css";

export const GeneratedFacadeRevitModel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [model, setModel] = useState<RevitFacadeGeneratedModel | null>(null);
    const [generationStage, setGenerationStage] = useState<GenerationStage>("panel types");

    useEffect(() => {
        const onLoaded = (event: IApplicationEvent<RevitFacadeGeneratedModel | null>) => {
            setIsLoading(false);
            setModel(event.payload);
        };

        const onGenerationStageChanged = (event: IApplicationEvent<RevitFacadeModelGenerationStage>) => {
            switch (event.payload) {
                case "panel-types":
                    setGenerationStage("panel types");
                    break;

                case "revit-families":
                    setGenerationStage("Revit families");
                    break;

                case "revit-model":
                    setGenerationStage("Revit facade model");
                    break;
            }
        };

        eventBus.addEventListener("Dextall.RevitFacadeModel.Loaded", onLoaded);
        eventBus.addEventListener("Dextall.RevitFacadeModel.GenerationStage", onGenerationStageChanged);

        return () => {
            eventBus.removeEventListener("Dextall.RevitFacadeModel.Loaded", onLoaded);
            eventBus.removeEventListener("Dextall.RevitFacadeModel.GenerationStage", onGenerationStageChanged);
        };
    }, []);

    return <div className="generated-revit-model-container">
        <h4 className="generated-revit-model-title-container">Facade model</h4>
        {isLoading && <ProcessingProgressBar style={progressBarStyle} />}
        {!isLoading &&
            <div style={{ display: "flex" }}>
                {model?.status === PanelTypeGenerationStatus.ModelGenerationInProgress &&
                    <ProcessingProgressBar
                        style={progressBarStyle}
                        tooltip={{
                            message: `generating ${generationStage}`,
                            style: { top: 10 },
                        }} />}

                {(!model || model.status === PanelTypeGenerationStatus.None) &&
                    <GenerateNewRevitFacadeModelControls />}

                {model?.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully &&
                    <GeneratedFacadeModel model={model} />}

                {model?.status === PanelTypeGenerationStatus.ModelGenerationFailed &&
                    <GeneratedFacadeModelFailed />}
            </div>}

        <GenerationStageFailure />
    </div>;
};

type GenerationStage = "panel types" | "Revit families" | "Revit facade model";