import { useState } from "react";
import Button from "@weave-design/button";
import Modal from "@weave-design/modal";
import { BooleanPropertyEditor } from "../../../editor-inputs/booleanPropertyEditor";
import { EditorPanelType } from "../../../editor-inputs/editorPanelType";
import { TextPropertyEditor } from "../../../editor-inputs/textPropertyEditor";
import "./panelTypeEditor.css";

type Props = {
    title: string;
    panelType: EditorPanelType;
    onConfirm: (result: EditedType) => void;
    onCancel: () => void;
};

export type EditedType = Omit<EditorPanelType, "id">;

export const PanelTypeEditor = ({
    title,
    panelType: { name: typeName, withoutCladdings },
    onConfirm,
    onCancel }: Props) => {
    const [localTypeName, setLocalTypeName] = useState<string>(typeName);
    const [localWithoutCladdings, setLocalWithoutCladdings] = useState<boolean>(withoutCladdings);

    const confirmChanges = () => onConfirm({
        name: localTypeName,
        withoutCladdings: localWithoutCladdings,
    });

    return <Modal
        open={true}
        title={title}
        onCloseClick={onCancel}
        stylesheet={modalStyles}>
        <>
            <div className="panel-type-editor-dialog-properties">
                <TextPropertyEditor
                    title="Type name:" value={localTypeName}
                    onChange={newName => setLocalTypeName(newName)} />

                <BooleanPropertyEditor
                    title="Without claddings:"
                    value={localWithoutCladdings}
                    onChange={setLocalWithoutCladdings}
                />
            </div>
            <div style={{ position: "absolute", right: 24, bottom: 30, display: "flex", flexFlow: "row" }}>
                <Button size="standard" type="secondary" title="Cancel" onClick={onCancel} />
                <div style={{ width: "14px" }} />
                <Button size="standard" type="primary" title="Confirm" onClick={confirmChanges} />
            </div>
        </>
    </Modal>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modalStyles = (styles: any) => ({
    ...styles,
    modal: {
        ...styles.modal,
        window: {
            ...styles.modal.window,
            width: "400px",
            height: "240px",
        },
    },
});